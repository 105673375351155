import useProjectId from "../../../../customHooks/useProjectId";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useAccessToken } from "../../../useAccessToken";
import { useCallback } from "react";
import { makeResultFetch } from "../../../genericFetch";
import { apiRequestParams } from "../../../fetchOptions";
import { useHandleRetry } from "../../../useHandleRetry";
import { ProfitOnContract } from "../../../../interfaces/reports/ProfitOnContract";


const useFetch = () => {
    const getAccessToken = useAccessToken();
    const projectId = useProjectId();


    const queryFunction = async () => {
        return getAccessToken().then((accessToken) => {
            if (!accessToken) throw new Error("Access token is undefined");

            const fetchedData = makeResultFetch<ProfitOnContract>(
                `/Reports/${projectId}/ProfitOnContract`,
                apiRequestParams(accessToken, 'GET')
            ).then((response) => {
                const { data, message, success } = response;

                if (!success) throw Error(message);

                return data;
            });

            return fetchedData;
        });
    };

    return { projectId, queryFunction };
}


export const usePrefetchProfitOnContractReport = () => {
    const { projectId, queryFunction } = useFetch();
    const queryClient = useQueryClient();

    const prefetchQuery = useCallback(() => {
        queryClient.prefetchQuery({
            queryKey: [projectId, 'profitOnContract'],
            queryFn: queryFunction,
        });
    }, [projectId, queryClient, queryFunction])

    return prefetchQuery;
}


export const useProfitOnContract = () => {
    const { projectId, queryFunction } = useFetch();
    const handleRetry = useHandleRetry();

    const { isLoading, error, data } = useQuery({
        queryKey: [projectId, 'profitOnContract'],
        queryFn: queryFunction,
        enabled: !!projectId && !!queryFunction,
        retry: handleRetry,
    });

    return { isLoading, error, data };
}