import { UseQueryResult } from "@tanstack/react-query";
import { useEffect, useState } from "react";

export const useQueriesProgress = <T,>(
    queries: UseQueryResult<T | undefined, unknown>[]
) => {
    const [progress, setProgress] = useState<number>(0);

    useEffect(() => {
        if (queries?.length > 0) {
            const nrOfQueries = queries.map(response => response)?.length;

            const fetchedQueries = queries.map(response => response?.isFetching)
                .filter((status: boolean) => status === false)?.length;

            const fetchProgress = fetchedQueries / nrOfQueries;

            setProgress(fetchProgress);
        }
    }, [progress, queries]);

    return progress;
}