import { useQuery } from "@tanstack/react-query";
import { useCallback } from "react";
import { useHandleRetry } from "../../useHandleRetry";
import { useAccessToken } from "../../useAccessToken";
import { makeResultFetch } from "../../genericFetch";
import { apiRequestParams } from "../../fetchOptions";
import { CheckUser } from "../../../interfaces/UserPermission";

interface CheckUserParams {
    handleCheckUser: boolean;
    email: string;
    projectId?: string;
}

const useFetch = ({
    email,
    projectId
}: CheckUserParams) => {
    const getAccessToken = useAccessToken();

    const queryFunction = useCallback(async () => {
        return getAccessToken().then(accessToken => {
            if (!accessToken) throw new Error("Access token is undefined");

            const endpointUrl = projectId
                ? `/User/${email}/project/${projectId}`
                : `/User/${email}`;

            const fetchedData = makeResultFetch<CheckUser>(
                endpointUrl,
                apiRequestParams(accessToken, 'GET')
            ).then((response) => {
                const { data, message, success } = response;

                if (!success) throw Error(message);

                return data;
            });

            return fetchedData;
        });
    }, [email, getAccessToken, projectId]);

    return queryFunction;
}



export const useCheckUser = ({
    handleCheckUser,
    email,
    projectId
}: CheckUserParams) => {

    const queryFunction = useFetch({
        handleCheckUser,
        email,
        projectId
    });

    const queryEnabled = !!queryFunction && handleCheckUser && email?.length > 0;
    const handleRetry = useHandleRetry();

    const { isLoading, error, data } = useQuery({
        queryKey: ['checkUser', email, projectId],
        queryFn: queryFunction,
        enabled: queryEnabled,
        retry: handleRetry,
    });

    return { isLoading, error, data };
}
