export interface FetchResponse<T> {
    message: string,
    success: boolean,
    data?: T
}


const getDefaultErrorMessage = (status: number) => {
    switch (status) {
        case 400:
            return 'Felaktig begäran';
        case 401:
            return 'Obehörig begäran';
        case 403:
            return 'Förbjuden begäran';
        case 404:
            return 'Begärd resurs hittades inte';
        case 422:
            return 'På grund av ett semantiskt fel kunde begäran inte behandlas';
        case 423:
            return 'Resursen är låst';
        case 500:
            return 'Internt serverfel';
        case 503:
            return 'Tjänsten är inte tillgänglig';
        default:
            return 'Ett oväntat fel inträffade';
    }
}


const generateErrorResultDto = <T>(response: Response) => {

    let resultDto = {
        message: '',
        success: false,
        data: null
    } as FetchResponse<T>;


    const errorResultDto = response.json().then((result: FetchResponse<T>) => {
        const errorMessage = result?.message;

        resultDto.message = errorMessage
            ? errorMessage
            : `${getDefaultErrorMessage(response.status)} (${response.status} - ${response.url})`;

        return resultDto;
    }).catch(() => {
        resultDto.message = `${getDefaultErrorMessage(response.status)} (${response.status} - ${response.url})`;

        return resultDto;
    });


    return errorResultDto;
}


export const makeResultFetch = async <T>(
    endpointUrl: URL | RequestInfo,
    fetchOptions?: RequestInit | undefined,
) => {
    const apiUrl = process.env.REACT_APP_API_URL!;
    const url = apiUrl + endpointUrl;

    return await fetch(url, fetchOptions).then<FetchResponse<T>>(async response => {
        if (!response.ok) {
            const errorResultDto = generateErrorResultDto<T>(response);

            return errorResultDto;
        } else {
            return response.json();
        }
    });
}