import { useEffect, useState } from 'react';
import Split from 'react-split';
import styles from './ProjectEconomy.module.css';
import CalculationsBar from '../CalculationsBar/CalculationsBar';
import CashflowGrid from '../MainGrids/CashflowGrid/CashflowGrid';
import { StartEndMonth } from '../../interfaces/project/StartEndMonth';
import ProjectEconomyHeader from '../Headers/ProjectEconomyHeader';
import AccountSpecification from '../AccountSpecification/AccountSpecification';
import { useEditingContext } from '../../context/EditingContext';
import { useInteractionContext } from '../../context/InteractionContext';
import { useUser } from '../../fetchData/queries/useUser';
import { usePrefetchSnapshots } from '../../fetchData/queries/snapshot/useSnapshots';
import { usePrefetchInternalCredits } from '../../fetchData/queries/internalCredits/useInternalCredits';
import { usePrefetchFinalAccountReport } from '../../fetchData/queries/reports/pdf/useFinalAccountReport';
import PerformanceGrid from '../MainGrids/PerformanceGrid/PerformanceGrid';
import { NotificationBars } from '../SystemState/ErrorManagement/MessageBars/NotificationBars';
import { usePrefetchResultCalculationReport } from '../../fetchData/queries/reports/pdf/useResultCalculationReport';
import { usePrefetchProfitOnContractReport } from '../../fetchData/queries/reports/grid/useProfitOnContract';


const ProjectEconomy = () => {

  const {
    edits,
    setEditingEnabled,
    setReadOnlyMode,
  } = useEditingContext();

  const prefetchResultCalculationReport = usePrefetchResultCalculationReport();
  const prefetchFinalAccountReport = usePrefetchFinalAccountReport();
  const prefetchProfitOnContractReport = usePrefetchProfitOnContractReport();
  const prefetchSnapshots = usePrefetchSnapshots();
  const prefetchInternalCredits = usePrefetchInternalCredits();

  const user = useUser();

  const { loadProjectEconomy } = useInteractionContext();

  const [isAccountSpecificationModalOpen, setIsAccountSpecificationModalOpen] = useState<boolean>(false);
  const [modalAccountId, setModalAccountId] = useState<string>('');
  const [modalOutcomeDate, setModalOutcomeDate] = useState<string>('');


  useEffect(() => {
    if (loadProjectEconomy) {
      prefetchResultCalculationReport();
      prefetchFinalAccountReport();
      prefetchProfitOnContractReport();
      prefetchSnapshots();
      prefetchInternalCredits();

      const userHasWritePermission = user.data?.scopes['sheet_write'] ?? false;
      setEditingEnabled(userHasWritePermission); // used to control write permission
      setReadOnlyMode(!userHasWritePermission); // used for enabling/disabling write on user interaction
    }
  }, [loadProjectEconomy, prefetchFinalAccountReport, prefetchInternalCredits, prefetchProfitOnContractReport, prefetchResultCalculationReport,
    prefetchSnapshots, setEditingEnabled, setReadOnlyMode, user.data?.scopes]);


  return (
    <>
      <div className={styles.projectEconomyContainer} data-testid="ProjectEconomy">
        <ProjectEconomyHeader />

        <NotificationBars />

        <Split
          className="split-vertical"
          sizes={[70, 30]}
          minSize={0}
          expandToMin={false}
          gutterSize={5}
          gutterAlign="center"
          snapOffset={0}
          dragInterval={1}
          direction="vertical"
        >
          <CashflowGrid
            setIsAccountSpecificationModalOpen={setIsAccountSpecificationModalOpen}
            modalAccountId={modalAccountId}
            setModalAccountId={setModalAccountId}
            modalOutcomeDate={modalOutcomeDate}
            setModalOutcomeDate={setModalOutcomeDate}
          />

          <Split
            className="split-horizontal"
            sizes={[50, 50]}
            minSize={0}
            expandToMin={false}
            gutterSize={5}
            gutterAlign="center"
            snapOffset={0}
            dragInterval={1}
            direction="horizontal"
          >
            <PerformanceGrid
              setIsAccountSpecificationModalOpen={setIsAccountSpecificationModalOpen}
              modalAccountId={modalAccountId}
              setModalAccountId={setModalAccountId}
              modalOutcomeDate={modalOutcomeDate}
              setModalOutcomeDate={setModalOutcomeDate}
            />

            <CalculationsBar />
          </Split>
        </Split>
      </div>

      <AccountSpecification
        modalAccountId={modalAccountId}
        modalOutcomeDate={modalOutcomeDate}
        startEndMonth={{
          startMonth: edits.projectAttributes.startMonth ?? '',
          endMonth: edits.projectAttributes.endMonth ?? ''
        } as StartEndMonth}
        isAccountSpecificationModalOpen={isAccountSpecificationModalOpen}
        setIsAccountSpecificationModalOpen={setIsAccountSpecificationModalOpen}
      />
    </>
  )
};

export default ProjectEconomy;
