import styles from '../ResultCalculationLog.module.css';
import { AgGridReact } from "ag-grid-react";
import { RefObject, useEffect, useMemo, useState } from "react";
import { useRevenueRecognitionLogDetails } from "../../../../fetchData/queries/accounting/useRevenueRecognitionLogDetails";
import { ValueFormatterParams } from "ag-grid-community";
import { localizeDateToString } from "../../../../utils/localizedDateStrings";
import { LogStatusCellStyle } from "../LogStatusCellsStyle";
import { defaultColDef } from '../../../MainGrids/gridConfig/DefaultColDef';
import { AG_GRID_LOCALE_SV } from '../../../MainGrids/gridConfig/SwedishLocale';
import LoadingSpinner from '../../../StatusIndicators/LoadingSpinner';
import { RevenueRecognitionLogDetail } from '../../../../interfaces/RevenueRecognitionLog';


interface ResCalcLogDetailsGridProps {
    logDetailsGridRef: RefObject<AgGridReact>;
    taskId: number | null;
}


const ResCalcLogDetailsGrid = ({
    logDetailsGridRef,
    taskId
}: ResCalcLogDetailsGridProps) => {

    const [logDetails, setLogDetails] = useState<RevenueRecognitionLogDetail[] | undefined>(undefined);

    const resultCalculationLogDetails = useRevenueRecognitionLogDetails(taskId);

    useEffect(() => {
        if (resultCalculationLogDetails.data) {
            setLogDetails(resultCalculationLogDetails.data);
        }
    }, [resultCalculationLogDetails.data]);


    const logDetailsColDefs: object[] = useMemo(() => {
        return [
            {
                field: 'timeStamp',
                headerName: 'Startad',
                sort: 'desc',
                valueFormatter: (params: ValueFormatterParams) => localizeDateToString(params.value),
                flex: 2
            },
            {
                field: 'description',
                headerName: 'Beskrivning',
                flex: 6,
            },
            {
                field: 'status',
                headerName: 'Status',
                cellStyle: LogStatusCellStyle
            },
        ]
    }, []);



    return (
        <div className={`ag-theme-balham ${styles.resCalcLogGrid}`}>
            <AgGridReact
                ref={logDetailsGridRef}
                rowData={logDetails}
                columnDefs={logDetailsColDefs}
                defaultColDef={defaultColDef}
                localeText={AG_GRID_LOCALE_SV}
                loadingOverlayComponent={LoadingSpinner}
                rowSelection={'single'}
                suppressContextMenu={true}
            />
        </div>
    )
}

export default ResCalcLogDetailsGrid;