import { CSSProperties } from 'react';
import { ICellRendererParams } from 'ag-grid-community';


const totalPrognosisTextColor = (params: ICellRendererParams) => {
  let totalOutcome: number | null = null;
  let totalPrognosis: number | null = null;

  const node = params.node;
  const nodeData = node?.data;

  if (nodeData) {
    const outcome = nodeData?.totalOutcome;
    const prognosis = nodeData?.totalPrognosis;

    if (outcome && prognosis) {
      totalOutcome = Math.round(outcome);
      totalPrognosis = Math.round(prognosis);
    }

  } else {
    const aggData = node?.aggData;

    if (aggData) {
      totalOutcome = Math.round(aggData?.totalOutcome);
      totalPrognosis = Math.round(aggData?.totalPrognosis);
    }
  }


  if (totalOutcome && totalPrognosis && totalOutcome > totalPrognosis) {
    return 'red';
  }
}


export const TotalPrognosisCellRenderer = (params: ICellRendererParams) => {
  let cellStyle: CSSProperties = { color: '' };
  cellStyle.color = totalPrognosisTextColor(params);

  const totalPrognosisElement = <span style={cellStyle}>{params?.valueFormatted}</span>;

  return totalPrognosisElement;
};