import { useMemo } from "react";
import { FullDateInput } from "../CalculationBarInputs/DateInput/FullDateInput";
import { NonEditablePercentInput } from "../CalculationBarInputs/PercentInput/NonEditablePercentInput";
import styles from '../CalculationsBar.module.css';
import { BaseCalculations } from "../../../interfaces/baseCalculation/BaseCalculations";
import { CalcBarParams } from "./interfaces/CalcBarParams";


interface SaleToBrfParams extends CalcBarParams {
    baseCalculations: BaseCalculations;
}


export const useSaleToBrf = ({
    projectAttributes,
    setProjectAttributes,
    initialValues,
    setChangedValue,
    translation,
    readOnlyMode,
    baseCalculations
}: SaleToBrfParams) => {
    const saleToBrf = useMemo(() => {
        return (<div className={styles.fieldsContainer}>
            <h5 className={styles.fieldsHeader}> Försäljning till BRF</h5>

            <FullDateInput
                values={projectAttributes}
                initialValues={initialValues}
                setValues={setProjectAttributes}
                setChangedValue={setChangedValue}
                label={translation.saleOfLandPlanned}
                attributeName={'saleOfLandPlanned'}
                disabled={readOnlyMode}
            />

            <FullDateInput
                values={projectAttributes}
                initialValues={initialValues}
                setValues={setProjectAttributes}
                setChangedValue={setChangedValue}
                label={translation.saleOfLand}
                attributeName={'saleOfLand'}
                disabled
            />

            <NonEditablePercentInput
                value={baseCalculations?.projectedCostsSubjectToVat}
                label={translation.projectedCostsSubjectToVat}
            />
        </div>)
    }, [projectAttributes, initialValues, setProjectAttributes, setChangedValue, translation.saleOfLandPlanned, translation.saleOfLand,
        translation.projectedCostsSubjectToVat, readOnlyMode, baseCalculations?.projectedCostsSubjectToVat]);


    return saleToBrf;
}