import { TooltipHost } from "@fluentui/react";
import { GroupCellRendererParams } from "ag-grid-enterprise";
import { CSSProperties } from "react";

const TotalTextCellRenderer = ({
  value,
  data,
  innerRendererParams
}: GroupCellRendererParams) => {

  const cellNotice: string = data?.notice ?? innerRendererParams?.[value] ?? '';
  const cellVatType: number = data?.vatType ?? null;
  let style: CSSProperties = { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', padding: 0 };

  if (cellNotice) {
    style.fontStyle = 'italic';
    style.background = 'linear-gradient(to top right,#0000 50%,red 50.1%) top right/5px 5px no-repeat';
  }
  if (cellVatType === 0) {
    style.color = 'green';
  }

  return (
    <>
      <TooltipHost
        content={`${value} ${cellNotice && `- ${cellNotice}`}`}
        id={'cellNotice'}>
        <div style={style}>{value}</div>
      </TooltipHost>
    </>
  );
};

export default TotalTextCellRenderer;