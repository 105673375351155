import { IContextualMenuItem } from "@fluentui/react";
import { useCallback, useMemo } from "react";
import useProjectId from "../../../../customHooks/useProjectId";
import { useUser } from "../../../../fetchData/queries/useUser";
import { menuItemStyle } from "./menuItemStyle";

export const useReportsMenu = () => {
    const projectId = useProjectId();

    const { data } = useUser();
    const scopes = data?.scopes;

    const navigateToReport = useCallback((reportRoute: string) => {
        const newTabUrl: string = `${window.location.origin}/rapport/${reportRoute}`;
        window.open(newTabUrl, "_blank", "noreferrer");
    }, []);


    const menuItems = useMemo(() => {
        const items: IContextualMenuItem[] = [];

        if (scopes?.['report_read']) {
            items.push(
                {
                    key: 'resCalc',
                    text: 'Resultatavräkning',
                    onClick: () => navigateToReport(`resultatavrakning/${projectId}`),
                    style: menuItemStyle,
                },
                {
                    key: 'finalAccount',
                    text: 'Slutredovisning',
                    onClick: () => navigateToReport(`slutredovisning/${projectId}`),
                    style: menuItemStyle
                },
                {
                    key: 'profitOnContract',
                    text: 'Entreprenadvinst',
                    onClick: () => navigateToReport(`entreprenadVinst/${projectId}`),
                    style: menuItemStyle
                },
            )
        }


        if (scopes?.['accounting_read']) {
            items.push(
                {
                    key: 'resCalcProj',
                    text: 'Resultatavräknade projekt',
                    onClick: () => navigateToReport('resultatavraknadeProjekt'),
                    style: menuItemStyle
                },
                {
                    key: 'resCalcForecast',
                    text: 'Prognos resultatavräkning',
                    onClick: () => navigateToReport('prognosResultatavrakning'),
                    style: menuItemStyle
                },
            )
        }

        return items;
    }, [navigateToReport, projectId, scopes]);


    return menuItems;
}

