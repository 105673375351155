import styles from './ResultCalculationLog.module.css';
import { Dispatch, SetStateAction, useRef, useState } from 'react';
import Split from 'react-split';
import { AgGridReact } from 'ag-grid-react';
import ResCalcLog from './ResCalcLog/ResCalcLog';
import ResCalcLogDetails from './ResCalcLogDetails/ResCalcLogDetails';


interface ResultCalculationLogsProps {
  selectNrOfRows: number;
  setSelectNrOfRows: Dispatch<SetStateAction<number>>;
}

const ResultCalculationLogs = ({
  selectNrOfRows,
  setSelectNrOfRows
}: ResultCalculationLogsProps) => {

  const [taskId, setTaskId] = useState<number | null>(null);
  const logGridRef = useRef<AgGridReact>(null);
  const logDetailsGridRef = useRef<AgGridReact>(null);



  const mainLog = () => (
    <div className={`${styles.resCalcLog} ${styles.resCalcLogWidth}`}>
      <ResCalcLog
        logGridRef={logGridRef}
        setTaskId={setTaskId}
        selectNrOfRows={selectNrOfRows}
        setSelectNrOfRows={setSelectNrOfRows}
      />
    </div>
  )


  const logDetails = () => {
    if (taskId) {
      return (
        <div className={`${styles.resCalcLog} ${styles.resCalcLogDetailsWidth}`}>
          <ResCalcLogDetails
            logDetailsGridRef={logDetailsGridRef}
            taskId={taskId}
          />
        </div>
      );
    }

    return <div></div>; // needed for split
  }



  return (
    <div className={styles.resCalcLogsContainer}>
      <Split
        className="split-horizontal"
        sizes={[40, 60]}
        minSize={300}
        expandToMin={false}
        gutterSize={5}
        gutterAlign="center"
        snapOffset={0}
        dragInterval={1}
        direction="horizontal"
      >
        {mainLog()}

        {logDetails()}
      </Split>
    </div>
  )
}

export default ResultCalculationLogs;
