import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAccessToken } from "../../useAccessToken";
import { useCallback, } from "react";
import { FetchResponse, makeResultFetch } from "../../genericFetch";
import { apiRequestParams } from "../../fetchOptions";
import { useHandleError } from "../../useHandleError";
import useProjectId from "../../../customHooks/useProjectId";


interface DeleteInternalCreditParams {
    internalCreditId: string;
}

export const useDeleteInternalCredit = () => {
    const getAccessToken = useAccessToken();
    const handleError = useHandleError();
    const projectId = useProjectId();
    const queryClient = useQueryClient();


    const mutationFunction = useCallback(async ({
        internalCreditId
    }: DeleteInternalCreditParams) => {
        return getAccessToken().then(accessToken => {
            if (!accessToken) throw new Error("Access token is undefined");

            const fetchedData = makeResultFetch<boolean>(
                `/Projects/${projectId}/InternalCredits/${internalCreditId}`,
                apiRequestParams(accessToken, 'DELETE')
            ).then((response: FetchResponse<boolean>) => {
                const { message, success } = response;

                if (!success) throw Error(message);

                return response;
            });

            return fetchedData;
        });
    }, [getAccessToken, projectId]);


    const handleSuccess = (response: FetchResponse<boolean>) => {
        if (response.success) {
            queryClient.invalidateQueries([projectId, 'internalCredits'])
        };
    }


    const deleteFinalAccounting = useMutation({
        mutationFn: mutationFunction,
        onSuccess: handleSuccess,
        onError: handleError,
    });


    return deleteFinalAccounting;
}