import { ICellRendererParams } from 'ag-grid-community';
import { formatNumber } from './../../../utils/formatNumber';
import { AggTranslations } from './../../../interfaces/grids/performance/AggTranslation';
import { getAggValue } from './../../../utils/getAggValue';
import { convertNumberToWords } from '../../../utils/convertNumberToWords';
import { useCallback } from 'react';

const TotalAggCellRenderer = (params: ICellRendererParams, easterEggMode?: boolean) => {
  const aggTranslations: AggTranslations = params.colDef?.cellRendererParams.aggTranslations;
  const accountKey: string = params.node?.key ?? "";
  const columnId: string = params.column?.getColId() ?? "";

  const formatAggValue = useCallback((aggValue: number) => {
    const formattedAggValue = easterEggMode === true
      ? convertNumberToWords(aggValue)
      : formatNumber(aggValue);

    return <span>{formattedAggValue}</span>;
  }, [easterEggMode])

  if (aggTranslations && accountKey && columnId) {
    const aggValue: number | null = getAggValue(aggTranslations, accountKey, columnId);

    if (aggValue) {
      return formatAggValue(aggValue);
    }
  }
  return <span>{params.valueFormatted}</span>;
};

export default TotalAggCellRenderer;