import SystemStatus from "../SystemState";

// used if nothing can be loaded on the page
export const SystemError = ({ errors }: { errors: unknown[] }) => (
    <SystemStatus>
        <p>Något fel har inträffat</p>
        {errors?.map((error, index) => {
            if (error instanceof Error) {
                return <p key={index}>{error?.message}</p>;
            }
            return null;
        })}
    </SystemStatus>
);