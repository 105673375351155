import { SignInButton } from "../../Buttons/SignInButton";
import SystemStatus from "../SystemState";

export const UserNotAuthenticated = () => (
    <SystemStatus>
        <p>Du är ej inloggad i Projektekonomi</p>
        <SignInButton />
    </SystemStatus>
);

