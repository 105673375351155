import { useQuery } from "@tanstack/react-query";
import { useAccessToken } from "../../useAccessToken";
import { makeResultFetch } from "../../genericFetch";
import { apiRequestParams } from "../../fetchOptions";
import { useHandleRetry } from "../../useHandleRetry";
import { RevenueRecognitionLogDetail } from "../../../interfaces/RevenueRecognitionLog";


const useFetch = (taskId: number | null) => {
    const getAccessToken = useAccessToken();

    const queryFunction = async () => {
        return getAccessToken().then((accessToken) => {
            if (!accessToken) throw new Error("Access token is undefined");

            const fetchedData = makeResultFetch<RevenueRecognitionLogDetail[]>(
                `/Financials/RevenueRecognition/Task/${taskId}/Details`,
                apiRequestParams(accessToken, 'GET')
            ).then((response) => {
                const { data, message, success } = response;

                if (!success) throw Error(message);

                return data;
            });

            return fetchedData;
        });
    };

    return queryFunction;
}


export const useRevenueRecognitionLogDetails = (taskId: number | null) => {
    const queryFunction = useFetch(taskId);
    const handleRetry = useHandleRetry();


    const { isLoading, error, data } = useQuery({
        queryKey: ['revenueRecognitionLogDetails', taskId],
        queryFn: queryFunction,
        enabled: !!queryFunction && !!taskId,
        retry: handleRetry,
    });

    return { isLoading, error, data };
}