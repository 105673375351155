import { DefaultButton, PrimaryButton } from '@fluentui/react';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styles from './Confirmation.module.css';
import LoadingSpinner from '../../../../StatusIndicators/LoadingSpinner';
import { useSaveProjectAttributes } from '../../../../../fetchData/mutations/useSaveProjectAttributes';
import { useSaveSheets } from '../../../../../fetchData/mutations/useSaveSheets';
import { useEditingContext } from '../../../../../context/EditingContext';
import { useInteractionContext } from '../../../../../context/InteractionContext';
import SaveEditsPreview from '../../../../SaveProjectEconomy/SaveEditsPreview/SaveEditsPreview';
import { modalTimeout } from '../../../../../utils/modalTimeout';

interface ConfirmSaveProps {
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  setSaveIsLoading: Dispatch<SetStateAction<boolean>>;
}

const ConfirmSave = ({
  setIsModalOpen,
  setSaveIsLoading
}: ConfirmSaveProps) => {

  const { saveProjectAttributes, sheetsMutation } = useSaveProjectAttributes();
  const saveSheets = useSaveSheets();

  const { edits } = useEditingContext();
  const { setLoadProjectEconomy } = useInteractionContext();

  const [showEdits, setShowEdits] = useState<boolean>(false);
  const [saveConfirmed, setSaveConfirmed] = useState(false);


  useEffect(() => {
    if (saveConfirmed && !(saveSheets.isLoading && saveProjectAttributes.isLoading && sheetsMutation.isLoading)) {

      if (Object.keys(edits.projectAttributes).length > 0) {
        saveProjectAttributes.mutate();
      } else {
        saveSheets.mutate();
      }
      setSaveConfirmed(false);
    }
  }, [edits.projectAttributes, saveConfirmed, saveProjectAttributes, saveSheets, sheetsMutation.isLoading]);


  const confirmSave = () => {
    if (saveSheets.isIdle && saveProjectAttributes.isIdle && sheetsMutation.isIdle) {
      return (<>
        <p>Är du säker på att du vill spara projektekonomin?</p>

        <div className={styles.buttonContainer}>
          <div className={styles.buttonMargin}>
            <PrimaryButton
              onClick={() => setSaveConfirmed(true)}
            >Ja</PrimaryButton>
          </div>

          <div className={styles.buttonMargin}>
            <DefaultButton
              onClick={() => setIsModalOpen(false)}
            >Avbryt</DefaultButton>
          </div>

          <div className={styles.buttonMargin}>
            <DefaultButton onClick={() => setShowEdits(!showEdits)}>{showEdits ? 'Dölj' : 'Visa'} ändringar</DefaultButton>
          </div>
        </div>

        <SaveEditsPreview showEdits={showEdits} />
      </>);
    }
  }

  const saveLoading = () => {
    if (saveSheets.isLoading || saveProjectAttributes.isLoading || sheetsMutation.isLoading) {
      setSaveIsLoading(true);

      return (
        <div className={`${styles.messageContainer} ${styles.spinnerContainer}`}>
          <LoadingSpinner text={'Projektekonomin sparas...'} />
        </div>
      );

    } else {
      setSaveIsLoading(false);
    }
  };

  const saveResult = () => {
    if (saveSheets.data || (saveProjectAttributes.data && (sheetsMutation.data || sheetsMutation.isIdle))) {
      modalTimeout(setIsModalOpen);

      // refresh cache on save
      setLoadProjectEconomy(true);

      return (
        <div className={`${styles.messageContainer} ${styles.spinnerContainer}`}>
          {saveSheets.data?.message && <p>{saveSheets.data.message}</p>}
          {sheetsMutation.data?.message && <p>{sheetsMutation.data.message}</p>}
          {saveProjectAttributes.data?.message && <p>{saveProjectAttributes.data.message}</p>}
        </div>
      )
    }
  };


  return (
    <div className={`${styles.confirmBody} ${styles.saveBody}`}>
      {confirmSave()}
      {saveLoading()}
      {saveResult()}
    </div>
  )
};

export default ConfirmSave;
