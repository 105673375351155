export const makePercent = (number: number | null | undefined) => {
    if (typeof number === 'number') {
        const numberSign = Math.sign(number * 100) < 0 ? '-' : '';

        const formattedPercent = (Math.abs(number))?.toLocaleString('sv-SV', {
            style: 'percent',
            minimumFractionDigits: 2,
        });

        const formattedPercentString = `${numberSign} ${formattedPercent}`;

        return formattedPercentString;
    }

    return '-';
}


export const roundNumber = (number: number | undefined) => {
    if (typeof number === 'number') {
        const thousandNumber = number * 1000;
        const numberSign = Math.sign(Math.round(thousandNumber)) < 0 ? '-' : '';

        const formattedNumber: string = Math.round(Math.abs(thousandNumber))?.toLocaleString('sv-SV',
            {
                style: 'currency',
                currency: 'SEK',
                minimumFractionDigits: 0,
            });

        const formattedNumberString = `${numberSign} ${formattedNumber}`;

        return formattedNumberString;
    }

    return '-';
}

export const formatText = (text: string | undefined) => {
    if (typeof text === 'string') {
        return text?.length > 0 ? text : '-';
    }
    return '-';
}


export const formatDate = (date: string | undefined | null) => {
    if (typeof date === 'string') {
        const formattedDate = date?.split('T')[0];

        return formattedDate;
    }

    return '-';
}