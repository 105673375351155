import { GridRow } from "../interfaces/grids/GridRow";
import { GridRows } from "../interfaces/grids/GridRows";
import { Column } from "../interfaces/grids/Column";

export const formatData = (data: GridRow[]) => {
    const gridRows: GridRows = { prefixedData: [], emptyRowsHidden: [] };

    data?.forEach((row: GridRow) => {
        if (row.columns) {
            Object.entries(row.columns)?.forEach((mainAttribute: [key: string, value: Column]) => {
                const [mainKey, mainValue] = mainAttribute;

                Object.entries(mainValue)?.forEach((subAttribute: [key: string, value: string | number | null]) => {
                    const [subKey, subValue] = subAttribute;

                    const suffixedKey: string = `${subKey}_${mainKey}`;
                    row[suffixedKey] = subValue;
                })
            });
        }
        gridRows.prefixedData.push(row);
        !row.isEmpty && gridRows.emptyRowsHidden.push(row);
    });

    return gridRows;
}