import './App.css';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { Outlet } from 'react-router-dom';
import { QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { UserNotAuthenticated } from './components/SystemState/States/UserNotAuthenticated';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import { InteractionContextProvider } from './context/InteractionContext';


const queryClient = new QueryClient(
  {
    defaultOptions: {
      queries: {
        cacheTime: 1000 * 60 * 60, // 1 hour 
        // staleTime: 1000 * 60 * 5, // default 0 min
        refetchOnWindowFocus: false
      },
    },
  }
);

const persister = createSyncStoragePersister({
  storage: window.localStorage,
})

const options = {
  persister,
  maxAge: 1000 * 60 * 60 * 24, // 24 hours
}


const App = () => (
  <div className="app" data-testid="App">
    <AuthenticatedTemplate>
      <PersistQueryClientProvider
        client={queryClient}
        persistOptions={{
          ...options,
        }}
      >
        <InteractionContextProvider>
          <Outlet />
        </InteractionContextProvider>

        <ReactQueryDevtools initialIsOpen={false} />

      </PersistQueryClientProvider>
    </AuthenticatedTemplate>

    <UnauthenticatedTemplate>
      <UserNotAuthenticated />
    </UnauthenticatedTemplate>
  </div>
);

export default App;
