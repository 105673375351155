import { Dispatch, SetStateAction } from 'react';
import { percentageBoundary } from './PercentageBoundary';
import styles from './PercentInput.module.css';
import { ProjectAttributes } from '../../../../interfaces/project/ProjectAttributes';
import { useInteractionContext } from '../../../../context/InteractionContext';
import { useMemorizedEditing } from '../../customHooks/useMemorizedEditing';


interface PercentInputProps {
    values: ProjectAttributes;
    initialValues: ProjectAttributes;
    label: string;
    setValues: Dispatch<SetStateAction<ProjectAttributes>>;
    setChangedValue: Dispatch<SetStateAction<{ [key: string]: string | number | null }>>;
    attributeName: string;
    disabled: boolean;
    limit?: number | null;
}

export const PercentInput = ({
    values,
    initialValues,
    setValues,
    setChangedValue,
    label,
    attributeName,
    limit,
    disabled
}: PercentInputProps) => {

    const { refreshLoading } = useInteractionContext();


    const {
        setInputBlurEvent,
        setKeyTriggerEvent,
        handleChangedPercent
    } = useMemorizedEditing(
        {
            values,
            initialValues,
            setValues,
            setChangedValue,
            attributeName,
            regExp: /^(100(\.00?)?|\d{1,2}(\.\d{1,2})?|0)$/
        }
    );


    const percentInput = () => (
        <input
            data-testid={attributeName}
            className={styles.percentInput}
            value={values?.[attributeName as keyof ProjectAttributes] ?? ''}
            type={'number'}
            step='0.01'
            min='0'
            max='100'
            disabled={disabled}
            tabIndex={0}
            onChange={e => handleChangedPercent(e)}
            onKeyDown={e => setKeyTriggerEvent(e)}
            onBlur={e => setInputBlurEvent(e)}
        />
    );


    const disabledPercentInput = () => (
        <input
            data-testid={attributeName}
            className={styles.percentInput}
            value={values?.[attributeName as keyof ProjectAttributes] ?? ''}
            type={'number'}
            disabled
        />
    );


    return (
        <div className={styles.percentInputContainer}>
            <label className={styles.percentInputLabel}>{label}</label>
            <span className={percentageBoundary(Number(values?.[attributeName as keyof ProjectAttributes]), limit)}>
                {refreshLoading
                    ?
                    disabledPercentInput()
                    :
                    percentInput()
                }
            </span>
        </div>
    );
};

