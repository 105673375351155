import { Dispatch, SetStateAction, useEffect } from "react";
import { useInternalCreditTypes } from "../../../../fetchData/queries/internalCredits/useInternalCreditTypes";
import { NumberStringPairs } from "../../../../interfaces/systemSettings/AccountTemplate";
import styles from './Inputs.module.css';
import { DefaultSelect } from "../../../Inputs/DefaultSelect/DefaultSelect";
import { InternalCreditType } from "../../interfaces/InternalCreditType";


interface InternalCreditSelectProps {
    selectedType: number;
    setSelectedType: Dispatch<SetStateAction<number>>;
}

export const InternalCreditSelect = ({
    selectedType,
    setSelectedType,
}: InternalCreditSelectProps) => {

    const { isLoading, data } = useInternalCreditTypes();

    useEffect(() => {
        if (data) setSelectedType(data[0]?.type);
    }, [data, setSelectedType])


    const arrayToObject = (array: InternalCreditType[] | undefined) => {
        const arrayAsObject = array && array.reduce((obj: NumberStringPairs, item) => {
            obj[item.type] = item.description;
            return obj;
        }, {})

        return arrayAsObject ?? {};
    };


    const options = () => {
        if (isLoading) return {};

        const optionsAsObject = arrayToObject(data);

        return optionsAsObject;
    }


    const handleSelect = (key: number) => setSelectedType(key);


    const select = () => (
        <div className={styles.inputContainer}>
            <label className={styles.inputLabel}>Beskrivning</label>

            <DefaultSelect
                options={options()}
                setSelectedOption={handleSelect}
                defaultSelectedKey={selectedType}
                selectWidth={150}
            />
        </div>
    )


    return select();
}