import { useMemo } from "react";
import { RowStyle } from "../../../../interfaces/grids/RowStyle";
import { CellClassParams, ValueGetterParams, ValueSetterParams } from "ag-grid-community";
import { getRowStyle } from "../../../../utils/getRowStyle";
import CommentCellRenderer from "../../../MainGrids/cellRenderers/CommentCellRenderer/CommentCellRenderer";
import CheckBoxCellRenderer from "./CheckBoxCellRenderer/CheckBoxCellRenderer";
import { NumberStringPairs } from "../../../../interfaces/systemSettings/AccountTemplate";

export const useAccountTemplateColDefs = (vatTypes: NumberStringPairs) => {

    const accountTemplateColDefs: object[] = useMemo(() => {
        const rowStyle: RowStyle = { backgroundColor: '#d9dcde', fontWeight: '' };

        return ([
            {
                field: 'accountNumber',
                headerName: 'Kontonr',
                flex: 1,
                editable: true,
                cellStyle: (params: CellClassParams) => getRowStyle(params, { ...rowStyle }, false),
                valueSetter: (params: ValueSetterParams): boolean => {
                    params.data.accountNumber = params.newValue.length > 0 ? params.newValue : params.oldValue;
                    return true;
                }
            },
            {
                field: 'notice',
                headerName: 'Hjälptext',
                flex: 1,
                editable: true,
                singleClickEdit: true,
                cellEditor: 'agLargeTextCellEditor',
                cellEditorParams: {
                    maxLength: 500,
                    rows: 10,
                    cols: 30
                },
                cellEditorPopup: true,
                cellRenderer: CommentCellRenderer,
                valueGetter: (params: ValueGetterParams) => params.data?.notice ?? '',
                cellStyle: (params: CellClassParams) => getRowStyle(params, { ...rowStyle }, false),
            },
            {
                field: 'shouldAggregateAmount',
                headerName: 'Summeras upp',
                valueFormatter: (params: any) => {
                    return params.data?.aggregateAmount
                },
                flex: 1,
                cellRenderer: CheckBoxCellRenderer,
                cellStyle: (params: CellClassParams) => getRowStyle(params, { ...rowStyle }, false),
            },
            {
                field: 'isEditable',
                headerName: 'Editerbar',
                flex: 1,
                cellRenderer: CheckBoxCellRenderer,
                cellStyle: (params: CellClassParams) => getRowStyle(params, { ...rowStyle }, false),
            },
            {
                field: 'isNegative',
                headerName: 'Omvänt tecken',
                flex: 1,
                cellRenderer: CheckBoxCellRenderer,
                cellStyle: (params: CellClassParams) => getRowStyle(params, { ...rowStyle }, false),
            },
            {
                field: 'vatType',
                headerName: 'Momsplikt',
                flex: 2,
                editable: true,
                singleClickEdit: true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: Object.values(vatTypes ?? {}),
                },
                valueGetter: (params: ValueGetterParams) => vatTypes && vatTypes[params.data?.vatType],
                valueSetter: (params: ValueSetterParams) => {
                    const vatTypeKey: number | undefined = vatTypes && Object.keys(vatTypes).map(Number).find((key) => vatTypes[Number(key)] === params.newValue);
                    params.data.vatType = vatTypeKey;
                    return true;
                },
                cellStyle: (params: CellClassParams) => getRowStyle(params, { ...rowStyle }, false),
            },
            {
                field: 'sortOrder',
                headerName: 'Ordning',
                flex: 1,
                editable: true,
                valueSetter: (params: ValueSetterParams) => {
                    params.data.sortOrder = params.newValue.length > 0 ? Number(params.newValue) : params.oldValue;
                    return true
                },
                cellStyle: (params: CellClassParams) => getRowStyle(params, { ...rowStyle }, false),
            },
        ]
        );
    }, [vatTypes]);


    return accountTemplateColDefs;
}