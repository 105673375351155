import { useMemo } from "react";
import { NonEditablePercentInput } from "../CalculationBarInputs/PercentInput/NonEditablePercentInput";
import styles from '../CalculationsBar.module.css';
import { BaseCalcParams } from "./interfaces/BaseCalcParams";


export const useContractProfit = ({
    baseCalculations,
    translation,
}: BaseCalcParams) => {
    const contractProfit = useMemo(() => {
        return (<div className={styles.fieldsContainer}>
            <h5 className={styles.fieldsHeader}>Entrepenadvinst</h5>

            <NonEditablePercentInput
                value={baseCalculations?.profitOnContract}
                label={translation.profitOnContract}
                limit={baseCalculations?.profitOnContractLimit}
            />
        </div>)
    }, [baseCalculations?.profitOnContract, baseCalculations?.profitOnContractLimit, translation.profitOnContract]);

    return contractProfit;
}