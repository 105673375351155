import { useEffect, useState } from 'react';
import styles from './CalculationsBar.module.css';
import LoadingSpinner from '../StatusIndicators/LoadingSpinner';
import { calculationBarTranslation } from './calculationBarTranslation';
import { useEditingContext } from '../../context/EditingContext';
import { useInteractionContext } from '../../context/InteractionContext';
import { useSheetContext } from '../../context/SheetContext';
import { useProjectInfo } from '../../fetchData/queries/useProjectInfo';
import { useBaseCalculations } from '../../fetchData/queries/useBaseCalculations';
import { createEditsDto } from '../../utils/createEditsDto';
import { useShiftPrognosis } from '../../fetchData/mutations/useShiftPrognosis';
import { useRefresh } from '../../fetchData/mutations/useRefresh';
import { ProjectAttributesDto } from '../../interfaces/editing/dtos/ProjectAttributesDto';
import { ProjectAttributes } from '../../interfaces/project/ProjectAttributes';
import { UnableToLoadData } from '../SystemState/ErrorManagement/UnableToLoadData/UnableToLoadData';
import { useEconomyTypeFields } from './customHooks/useEconomyTypeFields';


const CalculationsBar = () => {

  const { isLoading, error, data } = useProjectInfo();
  const economyTypeId = data?.economyTypeId;

  const baseCalcData = useBaseCalculations().data;
  const shiftPrognosis = useShiftPrognosis();
  const refresh = useRefresh();

  const {
    projectAttributes,
    setProjectAttributes,
    setLockedBudget,
    setDatesInfo,
    initiallyUnsetBuildStartMonth,
    setInitiallyUnsetBuildStartMonth,
    baseCalculations,
    setBaseCalculations
  } = useSheetContext();

  const { loadProjectEconomy } = useInteractionContext();

  const {
    edits,
    setEdits,
    readOnlyMode,
    prognosisShifted,
    setPrognosisShifted
  } = useEditingContext();

  const translation: { [key: string]: string } = calculationBarTranslation;
  const [handleShiftPrognosis, setHandleShiftPrognosis] = useState<boolean>(false);
  const [changedValue, setChangedValue] = useState<{ [key: string]: string | number | null }>({});
  const [initialValues, setInitialValues] = useState<ProjectAttributes>({
    buildStartMonth: '',
    startMonth: '',
    endMonth: '',
    mgmtBuildStartConsentPlanned: '',
    mgmtBuildStartConsent: '',
    saleOfLandPlanned: '',
    saleOfLand: '',
    buildingCreditInterest: null,
    internalInterest: null,
    indexBaseMonth: '',
    indexPercentage: null,
  });


  useEffect(() => {
    if (loadProjectEconomy) {
      const datesInfo = data?.datesInfo;
      const interestInfo = data?.interestInfo;
      const indexInfo = data?.indexInfo;

      if (datesInfo && interestInfo && indexInfo) {
        setDatesInfo({ ...datesInfo });

        setLockedBudget(data?.budgetIsLocked as boolean);

        !datesInfo?.buildStartMonth && setInitiallyUnsetBuildStartMonth(true);

        const projectAttributes: ProjectAttributes = {
          buildStartMonth: datesInfo?.buildStartMonth?.slice(0, 7) ?? null,
          startMonth: datesInfo?.startMonth?.slice(0, 7) ?? null,
          endMonth: datesInfo?.endMonth?.slice(0, 7) ?? null,
          mgmtBuildStartConsentPlanned: datesInfo?.mgmtBuildStartConsentPlanned?.slice(0, 10) ?? null,
          mgmtBuildStartConsent: datesInfo?.mgmtBuildStartConsent?.slice(0, 10) ?? null,
          saleOfLandPlanned: datesInfo?.saleOfLandPlanned?.slice(0, 10) ?? null,
          saleOfLand: datesInfo?.saleOfLand?.slice(0, 10) ?? null,
          buildingCreditInterest: interestInfo?.buildingCreditInterest,
          internalInterest: interestInfo?.internalInterest,
          indexBaseMonth: indexInfo?.indexBaseMonth?.slice(0, 7) ?? null,
          indexPercentage: indexInfo?.indexPercentage,
        };

        setProjectAttributes(projectAttributes);
        setInitialValues(projectAttributes);
      }

      if (baseCalcData) setBaseCalculations(baseCalcData);
    }
  }, [baseCalcData, loadProjectEconomy, data?.budgetIsLocked, data?.datesInfo, data?.indexInfo, data?.interestInfo,
    setBaseCalculations, setDatesInfo, setInitiallyUnsetBuildStartMonth, setLockedBudget, setProjectAttributes])


  useEffect(() => {
    if (Object.keys(changedValue).length > 0) {

      const revertedEditKey = Object.keys(initialValues).find((key) =>
        initialValues[key as keyof ProjectAttributesDto] === changedValue[key as keyof ProjectAttributesDto]);
      const revertedEdit = revertedEditKey && changedValue[revertedEditKey as keyof ProjectAttributesDto] === initialValues[revertedEditKey as keyof ProjectAttributesDto];

      if (revertedEdit) {
        setEdits(prevState => {
          delete prevState.projectAttributes[revertedEditKey as keyof ProjectAttributesDto];
          return { ...prevState }
        });
      } else {
        setEdits(prevState => {
          return {
            ...prevState,
            projectAttributes: { ...prevState.projectAttributes, ...changedValue }
          }
        });
      }

      if (handleShiftPrognosis) {
        shiftPrognosis.mutate(createEditsDto({
          ...edits,
          projectAttributes: { ...edits.projectAttributes, ...changedValue }
        }));
        setPrognosisShifted(true);
      } else {
        refresh.mutate(createEditsDto({
          ...edits,
          projectAttributes: { ...edits.projectAttributes, ...changedValue }
        }));
      }

      setChangedValue({});
    }
  }, [changedValue, edits, handleShiftPrognosis, initialValues, refresh, setEdits, setPrognosisShifted, shiftPrognosis]);


  const economyTypeFields = useEconomyTypeFields({
    economyTypeId,
    projectAttributes,
    setProjectAttributes,
    initialValues,
    setChangedValue,
    translation,
    readOnlyMode,
    initiallyUnsetBuildStartMonth,
    setHandleShiftPrognosis,
    prognosisShifted,
    baseCalculations
  });

  if (isLoading) {
    return (
      < div className={styles.loadingSpinnerContainer}>
        <LoadingSpinner />
      </div >
    );
  }

  if (error) {
    return (
      <div className={styles.calculationsBarContainer}>
        <UnableToLoadData message={'Grunduppgifter kunde ej laddas'} />
      </div>
    );
  }

  return (
    <div className={styles.calculationsBarContainer}>
      {economyTypeFields}
    </div >
  );
};

export default CalculationsBar;
