import { useCallback } from "react";
import { useEditingContext } from "../../../context/EditingContext";
import { useUser } from "../../../fetchData/queries/useUser";


const useCheckIfSheetEditable = () => {
  const { readOnlyMode } = useEditingContext();
  const { data } = useUser();

  const checkIfEditable = useCallback((params): boolean => {

    const rowIsEditable: boolean = params.node?.data.editable;
    const isCommentColumn = params.column.getColId() === 'comment';

    if (rowIsEditable) {
      // readonlymode used to view comments in historic sheets
      if (readOnlyMode) {
        return isCommentColumn;
      } else {
        const writePermission = data?.scopes?.['sheet_write'] ?? false;
        return writePermission;
      }
    }

    return false;

  }, [data?.scopes, readOnlyMode]);

  return checkIfEditable;
};

export default useCheckIfSheetEditable;