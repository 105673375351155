import { useMemo } from 'react';
import { PercentInput } from '../CalculationBarInputs/PercentInput/PercentInput';
import styles from '../CalculationsBar.module.css';
import { CalcBarParams } from './interfaces/CalcBarParams';


export const useInterestCalculation = ({
    projectAttributes,
    setProjectAttributes,
    initialValues,
    setChangedValue,
    translation,
    readOnlyMode,
}: CalcBarParams) => {
    const interestCalculation = useMemo(() => {
        return (
            <div className={styles.fieldsContainer}>
                <h5 className={styles.fieldsHeader}>Ränteberäkning</h5>

                <PercentInput
                    values={projectAttributes}
                    setValues={setProjectAttributes}
                    initialValues={initialValues}
                    setChangedValue={setChangedValue}
                    label={translation.internalInterest}
                    attributeName={'internalInterest'}
                    disabled={readOnlyMode}
                />

                <PercentInput
                    values={projectAttributes}
                    setValues={setProjectAttributes}
                    initialValues={initialValues}
                    setChangedValue={setChangedValue}
                    label={translation.buildingCreditInterest}
                    attributeName={'buildingCreditInterest'}
                    disabled={readOnlyMode}
                />
            </div>
        );
    }, [projectAttributes, setProjectAttributes, initialValues, setChangedValue, translation.internalInterest,
        translation.buildingCreditInterest, readOnlyMode]);

    return interestCalculation;
};