import { useHandleError } from "./useHandleError";

export const useHandleRetry = () => {
    const handleError = useHandleError();

    const handleRetry = (failureCount: number, error: unknown) => {
        if (failureCount >= 1) {
            handleError(error);
            return false
        };

        return true;
    }

    return handleRetry;
}