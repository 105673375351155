import { useState } from "react";
import { useEditingContext } from "../../context/EditingContext";
import { FluentModal } from '../Modals/FluentModal/FluentModal';
import SnapshotSelect from './SnapshotSelect/SnapshotSelect';
import SaveSnapshot from "../Modals/FluentModal/Content/Confirmation/SaveSnapshot";
import SaveSnapshotButton from "./SaveSnapshotButton";


interface SnapshotSelectProps {
  edited: boolean;
}


const Snapshots = ({
  edited,
}: SnapshotSelectProps) => {

  const { selectedSnapshotId } = useEditingContext();
  const [isSnapshotModalOpen, setIsSnapshotModalOpen] = useState<boolean>(false);
  const [saveIsLoading, setSaveIsLoading] = useState(false);

  return (
    <>
      <SnapshotSelect edited={edited} />

      <SaveSnapshotButton
        edited={edited}
        selectedSnapshotId={selectedSnapshotId}
        setIsSnapshotModalOpen={setIsSnapshotModalOpen}
      />

      <FluentModal
        isModalOpen={isSnapshotModalOpen}
        setIsModalOpen={saveIsLoading ? undefined : setIsSnapshotModalOpen}
        isModeLess={false}
        modalHeader={'Spara historik'}
        modalContent={
          <SaveSnapshot
            setIsModalOpen={setIsSnapshotModalOpen}
            setSaveIsLoading={setSaveIsLoading}
          />
        }
      />
    </>
  )
};

export default Snapshots;
