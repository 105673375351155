import { UseQueryResult } from "@tanstack/react-query";
import { Dispatch, SetStateAction, useEffect } from "react";

export const useQueriesFetched = <T,>(
    queries: UseQueryResult<T | undefined, unknown>[],
    setReportIsGenerated: Dispatch<SetStateAction<boolean>>
) => {
    useEffect(() => {
        if (queries) {
            const isNotFetching = queries.map(response => response?.isFetching)
                ?.every(status => status === false);

            setReportIsGenerated(!isNotFetching);
        }
    }, [queries, setReportIsGenerated])
}