import { AccountTemplateRow } from "../../interfaces/systemSettings/AccountTemplate";
import { apiRequestParams, getApiUrl } from "../fetchOptions";


const apiUrl = getApiUrl();

export const ACCOUNTTEMPLATES = {
    async getAccountTemplate(economyType: number, sheetType: number, accessToken: string) {
        return await fetch(`${apiUrl}/AccountTemplates/${economyType}/List/${sheetType}`,
            apiRequestParams(accessToken, 'GET')
        ).then(response => {
            if (!response.ok) {
                throw Error('');
            }
            return response.json();
        })
    },
    async postValidateAccountTemplate(economyType: number, sheetType: number, validateObject: AccountTemplateRow[], deleteAction: boolean, accessToken: string) {
        return await fetch(`${apiUrl}/AccountTemplates/${economyType}/Validate/${sheetType}?deleteAction=${deleteAction}`,
            apiRequestParams(accessToken, 'POST', validateObject)
        ).then(response => {
            if (!response.ok) {
                throw Error('');
            }
            return response.json();
        })
    },
    async putSaveAccountTemplate(economyType: number, sheetType: number, edits: any, accessToken: string) {
        return await fetch(`${apiUrl}/AccountTemplates/${economyType}/Save/${sheetType}`,
            apiRequestParams(accessToken, 'PUT', Object.values(edits))
        ).then(response => {
            if (!response.ok) {
                throw Error('');
            }
            return response.json();
        })
    },
    async deleteAccountTemplateRows(economyType: number, sheetType: number, edits: any, accessToken: string) {
        return await fetch(`${apiUrl}/AccountTemplates/${economyType}/Delete/${sheetType}`,
            apiRequestParams(accessToken, 'DELETE', Object.values(edits))
        ).then(response => {
            if (!response.ok) {
                throw Error('');
            }
            return response.json();
        })
    },
    async getVatTypes(accessToken: string) {
        return await fetch(`${apiUrl}/AccountTemplates/VatTypes`,
            apiRequestParams(accessToken, 'GET')
        ).then(response => {
            if (!response.ok) {
                throw Error('');
            }
            return response.json();
        })
    },
    async getEconomyTypes(accessToken: string) {
        return await fetch(`${apiUrl}/AccountTemplates/EconomyTypes`,
            apiRequestParams(accessToken, 'GET')
        ).then(response => {
            if (!response.ok) {
                throw Error('');
            }
            return response.json();
        })
    },
    async getAccountTemplateMaxId(accessToken: string) {
        return await fetch(`${apiUrl}/AccountTemplates/MaxId`,
            apiRequestParams(accessToken, 'GET')
        ).then(response => {
            if (!response.ok) {
                throw Error('');
            }
            return response.json();
        })
    },
}