import { ICellRendererParams } from "ag-grid-community";
import styles from './CheckBoxCellRenderer.module.css';
import { ChangeEvent } from "react";

const CheckBoxCellRenderer = (props: ICellRendererParams) => {
  const checkedHandler = (e: ChangeEvent<HTMLInputElement>
  ) => {
    let checked: boolean = e.target.checked;
    let colId: string = props.column?.getColId() ?? '';
    props.node.setDataValue(colId, checked);
  }

  return (
    <span className={styles.checkBoxContainer}>
      <input
        className={styles.checkBoxInput}
        type="checkbox"
        onChange={e => checkedHandler(e)}
        tabIndex={0}
        checked={props.value}
      />
    </span>
  );
};

export default CheckBoxCellRenderer;