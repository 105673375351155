import { ValueFormatterParams } from "ag-grid-community";
import { formatNumber } from "../../../../utils/formatNumber";


export const reportNumberFormatter = (params: ValueFormatterParams) => {
    const cellValue: number | undefined = params.value;

    if (cellValue && cellValue !== 0) return formatNumber(cellValue);

    return '';
}


export const reportPercentageFormatter = (params: ValueFormatterParams) => {
    const cellValue: number | undefined = params.value;

    if (cellValue && cellValue !== 0) return Math.round(cellValue);

    return '';
}