export const calculationBarTranslation: { [key: string]: string } = {
    'startMonth': 'Startmånad',
    'buildStartMonth': 'Igångsättningsmånad',
    'endMonth': 'Slutmånad',
    'internalInterest': 'Beräknad avräkningsränta',
    'buildingCreditInterest': 'Ränta kreditiv',
    'indexBaseMonth': 'Indexbasmånad',
    'indexPercentage': 'Indexprocent',
    'saleOfLandPlanned': 'Planerad tidpunkt för fastighetsförsäljning',
    'saleOfLand': 'Tidpunkt för fastighetsförsäljning',
    'projectedCostsSubjectToVat': 'Progn. momspliktiga kostn. till ovantst. datum',
    'mgmtBuildStartConsentPlanned': 'Planerat igångsättn.medg. FL',
    'mgmtBuildStartConsent': 'Igångsättn.medg. FL',
    'expenditureOnCostsSubjectToVat': 'Utfall nedlagda momspliktiga kostnader',
    'profitOnContract': 'Beräknad entrepenadvinst'
}