import { useEditingContext } from './../../context/EditingContext';
import { useEffect, useState } from 'react';
import styles from './EasterEggButton.module.css';


interface EasterEggButtonProps {
    buttonContent: JSX.Element;
    clickLimit: number;
}

export const EasterEggButton = ({
    buttonContent,
    clickLimit
}: EasterEggButtonProps) => {
    const { easterEggMode, setEasterEggMode } = useEditingContext();
    const [clickCount, setClickCount] = useState<number>(0);

    const handleClick = () => setClickCount(clickCount + 1);


    useEffect(() => {
        if (clickCount === clickLimit) {
            setEasterEggMode(!easterEggMode);
            setClickCount(0);
        }
    }, [clickCount, clickLimit, easterEggMode, setEasterEggMode]);


    useEffect(() => {
        if (easterEggMode === true) {
            alert(`
        Grattis!
        Du har hittat systemets lätt att läsa läge. 
        Hoppas siffrorna är lättare att läsa i text.
        Mycket viktigt att du håller det hemligt.
        Tryck fem gånger på projektnamnet/
        numret eller ladda om sidan för att lämna läget.\n
        /Utvecklaren`
            );
        }
    }, [easterEggMode]);


    const button = () => (
        <button
            className={styles.easterEggButton}
            onClick={handleClick}
        >
            {buttonContent}
        </button>
    );


    return button();
}