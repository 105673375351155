import { Icon } from "@fluentui/react";
import styles from "./CommentCellRenderer.module.css";
import { ICellRendererParams } from "ag-grid-community";

const CommentCellRenderer = (props: ICellRendererParams) => {
  return (
    <span data-testid="CommentCellRenderer">
      {props.value && <Icon className={styles.commentIcon} iconName="CommentSolid" />}
    </span>
  );
};

export default CommentCellRenderer;