import { IconButton, TooltipHost } from "@fluentui/react";
import { useQueryClient } from "@tanstack/react-query";
import styles from './ProjectEconomyButton.module.css';
import useProjectId from "./../../../customHooks/useProjectId";


export const RefreshCacheButton = () => {
    const queryClient = useQueryClient();
    const projectId = useProjectId();


    const refetchProjectIdQueries = () => {
        queryClient.refetchQueries({
            queryKey: [projectId],
            type: 'active'
        });
    }


    const refetchNonProjectIdQueries = () => {
        const queryCache = queryClient.getQueryCache();
        const queryKeys = queryCache.getAll()?.map(cache => cache.queryKey);

        const nonProjectIdKeys = queryKeys?.filter(queryKey => !Number(queryKey[0]));

        if (nonProjectIdKeys?.length > 0) {
            nonProjectIdKeys.forEach(key => {
                queryClient.refetchQueries({
                    queryKey: key,
                    type: 'active'
                });
            })
        }
    }


    const handleRefresh = () => {
        refetchProjectIdQueries();
        refetchNonProjectIdQueries();
    }


    const button = () => (
        <TooltipHost content='Synka projektekonomin' id={'refreshButton'}>
            <IconButton
                className={styles.projectEconomyButton}
                iconProps={{ iconName: 'SyncOccurence' }}
                onClick={handleRefresh} />
        </TooltipHost>
    );


    return button();
}