import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAccessToken } from "../../useAccessToken";
import { useCallback, } from "react";
import { FetchResponse, makeResultFetch } from "../../genericFetch";
import { apiRequestParams } from "../../fetchOptions";
import { useHandleError } from "../../useHandleError";


export const useDeleteSystemMessage = () => {
    const getAccessToken = useAccessToken();
    const handleError = useHandleError();
    const queryClient = useQueryClient();

    const mutationFunction = useCallback(async () => {
        return getAccessToken().then(accessToken => {
            if (!accessToken) throw new Error("Access token is undefined");

            const fetchedData = makeResultFetch<boolean | null>(
                `/Settings/SystemMessage`,
                apiRequestParams(accessToken, 'DELETE')
            )
                .then((response: FetchResponse<boolean | null>) => {
                    const { message, success } = response;

                    if (!success) throw Error(message);

                    return response;
                });

            return fetchedData;
        });
    }, [getAccessToken]);


    const handleSuccess = (response: FetchResponse<boolean | null>) => {
        if (response.success) {
            queryClient.refetchQueries(['systemMessage']);

            alert('Systemmeddelandet har raderats');
        }
    }



    const deleteFinalAccounting = useMutation({
        mutationFn: mutationFunction,
        onSuccess: handleSuccess,
        onError: handleError,
    });


    return deleteFinalAccounting;
}