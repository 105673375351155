import { Dispatch, SetStateAction } from "react";
import { DefaultDatePicker } from "../../../Inputs/DefaultDatePicker/DefaultDatePicker";
import styles from './Inputs.module.css';

interface InternalCreditsDatePickerProps {
    defaultDate: string;
    setSelectedDate: Dispatch<SetStateAction<string>>;
}

export const InternalCreditDatePicker = ({
    defaultDate,
    setSelectedDate
}: InternalCreditsDatePickerProps) => {

    const datePicker = (
        <div className={styles.inputContainer}>
            <label className={styles.inputLabel}>Datum</label>

            <DefaultDatePicker
                setChangedDate={setSelectedDate}
                dateFormat={'date'}
                defaultDate={defaultDate}
            />
        </div>
    );

    return datePicker;
}