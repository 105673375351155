import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { useAccessToken } from "../../../useAccessToken";
import { makeResultFetch } from "../../../genericFetch";
import { apiRequestParams } from "../../../fetchOptions";
import { useHandleRetry } from "../../../useHandleRetry";
import { OrgStructureRow } from "../../../../interfaces/OrgStructure";


const useFetch = () => {
    const getAccessToken = useAccessToken();

    const queryFunction = useCallback(async () => {
        return getAccessToken().then(accessToken => {
            if (!accessToken) throw new Error("Access token is undefined");

            const fetchedData = makeResultFetch<OrgStructureRow[]>(
                `/organizations`,
                apiRequestParams(accessToken, 'GET')
            ).then((response) => {
                const { data, message, success } = response;

                if (!success) throw Error(message);

                return data;
            });

            return fetchedData;
        });
    }, [getAccessToken]);

    return queryFunction;
}


export const useOrgStructure = () => {
    const queryFunction = useFetch();
    const handleRetry = useHandleRetry();

    const { isLoading, error, data } = useQuery({
        queryKey: ['orgStructure'],
        queryFn: queryFunction,
        enabled: !!queryFunction,
        retry: handleRetry,
    });


    return { isLoading, error, data };
}