import { ProcessCellForExportParams, ValueFormatterParams } from "ag-grid-community";
import { formatNumber } from "./formatNumber";
import { getIsCalculated } from "./getIsCalculated";
import { formatPercent } from "./formatPercent";

// value = data
// formatted value = presentation. String, e g 2 000

export const numberFormatter = (params: ValueFormatterParams | ProcessCellForExportParams, easterEggMode?: boolean) => {
    const isCalculated: (boolean | null | undefined) = getIsCalculated(params);
    const cellValue: string = params.value?.toString();

    if (Math.abs(Number(cellValue)) > 0) {
        return params.node?.data?.isPercentage
            ? formatPercent(cellValue, easterEggMode)
            : formatNumber(Number(cellValue), easterEggMode);
    }
    if (cellValue === '0' && (isCalculated === true || isCalculated === false)) {
        return easterEggMode ? 'noll' : cellValue;
    }
    return '';
}