import { Dispatch, SetStateAction, useMemo } from "react";
import { MonthDateInput } from "../CalculationBarInputs/DateInput/MonthDateInput";
import { ModalDateInput } from "../CalculationBarInputs/DateInput/ModalDateInput";
import styles from '../CalculationsBar.module.css';
import { CalcBarParams } from "./interfaces/CalcBarParams";


interface ProjectEconomySheetParams extends CalcBarParams {
    initiallyUnsetBuildStartMonth: boolean;
    setHandleShiftPrognosis: Dispatch<SetStateAction<boolean>>;
    prognosisShifted: boolean;
}

export const useProjectEconomySheet = ({
    projectAttributes,
    setProjectAttributes,
    initialValues,
    setChangedValue,
    translation,
    readOnlyMode,
    initiallyUnsetBuildStartMonth,
    setHandleShiftPrognosis,
    prognosisShifted
}: ProjectEconomySheetParams) => {

    const projectEconomySheet = useMemo(() => {
        if (initialValues) {
            return (<div className={styles.fieldsContainer}>
                <h5 className={styles.fieldsHeader}>Projektekonomiark</h5>

                <MonthDateInput
                    values={projectAttributes}
                    setValues={setProjectAttributes}
                    initialValues={initialValues}
                    setChangedValue={setChangedValue}
                    label={translation.startMonth}
                    attributeName={'startMonth'}
                    disabled={readOnlyMode}
                />

                {initiallyUnsetBuildStartMonth
                    ?
                    <MonthDateInput
                        values={projectAttributes}
                        setValues={setProjectAttributes}
                        initialValues={initialValues}
                        setChangedValue={setChangedValue}
                        label={translation.buildStartMonth}
                        attributeName={'buildStartMonth'}
                        disabled={readOnlyMode}
                    />
                    :

                    <ModalDateInput
                        disabled={readOnlyMode}
                        values={projectAttributes}
                        setValues={setProjectAttributes}
                        attributeName={'buildStartMonth'}
                        label={translation.buildStartMonth}
                        setHandleShiftPrognosis={setHandleShiftPrognosis}
                        prognosisShifted={prognosisShifted}
                        setChangedValue={setChangedValue}
                        initialValues={initialValues}
                    />
                }
                <MonthDateInput
                    values={projectAttributes}
                    setValues={setProjectAttributes}
                    initialValues={initialValues}
                    setChangedValue={setChangedValue}
                    label={translation.endMonth}
                    attributeName={'endMonth'}
                    disabled={readOnlyMode}
                />
            </div>)
        }
    }, [initialValues, projectAttributes, setProjectAttributes, setChangedValue, translation.startMonth, translation.buildStartMonth,
        translation.endMonth, readOnlyMode, initiallyUnsetBuildStartMonth, setHandleShiftPrognosis, prognosisShifted]);

    return projectEconomySheet;
}
