import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAccessToken } from "../useAccessToken";
import { useCallback, } from "react";
import { FetchResponse, makeResultFetch } from "../genericFetch";
import { apiRequestParams } from "../fetchOptions";
import { useHandleError } from "../useHandleError";
import { SaveStandardValues } from "../../interfaces/systemSettings/StandardValues";


export const useSaveStandardValues = () => {
    const getAccessToken = useAccessToken();
    const handleError = useHandleError();
    const queryClient = useQueryClient();

    const mutationFunction = useCallback(async (standardValues: SaveStandardValues) => {
        return getAccessToken().then(accessToken => {
            if (!accessToken) throw new Error("Access token is undefined");

            const fetchedData = makeResultFetch<SaveStandardValues>(
                `/Settings/Params`,
                apiRequestParams<SaveStandardValues>(accessToken, 'PUT', standardValues)
            ).then((response: FetchResponse<SaveStandardValues>) => {
                const { message, success } = response;

                if (!success) throw Error(message);

                return response;
            });

            return fetchedData;;
        });
    }, [getAccessToken]);


    const handleSuccess = (response: FetchResponse<SaveStandardValues>) => {
        if (response.success) {
            queryClient.invalidateQueries(['standardValues']);

            alert('Schablonvärden har sparats');
        }
    };


    const saveStandardValues = useMutation({
        mutationFn: mutationFunction,
        onSuccess: handleSuccess,
        onError: handleError,
    });


    return saveStandardValues;
}