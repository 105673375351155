import styles from './AdminTools.module.css';
import { StandardValues } from "./Tools/StandardValues";
import { DeleteFinalAccounting } from './Tools/DeleteFinalAccounting';
import { SystemMessage } from './Tools/SystemMessage';
import { DeleteProjectCache } from './Tools/DeleteProjectCache';
import { DeleteUserCache } from './Tools/DeleteUserCache';
import { CheckUser } from './Tools/CheckUser';


export const AdminTools = () => (
    <div className={styles.adminToolsContainer}>
        <div className={styles.adminToolsColumn}>
            <h3>Allmänt</h3>

            <SystemMessage />

            <StandardValues />
        </div>

        <div className={styles.adminToolsColumn}>
            <h3>Användare</h3>

            <CheckUser />
        </div>

        <div className={styles.adminToolsColumn}>
            <h3>Återställning</h3>

            <DeleteFinalAccounting />

            <DeleteProjectCache />

            <DeleteUserCache />
        </div>
    </div>
);
