import { ProgressIndicator } from "@fluentui/react";
import styles from './Status.module.css';

interface ProgressStatusProps {
    progress: number;
    description?: string;
}

export const ProgressStatus = ({
    progress,
    description
}: ProgressStatusProps) => (
    <div className={styles.progressStatus}>
        <div className={styles.progressContainer}>
            <p>{description ? description : 'Laddas...'}</p>
            <p>{`${Math.round(progress * 100)} %`}</p>
        </div>

        <ProgressIndicator percentComplete={progress} barHeight={3} />
    </div>
);