import { AccountTemplateStatus } from '../../../../../interfaces/systemSettings/AccountTemplate';
import styles from './AccountTemplateModal.module.css';

interface AccountTemplateModalProps {
  accountTemplateError: AccountTemplateStatus | undefined;
}

const AccountTemplateModal = ({ accountTemplateError }: AccountTemplateModalProps) => (
  <div className={styles.contentContainer}>
    {accountTemplateError?.[1]?.success === false &&
      <>
        <h3>Kassaflöde</h3>
        <div>{accountTemplateError?.[1]?.message}</div>
      </>}
    {accountTemplateError?.[2]?.success === false &&
      <>
        <h3>Resultatuppföljning</h3>
        <div>{accountTemplateError?.[2]?.message}</div>
      </>}
  </div>
);

export default AccountTemplateModal;