import { useCallback } from "react";
import { usePrefetchAccountSpecification } from "../../../fetchData/queries/cashflow/useAccountSpecification";

export const useOnCellContextMenu = (modalAccountId: string, modalOutcomeDate: string) => {
    const prefetchAccountSpecification = usePrefetchAccountSpecification(modalAccountId, modalOutcomeDate);

    const onCellContextMenu = useCallback(() => {
        if (modalAccountId) prefetchAccountSpecification();
    }, [modalAccountId, prefetchAccountSpecification]);

    return onCellContextMenu;
}