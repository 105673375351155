import { Text, View } from '@react-pdf/renderer';
import { formatDate, formatText, makePercent, roundNumber } from '../formatPdfContent';
import { styles } from '../styles';
import { PdfTemplate } from '../PdfTemplate';
import { ProfitOnContract } from '../../../../interfaces/reports/ProfitOnContract';

interface ProfitOnContractPdfProps {
    reportData: ProfitOnContract | undefined;
}

export const ProfitOnContractPdf = ({
    reportData,
}: ProfitOnContractPdfProps) => {

    const header = reportData?.header;
    const propertyValuation = reportData?.propertyValuation;
    const acquisitionValue = reportData?.acquisitionValue;
    const land = reportData?.land;
    const contract = reportData?.contract;


    const salesPriceGreaterThanEstimatedValue = () => {
        const salesPrice = propertyValuation?.salesPrice ?? 0;
        const estimatedValue = propertyValuation?.estimatedValue ?? 0;

        if (salesPrice > estimatedValue) {
            return (
                <View style={styles.section}>
                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            <Text style={styles.redMessage}>Försäljningspris (konto 3554) överstiger värdering för fastighet</Text>
                        </View>
                    </View>
                </View>
            );
        }
    }



    const page1 = () => (
        <>
            <View style={styles.section}>
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableRowCol1}>Projekt</Text>
                        <Text style={styles.tableRowCol2}>{formatText(header?.projectName)}</Text>
                    </View>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableRowCol1}>Projektnummer</Text>
                        <Text style={styles.tableRowCol2}>{formatText(header?.projectId)}</Text>
                    </View>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableRowCol1}>Ansvarig projektledare </Text>
                        <Text style={styles.tableRowCol2}>{formatText(header?.projectManager)}</Text>
                    </View>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableRowCol1}>Prognos senast ändrad/bekräftad</Text>
                        <Text style={styles.tableRowCol2}>{formatDate(header?.forecastLastUpdated)}</Text>
                    </View>

                    <View style={styles.tableRow}>
                        <Text style={styles.tableRowCol1}>
                            {header?.hasBuildStartConsent
                                ? 'FL igångsättningsmedgivande'
                                : 'Planerad igångsättning'
                            }
                        </Text>
                        <Text style={styles.tableRowCol2}>{formatDate(header?.buildingStartConsent)}</Text>
                    </View>

                    <View style={styles.tableRow}>
                        <Text style={styles.tableRowCol1}>
                            {!header?.isPlannedDateOfFinalInspection
                                ? 'Slutbesiktning'
                                : 'Planerad slutbesiktning'
                            }
                        </Text>
                        <Text style={styles.tableRowCol2}>{formatDate(header?.dateOfFinalInspection)}</Text>
                    </View>
                </View>
            </View>

            <View style={styles.sectionSpacing} />

            <View style={styles.section}>
                <Text style={styles.sectionHeader}>Fastighet (byggrättsvärdering)</Text>

                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableRowCol1}>Värdering utförd</Text>
                        <Text style={styles.tableRowCol2}>{formatDate(propertyValuation?.dateOfValuation)}</Text>
                    </View>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableRowCol1}>Värdering</Text>
                        <Text style={styles.tableRowCol2}>{roundNumber(propertyValuation?.estimatedValue)}</Text>
                    </View>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableRowCol1}>Försäljningspris</Text>
                        <Text style={styles.tableRowCol2}>{roundNumber(propertyValuation?.salesPrice)}</Text>
                    </View>
                </View>
            </View>


            {salesPriceGreaterThanEstimatedValue()}


            <View style={styles.sectionSpacing} />


            <View style={styles.section}>
                <Text style={styles.sectionHeader}>Anskaffning</Text>

                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableRowCol1}>Anskaffningskostnad BRF</Text>
                        <Text style={styles.tableRowCol2}>{roundNumber(acquisitionValue)}</Text>
                    </View>
                </View>
            </View>

            <View style={styles.section}>
                <Text style={styles.sectionHeader}>Mark</Text>

                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableRowCol1}>Försäljning</Text>
                        <Text style={styles.tableRowCol2}>{roundNumber(land?.salesPrice)}</Text>
                    </View>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableRowCol1}>Anskaffning (inkl. nedlagda kostnader)</Text>
                        <Text style={styles.tableRowCol2}>{roundNumber(land?.acquisitionValue)}</Text>
                    </View>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableRowCol1}>Resultat</Text>
                        <Text style={styles.tableRowCol2}>{roundNumber(land?.transactionResult)}</Text>
                    </View>
                    <View style={styles.tableSumRow}>
                        <Text style={styles.tableRowCol1}>Marginal</Text>
                        <Text style={styles.tableRowCol2}>{makePercent(land?.transactionResultMargin)}</Text>
                    </View>
                </View>
            </View>

            <View style={styles.section}>
                <Text style={styles.sectionHeader}>Entrepenad</Text>

                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableRowCol1}>Fakturerad (exkl. moms)</Text>
                        <Text style={styles.tableRowCol2}>{roundNumber(contract?.invoicedExclVat)}</Text>
                    </View>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableRowCol1}>Kostnader (exkl. moms)</Text>
                        <Text style={styles.tableRowCol2}>{roundNumber(contract?.landAndDevelopmentCosts)}</Text>
                    </View>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableRowCol1}>Resultat</Text>
                        <Text style={styles.tableRowCol2}>{roundNumber(contract?.profitOrLoss)}</Text>
                    </View>
                    <View style={styles.tableSumRow}>
                        <Text style={styles.tableRowCol1}>Marginal</Text>
                        <Text style={styles.tableRowCol2}>{makePercent(contract?.profitMargin)}</Text>
                    </View>
                </View>
            </View>
        </>
    );


    return PdfTemplate([page1], 'Rapport entreprenadvinst');
}