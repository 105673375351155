import { ChangeEvent, useEffect, useState } from 'react';
import styles from '../AdminTools.module.css';
import { SaveStandardValues } from '../../../../interfaces/systemSettings/StandardValues';
import { useSaveStandardValues } from '../../../../fetchData/mutations/useSaveStandardValues';
import { useStandardValues } from '../../../../fetchData/queries/settings/useStandardValues';
import { AdminActionButton } from './AdminInputs/AdminActionButton';


export const StandardValues = () => {
    const { data } = useStandardValues();
    const saveStandardValues = useSaveStandardValues();

    const [standardValues, setStandardValues] = useState<SaveStandardValues>({
        standardPercentageIndirectCosts: null,
        guaranteeProvisionPercentage: null
    });

    const [initialStandardValues, setInitialStandardValues] = useState<SaveStandardValues>({
        standardPercentageIndirectCosts: null,
        guaranteeProvisionPercentage: null
    });


    useEffect(() => {
        if (data && !standardValues.guaranteeProvisionPercentage && !standardValues.standardPercentageIndirectCosts) {
            const standardValues = {
                standardPercentageIndirectCosts: data?.standardPercentageIndirectCosts,
                guaranteeProvisionPercentage: data?.guaranteeProvisionPercentage
            };

            setInitialStandardValues(standardValues);
            setStandardValues(standardValues);
        }
    }, [data, standardValues.guaranteeProvisionPercentage, standardValues?.standardPercentageIndirectCosts]);


    useEffect(() => {
        const savedStandardValues = saveStandardValues.data?.data;

        if (savedStandardValues) {
            const standardValues = {
                standardPercentageIndirectCosts: savedStandardValues?.standardPercentageIndirectCosts,
                guaranteeProvisionPercentage: savedStandardValues?.guaranteeProvisionPercentage
            };

            setInitialStandardValues(standardValues);
        }
    }, [saveStandardValues.data?.data]);



    const percentInput = (key: string, label: string) => {
        const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
            const { name, value } = e.target;
            const parsedValue = value ? parseFloat(value) : null;

            setStandardValues(prev => ({ ...prev, [name]: parsedValue }));
        }

        return (
            <div className={styles.inputSpaceRow}>
                <label className={styles.labelPadding}>{label}</label>
                <input className={styles.standardValueInput}
                    type="number"
                    name={key}
                    value={standardValues?.[key as keyof typeof standardValues] ?? ''}
                    onChange={handleChange}
                    step='0.1'
                    min="0"
                    max="999"
                />
            </div>
        );
    };


    const standardValuesForm = () => {
        const handleSave = () => saveStandardValues.mutate(standardValues);
        const saveDisabled = Object.values(standardValues).some(value => value === null) ||
            JSON.stringify(standardValues) === JSON.stringify(initialStandardValues);

        return (
            <div className={styles.formContainer}>
                <h6 className={styles.inputGroupHeader}>Schablonvärden</h6>

                {percentInput('standardPercentageIndirectCosts', 'Indirekta kostnader (%)')}
                {percentInput('guaranteeProvisionPercentage', 'Garantiprovision (%)')}

                <AdminActionButton
                    buttonText={'Spara'}
                    handleAction={handleSave}
                    actionDisabled={saveDisabled}
                />
            </div>
        );
    };


    return standardValuesForm();
}