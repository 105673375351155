import { CellClassParams, ColDef, GetRowIdParams, RowClickedEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { Dispatch, SetStateAction, useCallback, useMemo, useRef, useState } from "react";
import { AG_GRID_LOCALE_SV } from "../../../MainGrids/gridConfig/SwedishLocale";
import styles from './OrgStructureGrid.module.css';
import { getRowHierarchyStyle } from "../helper/getRowHierarchyStyle";
import { UnableToLoadData } from "../../../SystemState/ErrorManagement/UnableToLoadData/UnableToLoadData";
import { defaultColDef } from "../../../MainGrids/gridConfig/DefaultColDef";
import { useOrgStructure } from "../../../../fetchData/queries/reports/grid/useOrgStructure";

interface OrgStructureGridProps {
  setSelectedOrgUnitIds: Dispatch<SetStateAction<string[]>>;
  reportIsGenerated: boolean;
}

export const useOrgStructureGrid = ({
  setSelectedOrgUnitIds,
  reportIsGenerated,
}: OrgStructureGridProps) => {

  const { isLoading, error, data } = useOrgStructure();
  const [selectedOrgUnitId, setSelectedOrgUnitId] = useState<string>('');

  const gridRef = useRef<AgGridReact>(null);



  const columnDefs = useMemo(() => {
    const colDefs = [
      {
        field: 'rb',
        rowGroup: true,
        hide: true,
        cellStyle: (params: CellClassParams) => getRowHierarchyStyle(params),
      },
      {
        field: 'ao',
        rowGroup: true,
        hide: true,
        cellStyle: (params: CellClassParams) => getRowHierarchyStyle(params),
      },
      {
        field: 'region',
        rowGroup: true,
        hide: true,
        cellStyle: (params: CellClassParams) => getRowHierarchyStyle(params),
      },
    ];

    return colDefs;
  }, []);


  const autoGroupColumnDef = useMemo((): ColDef => {
    return {
      headerName: 'Organisationsstruktur',
      field: 'mo',
      cellRendererParams: {
        suppressCount: true,
      },
      cellStyle: (params: CellClassParams) => getRowHierarchyStyle(params),
    };
  }, []);


  const onRowClicked = useCallback((e: RowClickedEvent) => {
    if (!reportIsGenerated) {
      const children = e.node?.allLeafChildren;
      const nodeId = e.node.id;
      nodeId ? setSelectedOrgUnitId(nodeId) : setSelectedOrgUnitId('');

      if (children && children.length > 0) {
        const childrenIds: string[] = children.map(child => child.data.id);

        if (e.node.isSelected()) {
          setSelectedOrgUnitIds(childrenIds);
        } else {
          setSelectedOrgUnitIds(prevState => prevState.filter(id => !childrenIds.includes(id)));
        }
      } else {
        if (e.node.isSelected()) {
          setSelectedOrgUnitIds([e.data.id]);
        } else {
          setSelectedOrgUnitIds(prevState => prevState.filter(id => e.data.id !== id));
        }
      }
    }
  }, [reportIsGenerated, setSelectedOrgUnitIds]);


  const onGridReady = useCallback(() => {
    if (reportIsGenerated) {
      gridRef.current!.api?.showLoadingOverlay();
    } else {
      gridRef.current!.api?.hideOverlay()
    }

    if (selectedOrgUnitId) {
      gridRef.current!.api?.getRowNode(selectedOrgUnitId)?.setSelected(true);
    }
  }, [reportIsGenerated, selectedOrgUnitId]);


  const getRowId = (params: GetRowIdParams) => params?.data.id?.toString();

  const loadingOverlayOverride = () => <div></div>;


  const OrgStructureGrid = useCallback(() => {
    return (
      <div className={`ag-theme-balham ${styles.gridContainer}`}>
        {error ?
          <UnableToLoadData message={'Organisationsstruktur kunde ej laddas'} /> :
          <AgGridReact
            ref={gridRef}
            rowData={data}
            columnDefs={columnDefs}
            rowSelection={'single'}
            defaultColDef={defaultColDef}
            autoGroupColumnDef={autoGroupColumnDef}
            groupDefaultExpanded={3}
            suppressContextMenu={true}
            localeText={AG_GRID_LOCALE_SV}
            onRowClicked={onRowClicked}
            getRowId={getRowId}
            onGridReady={onGridReady}
            loadingOverlayComponent={loadingOverlayOverride}
          />
        }
      </div >
    );
  }, [autoGroupColumnDef, columnDefs, data, error, onGridReady, onRowClicked]);


  return { isLoading, OrgStructureGrid };
}