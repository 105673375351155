import { CellClassParams } from "ag-grid-community";

export const LogStatusCellStyle = (params: CellClassParams) => {

    // 1 inaktiv, 2 aktiv, 3 ok, 4 fel, 5 startar
    switch (params.node?.data?.statusType) {
        case 2:
            return { background: 'yellow', color: 'black' };
        case 3:
            return { background: 'green', color: 'white' };
        case 4:
            return { background: 'red', color: 'white' };
        case 5:
            return { fontStyle: 'italic' };
        default:
            return {};
    }
};