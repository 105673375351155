import { ExcelExportParams, ProcessCellForExportParams } from 'ag-grid-community';
import { AggTranslations } from './../../interfaces/grids/performance/AggTranslation';
import { dateFormatter } from './../../utils/dateFormatter';
import { getAggValue } from './../../utils/getAggValue';
import ActionButton from './ActionButton';
import { useCallback } from 'react';
import useProjectId from './../../customHooks/useProjectId';
import { dateTimeFormatter } from './../../utils/dateTimeFormatter';


interface ExportExcelButtonProps {
  gridRefs: any[];
  fileName: string;
  sheetNames: string[];
  aggTranslations?: AggTranslations;
}

const ExportExcelButton = ({
  gridRefs,
  fileName,
  sheetNames,
  aggTranslations
}: ExportExcelButtonProps) => {
  const projectId = useProjectId();

  const exportAsExcel = useCallback(() => {
    const sheetData: string[] = gridRefs?.map((gridRef, index) => gridRef?.current!.api.getSheetDataForExcel({
      sheetName: sheetNames?.[index],
      processCellCallback(params: ProcessCellForExportParams): string {
        const accountKey: string = params.node?.key ?? "";
        const columnId: string = params.column?.getColId() ?? "";

        if (aggTranslations && accountKey && columnId) {
          const aggValue: number | null = getAggValue(aggTranslations, accountKey, columnId);
          if (aggValue) return aggValue.toFixed(0);
        }


        const columnType = params.column.getColDef()?.type;

        switch (columnType) {
          case 'numericColumn':
            if (typeof params.value === 'number') return params.value?.toFixed(0);
            break;
          case 'date':
            return dateFormatter(params);
          case 'dateTime':
            return dateTimeFormatter(params);
        }

        return params.value;
      },
    }));

    const excelExportParams: ExcelExportParams = {
      fileName: `${fileName}${projectId && '_' + projectId}_${new Date().toJSON().slice(0, 10).replace(/-/g, '_')}`
    };

    gridRefs[0].current!.api.exportMultipleSheetsAsExcel({ ...excelExportParams, data: sheetData });
  }, [aggTranslations, fileName, gridRefs, projectId, sheetNames]);

  return (
    <div data-testid="ExportExcelBtn">
      <ActionButton
        text={'Exportera excel'}
        handleOnClick={exportAsExcel}
        buttonStyles={['defaultButton']}
      />
    </div>
  )
};

export default ExportExcelButton;