import { useCallback, useState } from "react";
import { useAddInternalCredit } from "../../../fetchData/mutations/internalCredits/useAddInternalCredit";
import { InternalCreditSelect } from "./inputs/InternalCreditSelect";
import { InternalCreditDatePicker } from "./inputs/InternalCreditDatePicker";
import { InternalCreditAmount } from "./inputs/InternalCreditAmount";
import { Spinner, SpinnerSize } from "@fluentui/react";
import styles from './AddInternalCredit.module.css';
import ActionButton from "../../Buttons/ActionButton";
import { useInternalCredits } from "../../../fetchData/queries/internalCredits/useInternalCredits";
import { useProjectInfo } from "../../../fetchData/queries/useProjectInfo";


export const AddInternalCredit = () => {
    const { isFetching } = useInternalCredits();
    const addInternalCredit = useAddInternalCredit();

    const projectInfo = useProjectInfo().data;
    const projectIsActive: boolean = !(projectInfo?.isFinalized || projectInfo?.isArchived);

    const defaultDate = new Date().toLocaleDateString().slice(0, 10);
    const [selectedDate, setSelectedDate] = useState<string>(defaultDate);
    const [selectedType, setSelectedType] = useState<number>(2);
    const [amount, setAmount] = useState<number | null>(null);


    const handleAddInternalCredit = () => {
        if (selectedDate?.length > 0 && selectedType && amount && amount > 0) {
            addInternalCredit.mutate({ date: selectedDate, type: selectedType, amount: amount });
        }
    }


    const activeSubmit = () => (projectIsActive && selectedDate?.length > 0 && amount && amount > 0
        && !addInternalCredit.isLoading && !isFetching);


    const loadingSpinner = useCallback(() => {
        if (addInternalCredit.isLoading || isFetching) {
            return <Spinner className={styles.visibleLoadingSpinner} size={SpinnerSize.small} />;
        }
        return <Spinner className={styles.hiddenLoadingSpinner} size={SpinnerSize.small} />;
    }, [addInternalCredit.isLoading, isFetching])


    return (
        <div className={`${styles.flexContainer} ${styles.addInternalCreditContainer}`}>
            <div className={styles.inputsContainer}>
                <InternalCreditDatePicker defaultDate={defaultDate} setSelectedDate={setSelectedDate} />

                <InternalCreditSelect selectedType={selectedType} setSelectedType={setSelectedType} />

                <InternalCreditAmount amount={amount} setAmount={setAmount} />
            </div>

            <div className={`${styles.flexContainer} ${styles.submitContainer}`}>
                {loadingSpinner()}

                <ActionButton
                    text={'Lägg till'}
                    handleOnClick={handleAddInternalCredit}
                    buttonStyles={['saveButton']}
                    disabled={!activeSubmit()}
                />
            </div>
        </div>)
}