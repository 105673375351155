import styles from '../AdminTools.module.css';
import { ChangeEvent, useEffect, useState } from "react";
import { IContextualMenuProps } from "@fluentui/react";
import { useDeleteFinalAccounting } from '../../../../fetchData/mutations/finalAccounting/useDeleteFinalAccounting';
import { AdminInput } from './AdminInputs/AdminInput';
import { AdminDeleteButton } from './AdminInputs/AdminDeleteButton';


export const DeleteFinalAccounting = () => {
    const [projectId, setProjectId] = useState<string>('');
    const deleteFinalAccounting = useDeleteFinalAccounting();

    useEffect(() => {
        if (deleteFinalAccounting.data?.success) setProjectId('');
    }, [deleteFinalAccounting.data?.success]);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setProjectId(e.target.value);
    };

    const handleDelete = () => deleteFinalAccounting.mutate(projectId);

    const menuProps: IContextualMenuProps = {
        items: [
            {
                key: 'removeFinalAccounting',
                text: 'Ja, ta bort slutredovisning',
                iconProps: { iconName: 'Undo' },
                onClick: handleDelete,
            },
        ],
    }

    return (
        <div className={styles.formContainer}>
            <h6 className={styles.inputGroupHeader}>Slutredovisning</h6>

            <AdminInput
                label={'Projekt-id'}
                inputValue={projectId}
                handleChange={handleChange}
            />

            <AdminDeleteButton
                buttonText={'Ta bort'}
                menuProps={menuProps}
                disabled={!projectId}
            />
        </div>
    );
}