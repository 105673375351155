import styles from './Status.module.css';
import { Spinner, SpinnerSize } from '@fluentui/react';

const LoadingSpinner = ({ text }: { text?: string }) => (
  <div data-testid="LoadingSpinner">
    <p className={styles.loadingSpinner}>
      {text ?? 'Laddas...'}
    </p>
    <Spinner size={SpinnerSize.large} />
  </div>
);

export default LoadingSpinner;