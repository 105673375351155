import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAccessToken } from "../useAccessToken";
import { useCallback, } from "react";
import { FetchResponse, makeResultFetch } from "../genericFetch";
import { apiRequestParams } from "../fetchOptions";
import useProjectId from "../../customHooks/useProjectId";


export const useManualRevenueRecognition = () => {
    const getAccessToken = useAccessToken();
    const projectId = useProjectId();
    const queryClient = useQueryClient();


    const mutationFunction = useCallback(async () => {
        return getAccessToken().then(accessToken => {
            if (!accessToken) throw new Error("Access token is undefined");

            // null on error...
            const fetchedData = makeResultFetch<boolean>(
                `/Financials/RevenueRecognition/Project/${projectId}`,
                apiRequestParams(accessToken, 'POST')
            ).then((response: FetchResponse<boolean>) => {
                const { message, success } = response;

                if (!success) throw Error(message);

                return response;
            });

            return fetchedData;
        });
    }, [getAccessToken, projectId]);


    const handleSuccess = (response: FetchResponse<boolean>) => {
        if (response.success) {
            queryClient.invalidateQueries([projectId, 'evaluateAccounting']);
            queryClient.invalidateQueries([projectId, 'finalAccountReport']);
        }

        return response;
    };


    const manualRevenueRecognition = useMutation({
        mutationFn: mutationFunction,
        onSuccess: handleSuccess,
    });


    return manualRevenueRecognition;
}