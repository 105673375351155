import { useMemo } from "react";
import { FullDateInput } from "../CalculationBarInputs/DateInput/FullDateInput";
import styles from '../CalculationsBar.module.css';
import { CalcBarParams } from "./interfaces/CalcBarParams";


export const usePlanning = ({
    projectAttributes,
    setProjectAttributes,
    initialValues,
    setChangedValue,
    translation,
    readOnlyMode,
}: CalcBarParams) => {
    const planning = useMemo(() => {
        return (<div className={styles.fieldsContainer}>
            <h5 className={styles.fieldsHeader}>Planering</h5>

            <FullDateInput
                values={projectAttributes}
                initialValues={initialValues}
                setValues={setProjectAttributes}
                setChangedValue={setChangedValue}
                label={translation.mgmtBuildStartConsentPlanned}
                attributeName={'mgmtBuildStartConsentPlanned'}
                disabled={readOnlyMode}
            />

            <FullDateInput
                values={projectAttributes}
                initialValues={initialValues}
                setValues={setProjectAttributes}
                setChangedValue={setChangedValue}
                label={translation.mgmtBuildStartConsent}
                attributeName={'mgmtBuildStartConsent'}
                disabled
            />
        </div>
        )
    }, [projectAttributes, initialValues, setProjectAttributes, setChangedValue, translation.mgmtBuildStartConsentPlanned,
        translation.mgmtBuildStartConsent, readOnlyMode]);


    return planning;
}