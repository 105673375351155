import { Checkbox, DefaultButton, PrimaryButton } from "@fluentui/react";
import { useEvaluateAccounting } from "../../../../../fetchData/queries/accounting/useEvaluateAccounting";
import LoadingSpinner from "../../../../StatusIndicators/LoadingSpinner";
import styles from './AccountingModalContent.module.css';
import { useState } from "react";
import { UseMutationResult } from "@tanstack/react-query";
import { FetchResponse } from "../../../../../fetchData/genericFetch";


interface AccountingModalContentProps {
    infoText: string;
    loadingText: string;
    successText: string;
    mutation: UseMutationResult<FetchResponse<boolean>, unknown, void, unknown>;
    verificationText?: string;
}

export const AccountingModalContent = ({
    infoText,
    loadingText,
    successText,
    mutation,
    verificationText,
}: AccountingModalContentProps) => {

    const evaluateAccounting = useEvaluateAccounting();

    const [executionDisabled, setExecutionDisabled] = useState(verificationText ? true : false);

    const lastUpdate = () => {
        const dateTime = evaluateAccounting.data?.lastRecordedOutcome?.split('T');

        if (dateTime) return <p>{`Utfall senast uppdaterat: ${dateTime?.[0]} - ${dateTime?.[1]}`} </p>;
    }

    const warnings = () => {
        const warningMessages = evaluateAccounting.data?.warnings;

        if (warningMessages && warningMessages?.length > 0) {
            return (
                <>
                    <h4 className={styles.warningText}>Varningar</h4>
                    <div>
                        {warningMessages.map((warning, index) => {
                            return <p key={index}>{`${index + 1}. ${warning}`}</p>
                        })
                        }
                    </div>
                </>
            );
        }
    }

    const infoTextElement = () => (
        <p><b>
            {infoText}
        </b></p>
    )


    const verificationCheckBox = () => {
        if (verificationText && verificationText?.length > 0) {
            return (
                <div className={styles.checkboxContainer}>
                    < Checkbox
                        onChange={() => setExecutionDisabled(!executionDisabled)}
                        label={verificationText}
                    />
                </div>
            );
        }
    }


    const buttons = () => (
        <div className={styles.buttonContainer}>
            {evaluateAccounting.data?.isAdvisable ?
                <PrimaryButton
                    onClick={() => mutation.mutate()}
                    disabled={executionDisabled}
                >Kör</PrimaryButton> :
                <DefaultButton
                    className={styles.warningText}
                    onClick={() => mutation.mutate()}
                    disabled={executionDisabled}
                >Kör</DefaultButton>
            }
        </div>
    )


    if (evaluateAccounting.isLoading || mutation.isLoading) {
        return (
            <div className={styles.contentContainer}>
                <LoadingSpinner text={loadingText} />
            </div>
        );
    }

    const responseMessage = (message: string) => (
        <div className={styles.contentContainer}>
            <p className={styles.feedbackText}>{message}</p>
        </div>
    );


    if (mutation.data?.success) return responseMessage(successText);

    if (mutation.error && mutation.error instanceof Error) return responseMessage(mutation.error.message);


    return (
        <div className={styles.contentContainer}>
            {warnings()}

            {infoTextElement()}

            {verificationCheckBox()}

            {buttons()}

            {lastUpdate()}
        </div>
    );
}

