import { PDFViewer } from '@react-pdf/renderer';
import { ReactNode } from 'react';
import styles from './PdfReportViewer.module.css';


interface PdfReportViewerProps {
  children: ReactNode;
}


export const PdfReportViewer = ({
  children
}: PdfReportViewerProps) => (
  <div className={styles.pdfReportViewerContainer}>
    {children &&
      <PDFViewer style={{ width: '100%', height: 'calc(100% - 4px)' }}>
        <>
          {children}
        </>
      </PDFViewer>}
  </div>
);
