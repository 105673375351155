import { Dispatch, SetStateAction } from "react"
import { DefaultDatePicker } from "../../Inputs/DefaultDatePicker/DefaultDatePicker"

export const SchedulerDatePicker = (
    setChangedDate: Dispatch<SetStateAction<string>>
) => {

    return (
        <DefaultDatePicker
            setChangedDate={setChangedDate}
            dateFormat={'date'}
            defaultDate={new Date().toLocaleDateString('sv-SV')}
        />
    )
}