import { MessageBar, MessageBarButton, MessageBarType } from '@fluentui/react';
import { ErrorModal } from '../ErrorModal';
import { useEffect, useState } from 'react';
import { useInteractionContext } from '../../../../context/InteractionContext';


// used on if error is partial or when caused by a user action
export const ErrorBar = () => {
    const [displayMessageBar, setDisplayMessageBar] = useState<boolean>(true);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const { errors } = useInteractionContext();



    useEffect(() => {
        if (errors?.length > 0) {
            setDisplayMessageBar(true);
        } else {
            setDisplayMessageBar(false);
        }
    }, [errors?.length])


    const notificationAction = () => (
        <MessageBarButton onClick={() => setIsModalOpen(true)}>
            Visa mer detaljer
        </MessageBarButton>
    );



    if (displayMessageBar) {
        return (
            <>
                <MessageBar
                    messageBarType={MessageBarType.error}
                    isMultiline={false}
                    onDismiss={() => setDisplayMessageBar(false)}
                    dismissButtonAriaLabel="Close"
                    actions={notificationAction()}
                >
                    Något gick fel
                </MessageBar>

                <ErrorModal
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                />
            </>
        )
    };


    return <></>;
}