import { Dispatch, SetStateAction } from "react";
import styles from './Inputs.module.css';

interface InternalCreditAmountProps {
    amount: number | null;
    setAmount: Dispatch<SetStateAction<number | null>>;
}

export const InternalCreditAmount = ({
    amount,
    setAmount
}: InternalCreditAmountProps) => {
    const internalCreditAmount = () => (
        <div className={styles.inputContainer}>
            <label className={styles.inputLabel}>Belopp</label>

            <input
                className={styles.amountInput}
                onChange={(e: any) => setAmount(e.target.valueAsNumber)}
                type="number"
                min="0"
                value={amount?.toString() ?? ''}
            ></input>
        </div>
    )

    return internalCreditAmount();
}