import { ContextualMenu, IContextualMenuItem } from '@fluentui/react/lib/ContextualMenu';
import { Dispatch, SetStateAction, useCallback, useRef } from 'react';
import styles from '../Buttons.module.css';


interface MenuButtonProps {
  showMenu: boolean;
  setShowMenu: Dispatch<SetStateAction<boolean>>;
  menuItems: IContextualMenuItem[];
  text: string;
  modal?: JSX.Element;
  disabledMenu?: boolean
}

const MenuButton = ({
  showMenu,
  setShowMenu,
  menuItems,
  text,
  modal,
  disabledMenu
}: MenuButtonProps) => {

  const linkRef = useRef(null);

  const onShowContextualMenu = useCallback((ev: React.MouseEvent<HTMLElement>) => {
    ev.preventDefault();
    setShowMenu(true);
  }, [setShowMenu]);

  const onHideContextualMenu = useCallback(() => setShowMenu(false), [setShowMenu]);

  return (
    <div>
      <button
        className={`${styles.actionButton} ${styles.defaultButton}`}
        ref={linkRef}
        onClick={onShowContextualMenu}
        disabled={disabledMenu}
      >
        {text}
      </button>

      <ContextualMenu
        items={menuItems}
        hidden={!showMenu}
        target={linkRef}
        onItemClick={onHideContextualMenu}
        onDismiss={onHideContextualMenu}
      />

      {modal}
    </div>
  );
};

export default MenuButton;
