import { useQueries } from "@tanstack/react-query";
import { useOrgUnitProjectIds } from "./useOrgUnitProjectIds";
import { useAccessToken } from "../../../useAccessToken";
import { makeResultFetch } from "../../../genericFetch";
import { ExtendedGridReportInputs, ResCalcProjReportRow } from "../../../../interfaces/reports/ResCalcProjReport";
import { apiRequestParams } from "../../../fetchOptions";
import { useHandleRetry } from "../../../useHandleRetry";
import PQueue from "p-queue";
import { useCallback } from "react";


const useFetch = (fromDate: string, toDate: string) => {
    const getAccessToken = useAccessToken();

    const queue = new PQueue({ concurrency: 10 });

    const queryFunction = async (
        projectId: string,
        signal?: AbortSignal | null
    ) => await queue.add(() => getAccessToken().then((accessToken) => {
        if (!accessToken) throw new Error("Access token is undefined");

        const fetchedData = makeResultFetch<ResCalcProjReportRow[]>(
            `/Reports/${projectId}/RevenueRecognitionHistory?from=${fromDate}&to=${toDate}`,
            apiRequestParams(accessToken, 'GET', null, signal)
        ).then((response) => {
            const { data, message, success } = response;

            if (!success) throw Error(message);

            return data;
        });

        return fetchedData;
    }));


    return queryFunction;
}


export const useResCalcProjReportFlow = ({
    orgUnitIds,
    projectTypeIds,
    fromDate,
    toDate
}: ExtendedGridReportInputs) => {

    const queryFunction = useFetch(fromDate, toDate);
    const handleRetry = useHandleRetry();

    // return loading state if slow response?
    const projectIds = useOrgUnitProjectIds(orgUnitIds, projectTypeIds).data;


    const queryEnabled = !!queryFunction && projectIds && projectIds?.length > 0
        && fromDate?.length > 0 && toDate?.length > 0;


    const mapQueries = useCallback(() => {
        if (projectIds) {
            return projectIds.map((projectId) => {
                return {
                    queryKey: ['resCalcProjReport', projectId, fromDate, toDate],
                    queryFn: ({ signal }: { signal?: AbortSignal }) => queryFunction(projectId, signal),
                    enabled: queryEnabled,
                    retry: handleRetry,
                }
            })
        }

        return [];

    }, [fromDate, handleRetry, projectIds, queryEnabled, queryFunction, toDate]);


    const report = useQueries({
        queries: mapQueries(),
    });

    return report;
}