import { CellValueChangedEvent } from "ag-grid-community";
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { Edits } from "../../../interfaces/editing/Edits";
import { createPrefixedEdits } from "../helper/createPrefixedEdits";
import { createEditsDto } from "../../../utils/createEditsDto";
import { useRefresh } from "../../../fetchData/mutations/useRefresh";


interface OnCellValueChanged {
    editingEnabled: boolean;
    readOnlyMode: boolean;
    edits: Edits;
    setEdits: Dispatch<SetStateAction<Edits>>;
    setLoadProjectEconomy: Dispatch<SetStateAction<boolean>>;
}


export const useOnCellValueChanged = ({
    editingEnabled,
    readOnlyMode,
    edits,
    setEdits,
    setLoadProjectEconomy,
}: OnCellValueChanged) => {

    const refresh = useRefresh();
    const [editEvent, setEditEvent] = useState<CellValueChangedEvent | undefined>(undefined);


    useEffect(() => {
        const debounceDelay: number = 1000;

        const timer = setTimeout(() => {
            if (editEvent) {
                const editsDto = createEditsDto(edits);
                refresh.mutate(editsDto);

                setEditEvent(undefined);
            }
        }, debounceDelay);

        return () => clearTimeout(timer);

    }, [editEvent, edits, refresh]);


    const onCellValueChanged = useCallback((event: CellValueChangedEvent) => {
        if (event && editingEnabled && !readOnlyMode) {
            const prefixedEdits = createPrefixedEdits(event, edits);
            setEdits(prefixedEdits);
            setLoadProjectEconomy(false);
            setEditEvent(event);
        }
    }, [editingEnabled, edits, readOnlyMode, setEdits, setLoadProjectEconomy]);


    return onCellValueChanged;
}