import { StyleSheet } from '@react-pdf/renderer';

// stylesheet required for react-pdf
export const styles = StyleSheet.create({
    page: {
        paddingLeft: 80,
        paddingRight: 80,
        paddingTop: 20,
        paddingBottom: 20,
        justifyContent: 'space-between'
    },
    title: {
        fontSize: 18,
        marginBottom: 20,
        textAlign: 'center',
        textTransform: 'uppercase',
    },

    section: {
        marginBottom: 10,
        height: 'auto'
    },
    sectionHeader: {
        fontSize: 14,
        marginBottom: 4,
        borderBottom: '1px solid black',
        textTransform: 'uppercase',
    },
    sectionSpacing: {
        marginTop: 20,
        marginBottom: 20
    },
    tableRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 4
    },

    blueMessage: {
        textTransform: 'uppercase',
        color: 'blue'
    },
    redMessage: {
        textTransform: 'uppercase',
        color: 'red'
    },

    tableSumRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderTop: '1px solid black',
        marginBottom: 4,
        paddingTop: 1
    },
    table: {
        width: '100%',
        fontSize: 10,
    },
    tableRowCol1: {
        width: '60%',
        textAlign: 'left'
    },
    tableRowCol2: {
        width: '40%',
        textAlign: 'right'
    },
    rowSpacing: {
        marginTop: 10,
    },


    headerFooter: {
        fontSize: 10,
        color: 'gray',
        textAlign: 'right'
    },
    headerFooterContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 10
    },
    rbLogo: {
        width: 80
    }
})