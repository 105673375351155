import { useEditingContext } from '../../../context/EditingContext';
import { createEditsDto } from '../../../utils/createEditsDto';
import { calculationBarTranslation } from '../../CalculationsBar/calculationBarTranslation';
import styles from './SaveEditsPreview.module.css';

interface SaveEditsPreviewProps {
  showEdits: boolean;
}

const SaveEditsPreview = ({ showEdits }: SaveEditsPreviewProps) => {

  const { edits } = useEditingContext();

  const editsDto = createEditsDto(edits);
  const translation: { [key: string]: string } = calculationBarTranslation;

  const projectAttributesPresentation: JSX.Element[] = Object.entries(edits.projectAttributes).map((entry) => {
    const [key, value] = entry;
    return value && <li key={key}>{`${translation[key]}: ${value}`}</li>
  });


  const budgetsPresentation: JSX.Element[] = editsDto.budgets.map((budget, index) => <li key={index}>{`Konto ${budget?.accountNumber}: ${budget?.budget ? budget?.budget : 'raderad'}`}</li>);
  const columnsPresentation: JSX.Element[] = editsDto.columns.map((column, index) =>
    <li key={index}>{`Konto ${column.accountNumber}${column.year > 0 && 0 + column.month > 0 ? ', ' + column.year + '-' + ('0' + column.month).slice(-2) : ''}: ${column.prognosis
      ? column.prognosis : 'raderad'}`}</li>);
  const commentsPresentation: JSX.Element[] = editsDto.comments.map((comment, index) => <li key={index}>{`Konto ${comment?.accountNumber}: ${comment?.comment ? comment?.comment : 'raderad'}`}</li>);


  const preview = () => {

    if (showEdits) {
      return (<div className={styles.showEditsContainer}>
        {projectAttributesPresentation?.length > 0 &&
          <>
            <h4>Grunduppgifter</h4>
            <ol>
              {projectAttributesPresentation}
            </ol>
          </>
        }

        {columnsPresentation?.length > 0 &&
          <>
            <h4>Prognoser</h4>
            <ol>
              {columnsPresentation}
            </ol>
          </>
        }

        {budgetsPresentation?.length > 0 &&
          <>
            <h4>Budgetar</h4>
            <ol>
              {budgetsPresentation}
            </ol>
          </>}

        {commentsPresentation?.length > 0 &&
          <>
            <h4>Kommentarer</h4>
            <ol>
              {commentsPresentation}
            </ol>
          </>}
      </div>)
    }
  }


  return <>{preview()}</>;
}

export default SaveEditsPreview;
