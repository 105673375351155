import { useMemo } from "react";
import { dateFormatter } from "../../../utils/dateFormatter";
import { numberFormatter } from "../../../utils/numberFormatter";

export const useAccountSpecColDefs = () => {
    const accountSpecColDefs = useMemo(() => [
        {
            field: 'grandParentAccountName',
            rowGroup: true,
            hide: true
        },
        {
            field: 'parentAccountName',
            rowGroup: true,
            hide: true
        },
        {
            field: 'amount',
            headerName: 'Belopp',
            aggFunc: 'sum',
            type: 'numericColumn',
            valueFormatter: numberFormatter
        },
        {
            headerName: 'Bokf. Dat.',
            field: 'postingDate',
            type: ['rightAligned', 'date'],
            valueFormatter: dateFormatter
        },
        {
            headerName: 'Period',
            field: 'period',
            type: 'rightAligned',
        },
        {
            headerName: 'Beskrivning',
            field: 'description',
            flex: 2,
            type: 'rightAligned',
        },
        {
            headerName: 'Ver.Id',
            field: 'verificationId',
            type: 'numericColumn',
        },
    ], []);

    return accountSpecColDefs;
}