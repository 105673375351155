import { useCallback, useEffect, useMemo, useState } from 'react';
import { FluentModal } from './../Modals/FluentModal/FluentModal';
import { SHEET } from './../../fetchData/fetch/sheet';
import useEvaluateSaveEnabled from './../../customHooks/useEvaluateSaveEnabled';
import { DirectionalHint, TooltipHost } from '@fluentui/react';
import { InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import ActionButton from './ActionButton';
import { useEditingContext } from './../../context/EditingContext';
import { useSheetContext } from './../../context/SheetContext';
import { useProjectInfo } from './../../fetchData/queries/useProjectInfo';
import useProjectId from './../../customHooks/useProjectId';
import { modalTimeout } from './../../utils/modalTimeout';
import ConfirmPrognosis from '../Modals/FluentModal/Content/Confirmation/ConfirmPrognosis';


const ConfirmPrognosisButton = () => {

  const projectId = useProjectId();

  const projectInfo = useProjectInfo().data;
  const isFinalized = projectInfo?.isFinalized;
  const isArchived = projectInfo?.isArchived;

  const { lockedBudget } = useSheetContext();

  const {
    editingEnabled,
    selectedSnapshotId
  } = useEditingContext();

  const [isConfirmPrognosisModalOpen, setIsConfirmPrognosisModalOpen] = useState<boolean>(false);
  // 0 cancel, 1 yes, 2 no
  const [confirmPrognosisModalInput, setConfirmPrognosisModalInput] = useState<string | number | null>(null);

  const edited: boolean = useEvaluateSaveEnabled();
  // 0 initial state, 1 loading, 2 confirmation
  const [confirmPrognosisStatus, setConfirmPrognosisStatus] = useState<number>(0);
  const [confirmPrognosisError, setConfirmPrognosisError] = useState<string>('');

  const { instance, inProgress, accounts } = useMsal();

  useEffect(() => {
    if (confirmPrognosisModalInput === 1) {
      const accessTokenRequest = {
        scopes: ["user.read"],
        account: accounts[0]
      }
      if (inProgress === InteractionStatus.None) {
        instance.acquireTokenSilent(accessTokenRequest).then((response) => {
          const accessToken: string = response.accessToken;

          SHEET.putConfirmPrognosis(projectId, accessToken).then(responseOk => {
            if (responseOk) {
              setTimeout(() => {
                setConfirmPrognosisStatus(2);
              }, 1000);

              modalTimeout(setIsConfirmPrognosisModalOpen);
            }
          }).catch((e: Error) => setConfirmPrognosisError(e?.message));

        }).catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(accessTokenRequest);
          }
          console.log(error);
        })
      }
    }
  }, [accounts, confirmPrognosisModalInput, inProgress, instance, projectId]);


  const confirmPrognosisDisabled = useMemo(() => {
    if (selectedSnapshotId !== 'aktuell projektekonomi' || isFinalized || isArchived || !lockedBudget ||
      !editingEnabled || edited) {
      return true;
    }
    return false;
  }, [selectedSnapshotId, isFinalized, isArchived, lockedBudget, editingEnabled, edited])


  const confirmPrognosisTooltip = useMemo(() => {
    if (!lockedBudget) return 'Prognos kan ej bekräftas då budgeten ej är låst';

    return selectedSnapshotId === 'aktuell projektekonomi'
      ? 'Spara projektekonomin för att bekräfta prognos'
      : 'Kan ej bekräfta prognos för historisk projektekonomi'
  }, [lockedBudget, selectedSnapshotId]);


  const handleOnClick = useCallback(() => {
    setConfirmPrognosisError('');
    setConfirmPrognosisModalInput(null);
    setConfirmPrognosisStatus(0);
    setIsConfirmPrognosisModalOpen(true);
  }, [])

  const confirmPrognosisButton = (disabled?: boolean) => (
    <ActionButton
      text={'Bekräfta prognos'}
      handleOnClick={handleOnClick}
      buttonStyles={['defaultButton']}
      disabled={disabled} />
  )

  return (
    <>
      {confirmPrognosisDisabled
        ?
        <TooltipHost
          content={confirmPrognosisTooltip}
          id={'confirmPrognosisButtonTooltip'}
          directionalHint={DirectionalHint.bottomCenter}>
          {confirmPrognosisButton(confirmPrognosisDisabled)}
        </TooltipHost>
        :
        confirmPrognosisButton()
      }

      <FluentModal
        isModalOpen={isConfirmPrognosisModalOpen}
        setIsModalOpen={(confirmPrognosisStatus !== 1 || confirmPrognosisError) ? setIsConfirmPrognosisModalOpen : undefined}
        isModeLess={false}
        modalHeader={confirmPrognosisError ? 'Något gick fel' : 'Bekräfta prognos'}
        setModalInput={setConfirmPrognosisModalInput}
        modalContent={
          confirmPrognosisError
            ?
            <p>{confirmPrognosisError}</p>
            :
            <ConfirmPrognosis
              setIsModalOpen={setIsConfirmPrognosisModalOpen}
              setModalInput={setConfirmPrognosisModalInput}
              confirmPrognosisStatus={confirmPrognosisStatus}
              setConfirmPrognosisStatus={setConfirmPrognosisStatus} />
        }
      />
    </>
  )
};

export default ConfirmPrognosisButton;
