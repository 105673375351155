import styles from './SystemSettings.module.css';
import MainHeader from '../Headers/MainHeader/MainHeader';
import { useState } from 'react';
import SystemSettingsSubHeader from '../Headers/SubHeaders/SystemSettingsSubHeader';
import AccountTemplates from './AccountTemplates/AccountTemplates';
import ElementAccess from '../../AccessControl/ElementAccess';
import ResultCalculationScheduler from './ResultCalculationScheduler/ResultCalculationScheduler';
import ResultCalculationLog from './ResultCalculationLogs/ResultCalculationLogs';
import { usePrefetchRevenueRecognitionSchedule } from '../../fetchData/queries/accounting/useRevenueRecognitionSchedule';
import { useUser } from '../../fetchData/queries/useUser';
import { usePrefetchRevenueRecognitionLog } from '../../fetchData/queries/accounting/useRevenueRecognitionLog';
import { NotificationBars } from '../SystemState/ErrorManagement/MessageBars/NotificationBars';
import { AdminTools } from './AdminTools/AdminTools';


const SystemSettings = () => {
  const [selectedPivot, setSelectedPivot] = useState<number>(0);
  const [selectNrOfRows, setSelectNrOfRows] = useState<number>(1000);

  const scopes = useUser().data?.scopes;
  const prefetchResCalcSchedule = usePrefetchRevenueRecognitionSchedule();
  const prefetchResCalcLog = usePrefetchRevenueRecognitionLog(selectNrOfRows);


  if (scopes?.['scheduling_crud']) prefetchResCalcSchedule();
  if (scopes?.['accounting_read']) prefetchResCalcLog();


  document.title = 'Inställningar';

  return (
    <div className={styles.systemSettingsContainer} data-testid="SystemSettings">
      <MainHeader />

      <SystemSettingsSubHeader
        selectedPivot={selectedPivot}
        setSelectedPivot={setSelectedPivot}
      />

      <NotificationBars />

      <ElementAccess scopeKey='accounting_read'>
        {selectedPivot === 0 &&
          <ResultCalculationLog
            selectNrOfRows={selectNrOfRows}
            setSelectNrOfRows={setSelectNrOfRows}
          />
        }
      </ElementAccess>

      <ElementAccess scopeKey='scheduling_crud'>
        {selectedPivot === 1 && <ResultCalculationScheduler />}
      </ElementAccess>

      <ElementAccess scopeKey='account_templates'>
        {selectedPivot === 2 && <AccountTemplates />}
      </ElementAccess>

      <ElementAccess scopeKey='system_settings'>
        {selectedPivot === 3 && <AdminTools />}
      </ElementAccess>
    </div>
  );
};

export default SystemSettings;
