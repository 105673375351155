import { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';
import { ACCOUNTTEMPLATES } from '../../../fetchData/fetch/accountTemplates';
import AccountTemplateGrid from './AccountTemplateGrid/AccountTemplateGrid';
import { AccountTemplate, AccountTemplateActions, AccountTemplateChanges, AccountTemplateResponse, AccountTemplateRow, AccountTemplateStatus, NumberStringPairs } from '../../../interfaces/systemSettings/AccountTemplate';
import { FluentModal } from '../../Modals/FluentModal/FluentModal';
import styles from './AccountTemplates.module.css';
import AccountTemplateModal from '../../Modals/FluentModal/Content/AccountTemplateModal/AccountTemplateModal';
import AccountTemplateGridHeader from '../../Headers/ActionHeaders/AccountTemplateGridHeader';

// todo refactor to rq and make components

const AccountTemplates = () => {
  const { instance, inProgress, accounts } = useMsal();

  const [accountTemplate, setAccountTemplate] = useState<AccountTemplate>({ 1: [], 2: [] });
  const [vatTypes, setVatTypes] = useState<NumberStringPairs>({});
  const [economyTypes, setEconomyTypes] = useState<NumberStringPairs>({ 0: 'Laddas...' });
  const [sheetTypeKey, setSheetTypeKey] = useState<number>(1);
  const [economyTypeKey, setEconomyTypeKey] = useState<number>(1);

  // 0 and negative ids never used in db, string in ag grid
  const [lastAddedRowId, setLastAddedRowId] = useState<number>(0);
  const [accountTemplateEdits, setAccountTemplateEdits] = useState<AccountTemplateChanges>({ 1: {}, 2: {} });
  const [accountTemplateDeletes, setAccountTemplateDeletes] = useState<AccountTemplateChanges>({ 1: {}, 2: {} });
  const [accountTemplateActions, setAccountTemplateActions] = useState<AccountTemplateActions>({ load: true, save: false });
  const [accountTemplateValidated, setAccountTemplateValidated] = useState<AccountTemplateStatus>({ 1: undefined, 2: undefined });
  const [accountTemplateSaved, setAccountTemplateSaved] = useState<AccountTemplateStatus>({ 1: undefined, 2: undefined });

  const [accountTemplateError, setAccountTemplateError] = useState<AccountTemplateStatus | undefined>(undefined);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);


  useEffect(() => {
    const accessTokenRequest = {
      scopes: ["user.read"],
      account: accounts[0]
    }
    if (inProgress === InteractionStatus.None) {
      instance.acquireTokenSilent(accessTokenRequest).then((response) => {
        const accessToken: string = response.accessToken;

        if (accountTemplateActions.load) {
          ACCOUNTTEMPLATES.getEconomyTypes(accessToken).then((data: NumberStringPairs) => setEconomyTypes(data)).catch((e: Error) => console.log(e));
          ACCOUNTTEMPLATES.getVatTypes(accessToken).then((data: NumberStringPairs) => setVatTypes(data)).catch((e: Error) => console.log(e));
          ACCOUNTTEMPLATES.getAccountTemplateMaxId(accessToken).then(response => setLastAddedRowId(response.data)).catch((e: Error) => console.log(e));

          [1, 2].forEach((sheetTypeKey: number) => {
            ACCOUNTTEMPLATES.getAccountTemplate(economyTypeKey, sheetTypeKey, accessToken).then((accountTemplateData: AccountTemplateRow[]) => {
              setAccountTemplate(prevState => { return { ...prevState, [sheetTypeKey]: accountTemplateData } });
            }).catch((e: Error) => console.log(e));
          });

          setAccountTemplateActions({ ...accountTemplateActions, load: false });
        }

        if (accountTemplateActions.save) {
          [1, 2].forEach((sheetTypeKey: number) => {
            Object.keys(accountTemplateEdits[sheetTypeKey as keyof AccountTemplateChanges])?.length > 0 &&
              ACCOUNTTEMPLATES.putSaveAccountTemplate(economyTypeKey, sheetTypeKey, accountTemplateEdits[sheetTypeKey as keyof AccountTemplateChanges], accessToken)
                .then((response: AccountTemplateStatus) => {
                  if (response && accountTemplateSaved[sheetTypeKey as keyof AccountTemplateStatus]?.success !== false) {
                    setAccountTemplateSaved(prevState => { return { ...prevState, [sheetTypeKey]: response } });
                  }
                });
          });

          [1, 2].forEach((sheetTypeKey: number) => {
            Object.keys(accountTemplateDeletes[sheetTypeKey as keyof AccountTemplateChanges])?.length > 0 &&
              ACCOUNTTEMPLATES.deleteAccountTemplateRows(economyTypeKey, sheetTypeKey, accountTemplateDeletes[sheetTypeKey as keyof AccountTemplateChanges], accessToken)
                .then((response: AccountTemplateStatus) => {
                  if (response && accountTemplateSaved[sheetTypeKey as keyof AccountTemplateStatus]?.success !== false) {
                    setAccountTemplateSaved(prevState => { return { ...prevState, [sheetTypeKey]: response } });
                  }
                });
          });

          setAccountTemplateActions({ ...accountTemplateActions, save: false });
        }
      }).catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenRedirect(accessTokenRequest);
        }
        console.log(error);
      });
    }
  }, [accountTemplateActions, accountTemplateDeletes, accountTemplateEdits, accountTemplateSaved, accounts, economyTypeKey, inProgress, instance]);

  useEffect(() => {
    [1, 2].forEach((sheetTypeKey: number) => {
      if (accountTemplateValidated[sheetTypeKey as keyof AccountTemplateStatus]?.success === false) {
        setAccountTemplateError(accountTemplateValidated);
        setIsModalOpen(true);
      }
    });
  }, [accountTemplate, accountTemplateValidated]);

  useEffect(() => {
    const cashflowSaved: AccountTemplateResponse | undefined = accountTemplateSaved[1];
    const performanceSaved: AccountTemplateResponse | undefined = accountTemplateSaved[2];

    if (cashflowSaved !== undefined || performanceSaved !== undefined) {
      if ((cashflowSaved?.success === true || cashflowSaved === undefined) &&
        (performanceSaved?.success === true || performanceSaved === undefined)) {
        setAccountTemplateEdits({ 1: {}, 2: {} });
        setAccountTemplateDeletes({ 1: {}, 2: {} });
      } else {
        setAccountTemplateError(accountTemplateSaved);
        setIsModalOpen(true);
      }
      setAccountTemplateSaved({ 1: undefined, 2: undefined });
    }
  }, [accountTemplateDeletes, accountTemplateEdits, accountTemplateSaved]);


  return (
    <div className={styles.accountTemplatesContainer}>
      <AccountTemplateGridHeader
        accountTemplateEdits={accountTemplateEdits}
        accountTemplateDeletes={accountTemplateDeletes}
        economyTypes={economyTypes}
        accountTemplateActions={accountTemplateActions}
        setSheetTypeKey={setSheetTypeKey}
        setEconomyTypeKey={setEconomyTypeKey}
        setAccountTemplateActions={setAccountTemplateActions}
      />

      {sheetTypeKey &&
        <AccountTemplateGrid
          rowData={accountTemplate[sheetTypeKey as keyof AccountTemplate]}
          vatTypes={vatTypes}
          sheetTypeKey={sheetTypeKey}
          economyTypeKey={economyTypeKey}
          accountTemplateEdits={accountTemplateEdits[sheetTypeKey as keyof AccountTemplateChanges]}
          setAccountTemplateEdits={setAccountTemplateEdits}
          setAccountTemplateDeletes={setAccountTemplateDeletes}
          setAccountTemplateValidated={setAccountTemplateValidated}
          lastAddedRowId={lastAddedRowId}
          setLastAddedRowId={setLastAddedRowId}
        />}

      <FluentModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        isModeLess={false}
        modalHeader={'Något gick fel'}
        modalContent={<AccountTemplateModal accountTemplateError={accountTemplateError} />}
      />
    </div>
  );
};

export default AccountTemplates;
