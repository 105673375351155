import { ChangeEvent, Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { ProjectAttributes } from "../../../interfaces/project/ProjectAttributes";


interface useMemorizedEditingProps {
    values: ProjectAttributes;
    initialValues: ProjectAttributes;
    setValues: Dispatch<SetStateAction<ProjectAttributes>>;
    setChangedValue: Dispatch<SetStateAction<{ [key: string]: string | number | null }>>;
    attributeName: string;
    regExp: RegExp;
    setChangedInitiationMonth?: Dispatch<SetStateAction<string>>;
}

export const useMemorizedEditing = ({
    values,
    initialValues,
    setValues,
    setChangedValue,
    attributeName,
    regExp,
    setChangedInitiationMonth
}: useMemorizedEditingProps) => {

    const [previousValue, setPreviousValue] = useState<string | number | null>(null);
    const [inputBlurEvent, setInputBlurEvent] = useState<React.FocusEvent<HTMLInputElement, Element> | null>(null);
    const [keyTriggerEvent, setKeyTriggerEvent] = useState<React.KeyboardEvent<HTMLInputElement> | null>(null);
    const [valueHasChanged, setValueHasChanged] = useState<boolean>(false);


    const updatePreviousValue = useCallback(() => {
        if (!previousValue) {
            setPreviousValue(initialValues[attributeName as keyof ProjectAttributes]);
        } else {
            setPreviousValue(values[attributeName as keyof ProjectAttributes]);
        }
    }, [attributeName, initialValues, previousValue, values]);


    const updateValues = useCallback((value: string | number | null) => {

        setValues(prevState => {
            return {
                ...prevState,
                [attributeName]: value
            }
        });

    }, [attributeName, setValues]);


    useEffect(() => {
        if (valueHasChanged && (inputBlurEvent || keyTriggerEvent?.key === 'Enter' || (keyTriggerEvent?.key === 'Tab' && inputBlurEvent))) {

            const newValue = values[attributeName as keyof ProjectAttributes];
            const initialValue = initialValues[attributeName as keyof ProjectAttributes];

            const validInput = newValue && regExp.test(newValue?.toString());

            if (validInput && newValue !== previousValue && newValue !== initialValue) {
                if (setChangedInitiationMonth) {
                    setChangedInitiationMonth(newValue as string);
                } else {
                    setChangedValue({ [attributeName]: newValue });
                }

            } else if (previousValue && newValue === initialValue) {
                setChangedValue({ [attributeName]: initialValue });
                setPreviousValue(null);
            } else if (!newValue) {
                updateValues(initialValue);
                setChangedValue({ [attributeName]: initialValue ?? null });
                setPreviousValue(null);
            }

            setInputBlurEvent(null);
            setKeyTriggerEvent(null);
            setValueHasChanged(false);
        }
    }, [attributeName, initialValues, inputBlurEvent, keyTriggerEvent?.key, previousValue, regExp, setChangedInitiationMonth, setChangedValue, setValues,
        updateValues, valueHasChanged, values]);


    const handleChangedDate = useCallback((e: ChangeEvent<HTMLInputElement>) => {

        updatePreviousValue();
        updateValues(e.target.value);
        setValueHasChanged(true);

    }, [updatePreviousValue, updateValues]);


    const handleChangedPercent = useCallback((e: ChangeEvent<HTMLInputElement>) => {

        const newValue = e.target.value !== '' ? Number(e.target.value) : null;

        updatePreviousValue();
        updateValues(newValue);
        setValueHasChanged(true);

    }, [updatePreviousValue, updateValues]);



    return { setInputBlurEvent, setKeyTriggerEvent, handleChangedDate, handleChangedPercent, previousValue }

}