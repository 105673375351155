import { DefaultButton, PrimaryButton } from '@fluentui/react';
import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react';
import styles from './Confirmation.module.css';
import LoadingSpinner from './../../../../StatusIndicators/LoadingSpinner';
import { useSaveSnapshot } from './../../../../../fetchData/mutations/useSaveSnapshot';
import { modalTimeout } from './../../../../../utils/modalTimeout';

interface SaveSnapshotProps {
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  setSaveIsLoading: Dispatch<SetStateAction<boolean>>;
}


const SaveSnapshot = ({
  setIsModalOpen,
  setSaveIsLoading
}: SaveSnapshotProps) => {

  const saveSnapshot = useSaveSnapshot();
  const error = saveSnapshot.error as Error;

  const [input, setInput] = useState<string>('');


  useEffect(() => {
    if (saveSnapshot.data?.success) {
      modalTimeout(setIsModalOpen);
      setSaveIsLoading(false);
    }
  }, [saveSnapshot.data?.success, setIsModalOpen, setSaveIsLoading]);


  const saveIdle = () => {
    if (saveSnapshot.isIdle) {
      return (<>
        <p><b>Vill du spara aktuell projektekonomi till historiken? Ange i så fall ett namn och spara</b></p>

        <label>Namn på historisk projektekonomi</label>
        <div className={styles.inputContainer}>
          <input
            className={styles.input}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setInput(e.target.value)}
            maxLength={30}
            placeholder={'Max 30 tecken'}
          />
        </div>

        <div className={styles.buttonContainer}>
          <div className={styles.buttonMargin}>
            <PrimaryButton
              onClick={() => {
                saveSnapshot.mutate(input);
                setSaveIsLoading(true);
              }}
              disabled={!(input.length > 0)}
            >Spara</PrimaryButton>
          </div>

          <div className={styles.buttonMargin}>
            <DefaultButton
              onClick={() => setIsModalOpen(false)}
            >Avbryt</DefaultButton>
          </div>
        </div>
      </>)
    }
  }

  const saveLoading = () => {
    if (saveSnapshot.isLoading) {
      return (
        <div className={`${styles.messageContainer} ${styles.spinnerContainer}`}>
          <LoadingSpinner text={"Historik sparas..."} />
        </div>
      )
    }
  }

  const saveSuccess = () => {
    if (saveSnapshot.data?.success) {
      return (
        <div className={`${styles.messageContainer} ${styles.spinnerContainer}`}>
          <p>Historik sparades</p>
        </div>
      )
    }
  }

  const saveError = () => {
    if (saveSnapshot.isError) {
      return (
        <div className={`${styles.messageContainer} ${styles.spinnerContainer}`}>
          <p>{error.message}</p>
        </div>
      )
    }
  }


  return (
    <div className={`${styles.confirmBody} ${styles.snapshotBody}`}>

      {saveIdle()}
      {saveLoading()}
      {saveSuccess()}
      {saveError()}

    </div>
  )
};

export default SaveSnapshot;