export const getGridReportDates = () => {
    const date = new Date();

    const toDate = date.toLocaleDateString('sv-SV').slice(0, 7);
    date.setMonth(date.getMonth() - 1);
    const fromDate = date.toLocaleDateString('sv-SV').slice(0, 7);

    return { fromDate, toDate };
}


export const getTwoYearPeriodDates = () => {
    const year = new Date().getFullYear();

    const lastDayOfNextYear = new Date(year + 1, 11)
        .toLocaleDateString('sv-SV').slice(0, 7);

    const firstDayOfThisYear = new Date(year, 0)
        .toLocaleDateString('sv-SV').slice(0, 7);

    return { firstDayOfThisYear, lastDayOfNextYear }
}