import styles from '../AdminTools.module.css';
import { ChangeEvent, useEffect, useState } from "react";
import { IContextualMenuProps } from "@fluentui/react";
import { AdminInput } from './AdminInputs/AdminInput';
import { AdminDeleteButton } from './AdminInputs/AdminDeleteButton';
import { useDeleteProjectCache } from '../../../../fetchData/mutations/adminTools/useDeleteProjectCache';


export const DeleteProjectCache = () => {
    const [projectId, setProjectId] = useState<string>('');
    const deleteProjectCache = useDeleteProjectCache();

    useEffect(() => {
        if (deleteProjectCache.data?.success) setProjectId('');
    }, [deleteProjectCache.data?.success]);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setProjectId(e.target.value);
    };

    const handleDelete = () => deleteProjectCache.mutate(projectId);

    const menuProps: IContextualMenuProps = {
        items: [
            {
                key: 'deleteProjectCache',
                text: 'Ja, ta bort projektcache',
                iconProps: { iconName: 'Delete' },
                onClick: handleDelete,
            },
        ],
    }

    return (
        <div className={styles.formContainer}>
            <h6 className={styles.inputGroupHeader}>Projektcache</h6>

            <AdminInput
                label={'Projekt-id'}
                inputValue={projectId}
                handleChange={handleChange}
            />

            <AdminDeleteButton
                buttonText={'Ta bort'}
                menuProps={menuProps}
                disabled={!projectId}
            />
        </div>
    );
}