import { useParams } from "react-router-dom";

const useProjectId = () => {
    let routeParams = useParams();

    const projectId = routeParams?.projectId ?? '';

    return projectId;
}

export default useProjectId;