import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import MoveBuildStart from '../../../Modals/FluentModal/Content/MoveBuildStart/MoveBuildStart';
import styles from './DateInput.module.css';
import { FluentModal } from '../../../Modals/FluentModal/FluentModal';
import { TooltipHost } from '@fluentui/react';
import { ProjectAttributes } from '../../../../interfaces/project/ProjectAttributes';
import { useInteractionContext } from '../../../../context/InteractionContext';
import { useMemorizedEditing } from '../../customHooks/useMemorizedEditing';


interface ModalDateInputProps {
  values: ProjectAttributes;
  setValues: Dispatch<SetStateAction<ProjectAttributes>>;
  attributeName: string;
  label?: string;
  disabled: boolean;
  setHandleShiftPrognosis: Dispatch<SetStateAction<boolean>>;
  prognosisShifted: boolean;
  setChangedValue: Dispatch<SetStateAction<{ [key: string]: string | number | null; }>>;
  initialValues: ProjectAttributes;
}


export const ModalDateInput = ({
  values,
  setValues,
  attributeName,
  label,
  disabled,
  setHandleShiftPrognosis,
  prognosisShifted,
  setChangedValue,
  initialValues
}: ModalDateInputProps) => {

  const { refreshLoading } = useInteractionContext();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalHeader, setModalHeader] = useState<string>('Varning');
  const [modalContent, setModalContent] = useState<JSX.Element>(<></>);
  // 0 cancel, 1 yes, 2 no
  const [modalInput, setModalInput] = useState<string | number | null>(null);

  const [changedInitiationMonth, setChangedInitiationMonth] = useState<string>('');

  const {
    setInputBlurEvent,
    setKeyTriggerEvent,
    handleChangedDate,
    previousValue
  } = useMemorizedEditing(
    {
      values,
      initialValues,
      setValues,
      setChangedValue,
      attributeName,
      regExp: /^(19[4-9]\d|20\d{2})-(0[1-9]|1[0-2])$/,
      setChangedInitiationMonth
    }
  );


  // handle modal selection
  useEffect(() => {
    if (typeof modalInput === 'number') {
      const newValue = values[attributeName as keyof ProjectAttributes] as string;

      if (newValue) {
        // move build start date and prognosis
        if (modalInput === 1) {
          setChangedValue({ [attributeName]: newValue });

          setHandleShiftPrognosis(true);
        }

        // cancel
        if (modalInput === 0) {
          setValues((prevState: any) => {
            return {
              ...prevState,
              [attributeName]: previousValue
            }
          });
        }

        // move build start date
        if (modalInput === -1) {
          setChangedValue({ [attributeName]: newValue });
        }

        setModalInput(null);
      }
    }
  }, [attributeName, initialValues, modalInput, previousValue, setChangedValue, setHandleShiftPrognosis, setValues, values]);


  useEffect(() => {
    if (changedInitiationMonth) {
      setModalHeader('Flytt av igångsättningsmånad');
      setModalContent(<MoveBuildStart
        setIsModalOpen={setIsModalOpen}
        changedInitiationMonth={changedInitiationMonth}
        unchangedInitiationMonth={initialValues[attributeName as keyof ProjectAttributes] as string}
        setModalInput={setModalInput}
      />)

      setChangedInitiationMonth('');
      setIsModalOpen(true);
    }
  }, [attributeName, changedInitiationMonth, initialValues, setModalInput]);


  const evaluateIfDisabled = useMemo(() => {
    if (disabled) return true;

    if (!(values.startMonth && values.endMonth)) return true;

    if (attributeName === 'buildStartMonth') {
      if (values?.['mgmtBuildStartConsent']) return true;
    }

    return false;
  }, [attributeName, disabled, values]);


  const dateInput = () => (
    <input
      lang='sv-Sv'
      data-testid={attributeName}
      value={values[attributeName as keyof ProjectAttributes] as string ?? ''}
      className={styles.dateInput}
      type={'month'}
      min={'1940-01'}
      max={'9999-12'}
      tabIndex={0}
      disabled={evaluateIfDisabled}
      onChange={e => handleChangedDate(e)}
      onKeyDown={e => setKeyTriggerEvent(e)}
      onBlur={e => setInputBlurEvent(e)}
    />
  );


  const disabledDateInput = () => (
    <input
      data-testid={attributeName}
      value={values[attributeName as keyof ProjectAttributes] as string ?? ''}
      className={styles.dateInput}
      type={'month'}
      disabled
    />
  );


  const toolTipDateInput = () => (
    <TooltipHost
      content={attributeName === 'buildStartMonth' ? 'Igångsättning och prognos har redan flyttats. Ladda om projektekonomin för att flytta igångsättning/prognos igen' : 'Datum kan ej ändras'}
      id={attributeName === 'buildStartMonth' ? 'shiftedPrognosisTooltip' : 'dateTooltip'}
    >
      <span className={styles.dateInputHolder}>
        {disabledDateInput()}
      </span>
    </TooltipHost>
  );


  return (
    <>
      <div className={styles.dateInputContainer}>
        <label className={styles.dateInputLabel}>{label}</label>
        {prognosisShifted
          ?
          toolTipDateInput()
          :
          <span className={styles.dateInputHolder}>
            {refreshLoading
              ?
              disabledDateInput()
              :
              dateInput()
            }
          </span>
        }
      </div>

      <FluentModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        isModeLess={false}
        modalHeader={modalHeader}
        modalContent={modalContent}
        setModalInput={setModalInput}
      />
    </>
  );
};
