import { TooltipHost } from '@fluentui/react';
import ActionButton from './ActionButton';

interface SaveButtonProps {
  text: string;
  handleOnSaveClick: () => void;
  disabled: boolean;
  tooltip: string;
}

const SaveButton = ({
  text,
  handleOnSaveClick,
  disabled,
  tooltip
}: SaveButtonProps) => {


  const saveButton = (disabled?: boolean) => (
    <ActionButton
      text={text}
      handleOnClick={handleOnSaveClick}
      buttonStyles={['saveButton']}
      disabled={disabled}
    />
  );

  return (
    <>
      {(disabled && tooltip)
        ?
        <TooltipHost
          content={tooltip}
          id={'saveButtonTooltip'}>
          {saveButton(disabled)}
        </TooltipHost>
        :
        saveButton()
      }
    </>
  )
}

export default SaveButton;