import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useAccessToken } from "../../../useAccessToken";
import { useCallback } from "react";
import { makeResultFetch } from "../../../genericFetch";
import { apiRequestParams } from "../../../fetchOptions";
import { useHandleRetry } from "../../../useHandleRetry";
import { ProjectType } from "../../../../interfaces/reports/ResCalcProjReport";


const useFetch = () => {
    const getAccessToken = useAccessToken();

    const queryFunction = useCallback(async () => {
        return getAccessToken().then(accessToken => {
            if (!accessToken) throw new Error("Access token is undefined");

            const fetchedData = makeResultFetch<ProjectType[]>(
                `/projects/types`,
                apiRequestParams(accessToken, 'GET')
            ).then((response) => {
                const { data, message, success } = response;

                if (!success) throw Error(message);

                const formattedData = data?.map((item) => {
                    return { name: item.name, id: item.id?.toString() }
                });

                return formattedData;
            });

            return fetchedData;
        });
    }, [getAccessToken]);

    return queryFunction;
}


export const usePrefetchProjectTypes = () => {
    const queryFunction = useFetch();
    const queryClient = useQueryClient();

    const prefetchQuery = useCallback(() => {
        queryClient.prefetchQuery({
            queryKey: ['projectTypes'],
            queryFn: queryFunction,
        });
    }, [queryClient, queryFunction])

    return prefetchQuery;
}


export const useProjectTypes = () => {
    const queryFunction = useFetch();
    const handleRetry = useHandleRetry();

    const { isLoading, error, data } = useQuery({
        queryKey: ['projectTypes'],
        queryFn: queryFunction,
        enabled: !!queryFunction,
        retry: handleRetry,
    });


    return { isLoading, error, data };
}