import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styles from '../ResultCalculationLog.module.css';
import { Slider } from "@fluentui/react";

interface NrOfRowsSliderProps {
    setSelectNrOfRows: Dispatch<SetStateAction<number>>;
}

export const NrOfRowsSlider = ({ setSelectNrOfRows }: NrOfRowsSliderProps) => {
    const [sliderValue, setSliderValue] = useState<number>(1000);

    useEffect(() => {
        const delayInputTimeoutId = setTimeout(() => {
            setSelectNrOfRows(sliderValue);
        }, 500);
        return () => clearTimeout(delayInputTimeoutId);
    }, [setSelectNrOfRows, sliderValue]);

    const sliderOnChange = (value: number) => setSliderValue(value);


    return (
        <div className={styles.sliderContainer}>
            <Slider
                min={100}
                max={10000}
                step={100}
                value={sliderValue}
                showValue
                snapToStep
                onChange={sliderOnChange}
            />
        </div>
    );
}