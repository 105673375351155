import styles from '../ResultCalculationLog.module.css';
import { Dispatch, RefObject, SetStateAction, useCallback, useEffect, useMemo } from "react";
import { useRevenueRecognitionLog } from "../../../../fetchData/queries/accounting/useRevenueRecognitionLog";
import { AgGridReact } from "ag-grid-react";
import { CellClickedEvent, GetRowIdFunc, GetRowIdParams, ValueFormatterParams } from "ag-grid-community";
import { localizeDateToString } from "../../../../utils/localizedDateStrings";
import { LogStatusCellStyle } from "../LogStatusCellsStyle";
import { defaultColDef } from '../../../MainGrids/gridConfig/DefaultColDef';
import { AG_GRID_LOCALE_SV } from '../../../MainGrids/gridConfig/SwedishLocale';
import LoadingSpinner from '../../../StatusIndicators/LoadingSpinner';

interface ResCalcLogGridProps {
    logGridRef: RefObject<AgGridReact>;
    setTaskId: Dispatch<SetStateAction<number | null>>;
    selectNrOfRows: number;
}


const ResCalcLogGrid = ({
    logGridRef,
    setTaskId,
    selectNrOfRows,
}: ResCalcLogGridProps) => {

    const resultCalculationLog = useRevenueRecognitionLog(selectNrOfRows);

    useEffect(() => {
        const data = resultCalculationLog.data;
        if (data) setTaskId(data[0].id);

    }, [logGridRef, resultCalculationLog.data, setTaskId]);


    const logColDefs: object[] = useMemo(() => {
        return [
            {
                field: 'startTime',
                headerName: 'Startad',
                sort: 'desc',
                valueFormatter: (params: ValueFormatterParams) => localizeDateToString(params.value),
                flex: 2,
            },
            {
                field: 'type',
                headerName: 'Typ',
                flex: 2,
                cellEditorParams: { maxLength: 10 }
            },
            {
                field: 'runTime',
                headerName: 'Körtid',
                valueFormatter: (params: ValueFormatterParams) => params.value?.split('.')[0],
            },
            {
                field: 'status',
                headerName: 'Status',
                cellStyle: LogStatusCellStyle
            },
        ]
    }, []);

    const onGridReady = useCallback(() => {
        logGridRef.current!.api?.getDisplayedRowAtIndex(0)?.setSelected(true);
    }, [logGridRef]);


    const onCellClicked = useCallback((params: CellClickedEvent) => setTaskId(params.data.id), [setTaskId]);

    const getRowId = useMemo<GetRowIdFunc>(() => {
        return (params: GetRowIdParams) => params.data.id;
    }, []);



    return (
        <div className={`ag-theme-balham ${styles.resCalcLogGrid}`}>
            <AgGridReact
                ref={logGridRef}
                rowData={resultCalculationLog.data}
                columnDefs={logColDefs}
                defaultColDef={defaultColDef}
                localeText={AG_GRID_LOCALE_SV}
                loadingOverlayComponent={LoadingSpinner}
                getRowId={getRowId}
                onCellClicked={onCellClicked}
                rowSelection={'single'}
                suppressContextMenu={true}
                onGridReady={onGridReady}
            />
        </div>
    )
}


export default ResCalcLogGrid;