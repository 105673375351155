export const convertNumberToWords = (number: number): string => {
    const units: string[] = ['', 'ett', 'två', 'tre', 'fyra', 'fem', 'sex', 'sju', 'åtta', 'nio'];
    const tens: string[] = ['', 'tio', 'tjugo', 'trettio', 'fyrtio', 'femtio', 'sextio', 'sjuttio', 'åttio', 'nittio'];
    const teens: string[] = ['tio', 'elva', 'tolv', 'tretton', 'fjorton', 'femton', 'sexton', 'sjutton', 'arton', 'nitton'];


    number = Math.round(number);

    if (number === 0) {
        return units[0];
    } else if (number < 0) {
        return 'minus' + convertNumberToWords(-number);
    } else if (number < 10) {
        return units[number];
    } else if (number < 20) {
        return teens[number - 10];
    } else if (number < 100) {
        return tens[Math.floor(number / 10)] + units[number % 10];
    } else if (number < 1000) {
        return units[Math.floor(number / 100)] + 'hundra' + convertNumberToWords(number % 100);
    } else if (number < 1000000) {
        return convertNumberToWords(Math.floor(number / 1000)) + 'tusen' + convertNumberToWords(number % 1000);
    } else {
        return 'väldigtstortvärde'
    }
}

