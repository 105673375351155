import { useQuery } from "@tanstack/react-query";
import { useCallback } from "react";
import { useAccessToken } from "../../useAccessToken";
import useProjectId from "../../../customHooks/useProjectId";
import { useInteractionContext } from "../../../context/InteractionContext";
import { makeResultFetch } from "../../genericFetch";
import { apiRequestParams } from "../../fetchOptions";
import { useHandleRetry } from "../../useHandleRetry";


export const useCashflowNotices = () => {
    const getAccessToken = useAccessToken();
    const handleRetry = useHandleRetry();
    const projectId = useProjectId();
    const { loadProjectEconomy } = useInteractionContext();


    const queryFunction = useCallback(async () => {
        return getAccessToken().then(accessToken => {
            if (!accessToken) throw new Error("Access token is undefined");

            const fetchedData = makeResultFetch<{ [key: string]: string }>(
                `/Sheet/${projectId}/Cashflow/Notices`,
                apiRequestParams(accessToken, 'GET')
            ).then((response) => {
                const { data, message, success } = response;

                if (!success) throw Error(message);

                return data;
            });

            return fetchedData;
        });
    }, [getAccessToken, projectId]);


    const { isLoading, error, data } = useQuery({
        queryKey: [projectId, 'cashflowNotices'],
        queryFn: queryFunction,
        enabled: !!projectId && !!queryFunction && loadProjectEconomy,
        retry: handleRetry,
    });


    return { isLoading, error, data };
}