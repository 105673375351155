import styles from './DefaultSelect.module.css';
import { NumberStringPairs } from '../../../interfaces/systemSettings/AccountTemplate';

interface DefaultSelectProps {
  options: NumberStringPairs;
  setSelectedOption: (key: number) => void;
  defaultSelectedKey?: number;
  label?: string;
  selectWidth?: number;
  disabled?: boolean;
};

export const DefaultSelect = ({
  options,
  label,
  defaultSelectedKey,
  setSelectedOption,
  selectWidth,
  disabled,
}: DefaultSelectProps
) => {

  const selectOptions = () => {
    if (Object.keys(options)?.length > 0) {
      return Object.entries(options).map(([key, value]) =>
        <option key={key} value={key}>{value}</option>)
    }

    return <option value={defaultSelectedKey?.toString()}>Laddar...</option>
  }

  return (
    <>
      {label && <label className={styles.inputLabel}>{label}</label>}
      <select
        className={styles.defaultSelect}
        style={{ width: selectWidth }}
        disabled={disabled}
        defaultValue={defaultSelectedKey?.toString()}
        onChange={(e) => setSelectedOption(Number(e.target.value))}>
        {selectOptions()}
      </select>
    </>
  );
};
