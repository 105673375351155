import { useQuery } from "@tanstack/react-query";
import { useCallback } from "react";
import { useAccessToken } from "../useAccessToken";
import useProjectId from "../../customHooks/useProjectId";
import { User } from "../../interfaces/UserPermission";
import { makeResultFetch } from "../genericFetch";
import { apiRequestParams } from "../fetchOptions";
import { useHandleRetry } from "../useHandleRetry";


export const useUser = () => {
    const getAccessToken = useAccessToken();
    const handleRetry = useHandleRetry();
    const projectId = useProjectId();


    const queryFunction = useCallback(async () => {
        return getAccessToken().then(accessToken => {
            if (!accessToken) throw new Error("Access token is undefined");

            const endpointUrl = projectId
                ? `/User/${projectId}/Get`
                : '/User';

            const fetchedData = makeResultFetch<User>(
                endpointUrl,
                apiRequestParams(accessToken, 'GET')
            ).then((response) => {
                const { data, message, success } = response;

                if (!success) throw Error(message);

                return data;
            });

            return fetchedData;
        });
    }, [getAccessToken, projectId]);


    const key = projectId ? [projectId, 'user'] : ['user'];

    const { isLoading, error, data } = useQuery({
        queryKey: key,
        queryFn: queryFunction,
        enabled: !!queryFunction,
        retry: handleRetry,
        staleTime: 1000 * 60 * 15, // 15 min
    });


    return { isLoading, error, data };
}