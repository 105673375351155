import { IContextualMenuItem } from "@fluentui/react";
import { Dispatch, SetStateAction, useMemo } from "react";
import { useUser } from "../../../../fetchData/queries/useUser";
import { menuItemStyle } from "./menuItemStyle";
import { FinalAccountModalContent } from "../MenuButtonModal/Content/FinalAccountModalContent";
import { ResultCalculationModalContent } from "../MenuButtonModal/Content/ResultCalculationModalContent";
import { useEvaluateAccounting } from "../../../../fetchData/queries/accounting/useEvaluateAccounting";


export const useAccountingMenu = (
    setModalContent: Dispatch<SetStateAction<{ header: string, body: JSX.Element }>>,
    setIsModalOpen: Dispatch<SetStateAction<boolean>>,
    setModalLock: Dispatch<SetStateAction<boolean>>,
) => {

    const scopes = useUser()?.data?.scopes;
    const evaluateAccounting = useEvaluateAccounting();

    const menuItems = useMemo(() => {
        const items: IContextualMenuItem[] = [];

        if (scopes?.['accounting_create']) {
            const style = evaluateAccounting.data?.isValid ? menuItemStyle :
                {
                    ...menuItemStyle,
                    backgroundColor: '#efefef',
                    color: 'rgba(16, 16, 16, 0.3)'
                };


            const handleManualResCalc = () => {
                setModalContent({
                    header: 'Manuell resultatavräkning',
                    body: <ResultCalculationModalContent
                        setIsModalOpen={setIsModalOpen}
                        setModalLock={setModalLock}
                    />
                });
                setIsModalOpen(true);
            };

            const manualResCalc = {
                key: 'manualResCalc',
                text: 'Manuell resultatavräkning',
                onClick: handleManualResCalc,
                style: style,
                disabled: !evaluateAccounting.data?.isValid,
            };


            const handleFinalAccount = () => {
                setModalContent({
                    header: 'Slutredovisning',
                    body: <FinalAccountModalContent
                        setIsModalOpen={setIsModalOpen}
                        setModalLock={setModalLock}
                    />
                });
                setIsModalOpen(true);
            };

            const finalAccount = {
                key: 'finalAccount',
                text: 'Slutredovisning',
                onClick: handleFinalAccount,
                style: style,
                disabled: !evaluateAccounting.data?.isValid,
            };


            items.push(manualResCalc, finalAccount);
        }

        return items;
    }, [evaluateAccounting, scopes, setIsModalOpen, setModalContent, setModalLock]);


    return menuItems;
}

