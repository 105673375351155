import { Text, View } from '@react-pdf/renderer';
import { formatDate, formatText, makePercent, roundNumber } from '../formatPdfContent';
import { ProjectInfo } from '../../../../interfaces/project/ProjectInfo';
import { ResultCalculationReport } from '../../../../interfaces/reports/ResultCalculationReport';
import { styles } from '../styles';
import { PdfTemplate } from '../PdfTemplate';

interface ResultCalculationPfdProps {
    reportData: ResultCalculationReport | undefined;
    projectInfo: ProjectInfo | undefined;
}

export const ResultCalculationPdf = ({
    reportData,
    projectInfo,
}: ResultCalculationPfdProps) => {

    const header = reportData?.header;
    const forecasts = reportData?.forecast;
    const sales = reportData?.sales;
    const totalObjects = sales?.totalObjects;
    const soldObjects = sales?.soldObjects;
    const calculation = reportData?.calculation;
    const salesRatio = calculation?.progress?.salesRatio;
    const summary = reportData?.summary;


    const reportMessages = () => {
        if (header?.isZeroSettlement || !header?.hasBuildStartConsent) {
            return (
                <View style={styles.section}>
                    <View style={styles.table}>
                        {!header?.hasBuildStartConsent &&
                            <View style={styles.tableRow}>
                                <Text style={styles.redMessage}>Igångsättningsmedgivande ej lämnat</Text>
                            </View>
                        }
                        {header?.isZeroSettlement &&
                            <View style={styles.tableRow}>
                                <Text style={styles.blueMessage}>Nollavräkning</Text>
                            </View>
                        }
                    </View>
                </View>
            );
        }
    }


    const salesRatioRow = () => {
        if (typeof salesRatio === 'number') {
            return (
                <View style={styles.tableRow}>
                    <Text style={styles.tableRowCol1}>Försäljningsgrad *</Text>
                    <Text style={styles.tableRowCol2}>{makePercent(salesRatio)}</Text>
                </View>
            );
        }
    }

    const salesRatioDetails = () => {
        if (soldObjects && totalObjects) {
            return (
                <View style={styles.rowSpacing}>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableRowCol1}>* Antal sålda lägenheter / Totalt antal lägenheter</Text>
                        <Text style={styles.tableRowCol2}>{`${soldObjects} / ${totalObjects}`}</Text>
                    </View>
                </View>
            );
        }
    }


    const page1 = () => (
        <>
            {reportMessages()}

            <View style={styles.section}>
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableRowCol1}>Projekt</Text>
                        <Text style={styles.tableRowCol2}>{formatText(projectInfo?.name)}</Text>
                    </View>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableRowCol1}>Projektnummer</Text>
                        <Text style={styles.tableRowCol2}>{formatText(projectInfo?.identificationNumber)}</Text>
                    </View>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableRowCol1}>Projektledare </Text>
                        <Text style={styles.tableRowCol2}>{formatText(header?.projectManager)}</Text>
                    </View>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableRowCol1}>Prognos senast ändrad/bekräftad</Text>
                        <Text style={styles.tableRowCol2}>{formatDate(header?.forecastLastUpdated)}</Text>
                    </View>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableRowCol1}>Rapportperiod</Text>
                        <Text style={styles.tableRowCol2}>{formatText(header?.calculationPeriod)}</Text>
                    </View>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableRowCol1}>Godkänd slutbesiktning</Text>
                        <Text style={styles.tableRowCol2}>{formatDate(header?.finalInspection)}</Text>
                    </View>
                </View>
            </View>


            <View style={styles.section}>
                <Text style={styles.sectionHeader}>Projektprognos</Text>

                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableRowCol1}>Intäkter</Text>
                        <Text style={styles.tableRowCol2}>{roundNumber(forecasts?.income)}</Text>
                    </View>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableRowCol1}>Kostnader (inkl. tidigare kostnadsförda poster)</Text>
                        <Text style={styles.tableRowCol2}>{roundNumber(forecasts?.cost)}</Text>
                    </View>
                    <View style={styles.tableSumRow}>
                        <Text style={styles.tableRowCol1}>Resultat</Text>
                        <Text style={styles.tableRowCol2}>{roundNumber(forecasts?.result)}</Text>
                    </View>
                </View>
            </View>


            <View style={styles.section}>
                <Text style={styles.sectionHeader}>Resultatavräknas</Text>

                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableRowCol1}>Resultatavräkning per</Text>
                        <Text style={styles.tableRowCol2}>{formatText(calculation?.period)}</Text>
                    </View>

                    <View style={styles.rowSpacing}>
                        <View style={styles.tableRow}>
                            <Text style={styles.tableRowCol1}>Bokförda kostnader exkl. mark & räntor</Text>
                            <Text style={styles.tableRowCol2}>{roundNumber(calculation?.recorded?.costsExclLand)}</Text>
                        </View>
                        <View style={styles.tableRow}>
                            <Text style={styles.tableRowCol1}>Bokförda kostnader mark</Text>
                            <Text style={styles.tableRowCol2}>{roundNumber(calculation?.recorded?.landCost)}</Text>
                        </View>
                        <View style={styles.tableRow}>
                            <Text style={styles.tableRowCol1}>Bokförda kostnader räntor</Text>
                            <Text style={styles.tableRowCol2}>{roundNumber(calculation?.recorded?.capitalCost)}</Text>
                        </View>
                    </View>

                    <View style={styles.rowSpacing}>
                        <View style={styles.tableRow}>
                            <Text style={styles.tableRowCol1}>Upparbetningsgrad (exkl. mark)</Text>
                            <Text style={styles.tableRowCol2}>{makePercent(calculation?.progress?.degreeOfCompletion)}</Text>
                        </View>

                        {salesRatioRow()}

                        <View style={styles.tableSumRow}>
                            <Text style={styles.tableRowCol1}>Avräkningsgrad</Text>
                            <Text style={styles.tableRowCol2}>{makePercent(calculation?.progress?.settlementRate)}</Text>
                        </View>
                    </View>

                    {salesRatioDetails()}
                </View>
            </View>


            <View style={styles.section}>
                <Text style={styles.sectionHeader}>Intäkter</Text>

                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableRowCol1}>Intäkter att resultatavräkna</Text>
                        <Text style={styles.tableRowCol2}>{roundNumber(summary?.revenue?.accumulated)}</Text>
                    </View>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableRowCol1}>Avgår tidigare resultatavräknat</Text>
                        <Text style={styles.tableRowCol2}>{roundNumber(summary?.revenue?.previouslyOffset)}</Text>
                    </View>
                    <View style={styles.tableSumRow}>
                        <Text style={styles.tableRowCol1}>Periodens intäkter att resultatavräkna </Text>
                        <Text style={styles.tableRowCol2}>{roundNumber(summary?.revenue?.period)}</Text>
                    </View>
                </View>
            </View>


            <View style={styles.section}>
                <Text style={styles.sectionHeader}>Kostnader</Text>

                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableRowCol1}>Kostnader att resultatavräkna</Text>
                        <Text style={styles.tableRowCol2}>{roundNumber(summary?.cost?.accumulated)}</Text>
                    </View>
                    <View style={styles.tableRow}>
                        <Text style={styles.tableRowCol1}>Avgår tidigare resultatavräknat</Text>
                        <Text style={styles.tableRowCol2}>{roundNumber(summary?.cost?.previouslyOffset)}</Text>
                    </View>
                    <View style={styles.tableSumRow}>
                        <Text style={styles.tableRowCol1}>Periodens kostnader att resultatavräkna</Text>
                        <Text style={styles.tableRowCol2}>{roundNumber(summary?.cost?.period)}</Text>
                    </View>
                </View>
            </View>


            <View style={styles.section}>
                <View style={styles.table}>
                    <View style={styles.tableSumRow}>
                        <Text style={styles.tableRowCol1}>Resultat att resultatavräkna</Text>
                        <Text style={styles.tableRowCol2}>{roundNumber(summary?.periodResult)}</Text>
                    </View>
                </View>
            </View>
        </>
    );


    return PdfTemplate([page1], 'Rapport resultatavräkning');
}