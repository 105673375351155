import { AgGridReact } from 'ag-grid-react';
import styles from '../ResultCalculationLog.module.css';
import { Dispatch, RefObject, SetStateAction } from 'react';
import ResultCalcLogHeader from '../../../Headers/ActionHeaders/ResultCalculation/ResultCalcLogHeader';
import { NrOfRowsSlider } from './NrOfRowsSlider';
import ResCalcLogGrid from './ResCalcLogGrid';

interface ResCalcLogProps {
    logGridRef: RefObject<AgGridReact>;
    setTaskId: Dispatch<SetStateAction<number | null>>;
    selectNrOfRows: number;
    setSelectNrOfRows: Dispatch<SetStateAction<number>>;
}


const ResCalcLog = ({
    logGridRef,
    setTaskId,
    selectNrOfRows,
    setSelectNrOfRows
}: ResCalcLogProps) => (
    <div className={styles.resCalcLog}>
        <ResultCalcLogHeader >
            <div className={styles.headerContentsContainer}>
                <h6 className={styles.headerTitle}>Körningar</h6>

                <NrOfRowsSlider setSelectNrOfRows={setSelectNrOfRows} />

                <p className={styles.headerInfo}>Välj körning för att visa detaljer</p>
            </div>
        </ResultCalcLogHeader>

        <ResCalcLogGrid
            logGridRef={logGridRef}
            setTaskId={setTaskId}
            selectNrOfRows={selectNrOfRows}
        />
    </div>
);


export default ResCalcLog;
