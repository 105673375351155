import { AgGridReact } from 'ag-grid-react';
import { RefObject, useCallback } from 'react';
import styles from './GridFilter.module.css';

interface GridFilterProps {
  gridRefs: RefObject<AgGridReact>[];
  elementId: string;
}

const GridFilter = ({
  gridRefs,
  elementId
}: GridFilterProps) => {

  const onFilterTextBoxChanged = useCallback(() => {
    gridRefs?.forEach((gridRef: any) => {
      gridRef?.current!.api.setQuickFilter(
        (document.getElementById(elementId) as HTMLInputElement).value
      );
    });
  }, [elementId, gridRefs]);

  return (
    <input
      data-testid="GridFilter"
      className={styles.filterInput}
      type="text"
      id={elementId}
      placeholder="Filtrera..."
      onInput={() => onFilterTextBoxChanged()}
      maxLength={25}
    />
  )
};

export default GridFilter;