import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useAccessToken } from "../../useAccessToken";
import { useCallback } from "react";
import { makeResultFetch } from "../../genericFetch";
import { apiRequestParams } from "../../fetchOptions";
import { useHandleRetry } from "../../useHandleRetry";
import { RevenueRecognitionLog } from "../../../interfaces/RevenueRecognitionLog";


const useFetch = (selectNrOfRows: number) => {
    const getAccessToken = useAccessToken();

    const queryFunction = async () => {
        return getAccessToken().then((accessToken) => {
            if (!accessToken) throw new Error("Access token is undefined");

            const fetchedData = makeResultFetch<RevenueRecognitionLog[]>(
                `/Financials/RevenueRecognition/Tasklog?top=${selectNrOfRows}`,
                apiRequestParams(accessToken, 'GET')
            ).then((response) => {
                const { data, message, success } = response;

                if (!success) throw Error(message);

                return data;
            });

            return fetchedData;
        });
    };

    return queryFunction;
}


export const usePrefetchRevenueRecognitionLog = (selectNrOfRows: number) => {
    const queryFunction = useFetch(selectNrOfRows);
    const queryClient = useQueryClient();

    const prefetchQuery = useCallback(() => {
        queryClient.prefetchQuery({
            queryKey: ['revenueRecognitionLog'],
            queryFn: queryFunction
        });
    }, [queryClient, queryFunction])

    return prefetchQuery;
}


export const useRevenueRecognitionLog = (selectNrOfRows: number) => {
    const queryFunction = useFetch(selectNrOfRows);
    const handleRetry = useHandleRetry();

    const { isLoading, error, data } = useQuery({
        queryKey: ['revenueRecognitionLog'],
        queryFn: queryFunction,
        enabled: !!queryFunction && !!selectNrOfRows,
        retry: handleRetry,
    });

    return { isLoading, error, data };
}