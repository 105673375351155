import useProjectId from "../../../customHooks/useProjectId";
import { useQuery } from "@tanstack/react-query";
import { useAccessToken } from "../../useAccessToken";
import { useCallback } from "react";
import { apiRequestParams } from "../../fetchOptions";
import { useHandleRetry } from "../../useHandleRetry";
import { makeResultFetch } from "../../genericFetch";
import { EvaluateAccounting } from "../../../interfaces/EvaluateAccounting";
import { useUser } from "../useUser";


const useFetch = () => {
    const getAccessToken = useAccessToken();
    const projectId = useProjectId();

    const queryFunction = useCallback(async () => {
        return getAccessToken().then(accessToken => {
            if (!accessToken) throw new Error("Access token is undefined");

            const fetchedData = makeResultFetch<EvaluateAccounting>(
                `/Financials/Accounting/Project/${projectId}/Evaluate`,
                apiRequestParams(accessToken, 'GET')
            ).then((response) => {
                const { data, message, success } = response;

                if (!success) throw Error(message);

                return data;
            });

            return fetchedData;
        });
    }, [getAccessToken, projectId]);

    return { projectId, queryFunction };
}


export const useEvaluateAccounting = () => {
    const handleRetry = useHandleRetry();
    const { projectId, queryFunction } = useFetch();
    const accountingScope = useUser().data?.scopes?.['accounting_create'];

    const { isLoading, error, data } = useQuery({
        queryKey: [projectId, 'evaluateAccounting'],
        queryFn: queryFunction,
        enabled: !!projectId && accountingScope,
        retry: handleRetry,
    });

    return { isLoading, error, data };
}