import { MessageBar, MessageBarType } from "@fluentui/react";
import { useProjectInfo } from "../../../../fetchData/queries/useProjectInfo";
import { useState } from "react";
import styles from './MessageBars.module.css';


export const InfoBar = () => {
    const projectInfo = useProjectInfo().data;
    const isFinalized = projectInfo?.isFinalized;
    const isArchived = projectInfo?.isArchived;
    const hasBuildStartConsent = projectInfo?.datesInfo?.mgmtBuildStartConsent;

    const [showInfoBar, setShowInfoBar] = useState<boolean>(true);

    let messages: string[] = [];

    if (isArchived) {
        messages.push('Projektet är arkiverat');
    }

    if (isFinalized) {
        messages.push('Projektet är slutredovisat');
    }

    if (!hasBuildStartConsent) {
        messages.push('Igångsättningsmedgivande saknas')
    }


    const mappedMessages = () => {
        return messages.map((message, index) =>
            <p className={styles.messageMargin} key={index}>{message}</p>)
    }


    if (showInfoBar && mappedMessages().length > 0) {
        return (
            <MessageBar
                messageBarType={MessageBarType.warning}
                isMultiline={false}
                onDismiss={() => setShowInfoBar(false)}
                dismissButtonAriaLabel="Close"
            >
                {mappedMessages()}
            </MessageBar>
        );
    }


    return <></>;
}