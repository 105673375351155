import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useCallback } from "react";

export const useAccessToken = () => {
    const { instance, accounts } = useMsal();

    const getAccessToken = useCallback(async () => {
        const accessTokenRequest = {
            scopes: ["user.read"],
            account: accounts[0],
        };

        const accessToken = await instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => accessTokenResponse.accessToken)
            .catch((error) => {
                if (error instanceof InteractionRequiredAuthError) {
                    console.log("Msal - interaction required"); // has never been triggered...

                    window.removeEventListener("beforeunload", (e) => e.preventDefault());

                    instance.acquireTokenRedirect(accessTokenRequest);
                }
            });

        return accessToken;
    }, [accounts, instance]);

    return getAccessToken;
}