import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { DatesInfo } from "../../../interfaces/baseCalculation/DatesInfo";
import { CellClassParams, KeyCreatorParams, ValueFormatterParams, ValueParserParams } from "ag-grid-community";
import { numberFormatter } from "../../../utils/numberFormatter";
import { getRowStyle } from "../../../utils/getRowStyle";
import useCheckIfSheetEditable from "../customHooks/useCheckIfSheetEditable";
import { numericValueParser } from "../helper/numericValueParser";
import { MonthColumn } from "../../../interfaces/grids/cashflow/MonthColumns";
import { MonthColumnIds } from "../../../interfaces/grids/cashflow/MonthColumnIds";
import { RowStyle } from "../../../interfaces/grids/RowStyle";
import CommentCellRenderer from "../cellRenderers/CommentCellRenderer/CommentCellRenderer";
import { TotalPrognosisCellRenderer } from "../cellRenderers/TotalPrognosisCellRenderer";
import { TextColorCellRenderer } from "../cellRenderers/TextColorCellRenderer";
import { useEditingContext } from "../../../context/EditingContext";


interface CashflowColDefs {
  datesInfo: DatesInfo;
  monthColumns: { [key: string]: MonthColumn };
  lockedBudget: boolean;
  setMonthColumnIds: Dispatch<SetStateAction<MonthColumnIds>>;
}


export const useCashflowColDefs = ({
  datesInfo,
  monthColumns,
  lockedBudget,
  setMonthColumnIds
}: CashflowColDefs) => {
  const checkIfEditable = useCheckIfSheetEditable();
  const { easterEggMode } = useEditingContext();

  const [fullColumnDefs, setFullColumnDefs] = useState<Array<object>>([]);

  const cashflowTotalsColDef: object[] = useMemo(() => {
    const rowStyle: RowStyle = { backgroundColor: '#d9dcde', fontWeight: '' };

    return [{
      headerName: 'Totaler',
      children: [
        {
          field: 'grandParentDisplayName',
          hide: true,
          rowGroup: true,
          pinned: 'left',
          keyCreator: (params: KeyCreatorParams) => {
            return params.node?.data.grandParentDisplayName ?? params.node?.data.displayName;
          },
        },
        {
          field: 'parentDisplayName',
          hide: true,
          rowGroup: true,
          pinned: 'left',
        },
        {
          headerName: !lockedBudget ? 'Budget' : 'Budget*',
          field: 'budget',
          pinned: 'left',
          type: 'numericColumn',
          aggFunc: 'customSum',
          valueFormatter: (params: ValueFormatterParams) => numberFormatter(params, easterEggMode),
          cellStyle: (params: CellClassParams) => getRowStyle(params, { ...rowStyle }),
          editable: !lockedBudget && checkIfEditable,
          valueParser: (params: ValueParserParams) => numericValueParser(params),
        },
        {
          headerName: 'Utfall',
          field: 'totalOutcome',
          pinned: 'left',
          type: 'numericColumn',
          aggFunc: 'customSum',
          valueFormatter: (params: ValueFormatterParams) => numberFormatter(params, easterEggMode),
          cellStyle: (params: CellClassParams) => getRowStyle(params, { ...rowStyle }),
        },
        {
          headerName: 'Prognos',
          field: 'totalPrognosis',
          pinned: 'left',
          type: 'numericColumn',
          aggFunc: 'customSum',
          valueFormatter: (params: ValueFormatterParams) => numberFormatter(params, easterEggMode),
          cellStyle: (params: CellClassParams) => getRowStyle(params, { ...rowStyle }),
          cellRenderer: TotalPrognosisCellRenderer,
        },
        {
          headerName: 'Diff',
          field: 'difference',
          pinned: 'left',
          type: 'numericColumn',
          aggFunc: 'customSum',
          valueFormatter: (params: ValueFormatterParams) => numberFormatter(params, easterEggMode),
          cellStyle: (params: CellClassParams) => getRowStyle(params, { ...rowStyle }),
        },
        {
          headerName: 'Kommentar',
          field: 'comment',
          pinned: 'left',
          cellRenderer: CommentCellRenderer,
          type: 'rightAligned',
          cellStyle: (params: CellClassParams) => getRowStyle(params, { ...rowStyle }),
          editable: checkIfEditable,
          singleClickEdit: true,
          cellEditor: 'agLargeTextCellEditor',
          cellEditorPopup: true,
          cellEditorParams: {
            maxLength: 500,
            rows: 10,
            cols: 30
          }
        },
      ]
    },
    ];
  }, [lockedBudget, checkIfEditable, easterEggMode]);


  const getYearMonthSuffix = useCallback((specifiedDate?: string): string => {
    if (specifiedDate) {
      const date = new Date(specifiedDate);
      const month: string = ('0' + (date.getMonth() + 1)?.toString()).slice(-2);
      const year: string = date.getFullYear()?.toString();
      const yearMonthSuffix: string = year + month;

      return yearMonthSuffix;
    }
    return "";
  }, []);

  const suffixedDatesInfo = useMemo(() => {
    const dates: DatesInfo = { ...datesInfo };

    Object.entries(dates).forEach((date: [key: string, value: string]) => {
      const [key, value] = date;
      const yearMonthSuffix: string = getYearMonthSuffix(value);
      dates[key as keyof DatesInfo] = yearMonthSuffix;
    });

    return dates;
  }, [datesInfo, getYearMonthSuffix])


  useEffect(() => {
    let monthColDefs: Array<object> = [];

    if (monthColumns && suffixedDatesInfo) {

      let colIds: MonthColumnIds = { prognosis: [], outcome: [] };

      Object.entries(monthColumns).forEach((monthCol: [key: string, value: MonthColumn]) => {
        const [key, value] = monthCol;
        const outcomeField: string = 'outcome_' + key;
        const prognosisField: string = 'prognosis_' + key;

        colIds.prognosis.push(prognosisField);
        colIds.outcome.push(outcomeField);

        let columnStyle: RowStyle = { backgroundColor: '', fontWeight: '' };
        const monthColDateSuffix: string = getYearMonthSuffix(key);

        const isUpUntilInitiation: boolean = Number((monthCol[0].split('T')[0].slice(-2))) > 1;

        // maximum known date set if no inspection dates
        const inspectionDate: string = suffixedDatesInfo?.finalInspection || suffixedDatesInfo?.finalInspectionPlanned ||
          new Date(8640000000000000).getFullYear() + ('0' + new Date(8640000000000000).getMonth()).slice(-2);

        if (isUpUntilInitiation) {
          columnStyle.backgroundColor = '#d9dcde';
        } else {
          if (suffixedDatesInfo?.startMonth && suffixedDatesInfo?.buildStartMonth
            && monthColDateSuffix >= suffixedDatesInfo?.startMonth && monthColDateSuffix < suffixedDatesInfo?.buildStartMonth) {
            columnStyle.backgroundColor = '#8B5729';
          }
          else if (suffixedDatesInfo?.buildStartMonth && inspectionDate.length > 0
            && monthColDateSuffix >= suffixedDatesInfo?.buildStartMonth && monthColDateSuffix <= inspectionDate) {
            columnStyle.backgroundColor = '#AACEED';
          }
          else if (suffixedDatesInfo?.endMonth && inspectionDate.length > 0
            && monthColDateSuffix > inspectionDate && monthColDateSuffix <= suffixedDatesInfo?.endMonth) {
            columnStyle.backgroundColor = '#B6D097';
          }
        }

        monthColDefs.push(
          {
            headerName: value.displayDate,
            children: [
              {
                headerName: 'Utfall',
                type: 'numericColumn',
                field: outcomeField,
                aggFunc: 'customSum',
                cellStyle: (params: CellClassParams) => getRowStyle(params, { ...columnStyle }),
                valueFormatter: (params: ValueFormatterParams) => numberFormatter(params, easterEggMode),
                minWidth: 75
              },
              {
                headerName: 'Prognos',
                type: 'numericColumn',
                field: prognosisField,
                aggFunc: 'customSum',
                cellStyle: (params: CellClassParams) => getRowStyle(params, { ...columnStyle }),
                valueFormatter: (params: ValueFormatterParams) => numberFormatter(params, easterEggMode),
                cellRenderer: TextColorCellRenderer,
                editable: !isUpUntilInitiation ? checkIfEditable : false,
                valueParser: (params: ValueParserParams) => numericValueParser(params),
                minWidth: 75
              },
            ]
          }
        );
      });

      const fullColumnDefs = [...cashflowTotalsColDef, ...monthColDefs];

      setFullColumnDefs(fullColumnDefs);

      setMonthColumnIds(colIds);


    }
  }, [cashflowTotalsColDef, checkIfEditable, easterEggMode, getYearMonthSuffix, monthColumns,
    setMonthColumnIds, suffixedDatesInfo]);

  return fullColumnDefs;


}