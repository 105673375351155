import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAccessToken } from "../../useAccessToken";
import { useHandleError } from "../../useHandleError";
import { useCallback } from "react";
import { FetchResponse, makeResultFetch } from "../../genericFetch";
import { apiRequestParams } from "../../fetchOptions";
import { SystemMessageDetails } from "../../../interfaces/systemSettings/SystemMessageDetails";



export const useSaveSystemMessage = () => {
    const getAccessToken = useAccessToken();
    const handleError = useHandleError();
    const queryClient = useQueryClient();


    const mutationFunction = useCallback(async (systemMessage: SystemMessageDetails) => {
        return getAccessToken().then(accessToken => {
            if (!accessToken) throw new Error("Access token is undefined");

            const fetchedData = makeResultFetch<boolean | null>(
                `/Settings/SystemMessage`,

                apiRequestParams<SystemMessageDetails>(accessToken, 'POST', systemMessage)
            );

            return fetchedData;
        });
    }, [getAccessToken]);


    const handleSuccess = (response: FetchResponse<boolean | null>) => {
        if (response.success) {
            queryClient.invalidateQueries(['systemMessage']);

            alert('Systemmeddelandet har sparats');
        }
    }


    const saveRevenueRecognitionSchedule = useMutation({
        mutationFn: mutationFunction,
        onSuccess: handleSuccess,
        onError: handleError,
    });


    return saveRevenueRecognitionSchedule;
}