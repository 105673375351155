import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import { useBoolean } from '@fluentui/react-hooks';
import { TooltipHost } from '@fluentui/react';
import ActionButton from '../../../../Buttons/ActionButton';
import styles from './PanelButton.module.css';
import { ReactNode, useEffect } from 'react';
import { ProgressStatus } from '../../../../StatusIndicators/ProgressStatus';

interface PanelButtonProps {
    panelText: string;
    children: ReactNode;
    reportIsGenerated: boolean;
    panelSubmitDisabled: boolean;
    panelButtonDisabled: boolean;
    queriesProgress: number;
    handleGenerate: () => void;
    handleAbort: () => void;
};

export const PanelButton = ({
    panelText,
    children,
    reportIsGenerated,
    panelSubmitDisabled,
    panelButtonDisabled,
    queriesProgress,
    handleGenerate,
    handleAbort
}: PanelButtonProps) => {

    const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);


    useEffect(() => {
        if (!panelButtonDisabled) openPanel();
    }, [openPanel, panelButtonDisabled]);


    const openPanelButton = () => (
        <TooltipHost
            content={`${panelButtonDisabled ? 'Laddas...' : ''}`}
            id={'reportPanelButtonTooltip'}
            hidden={!panelButtonDisabled}
        >
            <ActionButton
                text={panelText}
                handleOnClick={openPanel}
                buttonStyles={['defaultButton']}
                disabled={isOpen || panelButtonDisabled}
            />
        </TooltipHost>
    );



    const submitButton = () => (
        <div className={styles.buttons}>
            <PrimaryButton
                onClick={handleGenerate}
                disabled={panelSubmitDisabled || reportIsGenerated}
            >
                {panelText}
            </PrimaryButton>
        </div>
    );


    const loadingIndicator = () => (
        <div className={styles.buttons}>
            <div className={styles.progressStatusContainer}>
                <ProgressStatus
                    progress={queriesProgress}
                    description={'Rapport skapas...'}
                />
            </div>

            <DefaultButton onClick={handleAbort} >
                Avbryt
            </DefaultButton>
        </div>
    );


    const submitGenerateReport = () => {
        if (reportIsGenerated) {
            return loadingIndicator();
        } else {
            return submitButton();
        }
    };


    return (
        <>
            {openPanelButton()}

            <Panel
                headerText={panelText}
                isBlocking={false}
                isOpen={isOpen}
                onDismiss={dismissPanel}
                closeButtonAriaLabel="Close"
                onRenderFooterContent={submitGenerateReport}
                isFooterAtBottom={true}
                type={PanelType.custom}
                customWidth={'380px'}
            >
                <div className={styles.children}>
                    {children}
                </div>
            </Panel>
        </>
    );
};
