import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAccessToken } from "../../useAccessToken";
import { useCallback, } from "react";
import { FetchResponse, makeResultFetch } from "../../genericFetch";
import { apiRequestParams } from "../../fetchOptions";
import { useHandleError } from "../../useHandleError";


export const useDeleteFinalAccounting = () => {
    const getAccessToken = useAccessToken();
    const handleError = useHandleError();
    const queryClient = useQueryClient();

    const mutationFunction = useCallback(async (projectId: string) => {
        return getAccessToken().then(accessToken => {
            if (!accessToken) throw new Error("Access token is undefined");

            const fetchedData = makeResultFetch<string>(
                `/Settings/Project/${projectId}/Dates/FinalReport`,
                apiRequestParams(accessToken, 'DELETE')
            ).then((response: FetchResponse<string>) => {
                const { message, success } = response;

                if (!success) throw Error(message);

                return response;
            });

            return fetchedData;
        });
    }, [getAccessToken]);


    const handleSuccess = (response: FetchResponse<string>) => {
        if (response.success) {
            const projectId = response.data;

            queryClient.invalidateQueries([projectId, 'projectInfo']);
            queryClient.invalidateQueries([projectId, 'evaluateAccounting']);
            queryClient.invalidateQueries([projectId, 'finalAccountReport']);

            alert(`Slutredovisning har tagits bort`);
        }
    };


    const deleteFinalAccounting = useMutation({
        mutationFn: mutationFunction,
        onSuccess: handleSuccess,
        onError: handleError,
    });


    return deleteFinalAccounting;
}