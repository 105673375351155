import styles from './SubHeaders.module.css';
import GridFilter from "../../GridFilter/GridFilter";
import ExportExcelButton from "../../Buttons/ExportExcelButton";
import { AgGridReact } from 'ag-grid-react';
import { RefObject } from 'react';
import { PanelButton } from '../../Reports/GridReports/Panel/PanelButton/PanelButton';
import { PanelContent } from '../../Reports/GridReports/Panel/PanelContent/PanelContent';
import { InfoTooltip } from '../../InfoTooltip/InfoTooltip';


interface GridReportSubHeaderProps {
    reportGridRef: RefObject<AgGridReact>;
    header: string;
    excelReportName: string;
    panelSubmitDisabled: boolean;
    panelButtonDisabled: boolean;
    reportIsGenerated: boolean;
    queriesProgress: number;
    panelInputs: JSX.Element[];
    handleGenerate: () => void;
    handleAbort: () => void;
    tooltipContent?: JSX.Element;
}

export const GridReportSubHeader = ({
    reportGridRef,
    handleGenerate,
    header,
    excelReportName,
    panelSubmitDisabled,
    panelButtonDisabled,
    reportIsGenerated,
    queriesProgress,
    panelInputs,
    tooltipContent,
    handleAbort
}: GridReportSubHeaderProps) => {
    return (
        <div className={styles.subBanner}>
            <div className={`${styles.flexContainer} ${styles.flexStart}`}>
                <h4 className={styles.subHeader}>{header}</h4>
                {<InfoTooltip content={tooltipContent} />}
            </div>

            <div className={styles.flexContainer}>
                <PanelButton
                    panelText={'Skapa rapport'}
                    reportIsGenerated={reportIsGenerated}
                    panelSubmitDisabled={panelSubmitDisabled}
                    panelButtonDisabled={panelButtonDisabled}
                    queriesProgress={queriesProgress}
                    handleGenerate={handleGenerate}
                    handleAbort={handleAbort}
                >
                    <PanelContent panelInputs={panelInputs} />
                </PanelButton>

                <div className={styles.subHeaderFilterInput}>
                    <GridFilter gridRefs={[reportGridRef]} elementId={'reportGridFilter'} />
                </div>

                <ExportExcelButton
                    gridRefs={[reportGridRef]}
                    fileName={excelReportName}
                    sheetNames={[excelReportName]}
                />
            </div>

        </div>
    );
}