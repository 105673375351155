import { useMemo } from "react";
import { NonEditablePercentInput } from "../CalculationBarInputs/PercentInput/NonEditablePercentInput";
import styles from '../CalculationsBar.module.css';
import { BaseCalcParams } from "./interfaces/BaseCalcParams";


export const useVatCosts = ({
    baseCalculations,
    translation,
}: BaseCalcParams) => {
    const vatCosts = useMemo(() => {
        return (<div className={styles.fieldsContainer}>
            <h5 className={styles.fieldsHeader}>Momspliktiga kostn.</h5>

            <NonEditablePercentInput
                value={baseCalculations?.expenditureOnCostsSubjectToVat}
                label={translation.expenditureOnCostsSubjectToVat}
            />
        </div>)
    }, [baseCalculations?.expenditureOnCostsSubjectToVat, translation.expenditureOnCostsSubjectToVat]);

    return vatCosts;
}