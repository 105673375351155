import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { useAccessToken } from "../../useAccessToken";
import useProjectId from "../../../customHooks/useProjectId";
import { StartEndMonth } from "../../../interfaces/project/StartEndMonth";
import { useHandleRetry } from "../../useHandleRetry";
import { apiRequestParams } from "../../fetchOptions";
import { makeResultFetch } from "../../genericFetch";
import { GridRow } from "../../../interfaces/grids/GridRow";


const useFetch = (
    accountId: string,
    outcomeDate: string,
    startEndMonth?: StartEndMonth
) => {
    const getAccessToken = useAccessToken();
    const projectId = useProjectId();

    const queryFunction = useCallback(async () => {
        return getAccessToken().then(accessToken => {
            if (!accessToken) throw new Error("Access token is undefined");


            const fetchOptions = startEndMonth && (startEndMonth.startMonth || startEndMonth.endMonth) ?
                apiRequestParams<StartEndMonth>(accessToken, 'PUT', startEndMonth) :
                apiRequestParams(accessToken, 'GET');

            const queryParams = outcomeDate ? `?date=${outcomeDate}` : '';
            const apiUrl = `/Outcome/${projectId}/${accountId}${queryParams}`

            const fetchedData = makeResultFetch<GridRow[]>(
                apiUrl,
                fetchOptions
            ).then((response) => {
                const { data, message, success } = response;

                if (!success) throw Error(message);

                return data;
            });

            return fetchedData;
        });
    }, [accountId, getAccessToken, outcomeDate, projectId, startEndMonth]);

    return { projectId, queryFunction };
}


export const usePrefetchAccountSpecification = (
    accountId: string,
    outcomeDate: string,
    startEndMonth?: StartEndMonth
) => {
    const { projectId, queryFunction } = useFetch(accountId, outcomeDate, startEndMonth);
    const queryClient = useQueryClient();

    const prefetchQuery = useCallback(() => {
        queryClient.prefetchQuery({
            queryKey: [projectId, 'accountSpecification', accountId, outcomeDate],
            queryFn: queryFunction,
        });
    }, [accountId, outcomeDate, projectId, queryClient, queryFunction])

    return prefetchQuery;
}


export const useAccountSpecification = (
    accountId: string,
    outcomeDate: string,
    startEndMonth: StartEndMonth
) => {
    const { projectId, queryFunction } = useFetch(accountId, outcomeDate, startEndMonth);
    const handleRetry = useHandleRetry();

    const { isLoading, error, data } = useQuery({
        queryKey: [projectId, 'accountSpecification', accountId, outcomeDate],
        queryFn: queryFunction,
        enabled: !!projectId && !!queryFunction && !!accountId,
        retry: handleRetry,
    });

    return { isLoading, error, data };
}
