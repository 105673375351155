import useProjectId from "../../../customHooks/useProjectId";
import { useQuery } from "@tanstack/react-query";
import { useAccessToken } from "../../useAccessToken";
import { useEditingContext } from "../../../context/EditingContext";
import { SheetData } from "../../../interfaces/grids/SheetData";
import { useCallback } from "react";
import { apiRequestParams } from "../../fetchOptions";
import { useHandleRetry } from "../../useHandleRetry";
import { makeResultFetch } from "../../genericFetch";


const Query = () => {
    const getAccessToken = useAccessToken();
    const projectId = useProjectId();
    const { selectedSnapshotId } = useEditingContext();

    const queryFunction = useCallback(async () => {
        return getAccessToken().then(accessToken => {
            if (!accessToken) throw new Error("Access token is undefined");

            const fetchedData = makeResultFetch<SheetData>(
                `/Snapshots/${projectId}/Load?guid=${selectedSnapshotId}`,
                apiRequestParams(accessToken, 'GET')
            ).then((response) => {
                const { data, message, success } = response;

                if (!success) throw Error(message);

                return data;
            });

            return fetchedData;
        });
    }, [getAccessToken, projectId, selectedSnapshotId]);

    return { projectId, queryFunction };
}


export const useSnapshot = () => {
    const handleRetry = useHandleRetry();
    const { selectedSnapshotId } = useEditingContext();
    const { projectId, queryFunction } = Query();

    const isEnabled = !!projectId && !!queryFunction && selectedSnapshotId !== 'aktuell projektekonomi';

    const { isLoading, error, data } = useQuery({
        queryKey: ['snapshot', selectedSnapshotId],
        queryFn: queryFunction,
        enabled: isEnabled,
        retry: handleRetry,
    });

    return { isLoading, error, data };
}