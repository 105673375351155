import { Edits } from "../interfaces/editing/Edits";
import { EditsDto } from "../interfaces/editing/dtos/EditsDto";

export const createEditsDto = (edits: Edits): EditsDto => {
    return {
        columns: Object.values(edits.columns),
        budgets: Object.values(edits.budgets),
        comments: Object.values(edits.comments),
        projectAttributes: edits.projectAttributes
    }
}