import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAccessToken } from "../useAccessToken";
import { useCallback, } from "react";
import { FetchResponse, makeResultFetch } from "../genericFetch";
import { apiRequestParams } from "../fetchOptions";
import { useHandleError } from "../useHandleError";
import { ScheduleEdits } from "../../interfaces/systemSettings/RevenueRecognitionSchedule";


export const useSaveRevenueRecognitionSchedule = () => {
    const getAccessToken = useAccessToken();
    const handleError = useHandleError();
    const queryClient = useQueryClient();


    const mutationFunction = useCallback(async (edits: ScheduleEdits) => {
        return getAccessToken().then(accessToken => {
            if (!accessToken) throw new Error("Access token is undefined");

            const fetchedData = makeResultFetch<boolean>(
                `/Settings/RevenueRecognitionExecutionDates`,
                apiRequestParams<ScheduleEdits>(accessToken, 'PUT', edits)
            );

            return fetchedData;
        });
    }, [getAccessToken]);


    const handleSuccess = (response: FetchResponse<boolean>) => {
        if (response.success) {
            queryClient.invalidateQueries(['revenueRecognitionSchedule']);
        }
    }


    const saveRevenueRecognitionSchedule = useMutation({
        mutationFn: mutationFunction,
        onSuccess: handleSuccess,
        onError: handleError,
    });


    return saveRevenueRecognitionSchedule;
}