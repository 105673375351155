import { Dispatch, SetStateAction, useMemo } from "react";
import { useProjectEconomySheet } from "./useProjectEconomySheet";
import { useInterestCalculation } from "./useInterestCalculation";
import { useIndex } from "./useIndex";
import { useSaleToBrf } from "./useSaleToBrf";
import { usePlanning } from "./usePlanning";
import { useVatCosts } from "./useVatCosts";
import { useContractProfit } from "./useContractProfit";
import { ProjectAttributes } from "../../../interfaces/project/ProjectAttributes";
import { BaseCalculations } from "../../../interfaces/baseCalculation/BaseCalculations";


interface EconomyTypeFieldsParams {
    economyTypeId: number | undefined;
    projectAttributes: ProjectAttributes;
    setProjectAttributes: Dispatch<SetStateAction<ProjectAttributes>>;
    initialValues: ProjectAttributes;
    setChangedValue: Dispatch<SetStateAction<{ [key: string]: string | number | null }>>;
    translation: { [key: string]: string };
    readOnlyMode: boolean;
    initiallyUnsetBuildStartMonth: boolean;
    setHandleShiftPrognosis: Dispatch<SetStateAction<boolean>>;
    prognosisShifted: boolean;
    baseCalculations: BaseCalculations;
}


export const useEconomyTypeFields = ({
    economyTypeId,
    projectAttributes,
    setProjectAttributes,
    initialValues,
    setChangedValue,
    translation,
    readOnlyMode,
    initiallyUnsetBuildStartMonth,
    setHandleShiftPrognosis,
    prognosisShifted,
    baseCalculations
}: EconomyTypeFieldsParams) => {

    const projectEconomySheet = useProjectEconomySheet({
        projectAttributes,
        setProjectAttributes,
        initialValues,
        setChangedValue,
        translation,
        readOnlyMode,
        initiallyUnsetBuildStartMonth,
        setHandleShiftPrognosis,
        prognosisShifted
    });

    const interestCalculation = useInterestCalculation({
        projectAttributes,
        setProjectAttributes,
        initialValues,
        setChangedValue,
        translation,
        readOnlyMode,
    });

    const index = useIndex({
        projectAttributes,
        setProjectAttributes,
        initialValues,
        setChangedValue,
        translation,
        readOnlyMode,
    });

    const saleToBrf = useSaleToBrf({
        projectAttributes,
        setProjectAttributes,
        initialValues,
        setChangedValue,
        translation,
        readOnlyMode,
        baseCalculations
    });

    const planning = usePlanning({
        projectAttributes,
        setProjectAttributes,
        initialValues,
        setChangedValue,
        translation,
        readOnlyMode,
    });

    const vatCosts = useVatCosts({
        baseCalculations,
        translation
    });

    const contractProfit = useContractProfit({
        baseCalculations,
        translation
    })


    const economyTypeFields = useMemo(() => {
        switch (economyTypeId) {
            case 1:
                return (<>
                    {projectEconomySheet}
                    {interestCalculation}
                    {index}
                    {saleToBrf}
                    {planning}
                    {vatCosts}
                    {contractProfit}
                </>)
            case 2:
                return (<>
                    {projectEconomySheet}
                    {interestCalculation}
                    {index}
                    {planning}
                </>)
            case 3:
                return (<>
                    {projectEconomySheet}
                    {interestCalculation}
                    {index}
                    {planning}
                    {vatCosts}
                </>)
            case 4:
                return (<>
                    {projectEconomySheet}
                    {interestCalculation}
                    {index}
                    {planning}
                    {vatCosts}
                </>)
        }
    }, [economyTypeId, projectEconomySheet, interestCalculation, index, saleToBrf, vatCosts, planning, contractProfit]);


    return economyTypeFields;
}