import { CellClassParams, GetContextMenuItemsParams, GetRowIdFunc, GetRowIdParams, LicenseManager, RowClassParams } from 'ag-grid-enterprise';
import { useCallback, useMemo, useRef } from 'react';
import styles from './InternalCreditsGrid.module.css';
import LoadingSpinner from '../../StatusIndicators/LoadingSpinner';
import { AgGridReact } from 'ag-grid-react';
import { dateFormatter } from '../../../utils/dateFormatter';
import { numberFormatter } from '../../../utils/numberFormatter';
import { formatNumber } from '../../../utils/formatNumber';
import { GridRow } from '../../../interfaces/grids/GridRow';
import { defaultColDef } from '../../MainGrids/gridConfig/DefaultColDef';
import { AG_GRID_LOCALE_SV } from '../../MainGrids/gridConfig/SwedishLocale';
import { gridLicenseKey } from '../../MainGrids/gridLicenseKey';
import { useDeleteInternalCredit } from '../../../fetchData/mutations/internalCredits/useDeleteInternalCredit';
import { InternalCreditsAmounts } from '../interfaces/InternalCreditsAmounts';
import { useProjectInfo } from '../../../fetchData/queries/useProjectInfo';


interface InternalCreditsGridProps {
  internalCredits: GridRow[] | undefined;
  internalCreditsAmounts: InternalCreditsAmounts;
}

LicenseManager.setLicenseKey(gridLicenseKey);

const InternalCreditsGrid = ({
  internalCredits,
  internalCreditsAmounts
}: InternalCreditsGridProps) => {

  const gridRef = useRef<AgGridReact>(null);

  const deleteInternalCredit = useDeleteInternalCredit();

  const projectInfo = useProjectInfo().data;
  const projectIsActive: boolean = !(projectInfo?.isFinalized || projectInfo?.isArchived);

  const nonPinRows = internalCredits?.filter(row => row.id !== 0);
  const pinRows = internalCredits?.filter(row => row.id === 0);


  const getContextMenuItems = useCallback((params: GetContextMenuItemsParams) => {
    const node = params.node;

    const menuItems: any = ['copy', 'cut', 'paste'];

    if (node?.data.id !== 0) {

      const handleDelete = () => {
        const internalCreditId = node?.id?.toString();

        if (internalCreditId) {
          deleteInternalCredit.mutate({ internalCreditId })
        }
      }

      menuItems.push(
        'separator',
        {
          icon: '<span class="ag-icon ag-icon-cross" role="presentation"></span>',
          name: 'Ta bort',
          action: () => handleDelete(),
          disabled: !projectIsActive
        }
      )
    }

    return menuItems;
  }, [deleteInternalCredit, projectIsActive]);



  const getRowStyle = (params: RowClassParams) => {
    // nedlagda kostnader
    if (params.node.data.type === 0) return { background: '#5BBF2150' };
    // intänkter
    if (params.node.data.type === 1) return { background: '#D51C2950' };
  };


  const internalCreditsColDefs: object[] = useMemo(() => {
    return [
      {
        field: 'date',
        headerName: 'Datum',
        sort: 'asc',
        valueFormatter: dateFormatter,
        cellStyle: (params: CellClassParams) => getRowStyle(params),
      },
      {
        field: 'description',
        headerName: 'Beskrivning',
        cellStyle: (params: CellClassParams) => getRowStyle(params),
        flex: 2
      },
      {
        field: 'amount',
        headerName: 'Belopp (tkr)',
        valueFormatter: numberFormatter,
        type: 'numericColumn',
        cellStyle: (params: CellClassParams) => getRowStyle(params),
      },
      {
        field: 'updatedBy',
        headerName: 'Uppdaterad av',
        cellStyle: (params: CellClassParams) => getRowStyle(params),
        flex: 2
      },
    ]
  }, []);


  const getRowId = useMemo<GetRowIdFunc>(() => {
    return (params: GetRowIdParams) => params.data.id?.toString();
  }, []);



  return (
    <div className={styles.internalCreditsGridContainer}>
      <div className={`ag-theme-balham ${styles.internalCreditsGrid}`} data-testid="InternalCreditsGrid">
        <AgGridReact
          ref={gridRef}
          rowData={nonPinRows}
          columnDefs={internalCreditsColDefs}
          rowSelection={"multiple"}
          defaultColDef={defaultColDef}
          getContextMenuItems={getContextMenuItems}
          localeText={AG_GRID_LOCALE_SV}
          loadingOverlayComponent={LoadingSpinner}
          pinnedBottomRowData={pinRows}
          getRowId={getRowId}
        />
      </div>

      <div className={styles.internalCreditsSumContainer}>
        <div className={styles.internalCreditsGridSum}>
          Beviljat: {formatNumber(internalCreditsAmounts.grantedAmount)} tkr
        </div>

        <div className={styles.internalCreditsGridSum}>
          {internalCreditsAmounts.currentBalance >= 0 ? 'Tillgodo' : 'Överskridet'}: {formatNumber(internalCreditsAmounts.currentBalance)} tkr
        </div>
      </div>
    </div >
  );
};

export default InternalCreditsGrid;
