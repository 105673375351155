import { Dispatch, SetStateAction, useMemo } from 'react';
import { TooltipHost } from '@fluentui/react';
import ActionButton from './../Buttons/ActionButton';
import { useEditingContext } from './../../context/EditingContext';
import { useProjectInfo } from './../../fetchData/queries/useProjectInfo';

interface SaveSnapshotButtonProps {
  edited: boolean;
  selectedSnapshotId: string;
  setIsSnapshotModalOpen: Dispatch<SetStateAction<boolean>>;
}

const SaveSnapshotButton = ({
  edited,
  selectedSnapshotId,
  setIsSnapshotModalOpen,
}: SaveSnapshotButtonProps) => {

  const projectInfo = useProjectInfo().data;
  const projectIsActive: boolean = !(projectInfo?.isFinalized || projectInfo?.isArchived);

  const editingContext = useEditingContext();
  const editingEnabled = editingContext.editingEnabled;

  const handleOnClick = () => setIsSnapshotModalOpen(true);


  const saveSnapshotDisabled = useMemo(() => {
    if (!projectIsActive || edited || selectedSnapshotId !== 'aktuell projektekonomi' || !editingEnabled) {
      return true;
    }
    return false;
  }, [edited, editingEnabled, projectIsActive, selectedSnapshotId])


  const saveSnapshotTooltip = useMemo(() => {
    return selectedSnapshotId === 'aktuell projektekonomi'
      ?
      'Spara projektekonomin för att spara historik'
      :
      'Kan ej spara historik för historisk projektekonomi'
  }, [selectedSnapshotId]);


  const saveSnapshotButton = (disabled?: boolean) => (
    <ActionButton
      text={'Spara historik'}
      handleOnClick={handleOnClick}
      buttonStyles={['defaultButton']}
      disabled={disabled}
    />
  );

  return (
    <>
      {saveSnapshotDisabled
        ?
        <TooltipHost
          content={saveSnapshotTooltip}
          id={'confirmPrognosisButtonTooltip'}>
          {saveSnapshotButton(saveSnapshotDisabled)}
        </TooltipHost>
        :
        saveSnapshotButton()
      }
    </>);
};

export default SaveSnapshotButton;