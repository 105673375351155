import { useResultCalculationReport } from "../../../../fetchData/queries/reports/pdf/useResultCalculationReport";
import { useProjectInfo } from "../../../../fetchData/queries/useProjectInfo";
import { SystemError } from "../../../SystemState/States/SystemError";
import { SystemLoading } from "../../../SystemState/States/SystemLoading";
import { ResultCalculationPdf } from "../Documents/ResultCalculationPdf";
import { PdfReportViewer } from "../PdfReportViewer/PdfReportViewer";


export const ResultCalculationReport = () => {
    const projectInfo = useProjectInfo();
    const resultCalcReport = useResultCalculationReport();

    document.title = 'Rapport resultatavräkning';

    if (projectInfo.error || resultCalcReport.error) {
        return <SystemError errors={[projectInfo.error, resultCalcReport.error]} />;
    }

    if (projectInfo.isLoading || resultCalcReport.isLoading) {
        return <SystemLoading text="Laddar rapport resultatavräkning..." />;
    }

    return (
        <PdfReportViewer>
            <ResultCalculationPdf
                reportData={resultCalcReport.data}
                projectInfo={projectInfo.data}
            />
        </PdfReportViewer>
    );
}