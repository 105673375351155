import { Dispatch, SetStateAction, useMemo } from 'react';
import styles from './DateInput.module.css';
import { ProjectAttributes } from '../../../../interfaces/project/ProjectAttributes';
import { useInteractionContext } from '../../../../context/InteractionContext';
import { useMemorizedEditing } from '../../customHooks/useMemorizedEditing';


interface FullDateInputProps {
    values: ProjectAttributes;
    initialValues: ProjectAttributes;
    label: string;
    setValues: Dispatch<SetStateAction<ProjectAttributes>>;
    setChangedValue: Dispatch<SetStateAction<{ [key: string]: string | number | null }>>;
    attributeName: string;
    disabled: boolean;
}


export const FullDateInput = ({
    values,
    initialValues,
    setValues,
    setChangedValue,
    label,
    attributeName,
    disabled
}: FullDateInputProps) => {

    const { refreshLoading } = useInteractionContext();

    const {
        setInputBlurEvent,
        setKeyTriggerEvent,
        handleChangedDate,
    } = useMemorizedEditing(
        {
            values,
            initialValues,
            setValues,
            setChangedValue,
            attributeName,
            regExp: /^(19[4-9]\d|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/,
        }
    );


    const evaluateIfDisabled = useMemo(() => {

        if (disabled) return true;

        if (attributeName?.endsWith('Planned')) {
            if (values[attributeName as keyof ProjectAttributes]) {
                const controllingDate: string = attributeName.replace('Planned', '');

                if (values[controllingDate as keyof ProjectAttributes]) return true;
            }
        }

        return false;

    }, [attributeName, disabled, values]);


    const fullDateInput = () => (
        <input
            lang='sv-Sv'
            data-testid={attributeName}
            value={values[attributeName as keyof ProjectAttributes] as string ?? ''}
            className={styles.dateInput}
            type={'date'}
            min={'1940-01-01'}
            max={'9999-12-01'}
            disabled={evaluateIfDisabled}
            tabIndex={0}
            onChange={e => handleChangedDate(e)}
            onKeyDown={e => setKeyTriggerEvent(e)}
            onBlur={e => setInputBlurEvent(e)}
        />
    );

    const disabledFullDateInput = () => (
        <input
            lang='sv-Sv'
            data-testid={attributeName}
            value={values[attributeName as keyof ProjectAttributes] as string ?? ''}
            className={styles.dateInput}
            type={'date'}
            disabled
        />
    );


    return (
        <div className={styles.dateInputContainer}>
            <label className={styles.dateInputLabel}>{label}</label>
            <span className={styles.dateInputHolder}>
                {refreshLoading
                    ?
                    disabledFullDateInput()
                    :
                    fullDateInput()
                }
            </span>
        </div>
    );
};
