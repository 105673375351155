import { useRef, useMemo, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import { CellDoubleClickedEvent, RowClassParams, ValueGetterParams } from 'ag-grid-community';
import { AG_GRID_LOCALE_SV } from '../../MainGrids/gridConfig/SwedishLocale';
import { defaultColDef } from '../../MainGrids/gridConfig/DefaultColDef';
import LoadingSpinner from '../../StatusIndicators/LoadingSpinner';
import { StartEndMonth } from '../../../interfaces/project/StartEndMonth';
import styles from './AccountSpecificationGrid.module.css';
import GridFilter from '../../GridFilter/GridFilter';
import ExportExcelButton from '../../Buttons/ExportExcelButton';
import { LicenseManager } from 'ag-grid-enterprise';
import { useAccountSpecification } from '../../../fetchData/queries/cashflow/useAccountSpecification';
import { useDefaultGroupsContextMenu } from '../../../customHooks/useDefaultGroupsContextMenu';
import { gridLicenseKey } from '../../MainGrids/gridLicenseKey';
import { useAccountSpecColDefs } from './useAccountSpecColDefs';
import { UnableToLoadData } from '../../SystemState/ErrorManagement/UnableToLoadData/UnableToLoadData';


LicenseManager.setLicenseKey(gridLicenseKey);


interface AccountSpecificationGridProps {
  modalAccountId: string;
  modalOutcomeDate: string;
  startEndMonth: StartEndMonth;
}

const AccountSpecificationGrid = ({
  modalAccountId,
  modalOutcomeDate,
  startEndMonth,
}: AccountSpecificationGridProps) => {

  const { error, data } = useAccountSpecification(modalAccountId, modalOutcomeDate, startEndMonth);

  const gridRef = useRef<AgGridReact>(null);

  const accountSpecColDefs = useAccountSpecColDefs();
  const contextMenu = useDefaultGroupsContextMenu(gridRef);


  const autoGroupColumnDef = useMemo(() => {
    return {
      headerName: 'Kontospecifikation',
      flex: 3,
      maxWidth: 500,
      valueGetter: (params: ValueGetterParams) => `${params.data.accountNumber}, ${params.data.accountName}`,
      cellRendererParams: {
        suppressCount: true,
        suppressDoubleClickExpand: true,
      },
    };
  }, []);

  const getRowStyle = (params: RowClassParams) => {
    if (params.node.level === 0) return { fontWeight: '900' };
    if (params.node.level === 1) return { fontWeight: '700' };
  };

  const onCellDoubleClicked = useCallback((params: CellDoubleClickedEvent) => {
    if (params.node.group) {
      const expandCollapse: boolean = params.node.expanded ? false : true;

      gridRef.current!.api.setRowNodeExpanded(params.node, expandCollapse);
    } else {
      const verificationId: string = params.node.data?.verificationId?.toString();

      window.open("https://erp.riksbyggen.se/ubw/ContentContainer.aspx"
        + "?type=topgen&menu_id=AG363&activityStepId=1-2&Default.aspx?type=topgen&title=Transaction+details&popup=true&NavWarning=false&voucher_no="
        + verificationId + "&sequence_no=0&showmenu=true&client=101000", "popup");
    }
  }, []);


  return (
    <div className={styles.accountSpecificationGridContainer}>
      <div className={styles.accountSpecificationGridHeader}>
        <GridFilter gridRefs={[gridRef]} elementId={'account-sprecification-filter'} />

        <p className={styles.accountSpecificationGridDescription}>Dubbelklicka på en rad för att öppna fakturor i BW</p>

        <ExportExcelButton
          gridRefs={[gridRef]}
          fileName={'kontospecifikation'}
          sheetNames={['Kontospecifikation']} />
      </div>

      <div className={`ag-theme-balham ${styles.accountSpecificationGrid}`} data-testid="AccountSpecificationGrid">
        {error ?
          <UnableToLoadData message={'Kontospecifikation kunde ej laddas'} />
          :
          <AgGridReact
            ref={gridRef}
            rowData={data}
            columnDefs={accountSpecColDefs}
            rowSelection={"multiple"}
            defaultColDef={defaultColDef}
            autoGroupColumnDef={autoGroupColumnDef}
            getContextMenuItems={contextMenu}
            onCellDoubleClicked={onCellDoubleClicked}
            getRowStyle={getRowStyle}
            groupDefaultExpanded={2}
            localeText={AG_GRID_LOCALE_SV}
            loadingOverlayComponent={LoadingSpinner}
            suppressAggFuncInHeader={true}
            cacheQuickFilter={true}
          />
        }
      </div>
    </div >
  );
};

export default AccountSpecificationGrid;
