import { useMemo, Dispatch, SetStateAction, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import { CellClassParams, CellClickedEvent } from 'ag-grid-community';
import { defaultColDef } from '../gridConfig/DefaultColDef';
import { AG_GRID_LOCALE_SV } from '../gridConfig/SwedishLocale';
import TotalTextCellRenderer from '../cellRenderers/TotalTextCellRenderer';
import LoadingSpinner from '../../StatusIndicators/LoadingSpinner';
import { customAggFuncs } from '../helper/customAggFuncs';
import { LicenseManager } from 'ag-grid-enterprise';
import { useEditingContext } from '../../../context/EditingContext';
import { useInteractionContext } from '../../../context/InteractionContext';
import { useSheetContext } from '../../../context/SheetContext';
import { usePerformanceNotices } from '../../../fetchData/queries/performance/usePerformanceNotices';
import { usePerformanceTotals } from '../../../fetchData/queries/performance/usePerformanceTotals';
import { UnableToLoadData } from '../../SystemState/ErrorManagement/UnableToLoadData/UnableToLoadData';
import { useStatusBar } from '../customHooks/useStatusBar';
import { useGetRowId } from '../customHooks/useGetRowId';
import { useOnCellDoubleClicked } from '../customHooks/useOnCellDoubleClicked';
import { usePerformance } from '../../../fetchData/queries/performance/usePerformance';
import { useOnCellContextMenu } from '../customHooks/useOnCellContextMenu';
import { useGetContextMenuItems } from '../customHooks/useGetContextMenuItems';
import { gridLicenseKey } from '../gridLicenseKey';
import { usePerformanceColDefs } from './usePerformanceColDefs';
import { useOnCellValueChanged } from '../customHooks/useOnCellValueChanged';


LicenseManager.setLicenseKey(gridLicenseKey);



interface PerformanceGridProps {
  setIsAccountSpecificationModalOpen: Dispatch<SetStateAction<boolean>>;
  modalAccountId: string;
  setModalAccountId: Dispatch<SetStateAction<string>>;
  modalOutcomeDate: string;
  setModalOutcomeDate: Dispatch<SetStateAction<string>>;
}


const PerformanceGrid = ({
  setIsAccountSpecificationModalOpen,
  modalAccountId,
  setModalAccountId,
  modalOutcomeDate,
  setModalOutcomeDate,
}: PerformanceGridProps) => {


  const performance = usePerformance();
  const accountNotices = usePerformanceNotices().data;
  const performanceTot = usePerformanceTotals();


  const {
    performanceGridRef,
    lockedBudget,
    performanceRows,
    setPerformanceRows,
    performanceTotals,
    setPerformanceTotals
  } = useSheetContext();

  const {
    loadProjectEconomy,
    setLoadProjectEconomy
  } = useInteractionContext();

  const {
    edits,
    setEdits,
    emptyRowsHiddenToggle,
    editingEnabled,
    readOnlyMode
  } = useEditingContext();


  useEffect(() => {
    if (performance.data) setPerformanceRows(performance.data);
    if (performanceTot.data) setPerformanceTotals(performanceTot.data);
  }, [loadProjectEconomy, performance.data, performanceTot.data, setPerformanceRows, setPerformanceTotals]);


  const performanceColDefs = usePerformanceColDefs({ lockedBudget, performanceTotals })
  const getRowId = useGetRowId();
  const statusBar = useStatusBar();
  const onCellContextMenu = useOnCellContextMenu(modalAccountId, modalOutcomeDate);

  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);


  const onCellValueChangedProps = {
    editingEnabled,
    readOnlyMode,
    edits,
    setEdits,
    setLoadProjectEconomy
  };

  const onCellValueChanged = useOnCellValueChanged({ ...onCellValueChangedProps })
  const onCellDoubleClicked = useOnCellDoubleClicked(performanceGridRef);

  const getContextMenuItemsHook = {
    totals: performanceTotals,
    setIsAccountSpecificationModalOpen,
    setModalAccountId,
    setModalOutcomeDate,
  };

  const getContextMenuItems = useGetContextMenuItems({ ...getContextMenuItemsHook });



  const autoGroupColumnDef = useMemo(() => {
    return {
      headerName: 'Resultatuppföljning',
      cellRendererParams: {
        suppressCount: true,
        suppressDoubleClickExpand: true,
        innerRenderer: TotalTextCellRenderer,
        innerRendererParams: { ...accountNotices }
      },
      field: 'displayName',
      flex: 2,
      cellStyle: (params: CellClassParams) => {
        const colStyle = { backgroundColor: '#d9dcde', fontWeight: '' };

        switch (params.node.level) {
          case 1:
            colStyle.backgroundColor = `${colStyle.backgroundColor}30`;
            colStyle.fontWeight = '700';
            break;
          case 2:
            colStyle.backgroundColor = params.node.data.editable ? 'transparent' : `${colStyle.backgroundColor}30`;
            break;
          default:
            colStyle.backgroundColor = `${colStyle.backgroundColor}80`;
            colStyle.fontWeight = '900';
            break;
        }
        return colStyle
      },
    };
  }, [accountNotices]);



  return (
    <div className="ag-theme-balham" style={gridStyle} data-testid="PerformanceGrid">
      {performance.error ?
        <UnableToLoadData message={'Resultatuppföljning kunde ej laddas'} /> :
        <AgGridReact
          ref={performanceGridRef}
          columnDefs={performanceColDefs}
          rowData={emptyRowsHiddenToggle ? performanceRows?.emptyRowsHidden : performanceRows?.prefixedData}
          rowSelection={"multiple"}
          defaultColDef={defaultColDef}
          autoGroupColumnDef={autoGroupColumnDef}
          getContextMenuItems={getContextMenuItems}
          onCellContextMenu={onCellContextMenu}
          onCellDoubleClicked={onCellDoubleClicked}
          onCellValueChanged={onCellValueChanged}
          groupDefaultExpanded={1}
          statusBar={statusBar}
          enableRangeSelection={true}
          enableRangeHandle={true}
          localeText={AG_GRID_LOCALE_SV}
          loadingOverlayComponent={LoadingSpinner}
          stopEditingWhenCellsLoseFocus={true}
          suppressAggFuncInHeader={true}
          getRowId={getRowId}
          groupRowsSticky={true}
          aggFuncs={customAggFuncs}
          groupAllowUnbalanced
          groupMaintainOrder

          // dev
          onCellClicked={(params: CellClickedEvent) => console.log('Cell was clicked', params)}
        />
      }
    </div>
  );
};

export default PerformanceGrid;
