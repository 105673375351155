import { Dispatch, SetStateAction } from 'react';
import { StartEndMonth } from '../../interfaces/project/StartEndMonth';
import { FluentModal } from '../Modals/FluentModal/FluentModal';
import AccountSpecificationGrid from './AccountSpecificationGrid/AccountSpecificationGrid';
import { useProjectInfo } from '../../fetchData/queries/useProjectInfo';


interface AccountSpecificationProps {
  modalAccountId: string;
  modalOutcomeDate: string;
  startEndMonth: StartEndMonth;
  isAccountSpecificationModalOpen: boolean;
  setIsAccountSpecificationModalOpen: Dispatch<SetStateAction<boolean>>;
}

const AccountSpecification = ({
  modalAccountId,
  modalOutcomeDate,
  startEndMonth,
  isAccountSpecificationModalOpen,
  setIsAccountSpecificationModalOpen,
}: AccountSpecificationProps) => {

  const { data } = useProjectInfo();
  const projectName = data?.name;
  const projectIdNr = data?.identificationNumber?.toString();

  const modalContent = <AccountSpecificationGrid
    modalAccountId={modalAccountId}
    modalOutcomeDate={modalOutcomeDate}
    startEndMonth={startEndMonth}
  />;


  return (
    <FluentModal
      isModalOpen={isAccountSpecificationModalOpen}
      setIsModalOpen={setIsAccountSpecificationModalOpen}
      isModeLess={false}
      modalHeader={`${projectName} - ${projectIdNr}`}
      modalContent={modalContent}
    />
  );
};

export default AccountSpecification;
