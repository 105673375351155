import { useMutation } from "@tanstack/react-query";
import { useAccessToken } from "../useAccessToken";
import { useCallback, } from "react";
import useProjectId from "../../customHooks/useProjectId";
import { useSetFetchedContextData } from "../useSetFetchedContextData";
import { useInteractionContext } from "../../context/InteractionContext";
import { EditsDto } from "../../interfaces/editing/dtos/EditsDto";
import { FetchResponse, makeResultFetch } from "../genericFetch";
import { apiRequestParams } from "../fetchOptions";
import { SheetData } from "../../interfaces/grids/SheetData";
import { useHandleError } from "../useHandleError";

export const useRefresh = () => {
    const getAccessToken = useAccessToken();
    const handleError = useHandleError();
    const projectId = useProjectId();
    const { setRefreshLoading } = useInteractionContext()
    const setFetchedContextData = useSetFetchedContextData();


    const mutationFunction = useCallback(async (editsDto: EditsDto) => {
        setRefreshLoading(true);

        return getAccessToken().then(accessToken => {
            if (!accessToken) throw new Error("Access token is undefined");

            const fetchedData = makeResultFetch<SheetData>(
                `/Sheet/${projectId}/Refresh`,
                apiRequestParams<EditsDto>(accessToken, 'PUT', editsDto)
            );

            return fetchedData;
        });
    }, [getAccessToken, projectId, setRefreshLoading]);


    const handleSuccess = (refreshResponse: FetchResponse<SheetData>) => {
        setRefreshLoading(false);

        const { data, message, success } = refreshResponse;

        if (!success) throw Error(message);

        if (data) setFetchedContextData(data);
    }


    const handleRefreshError = (error: unknown) => {
        setRefreshLoading(false);
        handleError(error);
    }


    const refresh = useMutation({
        mutationFn: mutationFunction,
        onSuccess: handleSuccess,
        onError: handleRefreshError,
    });

    return refresh;
}


