import { CellValueChangedEvent } from "ag-grid-community";
import { BudgetDto } from "../../../interfaces/editing/dtos/BudgetDto";
import { CommentDto } from "../../../interfaces/editing/dtos/CommentDto";
import { Edits } from "../../../interfaces/editing/Edits";
import { PrognosisDto } from "../../../interfaces/editing/dtos/PrognosisDto";

export const createPrefixedEdits = (event: CellValueChangedEvent, edits: Edits): Edits => {
    if (event) {
        const colId: string = event.column.getColId();
        const accountNumber: string = event.data.accountNumber;
        const prefixedColId: string = `${accountNumber}_${colId}`;
        const cellValue: string = event.value;

        switch (colId) {
            case 'budget':
                if (edits.budgets?.[prefixedColId]?.budget && cellValue === null) {
                    delete edits.budgets[prefixedColId];
                } else {
                    const budgetDto: BudgetDto = {
                        accountNumber: Number(accountNumber),
                        budget: cellValue ? Number(cellValue) : null,
                    }

                    edits.budgets[prefixedColId] = budgetDto;
                }
                break;

            case 'comment':
                if (edits.comments?.[prefixedColId]?.comment && cellValue === '') {
                    delete edits.comments[prefixedColId];
                } else {
                    const commentDto: CommentDto = {
                        accountNumber: Number(accountNumber),
                        comment: cellValue,
                    }

                    edits.comments[prefixedColId] = commentDto;
                }
                break;

            // performance monitoring
            case 'totalPrognosis':
                if (edits.columns?.[prefixedColId]?.prognosis && cellValue === null) {
                    delete edits.columns[prefixedColId];
                } else {
                    const totalPrognosisDto: PrognosisDto = {
                        accountNumber: Number(accountNumber),
                        year: 0,
                        month: 0,
                        prognosis: cellValue ? Number(cellValue) : null
                    }

                    edits.columns[prefixedColId] = totalPrognosisDto;
                }
                break;

            default:
                const isCalculated: string = event.node.data[`isCalculated_${colId.split('_')[1]}`];

                if (!isCalculated && edits.columns?.[prefixedColId]?.prognosis && cellValue === null) {
                    delete edits.columns[prefixedColId];
                } else {
                    const colDate: string[] = colId?.split('_')[1]?.split('-');
                    const year: string = colDate[0];
                    const month: string = colDate[1];

                    const prognosisDto: PrognosisDto = {
                        accountNumber: Number(accountNumber),
                        year: Number(year),
                        month: Number(month),
                        prognosis: cellValue ? Number(cellValue) : null
                    }

                    edits.columns[prefixedColId] = prognosisDto;
                }
                break;
        }
    }
    return edits;
}