import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAccessToken } from "../useAccessToken";
import { useCallback, } from "react";
import useProjectId from "../../customHooks/useProjectId";
import { FetchResponse, makeResultFetch } from "../genericFetch";
import { apiRequestParams } from "../fetchOptions";
import { useHandleError } from "../useHandleError";
import { SavedSnapshot } from "../../interfaces/Snapshot";


export const useSaveSnapshot = () => {
    const getAccessToken = useAccessToken();
    const handleError = useHandleError();
    const projectId = useProjectId();
    const queryClient = useQueryClient();

    const mutationFunction = useCallback(async (snapshotModalInput: string) => {
        return getAccessToken().then(accessToken => {
            if (!accessToken) throw new Error("Access token is undefined");

            const fetchedData = makeResultFetch<SavedSnapshot>(
                `/Snapshots/${projectId}/Save`,
                apiRequestParams<string>(accessToken, 'POST', snapshotModalInput)
            );

            return fetchedData;
        });
    }, [getAccessToken, projectId]);


    const handleSuccess = (response: FetchResponse<SavedSnapshot>) => {
        if (response.success) {
            queryClient.refetchQueries(['snapshots']);
        }

        return response;
    }

    const saveSnapshot = useMutation({
        mutationFn: mutationFunction,
        onSuccess: handleSuccess,
        onError: handleError,
    });


    return saveSnapshot;
}