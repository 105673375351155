import styles from '../AdminTools.module.css';
import { useEffect, useState } from "react";
import { IContextualMenuProps } from "@fluentui/react";
import { AdminInput } from './AdminInputs/AdminInput';
import { AdminDeleteButton } from './AdminInputs/AdminDeleteButton';
import { useDeleteUserCache } from '../../../../fetchData/mutations/adminTools/useDeleteUserCache';


export const DeleteUserCache = () => {
    const [email, setEmail] = useState<string>('');
    const [projectId, setProjectId] = useState<string>('');

    const deleteUserCache = useDeleteUserCache();

    useEffect(() => {
        if (deleteUserCache.data?.success) {
            setEmail('');
            setProjectId('');
        }
    }, [deleteUserCache.data?.success]);


    const handleDelete = () => deleteUserCache.mutate({ email, projectId });


    const menuProps: IContextualMenuProps = {
        items: [
            {
                key: 'deleteUserCache',
                text: 'Ja, ta bort användarcache',
                iconProps: { iconName: 'Delete' },
                onClick: handleDelete,
            },
        ],
    }

    return (
        <div className={styles.formContainer}>
            <h6 className={styles.inputGroupHeader}>Användarcache</h6>

            <AdminInput
                label={'E-mail*'}
                inputValue={email}
                handleChange={(e) => setEmail(e.target.value)}
                maxLength={50}
                type={'email'}
            />

            <AdminInput
                label={'Projekt-id'}
                inputValue={projectId}
                handleChange={(e) => setProjectId(e.target.value)}
            />

            <AdminDeleteButton
                buttonText={'Ta bort'}
                menuProps={menuProps}
                disabled={!email}
            />

        </div>
    );
}