import { ICellRendererParams } from "ag-grid-enterprise";
import { getIsCalculated } from './../../../utils/getIsCalculated'
import { CSSProperties } from "react";



export const TextColorCellRenderer = (params: ICellRendererParams) => {
  const cellValue: string = params.valueFormatted ?? params.value?.toString();
  const cellStyle: CSSProperties = { color: '' };
  const isCalculated: (boolean | null | undefined) = getIsCalculated(params);

  if (typeof isCalculated === 'boolean') {
    if (isCalculated === true) {
      cellStyle.color = 'blue'
    }
    if (isCalculated === false) {
      cellStyle.color = 'orange'
    }
  }


  const textColorElement = <span style={cellStyle}>{cellValue}</span>;


  return textColorElement;
};