import { useCallback } from "react";
import useProjectId from "../../../customHooks/useProjectId";
import { useAccessToken } from "../../useAccessToken";
import { useHandleError } from "../../useHandleError";
import { FetchResponse, makeResultFetch } from "../../genericFetch";
import { apiRequestParams } from "../../fetchOptions";
import { useMutation, useQueryClient } from "@tanstack/react-query";


interface AddInternalCreditParams {
    date: string;
    type: number;
    amount: number;
}

export const useAddInternalCredit = () => {
    const getAccessToken = useAccessToken();
    const handleError = useHandleError();
    const projectId = useProjectId();
    const queryClient = useQueryClient();


    const mutationFunction = useCallback(async ({
        date,
        type,
        amount
    }: AddInternalCreditParams) => {
        return getAccessToken().then(accessToken => {
            if (!accessToken) throw new Error("Access token is undefined");

            const fetchedData = makeResultFetch<boolean>(
                `/Projects/${projectId}/InternalCredits/Add`,
                apiRequestParams(accessToken, 'POST', { date, type, amount })
            ).then((response: FetchResponse<boolean>) => {
                const { message, success } = response;

                if (!success) throw Error(message);

                return response;
            });

            return fetchedData;
        });
    }, [getAccessToken, projectId]);


    const handleSuccess = (response: FetchResponse<boolean>) => {
        if (response.success) {
            queryClient.invalidateQueries([projectId, 'internalCredits']);
        }
    };


    const manualRevenueRecognition = useMutation({
        mutationFn: mutationFunction,
        onSuccess: handleSuccess,
        onError: handleError
    });


    return manualRevenueRecognition;
}