import { useEffect } from "react";

export const useBeforeUnload = () => {
    useEffect(() => {
        const handleUnload = (event: BeforeUnloadEvent) => event.preventDefault();

        window.addEventListener("beforeunload", handleUnload);

        return () => window.removeEventListener("beforeunload", handleUnload);
    }, []);
}