import { Icon, TooltipHost } from '@fluentui/react';
import styles from './TooltipIconButton.module.css';

interface TooltipIconButtonProps {
  tooltipId: string,
  tooltipContent: string,
  buttonIcon?: string,
  buttonLetter?: string,
  clickFunction: () => boolean | void,
  clickState?: boolean,
  disabled?: boolean,
}

const TooltipIconButton = ({
  tooltipId,
  tooltipContent,
  buttonIcon,
  buttonLetter,
  clickFunction,
  clickState,
  disabled
}: TooltipIconButtonProps) => {

  return (
    <div>
      <TooltipHost
        content={tooltipContent}
        id={tooltipId}
      >
        <button
          data-testid={tooltipId}
          className={styles.TooltipIconButton}
          disabled={disabled}
          onClick={clickFunction}
          style={clickState ? { background: '#f5f7f7' } : {}}>
          {buttonIcon
            ?
            <Icon style={{ margin: '3px' }} iconName={buttonIcon}></Icon>
            :
            buttonLetter}
        </button>
      </TooltipHost>
    </div>
  )
};

export default TooltipIconButton;
