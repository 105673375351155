import { ErrorBar } from "./ErrorBar"
import { InfoBar } from "./InfoBar"
import { SystemBar } from "./SystemBar"

export const NotificationBars = () => {
    const route = window.location.pathname?.split('/')?.[1].toLocaleLowerCase();


    return (
        <>
            <ErrorBar />

            {route === 'projekt' ? <InfoBar /> : undefined}

            <SystemBar />
        </>
    )
}