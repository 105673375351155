import { useQuery } from "@tanstack/react-query";
import { useAccessToken } from "../../../useAccessToken";
import { useCallback } from "react";
import { makeResultFetch } from "../../../genericFetch";
import { apiRequestParams } from "../../../fetchOptions";
import { useHandleRetry } from "../../../useHandleRetry";


const useFetch = (orgUnitIds: string[], projectTypeIds: string[]) => {
    const getAccessToken = useAccessToken();

    const queryFunction = useCallback(async () => {
        return getAccessToken().then(accessToken => {
            if (!accessToken) throw new Error("Access token is undefined");

            const maQueryParams = orgUnitIds?.map((id, index) =>
                `${index === 0 ? '' : '&'}ou=${id}`).join('');
            const ptQueryParams = projectTypeIds?.map((id, index) =>
                `${index === 0 ? '' : '&'}pt=${id}`).join('');

            let queryParams = '';

            if (maQueryParams && ptQueryParams) {
                queryParams = `?${maQueryParams}&${ptQueryParams}`;
            } else if (maQueryParams || ptQueryParams) {
                queryParams = `?${maQueryParams || ptQueryParams}`
            }


            const fetchedData = makeResultFetch<string[]>(
                `/projects/filter${queryParams}`,
                apiRequestParams(accessToken, 'GET')
            ).then((response) => {
                const { data, message, success } = response;

                if (!success) throw Error(message);

                return data;
            });

            return fetchedData;
        });
    }, [getAccessToken, orgUnitIds, projectTypeIds]);

    return queryFunction;
}


export const useOrgUnitProjectIds = (
    orgUnitIds: string[],
    projectTypeIds: string[],
) => {
    const queryFunction = useFetch(orgUnitIds, projectTypeIds);
    const handleRetry = useHandleRetry();

    const queryEnabled = !!queryFunction && orgUnitIds && orgUnitIds?.length > 0;

    const { isLoading, error, data } = useQuery({
        queryKey: ['orgUnitProjectIds', projectTypeIds, orgUnitIds],
        queryFn: queryFunction,
        enabled: queryEnabled,
        retry: handleRetry,
    });

    return { isLoading, error, data };
}