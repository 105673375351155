import ProjectEconomy from '../ProjectEconomy/ProjectEconomy';
import styles from './ProjectEconomyWrappers.module.css';
import { EditingContextProvider } from '../../context/EditingContext';
import { SheetContextProvider } from '../../context/SheetContext';


const ProjectEconomyWrappers = () => (
  <EditingContextProvider>
    <SheetContextProvider>
      <div className={styles.projectEconomyWrappers}>
        <ProjectEconomy />
      </div>
    </SheetContextProvider>
  </EditingContextProvider>
);

export default ProjectEconomyWrappers;
