import { ReactNode } from 'react';
import { SystemLoading } from '../components/SystemState/States/SystemLoading';
import { SystemError } from '../components/SystemState/States/SystemError';
import { useUser } from '../fetchData/queries/useUser';
import { UserUnauthorized } from '../components/SystemState/States/UserUnauthorized';
import { useBeforeUnload } from './useBeforeUnload';
import { useScopedContent } from './useScopedContent';

interface SectionAccessProps {
  children: ReactNode;
  scopeKey?: string | string[];
}

const SectionAccess = ({
  children,
  scopeKey,
}: SectionAccessProps) => {
  const { isLoading, error } = useUser();
  const scopedContent = useScopedContent(children, scopeKey, <UserUnauthorized />);

  useBeforeUnload();

  return (
    <>
      {isLoading && <SystemLoading />}

      {scopedContent}

      {error && <SystemError errors={[error]} />}
    </>
  );
}

export default SectionAccess;