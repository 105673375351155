import { AggTranslation, AggTranslations } from "../interfaces/grids/performance/AggTranslation";

export const getAggValue = (aggTranslations: AggTranslations, accountKey: string, columnId: string): number | null => {
    const aggKey: string = Object.keys(aggTranslations).find((aggKey: string) => aggKey.toLocaleLowerCase() === accountKey.toLocaleLowerCase()) ?? "";

    if (aggKey) {
        const value: number | null = aggTranslations?.[aggKey as keyof AggTranslations]?.[columnId as keyof AggTranslation] ?? null;
        return value;
    }
    return null;
}