import SystemState from "../SystemState";

export const RouteNotFound = () => {

    const env = process.env.REACT_APP_env!;

    const redirectUrl = () => {
        if (env === ('at' || 'prod')) {
            return (
                <p>Gå till <a href="https://riksbyggense.sharepoint.com/sites/bobp-Projektportal">
                    Projektportalen
                </a> för att välja projekt
                </p>
            );
        }
    };

    return (
        <SystemState>
            <p>Sidan hittades inte i Projekekonomi</p>

            {redirectUrl()}
        </SystemState>
    );
}