import { AgGridReact } from "ag-grid-react";
import { RefObject, useCallback } from "react";

export const useDefaultGroupsContextMenu = (gridRef: RefObject<AgGridReact>) => {
    const defaultGroupsContextMenuItems = useCallback(() => {
        return [
            'copy',
            'cut',
            'paste',
            'separator',
            {
                name: 'Expandera grupper',
                icon: '<span class="ag-icon ag-icon-tree-open" unselectable="on" role="presentation"></span>',
                action: () => {
                    gridRef.current!.api.expandAll();
                }
            },
            {
                name: 'Stäng grupper',
                icon: '<span class="ag-icon ag-icon-tree-closed" unselectable="on" role="presentation"></span>',
                action: () => {
                    gridRef.current!.api.collapseAll();
                }
            },
        ]
    }, [gridRef]);

    return defaultGroupsContextMenuItems;
}