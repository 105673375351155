import { ReactNode } from 'react';
import actionHeaderStyles from '../ActionHeaders.module.css';

interface ResultCalcLogHeaderProps {
    children: ReactNode;
}

const ResultCalcLogHeader = ({
    children
}: ResultCalcLogHeaderProps) => {


    return (
        <div className={actionHeaderStyles.actionHeader}>
            {children}
        </div >
    )
};

export default ResultCalcLogHeader;
