import { useMemo } from "react";
import { RowStyle } from "../../../interfaces/grids/RowStyle";
import { CellClassParams, ICellRendererParams, KeyCreatorParams, ValueFormatterParams, ValueParserParams } from "ag-grid-community";
import { numberFormatter } from "../../../utils/numberFormatter";
import { getRowStyle } from "../../../utils/getRowStyle";
import { numericValueParser } from "../helper/numericValueParser";
import CommentCellRenderer from "../cellRenderers/CommentCellRenderer/CommentCellRenderer";
import useCheckIfSheetEditable from "../customHooks/useCheckIfSheetEditable";
import { AggTranslations } from "../../../interfaces/grids/performance/AggTranslation";
import TotalAggCellRenderer from "../cellRenderers/TotalAggCellRenderer";
import { useEditingContext } from "../../../context/EditingContext";


interface PerformanceColDefs {
    lockedBudget: boolean;
    performanceTotals: AggTranslations | undefined;
}


export const usePerformanceColDefs = ({
    lockedBudget,
    performanceTotals
}: PerformanceColDefs) => {
    const checkIfEditable = useCheckIfSheetEditable();
    const { easterEggMode } = useEditingContext();

    const performanceColDefs: object[] = useMemo(() => {
        const rowStyle: RowStyle = { backgroundColor: '#d9dcde', fontWeight: '' };

        return ([
            {
                field: 'grandParentDisplayName',
                hide: true,
                rowGroup: true,
                keyCreator: (params: KeyCreatorParams) => {
                    return params.node?.data.grandParentDisplayName ?? params.node?.data.displayName;
                }
            },
            {
                field: 'parentDisplayName',
                hide: true,
                rowGroup: true,
            },
            {
                headerName: !lockedBudget ? 'Budget' : 'Budget*',
                field: 'budget',
                type: 'numericColumn',
                cellRenderer: (params: ICellRendererParams) => TotalAggCellRenderer(params, easterEggMode),
                cellRendererParams: {
                    aggTranslations: performanceTotals
                },
                valueFormatter: (params: ValueFormatterParams) => numberFormatter(params, easterEggMode),
                cellStyle: (params: CellClassParams) => getRowStyle(params, { ...rowStyle }),
                editable: !lockedBudget && checkIfEditable,
                valueParser: (params: ValueParserParams) => numericValueParser(params)
            },
            {
                headerName: 'Utfall',
                field: 'totalOutcome',
                type: 'numericColumn',
                cellRenderer: (params: ICellRendererParams) => TotalAggCellRenderer(params, easterEggMode),
                cellRendererParams: {
                    aggTranslations: performanceTotals
                },
                valueFormatter: (params: ValueFormatterParams) => numberFormatter(params, easterEggMode),
                cellStyle: (params: CellClassParams) => getRowStyle(params, { ...rowStyle }),
            },
            {
                headerName: 'Prognos',
                field: 'totalPrognosis',
                type: 'numericColumn',
                cellRenderer: (params: ICellRendererParams) => TotalAggCellRenderer(params, easterEggMode),
                cellRendererParams: {
                    aggTranslations: performanceTotals
                },
                valueFormatter: (params: ValueFormatterParams) => numberFormatter(params, easterEggMode),
                cellStyle: (params: CellClassParams) => getRowStyle(params, { ...rowStyle }),
                editable: checkIfEditable,
                valueParser: (params: ValueParserParams) => numericValueParser(params)
            },
            {
                headerName: 'Diff',
                field: 'difference',
                type: 'numericColumn',
                cellRenderer: (params: ICellRendererParams) => TotalAggCellRenderer(params, easterEggMode),
                cellRendererParams: {
                    aggTranslations: performanceTotals
                },
                valueFormatter: (params: ValueFormatterParams) => numberFormatter(params, easterEggMode),
                cellStyle: (params: CellClassParams) => getRowStyle(params, { ...rowStyle }),
            },
            {
                headerName: 'Kommentar',
                field: 'comment',
                cellRenderer: CommentCellRenderer,
                type: 'rightAligned',
                cellStyle: (params: CellClassParams) => getRowStyle(params, { ...rowStyle }),
                editable: checkIfEditable,
                singleClickEdit: true,
                cellEditor: 'agLargeTextCellEditor',
                cellEditorPopup: true,
                cellEditorParams: {
                    maxLength: 500,
                    rows: 10,
                    cols: 30
                }
            },
        ]);
    }, [lockedBudget, performanceTotals, checkIfEditable, easterEggMode]);

    return performanceColDefs;
}