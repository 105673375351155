import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAccessToken } from "../useAccessToken";
import { useCallback, } from "react";
import useProjectId from "../../customHooks/useProjectId";
import { ProjectAttributesDto } from "../../interfaces/editing/dtos/ProjectAttributesDto";
import { useSaveSheets } from "./useSaveSheets";
import { useEditingContext } from "../../context/EditingContext";
import { createEditsDto } from "../../utils/createEditsDto";
import { FetchResponse, makeResultFetch } from "../genericFetch";
import { apiRequestParams } from "../fetchOptions";
import { useHandleError } from "../useHandleError";


export const useSaveProjectAttributes = () => {
    const getAccessToken = useAccessToken();
    const handleError = useHandleError();
    const projectId = useProjectId();
    const saveSheets = useSaveSheets();
    const { edits, setEdits } = useEditingContext();
    const queryClient = useQueryClient();

    const mutationFunction = useCallback(async () => {
        return getAccessToken().then(accessToken => {
            if (!accessToken) throw new Error("Access token is undefined");

            const editsDto = createEditsDto(edits);

            // boolean returned on ok, non saved project attributes returned on error
            const fetchedData = makeResultFetch<boolean | ProjectAttributesDto>(
                `/Projects/${projectId}/Save`,
                apiRequestParams<ProjectAttributesDto>(accessToken, 'PUT', editsDto.projectAttributes)
            );

            return fetchedData;
        });
    }, [edits, getAccessToken, projectId]);


    const handleSuccess = (response: FetchResponse<boolean | ProjectAttributesDto>) => {
        if (response.success) {
            setEdits(prevState => { return { ...prevState, projectAttributes: {} } });

            if (Object.keys(edits.budgets)?.length > 0 ||
                Object.keys(edits.columns)?.length > 0 ||
                Object.keys(edits.comments)?.length > 0) {

                queryClient.invalidateQueries([projectId, 'projectInfo']);
                queryClient.invalidateQueries([projectId, 'baseCalculations']);

                saveSheets.mutate();
            }
        }
    };


    const saveProjectAttributes = useMutation({
        mutationFn: mutationFunction,
        onSuccess: handleSuccess,
        onError: handleError,
    });


    return { saveProjectAttributes, sheetsMutation: saveSheets };
}
