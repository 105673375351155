import styles from './DefaultDatePicker.module.css';

interface DefaultDatePickerProps {
    setChangedDate: (value: string) => void;
    dateFormat: string;
    defaultDate: string;
    lowerLimit?: string;
    upperLimit?: string;
    disabled?: boolean;
}

export const DefaultDatePicker = ({
    setChangedDate,
    dateFormat,
    defaultDate,
    lowerLimit,
    upperLimit,
    disabled
}: DefaultDatePickerProps) => (
    <input
        lang='sv-Sv'
        className={styles.defaultDatePicker}
        type={dateFormat}
        disabled={disabled}
        onChange={e => setChangedDate(e.target.value)}
        defaultValue={defaultDate}
        onKeyDown={(e) => e.preventDefault()}
        min={lowerLimit ?? '1940-01'}
        max={upperLimit ?? '9999-12'}
    />
);