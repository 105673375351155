import { Page, Text, View, Document, Image } from '@react-pdf/renderer';
import rbLogo from '../../../images/Riksbyggen_logo_RGB.jpg';
import { styles } from './styles';


const Header = (reportName: string) => (
    <View style={styles.headerFooterContainer}>
        <Image src={rbLogo} style={styles.rbLogo} />
        <Text style={styles.headerFooter}>Projektekonomi - {reportName}</Text>
    </View>
)


const Footer = () => {
    const date = new Date();
    const currentDateTime: string = date.toLocaleDateString('sv-Sv') + ' ' +
        date.toLocaleTimeString('sv-Sv', {
            hour12: false,
            hour: "numeric",
            minute: "numeric",
            second: "numeric"
        });

    const pageNumbers = (pageNumber: number, totalPages: number) => (
        `${pageNumber} / ${totalPages}`
    );

    return (
        <View style={styles.headerFooterContainer}>
            <Text style={styles.headerFooter}>{currentDateTime}</Text>
            <Text style={styles.headerFooter}
                render={({ pageNumber, totalPages }) => pageNumbers(pageNumber, totalPages)}
                fixed />
        </View>
    );
}


const Title = (title: string) => (
    <Text style={styles.title}>{title}</Text>
);



const Content = (pages: (() => JSX.Element)[], reportName: string) => {
    return pages?.map((page, index) => (
        <Page key={index} size="A4" style={styles.page}>
            <View >
                {Header(reportName)}
                {index === 0 && Title(reportName)}
                {page()}
            </View>

            {Footer()}
        </Page>
    ));
}


export const PdfTemplate = (pages: (() => JSX.Element)[], reportName: string) => (
    <Document>
        {Content(pages, reportName)}
    </Document>
)