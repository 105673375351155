import { useFinalAccountReport } from "../../../../fetchData/queries/reports/pdf/useFinalAccountReport";
import { SystemError } from "../../../SystemState/States/SystemError";
import { SystemLoading } from "../../../SystemState/States/SystemLoading";
import { FinalAccountPdf } from "../Documents/FinalAccountPdf";
import { PdfReportViewer } from "../PdfReportViewer/PdfReportViewer";

export const FinalAccountReport = () => {
    const { isLoading, error, data } = useFinalAccountReport();

    document.title = 'Rapport slutredovisning';

    if (error) return <SystemError errors={[error]} />;

    if (isLoading) return <SystemLoading text="Laddar rapport slutredovisning..." />;

    return (
        <PdfReportViewer>
            <FinalAccountPdf reportData={data} />
        </PdfReportViewer>
    );
}