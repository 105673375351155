import ButtonHeader from './ActionHeaders/ButtonHeader/ButtonHeader';
import MainHeader from './MainHeader/MainHeader';
import ProjectEconomySubHeader from './SubHeaders/ProjectEconomySubHeader';


const ProjectEconomyHeader = () => (
  <div data-testid="ProjectEconomyHeader">
    <MainHeader />

    <ProjectEconomySubHeader />

    <ButtonHeader />
  </div>
);

export default ProjectEconomyHeader;