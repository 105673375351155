import styles from './PercentInput.module.css';
import { percentageBoundary } from './PercentageBoundary';

interface NonEditableInputProps {
  value: number | null;
  label: string;
  limit?: number | null;
}

export const NonEditablePercentInput = ({
  value,
  label,
  limit
}: NonEditableInputProps) => {

  return (
    <div className={styles.percentInputContainer}>
      <label className={styles.percentInputLabel}>{label}</label>
      <span className={percentageBoundary(value, limit)}>
        <input
          value={value ?? ''}
          className={styles.percentInput}
          type='number'
          disabled
        ></input>
      </span>
    </div>
  );
};