import { IAggFuncParams, IRowNode } from "ag-grid-community";

export const customAggFuncs = {
    'customSum': (params: IAggFuncParams) => {
        let sum: number = 0;

        if (params.rowNode.group && params.rowNode.childrenAfterGroup?.length === 1) {
            if (!isNaN(params.values[0])) sum += Number(params.values[0]);
        } else {
            params.rowNode.childrenAfterGroup?.forEach((child: IRowNode, index: number) => {
                // grupper huvudkonton
                if (params.values[index]) {
                    if (params.rowNode.level === 0 && (child.childrenAfterGroup?.[0].data.parentAggregateAmount === 1)) {
                        if (!isNaN(params.values[index])) sum += Number(params.values[index]);
                    }

                    // kontogrupper under huvudkonton
                    if (params.rowNode.level === 1 && child.data?.aggregateAmount !== 0) {
                        if (!isNaN(params.values[index])) sum += Number(params.values[index]);
                    }
                }
            })
        }
        return sum;
    },
}