import { useState } from 'react';
import { FluentModal } from '../Modals/FluentModal/FluentModal';
import SaveButton from '../Buttons/SaveButton';
import ConfirmSave from '../Modals/FluentModal/Content/Confirmation/ConfirmSave';
import { useInteractionContext } from '../../context/InteractionContext';

interface SaveProjectEconomyProps {
  edited: boolean;
}

const SaveProjectEconomy = ({
  edited,
}: SaveProjectEconomyProps) => {

  const { refreshLoading } = useInteractionContext()

  const [isSaveModalOpen, setIsSaveModalOpen] = useState<boolean>(false);
  const handleOnSaveClick = () => setIsSaveModalOpen(true);
  const [saveIsLoading, setSaveIsLoading] = useState(false);

  const tooltip = refreshLoading ? 'Laddas...' : 'Projektekonomin sparad';

  return (
    <>
      <SaveButton
        text={'Spara projektekonomi'}
        handleOnSaveClick={handleOnSaveClick}
        disabled={!edited}
        tooltip={tooltip}
      />

      <FluentModal
        isModalOpen={isSaveModalOpen}
        setIsModalOpen={saveIsLoading ? undefined : setIsSaveModalOpen}
        isModeLess={false}
        modalHeader={'Spara projektekonomi'}
        modalContent={
          <ConfirmSave
            setIsModalOpen={setIsSaveModalOpen}
            setSaveIsLoading={setSaveIsLoading} />
        }
      />
    </>
  )
}

export default SaveProjectEconomy;