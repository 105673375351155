import { IconButton, TooltipHost } from "@fluentui/react";
import styles from './ProjectEconomyButton.module.css';

const SettingsButton = () => {

  return (
    <TooltipHost content='Inställningar' id={'settingsButton'}>
      <IconButton
        data-testid="SettingsButton"
        className={styles.projectEconomyButton}
        iconProps={{ iconName: 'Settings' }}
        onClick={() => {
          const newTabUrl: string = `${window.location.origin}/installningar`;
          window.open(newTabUrl, "_blank");
        }} />
    </TooltipHost>
  );
};

export default SettingsButton;
