import { ChangeEvent, useEffect, useState } from 'react';
import styles from './SnapshotSelect.module.css';
import { Snapshot } from '../../../interfaces/Snapshot';
import { useEditingContext } from '../../../context/EditingContext';
import { useInteractionContext } from '../../../context/InteractionContext';
import { useSnapshots } from '../../../fetchData/queries/snapshot/useSnapshots';
import { useSnapshot } from '../../../fetchData/queries/snapshot/useSnapshot';
import { useSetFetchedContextData } from '../../../fetchData/useSetFetchedContextData';

interface SnapshotSelectProps {
  edited: boolean;
}

const SnapshotSelect = ({
  edited,
}: SnapshotSelectProps) => {

  const currentSnapshots = useSnapshots().data;
  const selectedSnapshot = useSnapshot().data;

  const setFetchedContextData = useSetFetchedContextData();

  const { setLoadProjectEconomy } = useInteractionContext();

  const {
    selectedSnapshotId,
    setSelectedSnapshotId,
    setReadOnlyMode,
    setSnapshotMode
  } = useEditingContext();

  const [snapshots, setSnapshots] = useState<Array<Snapshot>>([]);

  useEffect(() => {
    if (currentSnapshots) setSnapshots(currentSnapshots);
  }, [currentSnapshots]);


  useEffect(() => {
    if (selectedSnapshot) setFetchedContextData(selectedSnapshot);
  }, [selectedSnapshot, selectedSnapshotId, setFetchedContextData]);


  const handleOnChange = (value: string) => {
    setSelectedSnapshotId(value);

    if (value === 'aktuell projektekonomi') {
      setLoadProjectEconomy(true);
      setSnapshotMode(false);
    } else {
      setLoadProjectEconomy(false);
      setReadOnlyMode(true);
      setSnapshotMode(true);
    }
  };



  return (
    <select className={styles.snapshotSelect}
      value={selectedSnapshotId}
      onChange={(e: ChangeEvent<HTMLSelectElement>) => handleOnChange(e.target.value)}
      disabled={edited}
      name="snapshot"
      id="snapshot">
      <option value={'aktuell projektekonomi'}>Aktuell projektekonomi</option>
      {snapshots && snapshots?.slice(0).reverse().map((snapshot: Snapshot) => {
        return <option key={snapshot.id} value={snapshot?.id}>
          {`${snapshot?.date?.split('T')[0]} - ${snapshot?.description} - ${snapshot?.name}`}
        </option>
      })
      }
    </select>
  );
};

export default SnapshotSelect;
