
import { ModalV2 } from "../../../Modals/ModalV2";
import { Dispatch, SetStateAction } from "react";

interface MenuButtonModalProps {
    isModalOpen: boolean;
    setIsModalOpen: Dispatch<SetStateAction<boolean>> | undefined;
    modalContent: { header: string, body: JSX.Element };
}

export const MenuButtonModal = ({
    isModalOpen,
    setIsModalOpen,
    modalContent,
}: MenuButtonModalProps) => {
    const { header, body } = modalContent;

    return (
        <ModalV2
            modalHeader={header}
            modalContent={body}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
        />
    );
}
