import styles from './InfoTooltip.module.css';
import { Icon, TooltipHost } from '@fluentui/react';

interface InfoTooltipProps {
  content?: JSX.Element;
}

export const InfoTooltip = ({ content }: InfoTooltipProps) => {

  if (content) {
    return (
      <div className={styles.infoTooltipWrapper}>
        <TooltipHost content={content} id={'infoTooltip'} >
          <Icon
            style={{ display: 'flex', justifyContent: 'center', cursor: 'default' }}
            iconName={'Info'}
          />
        </TooltipHost>
      </div>
    );
  } else {
    return <></>;
  }
};

