import { useMemo } from 'react';
import styles from './SubHeaders.module.css';
import useEvaluateSaveEnabled from '../../../customHooks/useEvaluateSaveEnabled';
import { Icon, Spinner, SpinnerSize, TooltipHost } from '@fluentui/react';
import SaveProjectEconomy from '../../SaveProjectEconomy/SaveProjectEconomy';
import { useProjectInfo } from '../../../fetchData/queries/useProjectInfo';
import { useEditingContext } from '../../../context/EditingContext';
import Snapshots from '../../Snapshots/Snapshots';
import { useInteractionContext } from '../../../context/InteractionContext';
import { useIsFetching } from '@tanstack/react-query';
import { EasterEggButton } from '../../EasterEgg/EasterEggButton';



const ProjectEconomySubHeader = () => {
  const { easterEggMode } = useEditingContext();

  const isFetching = useIsFetching() > 0;
  const projectInfo = useProjectInfo().data;
  const projectIdNr = projectInfo?.identificationNumber?.toString();
  const projectName = projectInfo?.name;
  const isFinalized = projectInfo?.isFinalized;
  const isArchived = projectInfo?.isArchived;


  const {
    editingEnabled,
    readOnlyMode,
    snapshotMode
  } = useEditingContext();
  const { refreshLoading } = useInteractionContext();


  const projectHeader: string = projectName && projectIdNr ? `${projectName} - ${projectIdNr}` : '';
  document.title = projectHeader;


  const edited: boolean = useEvaluateSaveEnabled();


  const projectEconomyMode = useMemo((): { text: string, icon: string } => {
    if (easterEggMode) {
      return { text: 'Projektekonomi i lätt att läsa läge - ändringar kan sparas', icon: 'Glasses' };
    }

    if (isArchived) {
      return { text: 'Projekt arkiverat - ändringar kan ej sparas', icon: 'Archive' };
    } else if (isFinalized) {
      return { text: 'Projekt slutredovisat - ändringar kan ej sparas', icon: 'Lock' };
    } else if (editingEnabled && !readOnlyMode && !snapshotMode) {
      return { text: 'Projektekonomi i redigeringsläge - ändringar kan sparas', icon: 'Edit' };
    } else {
      return { text: 'Projektekonomi i läsläge - ändringar kan ej göras', icon: 'View' };
    }
  }, [easterEggMode, isArchived, isFinalized, editingEnabled, readOnlyMode, snapshotMode]);



  const systemInteractionStatus = () => {
    if (isFetching || refreshLoading) {
      return <Spinner style={{ margin: '8px' }} size={SpinnerSize.small} />;
    }

    return <Icon style={{ margin: '8px', cursor: 'default' }} iconName={projectEconomyMode.icon}></Icon>;
  };


  const tooltipHeader = () => (
    <div className={`${styles.flexContainer} ${styles.flexStart}`}>
      {(projectHeader && projectEconomyMode) &&
        <TooltipHost
          content={projectEconomyMode.text}
          id={'modeTooltip'}>
          <div className={styles.childFlexContainer}>
            <h4 className={styles.subHeader}>{projectHeader}</h4>

            {systemInteractionStatus()}
          </div>
        </TooltipHost>
      }
    </div>
  );


  return (
    <div className={styles.subBanner} >
      <EasterEggButton
        buttonContent={tooltipHeader()}
        clickLimit={5}
      />

      <div className={`${styles.flexContainer} ${styles.flexEnd}`}>
        <Snapshots edited={edited} />

        <SaveProjectEconomy edited={edited} />
      </div>
    </div >
  )
};

export default ProjectEconomySubHeader;