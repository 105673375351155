export const getIsCalculated = (params: any) => {
    const suffix: string = params.column.getColId()?.split('_')[1];

    if (suffix) {
        const isCalculatedId: string = `isCalculated_${suffix}`;
        const isCalculated: boolean | null = params.data?.[isCalculatedId];

        return isCalculated;
    }
    return undefined;
}