import { useQuery } from "@tanstack/react-query";
import { useCallback } from "react";
import { useAccessToken } from "../../useAccessToken";
import useProjectId from "../../../customHooks/useProjectId";
import { useInteractionContext } from "../../../context/InteractionContext";
import { formatData } from "../../../utils/formatData";
import { makeResultFetch } from "../../genericFetch";
import { apiRequestParams } from "../../fetchOptions";
import { GridRow } from "../../../interfaces/grids/GridRow";
import { useHandleRetry } from "../../useHandleRetry";


const useFetch = () => {
    const getAccessToken = useAccessToken();
    const projectId = useProjectId();

    const queryFunction = useCallback(async () => {
        return getAccessToken().then(accessToken => {
            if (!accessToken) throw new Error("Access token is undefined");

            const fetchedData = makeResultFetch<GridRow[]>(
                `/Sheet/${projectId}/Cashflow`,
                apiRequestParams(accessToken, 'GET')
            ).then((response) => {
                const { data, message, success } = response;

                if (!success) throw Error(message);

                if (data) {
                    const formattedData = formatData(data);
                    return formattedData;
                }
            });

            return fetchedData;
        });
    }, [getAccessToken, projectId]);

    return { projectId, queryFunction };
}


export const useCashflow = () => {
    const { projectId, queryFunction } = useFetch();
    const { loadProjectEconomy } = useInteractionContext();
    const handleRetry = useHandleRetry();


    const { isLoading, error, data } = useQuery({
        queryKey: [projectId, 'cashflow'],
        queryFn: queryFunction,
        enabled: !!projectId && !!queryFunction && loadProjectEconomy,
        retry: handleRetry,
    });

    return { isLoading, error, data };
}