import { useCallback } from "react";
import { useEditingContext } from "../context/EditingContext";
import { useProjectInfo } from "../fetchData/queries/useProjectInfo";
import { useInteractionContext } from "../context/InteractionContext";
import { useIsFetching } from "@tanstack/react-query";


const useEvaluateSaveEnabled = () => {

    const isFetching = useIsFetching() > 0;

    const projectInfo = useProjectInfo().data;
    const projectIsActive = !(projectInfo?.isFinalized || projectInfo?.isArchived);

    const {
        edits,
        selectedSnapshotId
    } = useEditingContext();
    const { editingEnabled } = useEditingContext();
    const { refreshLoading } = useInteractionContext()


    const evaluateIfSaveEnabled = useCallback(() => {
        if (!projectIsActive || selectedSnapshotId !== 'aktuell projektekonomi' || !editingEnabled
            || refreshLoading || isFetching) {
            return false;
        }

        const unsavedEdits = Object.keys(edits.columns).length > 0 || Object.keys(edits.budgets).length > 0 ||
            Object.keys(edits.comments).length > 0 || Object.keys(edits.projectAttributes).length > 0;

        return unsavedEdits;
    }, [editingEnabled, edits.budgets, edits.columns, edits.comments, edits.projectAttributes, isFetching,
        projectIsActive, refreshLoading, selectedSnapshotId]);

    return evaluateIfSaveEnabled();
};

export default useEvaluateSaveEnabled;