import { useMemo } from "react";
import { MonthDateInput } from "../CalculationBarInputs/DateInput/MonthDateInput";
import { PercentInput } from "../CalculationBarInputs/PercentInput/PercentInput";
import styles from '../CalculationsBar.module.css';
import { CalcBarParams } from "./interfaces/CalcBarParams";


export const useIndex = ({
    projectAttributes,
    setProjectAttributes,
    initialValues,
    setChangedValue,
    translation,
    readOnlyMode,
}: CalcBarParams) => {
    const index = useMemo(() => {
        return (<div className={styles.fieldsContainer}>
            <h5 className={styles.fieldsHeader}>Index</h5>
            <MonthDateInput
                values={projectAttributes}
                setValues={setProjectAttributes}
                initialValues={initialValues}
                setChangedValue={setChangedValue}
                label={translation.indexBaseMonth}
                attributeName={'indexBaseMonth'}
                disabled={readOnlyMode}
            />

            <PercentInput
                values={projectAttributes}
                setValues={setProjectAttributes}
                initialValues={initialValues}
                setChangedValue={setChangedValue}
                label={translation.indexPercentage}
                attributeName={'indexPercentage'}
                disabled={readOnlyMode}
            />
        </div>)
    }, [projectAttributes, setProjectAttributes, initialValues, setChangedValue, translation.indexBaseMonth,
        translation.indexPercentage, readOnlyMode]);

    return index;
}