import styles from '../InfoTooltip.module.css';

export const AccountTemplateTooltip = () => (
    <div className={styles.infoTooltip}>
        <h2 className={styles.infoTooltipHeader}>Information</h2>
        <ul className={styles.infoList}>
            <li>
                <p className={styles.infoText}>- Mall för kassaflöde och resultatuppföjning kan redigeras parallellt. Då redigering av mall gjorts kan ej projekttyp ändras</p>
            </li>
            <li>
                <p className={styles.infoText}>- Rader kan dras mellan grupper genom att släppa raden i gruppen dit den ska flyttas</p>
            </li>
            <li>
                <p className={styles.infoText}>- Huvudkonton kan läggas till men visas ej i projektekonomin (kräver ändring i backend)</p>
            </li>
            <li>
                <p className={styles.infoText}>- Konton/grupper kan läggas till på högre nivå än nedersta men visas ej i projektekonomin om konto på nedersta nivån ej finns</p>
            </li>
        </ul>
    </div>
);