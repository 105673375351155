import { ReactNode } from "react";
import { useUser } from "../fetchData/queries/useUser";
import { useControlAccess } from "./controlAccess/useControlAccess";


export const useScopedContent = (
    children: ReactNode,
    scopeKey?: string | string[],
    renderNoAccess?: JSX.Element,
) => {
    const scopes = useUser().data?.scopes;

    const access = useControlAccess(scopes, scopeKey);

    if (access) {
        return children;
    } else if (renderNoAccess) {
        return renderNoAccess;
    }
}