import { AgGridReact } from "ag-grid-react";
import { Dispatch, RefObject, SetStateAction, useCallback } from "react";
import ActionButton from "../../Buttons/ActionButton";
import { useUser } from "../../../fetchData/queries/useUser";
import { ScheduleEdit } from "../../../interfaces/systemSettings/RevenueRecognitionSchedule";

export const AddScheduleRowsForYearButton = (
    gridRef: RefObject<AgGridReact>,
    lastAddedRowId: number,
    changedDate: string,
    creates: ScheduleEdit[],
    setLastAddedRowId: Dispatch<SetStateAction<number>>,
    setCreates: Dispatch<SetStateAction<ScheduleEdit[]>>,
) => {

    const { data } = useUser();

    const getSecondWeekdayOfMonth = useCallback(() => {
        let dates: string[] = [];
        const year = new Date(changedDate).getFullYear();

        for (let month = 0; month < 12; month++) {
            const date = new Date(year, month, 1);

            // increment until weekday
            while (!(date.getDay() % 6)) {
                date.setDate(date.getDate() + 1);
            }

            date.setDate(date.getDate() + 1);

            // increment until next weekday if first weekday is friday
            while (!(date.getDay() % 6)) {
                date.setDate(date.getDate() + 1);
            }

            dates.push(date.toLocaleDateString('sv-SV'));
        };

        return dates;
    }, [changedDate]);

    const addScheduleRowsForYear = useCallback(() => {
        let dates = getSecondWeekdayOfMonth();
        let id = lastAddedRowId;
        let newScheduleRows: ScheduleEdit[] = [];

        if (gridRef?.current) {
            dates?.forEach(date => {
                id--;

                const currentDateTime = new Date().toLocaleString('sv-SV');

                const createTransaction = {
                    id: id,
                    date: date,
                    setBy: data?.accountId ?? data?.email ?? data?.name,
                    setTime: currentDateTime
                };

                gridRef.current!.api.applyTransaction({ add: [createTransaction] });

                const newScheduleRow: ScheduleEdit = { id: id, date: date };
                newScheduleRows.push(newScheduleRow);
            });

            setCreates([...creates, ...newScheduleRows]);
        }

        setLastAddedRowId(lastAddedRowId + id);

    }, [creates, getSecondWeekdayOfMonth, gridRef, lastAddedRowId, setCreates, setLastAddedRowId,
        data?.accountId, data?.email, data?.name]);


    return (
        <ActionButton
            text={'Lägg till rader för hela valda året'}
            handleOnClick={addScheduleRowsForYear}
            buttonStyles={['defaultButton']}
        />)
}