import { useMutation } from "@tanstack/react-query";
import { useAccessToken } from "../useAccessToken";
import { useCallback, } from "react";
import useProjectId from "../../customHooks/useProjectId";
import { useEditingContext } from "../../context/EditingContext";
import { Edits } from "../../interfaces/editing/Edits";
import { PrognosisColumn } from "../../interfaces/grids/PrognosisColumn";
import { useRefresh } from "./useRefresh";
import { EditsDto } from "../../interfaces/editing/dtos/EditsDto";
import { FetchResponse, makeResultFetch } from "../genericFetch";
import { apiRequestParams } from "../fetchOptions";
import { useHandleError } from "../useHandleError";


export const useShiftPrognosis = () => {
    const getAccessToken = useAccessToken();
    const handleError = useHandleError();
    const projectId = useProjectId();
    const refresh = useRefresh();
    const { edits, setEdits } = useEditingContext();


    const mutationFunction = useCallback(async (editsDto: EditsDto) => {
        return getAccessToken().then(accessToken => {
            if (!accessToken) throw new Error("Access token is undefined");

            const fetchedData = makeResultFetch<EditsDto>(
                `/Sheet/${projectId}/Shift`,
                apiRequestParams<EditsDto>(accessToken, 'PUT', editsDto)
            );

            return fetchedData;
        });
    }, [getAccessToken, projectId]);


    const handleSuccess = (shiftPrognosisResponse: FetchResponse<EditsDto>) => {
        const { data, message, success } = shiftPrognosisResponse;

        if (!success) throw Error(message);

        if (data) {
            let updateEdits: Edits = { ...edits, projectAttributes: data.projectAttributes };

            data.columns?.forEach((prognosisColumn: PrognosisColumn) => {
                const date = new Date(prognosisColumn.year, prognosisColumn.month);
                date.setUTCDate(1);
                date.setUTCHours(0, 0, 0, 0);
                const monthColumnPrefix = `${prognosisColumn.accountNumber}_prognosis_${date.toISOString().split('.')[0]}`;
                updateEdits.columns = { ...updateEdits.columns, [monthColumnPrefix]: prognosisColumn }
            });

            setEdits(updateEdits);

            refresh.mutate(data);
        }
    }


    const shiftPrognosis = useMutation({
        mutationFn: mutationFunction,
        onSuccess: handleSuccess,
        onError: handleError,
    });


    return shiftPrognosis;
}