const clientId: string = process.env.REACT_APP_AUTH_CLIENT_ID!;

export const msalConfig = {
    auth: {
        clientId: clientId,
        authority: "https://login.microsoftonline.com/35437cd1-f5cc-4dae-a872-d858dbf3471e",
        redirectUri: window.location.origin,
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: ["User.Read"]
};

