import styles from './SystemState.module.css';
import logo from '../../images/Riksbyggen_logo_RGB.jpg';
import { ReactNode } from 'react';

interface SystemStateProps {
    children: ReactNode;
}

const SystemState = ({ children }: SystemStateProps) => (
    <div className={styles.systemState}>
        <img height="100" src={logo} alt=""></img>
        {children}
    </div>
);

export default SystemState;
