import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import styles from './ActionHeaders.module.css';
import SaveButton from '../../Buttons/SaveButton';
import { AccountTemplateActions, AccountTemplateChanges, NumberStringPairs } from '../../../interfaces/systemSettings/AccountTemplate';
import { DefaultSelect } from '../../Inputs/DefaultSelect/DefaultSelect';

interface AccountTemplateGridHeaderProps {
  accountTemplateEdits: AccountTemplateChanges;
  accountTemplateDeletes: AccountTemplateChanges;
  economyTypes: NumberStringPairs;
  accountTemplateActions: AccountTemplateActions;
  setSheetTypeKey: Dispatch<SetStateAction<number>>;
  setEconomyTypeKey: Dispatch<SetStateAction<number>>;
  setAccountTemplateActions: Dispatch<SetStateAction<AccountTemplateActions>>;
}

const AccountTemplateGridHeader = ({
  accountTemplateEdits,
  accountTemplateDeletes,
  economyTypes,
  accountTemplateActions,
  setSheetTypeKey,
  setEconomyTypeKey,
  setAccountTemplateActions
}: AccountTemplateGridHeaderProps) => {

  const accountTemplateEdited = useMemo(() => {
    return (Object.keys(accountTemplateEdits[1]).length > 0 || Object.keys(accountTemplateEdits[2]).length > 0) ||
      (Object.keys(accountTemplateDeletes[1]).length > 0 || Object.keys(accountTemplateDeletes[2]).length > 0)
  }, [accountTemplateDeletes, accountTemplateEdits]);

  const handleEconomyTypeSelect = useCallback((key: number) => {
    setEconomyTypeKey(key);
    setAccountTemplateActions && setAccountTemplateActions(prevState => { return { ...prevState, load: true } });
  }, [setAccountTemplateActions, setEconomyTypeKey]);

  const handleSheetTypeSelect = useCallback((key: number) => setSheetTypeKey(key), [setSheetTypeKey]);

  const handleOnSaveClick = useCallback(() => {
    setAccountTemplateActions({ ...accountTemplateActions, save: true });
  }, [accountTemplateActions, setAccountTemplateActions]);

  return (
    <div className={styles.actionHeader}>
      <div className={styles.actionContainer}>
        <div className={styles.accountTemplateSelectContainer}>
          <DefaultSelect
            options={economyTypes}
            setSelectedOption={handleEconomyTypeSelect}
            label={'Projekttyp'}
            selectWidth={180}
            disabled={accountTemplateEdited}
          />
        </div>

        <div className={styles.accountTemplateSelectContainer}>
          <DefaultSelect
            options={{ 1: 'Kassaflöde', 2: 'Resultatuppföljning' }}
            setSelectedOption={handleSheetTypeSelect}
            label={'Projektekonomiark'}
            selectWidth={180}
          />
        </div>
      </div>

      <SaveButton
        text={'Spara mallar'}
        handleOnSaveClick={handleOnSaveClick}
        disabled={!accountTemplateEdited}
        tooltip={'Mallar sparade'}
      />
    </div>
  )
};

export default AccountTemplateGridHeader;
