import { Dispatch, SetStateAction } from "react";
import { AggTranslations } from "../interfaces/grids/performance/AggTranslation";

export const getContextMenu = (
    params: any, // ag grid default type does not work
    setIsModalOpen: Dispatch<SetStateAction<boolean>>,
    setModalAccountId: Dispatch<SetStateAction<string>>,
    setModalOutcomeDate: Dispatch<SetStateAction<string>>,
    aggTranslations: AggTranslations | undefined
) => {
    type ContextMenuItems = (string | { name: string, icon: string, action: any })[];

    let contextMenu: ContextMenuItems = [
        'copy',
        'cut',
        'paste'
    ];

    if ((params.value || aggTranslations?.[params.node?.key]?.totalOutcome) && params.column?.colDef.headerName?.toLocaleLowerCase() === 'utfall') {
        let accountId: string = '';

        if (params.node.allLeafChildren?.length > 1) {
            const nodeLevel: number = params.node.level;

            if (nodeLevel === 0) {
                accountId = params.node.allLeafChildren.find((child: any) => {
                    return params.node?.key === child.data.grandParentDisplayName
                }).data.grandParentAccountId;
            }
            if (nodeLevel === 1) {
                accountId = params.node.allLeafChildren.find((child: any) => {
                    return params.node?.key === child.data.parentDisplayName
                }).data.parentId;
            }
        } else {
            accountId = params.node.data?.accountId ?? params.node.allLeafChildren?.[0].data.accountId;
        }

        if (accountId) {
            const outcomeDate: string = params.column.getColId()?.split('_')[1]?.split('T')[0]?.slice(0, 7) ?? '';

            setModalAccountId(accountId);
            setModalOutcomeDate(outcomeDate);

            const accountSpecification: ContextMenuItems = [
                {
                    name: 'Kontospecifikation',
                    icon: '<span class="ag-icon ag-icon-aggregation" unselectable="on" role="presentation"></span>',
                    action: () => setIsModalOpen(true)
                },
                'separator'
            ];
            contextMenu.unshift(...accountSpecification);
        }
    }
    return contextMenu;
}