import { Dispatch, SetStateAction, useMemo } from 'react';
import { dateFormatter } from '../../../../utils/dateFormatter';
import { CellClassParams, ColDef, ValueGetterParams } from 'ag-grid-community';
import { GridReportInputs } from '../../../../interfaces/reports/ResCalcProjReport';
import { useQueriesProgress } from '../customHooks/useQueriesProgress';
import { useQueriesFetched } from '../customHooks/useQueriesFetched';
import { reportNumberFormatter, reportPercentageFormatter } from '../helper/reportValueFormatter';
import { getRowHierarchyStyle } from '../helper/getRowHierarchyStyle';
import { ForecastResCalcReportRow, ForecastResCalcReportTotals } from '../../../../interfaces/reports/ForecastResCalcReport';
import { TotalsCellRenderer } from './TotalsCellRenderer';
import { useForeCastResCalcFlow } from '../../../../fetchData/queries/reports/grid/useForecastResCalcFlow';


export const useForecastResCalcGrid = (
  gridReportInputs: GridReportInputs,
  setReportIsGenerated: Dispatch<SetStateAction<boolean>>,
) => {

  const report = useForeCastResCalcFlow(gridReportInputs);

  const queriesProgress = useQueriesProgress(report);
  useQueriesFetched(report, setReportIsGenerated);


  const reportData = report?.map(response => response.data)?.flat()
    ?.filter(data => data !== undefined && data !== null);

  const reportError = report?.map(response => response.error)
    ?.filter(error => error !== undefined && error !== null)?.length > 0;


  const rowData = reportData?.map(project => project?.periodSummaries
    ?.map(periodSummary => {
      let projectCopy = { ...project };
      delete projectCopy.periodSummaries;
      const reportRow: ForecastResCalcReportRow = { ...projectCopy, ...periodSummary };

      return reportRow;
    }))?.flat();


  const filteredTotals = reportData?.map((data) => {
    const key = data?.projectId ?? '';

    const totals: ForecastResCalcReportTotals = {
      totalApartments: data?.totalApartments,
      totalCost: data?.total?.cost,
      totalIncome: data?.total?.income,
      totalResult: data?.total?.result,
      lastUpdated: data?.lastUpdated,
      buildStartDate: data?.mgmtBuildStartConsent ?? data?.mgmtBuildStartConsentPlanned,
      buildStartDatePlanned: data?.mgmtBuildStartConsent ? false : true,
    }
    const projectIdTotals = { [key]: totals };

    return projectIdTotals;
  })?.reduce(((object, objects) => Object.assign(object, objects)), {});



  const getRowStyle = (params: CellClassParams) => {
    if (params.node.data?.isOutcome) {
      return { background: '#A7AED870', fontWeight: '' };
    } else {
      return getRowHierarchyStyle(params);
    }
  };


  const columnDefs = useMemo(() => {
    const colDefs = [
      {
        headerName: 'Projekt',
        children: [
          {
            headerName: 'Byggregion',
            rowGroup: true,
            hide: true,
            pinned: 'left',
            field: 'region',
            cellStyle: (params: CellClassParams) => getRowStyle(params),
          },
          {
            headerName: 'Byggrupp',
            rowGroup: true,
            hide: true,
            pinned: 'left',
            field: 'group',
            cellStyle: (params: CellClassParams) => getRowStyle(params),
          },
          {
            headerName: 'Projekt',
            rowGroup: true,
            hide: true,
            pinned: 'left',
            valueGetter: (params: ValueGetterParams) => {
              const projectName = params.data?.projectName.trim();
              const projectId = params.data?.projectId;

              if (projectName && projectId) return `${projectId} - ${projectName}`;
              return '';
            },
            cellStyle: (params: CellClassParams) => getRowStyle(params),
          },
          {
            headerName: 'År',
            hide: true,
            valueGetter: (params: ValueGetterParams) => {
              const period = params.data?.period;
              if (period) return period?.split('-')?.[0]
              return ''
            },
            pinned: 'left',
            cellStyle: (params: CellClassParams) => getRowStyle(params),
          },
        ]
      },
      {
        headerName: 'Total projektprognos (tkr)',
        children: [
          {
            headerName: 'Antal lägenheter',
            field: 'totalApartments',
            type: 'numericColumn',
            valueFormatter: reportNumberFormatter,
            cellStyle: (params: CellClassParams) => getRowStyle(params),

            cellRenderer: TotalsCellRenderer,
            cellRendererParams: { filteredTotals: { ...filteredTotals } },
          },
          {
            headerName: 'Intäkter',
            field: 'totalIncome',
            valueGetter: (params: ValueGetterParams) => params.data?.total?.income,
            type: 'numericColumn',
            valueFormatter: reportNumberFormatter,
            cellStyle: (params: CellClassParams) => getRowStyle(params),

            cellRenderer: TotalsCellRenderer,
            cellRendererParams: { filteredTotals: { ...filteredTotals } },
          },
          {
            headerName: 'Kostnader',
            field: 'totalCost',
            valueGetter: (params: ValueGetterParams) => params.data?.total?.cost,
            type: 'numericColumn',
            valueFormatter: reportNumberFormatter,
            cellStyle: (params: CellClassParams) => getRowStyle(params),
            cellRenderer: TotalsCellRenderer,
            cellRendererParams: { filteredTotals: { ...filteredTotals } },
          },
          {
            headerName: 'Resultat',
            field: 'totalResult',
            valueGetter: (params: ValueGetterParams) => params.data?.total?.result,
            type: 'numericColumn',
            valueFormatter: reportNumberFormatter,
            cellStyle: (params: CellClassParams) => getRowStyle(params),
            cellRenderer: TotalsCellRenderer,
            cellRendererParams: { filteredTotals: { ...filteredTotals } },
          },
          {
            headerName: 'Prognos bekräftad',
            field: 'lastUpdated',
            type: 'date',
            valueFormatter: dateFormatter,
            cellStyle: (params: CellClassParams) => getRowStyle(params),
            cellRenderer: TotalsCellRenderer,
            cellRendererParams: { filteredTotals: { ...filteredTotals } },
          },
          {
            headerName: 'Igångsättning (*=planerad)',
            field: 'buildStartDate',
            valueGetter: (params: ValueGetterParams) => {
              const planned = params.data?.mgmtBuildStartConsentPlanned;
              const consent = params.data?.mgmtBuildStartConsent;
              const buildStartDate = consent ?? planned;

              if (buildStartDate) return buildStartDate;
              return '';
            },
            type: 'date',
            valueFormatter: dateFormatter,
            cellStyle: (params: CellClassParams) => getRowStyle(params),
            cellRenderer: TotalsCellRenderer,
            cellRendererParams: { filteredTotals: { ...filteredTotals } },
          },
        ]
      },
      {
        headerName: 'Prognos resultatavräkning (tkr)',
        children: [
          {
            headerName: 'Period',
            field: 'period',
            type: 'date',
            valueFormatter: dateFormatter,
            sort: 'asc',
            cellStyle: (params: CellClassParams) => getRowStyle(params),
          },
          {
            headerName: 'Upparb. grad (%)',
            field: 'progress.degreeOfCompletion',
            valueFormatter: reportPercentageFormatter,
            cellStyle: (params: CellClassParams) => getRowStyle(params),
          },
          {
            headerName: 'Försälj. grad (%)',
            field: 'progress.salesRatio',
            valueFormatter: reportPercentageFormatter,
            cellStyle: (params: CellClassParams) => getRowStyle(params),
          },
          {
            headerName: 'Avr. grad (%)',
            field: 'progress.settlementRate',
            valueFormatter: reportPercentageFormatter,
            cellStyle: (params: CellClassParams) => getRowStyle(params),
          },
          {
            headerName: 'Intäkter att avräkna',
            field: 'monthSummary.revenue.total',
            type: 'numericColumn',
            valueFormatter: reportNumberFormatter,
            cellStyle: (params: CellClassParams) => getRowStyle(params),
          },
          {
            headerName: 'Avgår tid. avräknat (intäkter)',
            field: 'monthSummary.revenue.previouslyOffset',
            hide: true,
            type: 'numericColumn',
            valueFormatter: reportNumberFormatter,
            cellStyle: (params: CellClassParams) => getRowStyle(params),
          },
          {
            headerName: 'Månadens intäkter att avräkna',
            field: 'monthSummary.revenue.value',
            type: 'numericColumn',
            valueFormatter: reportNumberFormatter,
            cellStyle: (params: CellClassParams) => getRowStyle(params),
          },
          {
            headerName: 'Kostnader att avräkna',
            field: 'monthSummary.cost.total',
            type: 'numericColumn',
            valueFormatter: reportNumberFormatter,
            cellStyle: (params: CellClassParams) => getRowStyle(params),
          },
          {
            headerName: 'Avgår tid. avräknat (kostnader)',
            field: 'monthSummary.cost.previouslyOffset',
            hide: true,
            type: 'numericColumn',
            valueFormatter: reportNumberFormatter,
            cellStyle: (params: CellClassParams) => getRowStyle(params),
          },
          {
            headerName: 'Månadens kostnader att avräkna',
            field: 'monthSummary.cost.value',
            type: 'numericColumn',
            valueFormatter: reportNumberFormatter,
            cellStyle: (params: CellClassParams) => getRowStyle(params),
          },
          {
            headerName: 'Månadens resultat',
            field: 'monthSummary.result',
            type: 'numericColumn',
            valueFormatter: reportNumberFormatter,
            cellStyle: (params: CellClassParams) => getRowStyle(params),
          },
        ]
      },
    ];

    return colDefs;
  }, [filteredTotals]);


  const autoGroupColumnDef = useMemo((): ColDef => {
    return {
      headerName: '',
      field: '',
      width: 250,
      pinned: 'left',
      cellRendererParams: {
        suppressCount: true,
        suppressDoubleClickExpand: true,
      },
      cellStyle: (params: CellClassParams) => getRowStyle(params),
    };
  }, []);


  const gridDefs = {
    columnDefs: columnDefs,
    autoGroupColumnDef: autoGroupColumnDef,
    rowData: rowData,
    queriesProgress: queriesProgress,
    reportError: reportError,
  };


  return gridDefs;
}

