import styles from '../ActionHeaders.module.css';
import ButtonRow from './ButtonRow/ButtonRow';
import GridFilter from '../../../GridFilter/GridFilter';
import InternalCreditsButton from '../../../InternalCredits/InternalCreditsButton/InternalCreditsButton';
import ExportExcelButton from '../../../Buttons/ExportExcelButton';
import { useCallback, useState } from 'react';
import MenuButton from '../../../Buttons/MenuButton/MenuButton';
import { useReportsMenu } from '../../../Buttons/MenuButton/Menus/useReportsMenu';
import { useSheetContext } from '../../../../context/SheetContext';
import { useAccountingMenu } from '../../../Buttons/MenuButton/Menus/useAccountingMenu';
import { MenuButtonModal } from '../../../Buttons/MenuButton/MenuButtonModal/MenuButtonModal';
import { useProjectInfo } from '../../../../fetchData/queries/useProjectInfo';
import { useEditingContext } from '../../../../context/EditingContext';
import ConfirmPrognosisButton from '../../../Buttons/ConfirmPrognosisButton';


const ButtonHeader = () => {
  const {
    cashflowGridRef,
    performanceGridRef,
    performanceTotals
  } = useSheetContext();

  const [showReportsMenu, setShowReportsMenu] = useState(false);
  const [showAccountingMenu, setShowAccountingMenu] = useState(false);

  const [modalContent, setModalContent] = useState<{ header: string, body: JSX.Element }>({ header: '', body: <></> });
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalLock, setModalLock] = useState<boolean>(false);

  const reportsMenuItems = useReportsMenu();
  const accountMenuItems = useAccountingMenu(setModalContent, setIsModalOpen, setModalLock);

  const projectInfo = useProjectInfo().data;
  const projectIsActive: boolean = !(projectInfo?.isFinalized || projectInfo?.isArchived);

  const { snapshotMode } = useEditingContext();


  const accountingModal = useCallback(() => {
    return <MenuButtonModal
      isModalOpen={isModalOpen}
      setIsModalOpen={modalLock ? undefined : setIsModalOpen}
      modalContent={modalContent}
    />
  }, [isModalOpen, modalContent, modalLock]);


  return (
    <>
      <div className={styles.actionHeader} data-testid="ButtonHeader">
        <div className={styles.filterInputContainer}>
          <GridFilter gridRefs={[cashflowGridRef, performanceGridRef]} elementId={'main-filter'} />
        </div>
        {(cashflowGridRef && performanceGridRef) &&
          <ButtonRow
            cashflowGridRef={cashflowGridRef}
            performanceGridRef={performanceGridRef}
          />}

        <div className={styles.actionContainer}>
          {reportsMenuItems?.length > 0 &&
            <MenuButton
              showMenu={showReportsMenu}
              setShowMenu={setShowReportsMenu}
              menuItems={reportsMenuItems}
              text={'Rapporter'}
              disabledMenu={snapshotMode}
            />
          }

          {accountMenuItems?.length > 0 &&
            <MenuButton
              showMenu={showAccountingMenu}
              setShowMenu={setShowAccountingMenu}
              menuItems={accountMenuItems}
              text={'Bokföring'}
              modal={accountingModal()}
              disabledMenu={!projectIsActive || snapshotMode}
            />
          }

          <InternalCreditsButton />

          <ExportExcelButton
            gridRefs={[cashflowGridRef, performanceGridRef]}
            fileName={'projektekonomi'}
            sheetNames={['Kassaflöde', 'Resultatuppföljning']}
            aggTranslations={performanceTotals}
          />

          <ConfirmPrognosisButton />
        </div>
      </div>
    </>
  )
};

export default ButtonHeader;