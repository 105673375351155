import { ChangeEvent, useEffect, useState } from "react";
import { useSaveSystemMessage } from "../../../../fetchData/mutations/systemMessage/useSaveSystemMessage"
import { useSystemMessage } from "../../../../fetchData/queries/settings/useSystemMessage";
import { IContextualMenuProps } from "@fluentui/react";
import { useDeleteSystemMessage } from "../../../../fetchData/mutations/systemMessage/useDeleteSystemMessage";
import styles from '../AdminTools.module.css';
import { SystemMessageDetails } from "../../../../interfaces/systemSettings/SystemMessageDetails";
import { AdminDeleteButton } from "./AdminInputs/AdminDeleteButton";
import { AdminActionButton } from "./AdminInputs/AdminActionButton";


export const SystemMessage = () => {
    const systemMessage = useSystemMessage().data;
    const saveSystemMessage = useSaveSystemMessage();
    const deleteSystemMessage = useDeleteSystemMessage();

    const [type, setType] = useState<number>(0);
    const [durationUnit, setDurationUnit] = useState<number>(1);
    const [durationInMinutes, setDurationInMinutes] = useState<number>(0);
    const [message, setMessage] = useState<string>('');


    useEffect(() => {
        if (deleteSystemMessage.data?.success) {
            setType(0);
            setDurationInMinutes(0);
            setMessage('');
        }
    }, [deleteSystemMessage.data?.success]);


    const messageInput = () => (
        <div className={styles.inputCol}>
            <label>Meddelande</label>

            <textarea
                className={styles.messageTextArea}
                rows={5}
                maxLength={200}
                placeholder="Max 200 tecken"
                value={systemMessage?.message ?? message}
                onChange={(e) => setMessage(e.target.value)}
                disabled={typeof systemMessage?.message === 'string'}
            />
        </div>
    )


    const unitSelect = () => {
        const units: { [key: number]: string } = {
            1: 'Minuter',
            60: 'Timmar',
            1440: 'Dagar',
            10080: 'Veckor',
        }

        return (
            <select
                value={durationUnit}
                onChange={(e) => setDurationUnit((Number(e.target.value)))}
            >
                {
                    Object.entries(units).map(([key, value]) => {
                        return <option key={key} value={key}>{value}</option>
                    })
                }
            </select>
        )
    }


    const unitInput = () => {
        const unitValue = systemMessage?.durationInMinutes ?? durationInMinutes;
        const value = unitValue > 0 ? unitValue / durationUnit : '';

        const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.value === '') {
                setDurationInMinutes(0);
            } else {
                const numericValue = Number(e.target.value);

                if (numericValue) {
                    setDurationInMinutes(numericValue * durationUnit);
                }
            }
        }

        return (
            <div className={`${styles.inputCol} ${styles.unitInput}`}>
                <input
                    value={value}
                    type="text"
                    onChange={(e) => handleChange(e)}
                    pattern="[0-9]+"
                    disabled={typeof systemMessage?.message === 'string'}
                ></input>
            </div>
        )
    }


    const durationInput = () => (
        <div className={styles.inputCol}>
            <label className={styles.labelPadding}>Tid</label>

            <div className={styles.inputRow}>
                {unitInput()}
                {unitSelect()}
            </div>
        </div>
    )


    const typeInput = () => {
        const typeOptions: { [key: number]: string } = {
            0: 'Info',
            1: 'Varning',
            2: 'Kritisk'
        }

        return (
            <div className={styles.inputCol}>
                <label className={styles.labelPadding}>Typ</label>

                <select
                    value={systemMessage?.type ?? type}
                    onChange={(e) => setType(Number(e.target.value))}
                    disabled={typeof systemMessage?.message === 'string'}
                >

                    {Object.entries(typeOptions).map(([key, value]) =>
                        <option key={key} value={key}>{value}</option>
                    )}
                </select>
            </div>
        )
    }


    const saveButton = () => {
        const saveMessage: SystemMessageDetails = {
            message: message,
            type: type,
            durationInMinutes: durationInMinutes,
        }

        const handleSave = () => saveSystemMessage.mutate(saveMessage);
        const saveDisabled = message?.length === 0 || !(durationInMinutes >= 1);

        return (
            <AdminActionButton
                buttonText={'Spara'}
                handleAction={handleSave}
                actionDisabled={saveDisabled}
            />
        )
    }


    const deleteButton = () => {
        const handleDelete = () => deleteSystemMessage.mutate();

        const menuProps: IContextualMenuProps = {
            items: [
                {
                    key: 'deleteSystemMessage',
                    text: 'Ja, radera meddelandet',
                    iconProps: { iconName: 'Delete' },
                    onClick: handleDelete,
                },
            ],
        }

        return (
            <AdminDeleteButton
                buttonText={'Radera'}
                menuProps={menuProps}
            />
        );
    }


    const systemMessageForm = () => {
        const usageInstruction = typeof systemMessage?.message === 'string'
            ? 'Ta bort meddelandet för att lägga till ett nytt'
            : 'Här kan du ange ett meddelande som visas för användare';

        const button = typeof systemMessage?.message === 'string'
            ? deleteButton()
            : saveButton();

        return (
            <>
                <p className={styles.usageInstruction}>{usageInstruction}</p>

                <div className={styles.inputSpaceRow}>
                    {typeInput()}
                    {durationInput()}
                </div>

                {messageInput()}
                {button}
            </>
        )
    }



    return (
        <div className={styles.formContainer}>
            <h6 className={styles.inputGroupHeader}>Systemmeddelande</h6>

            {systemMessageForm()}
        </div>
    )
}