import { useCallback } from "react";
import { useInteractionContext } from "../context/InteractionContext";

export const useHandleError = () => {
    const { setErrors } = useInteractionContext();

    const handleError = useCallback((error: unknown) => {

        if (error instanceof Error) {
            setErrors((prevState) => [
                ...prevState,
                error
            ]);
        }
    }, [setErrors]);

    return handleError;
}