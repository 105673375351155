import { ReactNode } from 'react';
import { useScopedContent } from './useScopedContent';

interface ElementAccessProps {
  children: ReactNode;
  scopeKey?: string | string[];
}

const ElementAccess = ({
  children,
  scopeKey,
}: ElementAccessProps) => {
  const controlledContent = useScopedContent(children, scopeKey);

  return <>{controlledContent}</>;
}

export default ElementAccess;