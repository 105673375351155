import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useState } from "react";

type InteractionContextProviderProps = {
    children: ReactNode;
}

type InteractionContextStates = {
    loadProjectEconomy: boolean;
    setLoadProjectEconomy: Dispatch<SetStateAction<boolean>>;
    errors: Error[];
    setErrors: Dispatch<SetStateAction<Error[]>>;
    refreshLoading: boolean;
    setRefreshLoading: Dispatch<SetStateAction<boolean>>;
}


export const InteractionContext = createContext<InteractionContextStates | null>(null);

export const InteractionContextProvider = ({ children }: InteractionContextProviderProps) => {

    const [loadProjectEconomy, setLoadProjectEconomy] = useState(true);
    const [errors, setErrors] = useState<Error[]>([]);

    const [refreshLoading, setRefreshLoading] = useState(false);

    const value: InteractionContextStates = {
        loadProjectEconomy,
        setLoadProjectEconomy,
        errors,
        setErrors,
        refreshLoading,
        setRefreshLoading
    }

    return (<InteractionContext.Provider
        value={value}>
        {children}
    </InteractionContext.Provider>);
}


export const useInteractionContext = () => {
    const context = useContext(InteractionContext);

    if (!context) {
        throw Error("useInteractionContext must be used within a InteractionContextProvider")
    }

    return context;
}

