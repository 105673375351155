import { CellClassParams } from "ag-grid-community";
import { RowStyle } from "../interfaces/grids/RowStyle";

export const getRowStyle = (params: CellClassParams, colStyle: RowStyle, displayIfEditable?: boolean) => {
    switch (params.node.level) {
        case 1:
            colStyle.backgroundColor = `${colStyle.backgroundColor}30`;
            colStyle.fontWeight = '700';
            break;
        case 2:
            colStyle.backgroundColor = params.node.data?.editable || !displayIfEditable ? 'transparent' : `${colStyle.backgroundColor}30`;
            break;
        default:
            colStyle.backgroundColor = `${colStyle.backgroundColor}80`;
            colStyle.fontWeight = '900';
            break;
    }
    return colStyle;
}