import styles from './ResultCalcSchedulerHeader.module.css';
import actionHeaderStyles from '../ActionHeaders.module.css';
import SaveButton from '../../../Buttons/SaveButton';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useSaveRevenueRecognitionSchedule } from '../../../../fetchData/mutations/useSaveRevenueRecognitionSchedule';
import { ScheduleEdit } from '../../../../interfaces/systemSettings/RevenueRecognitionSchedule';
import { Spinner, SpinnerSize } from '@fluentui/react';


interface ResultCalcSchedulerHeaderProps {
    creates: ScheduleEdit[];
    setCreates: Dispatch<SetStateAction<ScheduleEdit[]>>;
    updates: ScheduleEdit[];
    setUpdates: Dispatch<SetStateAction<ScheduleEdit[]>>;
    deletes: ScheduleEdit[];
    setDeletes: Dispatch<SetStateAction<ScheduleEdit[]>>;
    schedulerDatePicker: () => JSX.Element;
    addScheduleRowButton: () => JSX.Element;
    addScheduleRowsForYearButton: () => JSX.Element;
}

const ResultCalcSchedulerHeader = ({
    creates,
    setCreates,
    updates,
    setUpdates,
    deletes,
    setDeletes,
    schedulerDatePicker,
    addScheduleRowButton,
    addScheduleRowsForYearButton,
}: ResultCalcSchedulerHeaderProps) => {

    const saveRevenueRecognitionSchedule = useSaveRevenueRecognitionSchedule();

    useEffect(() => {
        if (saveRevenueRecognitionSchedule.data?.success) {
            setCreates([]);
            setUpdates([]);
            setDeletes([]);
        }
    }, [saveRevenueRecognitionSchedule.data?.success, setCreates, setDeletes, setUpdates]);


    const handleSave = () => {
        const edits = {
            created: creates,
            updated: updates,
            deleted: deletes
        };

        saveRevenueRecognitionSchedule.mutate(edits);
    };

    const saveDisabled = () => {
        const disabled = saveRevenueRecognitionSchedule.isLoading ||
            !(creates?.length > 0 || updates?.length > 0 || deletes?.length > 0);
        return disabled;
    };


    return (
        <div className={actionHeaderStyles.actionHeader}>
            <div className={styles.resultCalcHeaderSection}>
                <div className={styles.resultCalcHeaderSubSection}>
                    {schedulerDatePicker()}
                    {addScheduleRowButton()}
                    {addScheduleRowsForYearButton()}
                </div>

                <div className={styles.resultCalcHeaderSubSection}>
                    {saveRevenueRecognitionSchedule.isLoading && <Spinner style={{ margin: '8px' }} size={SpinnerSize.small} />}

                    <SaveButton
                        text={'Spara'}
                        handleOnSaveClick={handleSave}
                        disabled={saveDisabled()}
                        tooltip={'Ändringar sparade'}
                    />
                </div>
            </div>
        </div>
    )
};

export default ResultCalcSchedulerHeader;
