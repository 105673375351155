import styles from './ResultCalcSchedulerGrid.module.css';
import { LicenseManager } from 'ag-grid-enterprise';
import { ChangeEvent, Dispatch, RefObject, SetStateAction, useCallback, useMemo } from 'react';
import {
    GetContextMenuItemsParams, GetRowIdFunc, GetRowIdParams, ICellRendererParams, IRowNode,
    MenuItemDef, ValueFormatterParams
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { defaultColDef } from '../../../MainGrids/gridConfig/DefaultColDef';
import { AG_GRID_LOCALE_SV } from '../../../MainGrids/gridConfig/SwedishLocale';
import LoadingSpinner from '../../../StatusIndicators/LoadingSpinner';
import { useRevenueRecognitionSchedule } from '../../../../fetchData/queries/accounting/useRevenueRecognitionSchedule';
import { localizeDateToDateString, localizeDateToString } from '../../../../utils/localizedDateStrings';
import { ScheduleEdit } from '../../../../interfaces/systemSettings/RevenueRecognitionSchedule';
import { gridLicenseKey } from '../../../MainGrids/gridLicenseKey';


LicenseManager.setLicenseKey(gridLicenseKey);


interface ResultCalculationSchedulerProps {
    creates: ScheduleEdit[];
    setCreates: Dispatch<SetStateAction<ScheduleEdit[]>>;
    updates: ScheduleEdit[];
    setUpdates: Dispatch<SetStateAction<ScheduleEdit[]>>;
    deletes: ScheduleEdit[];
    setDeletes: Dispatch<SetStateAction<ScheduleEdit[]>>;
    gridRef: RefObject<AgGridReact>;
}

const ResultCalcSchedulerGrid = ({
    creates,
    setCreates,
    updates,
    setUpdates,
    deletes,
    setDeletes,
    gridRef
}: ResultCalculationSchedulerProps) => {

    const revenueRecognitionSchedule = useRevenueRecognitionSchedule();


    const DatePickerCellRenderer = useCallback((params: ICellRendererParams) => {
        const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
            const rowId: number = params.node?.data.id;
            const editedRow: ScheduleEdit = { ...params.data, date: e.target.value };

            params.node.updateData(editedRow);

            let updatedCreate = creates.find(create => create.id === rowId);

            if (updatedCreate) {
                const createsCopy = [...creates];
                const index = creates.indexOf(updatedCreate);
                createsCopy[index] = { id: rowId, date: editedRow.date };

                setCreates(createsCopy);
            }
            else {
                setUpdates([...updates, { id: editedRow.id, date: editedRow.date }]);
            }
        }

        return (
            <input
                lang='sv-Sv'
                className={styles.datePicker}
                type='date'
                onKeyDown={(e) => e.preventDefault()}
                value={params.valueFormatted?.toString()}
                onChange={handleChange}
            />)
    }, [creates, setCreates, setUpdates, updates]);


    const schedulerColDefs: object[] = useMemo(() => {
        return [
            {
                field: 'date',
                headerName: 'Datum',
                sort: 'desc',
                valueFormatter: (params: ValueFormatterParams) => localizeDateToDateString(params.value),
                cellRenderer: DatePickerCellRenderer,
                flex: 3,
            },
            {
                field: 'setBy',
                headerName: 'Ändrad av',
                flex: 3,
                cellEditorParams: { maxLength: 10 }
            },
            {
                field: 'setTime',
                headerName: 'Senast ändrad/sparad',
                valueFormatter: (params: ValueFormatterParams) => localizeDateToString(params.value),
                flex: 3,
            },
        ]
    }, [DatePickerCellRenderer]);


    const deleteRow = useCallback((node: IRowNode, rowsToDelete: ScheduleEdit[]) => {
        const editedRowId: number = node?.data.id;

        let deletedCreate = creates.find(create => create.id === editedRowId);
        let deletedUpdate = updates.find(create => create.id === editedRowId);

        if (deletedCreate || deletedUpdate) {
            if (deletedCreate) {
                let createsCopy = [...creates];
                const index = creates.indexOf(deletedCreate);

                createsCopy.splice(index, 1);
                setCreates(createsCopy);
            }

            if (deletedUpdate) {
                let updatesCopy = [...updates];
                const index = creates.indexOf(deletedUpdate);

                updatesCopy.splice(index, 1);
                setUpdates(updatesCopy);

                const deletedRow = {
                    id: Number(node?.id),
                    date: node?.data?.date
                };

                rowsToDelete.push(deletedRow);
            }
        } else {
            const deletedRow = {
                id: Number(node?.id),
                date: new Date(node?.data?.date)?.toLocaleDateString('sv-SV')
            };

            rowsToDelete.push(deletedRow);
        }
    }, [creates, setCreates, setUpdates, updates]);


    const deleteRows = useCallback((nodes: IRowNode[] | null) => {
        if (nodes && nodes?.length > 0) {
            const rowData = nodes.map(node => node?.data);
            gridRef.current!.api.applyTransaction({ remove: rowData });

            let rowsToDelete: ScheduleEdit[] = [];
            nodes.forEach(node => deleteRow(node, rowsToDelete));

            setDeletes([...deletes, ...rowsToDelete]);
        }
    }, [deleteRow, deletes, gridRef, setDeletes]);


    const handleDelete = useCallback((params: GetContextMenuItemsParams) => {
        const selectedRows = params.api.getSelectedNodes();
        deleteRows(selectedRows);
    }, [deleteRows]);


    const getContextMenuItems = useCallback((params: GetContextMenuItemsParams): (
        string | MenuItemDef
    )[] => {
        let result: (string | MenuItemDef)[] = [{
            name: 'Ta bort markerade rader',
            icon: '<span class="ag-icon ag-icon-cross" role="presentation"></span>',
            action: () => handleDelete(params)
        }];

        return result;
    }, [handleDelete]);


    const getRowId = useMemo<GetRowIdFunc>(() => {
        return (params: GetRowIdParams) => params.data.id;
    }, []);


    return (
        <div className={`ag-theme-balham ${styles.resCalcSchedulerGrid}`}>
            <AgGridReact
                ref={gridRef}
                rowData={revenueRecognitionSchedule.data}
                columnDefs={schedulerColDefs}
                defaultColDef={defaultColDef}
                localeText={AG_GRID_LOCALE_SV}
                loadingOverlayComponent={LoadingSpinner}
                getContextMenuItems={getContextMenuItems}
                getRowId={getRowId}
                rowSelection={'multiple'}
            />
        </div>
    );
};



export default ResultCalcSchedulerGrid;
