import useProjectId from "../../../customHooks/useProjectId";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useAccessToken } from "../../useAccessToken";
import { useCallback } from "react";
import { Snapshot } from "../../../interfaces/Snapshot";
import { makeResultFetch } from "../../genericFetch";
import { useHandleRetry } from "../../useHandleRetry";
import { apiRequestParams } from "../../fetchOptions";


const Query = () => {
    const getAccessToken = useAccessToken();
    const projectId = useProjectId();

    const queryFunction = useCallback(async () => {
        return getAccessToken().then(accessToken => {
            if (!accessToken) throw new Error("Access token is undefined");

            const fetchedData = makeResultFetch<Snapshot[]>(
                `/Snapshots/${projectId}/List`,
                apiRequestParams(accessToken, 'GET')
            ).then((response) => {
                const { data, message, success } = response;

                if (!success) throw Error(message);

                return data;
            });

            return fetchedData;
        });
    }, [getAccessToken, projectId]);

    return { projectId, queryFunction };
}


export const usePrefetchSnapshots = () => {
    const { projectId, queryFunction } = Query();
    const queryClient = useQueryClient();

    const prefetchQuery = useCallback(() => {
        queryClient.prefetchQuery({
            queryKey: [projectId, 'snapshots'],
            queryFn: queryFunction,
        });
    }, [projectId, queryClient, queryFunction])

    return prefetchQuery;
}


export const useSnapshots = () => {
    const { projectId, queryFunction } = Query();
    const handleRetry = useHandleRetry();

    const { isLoading, error, data } = useQuery({
        queryKey: [projectId, 'snapshots'],
        queryFn: queryFunction,
        enabled: !!projectId && !!queryFunction,
        retry: handleRetry,
    });


    return { isLoading, error, data };
}