import styles from './ResultCalculationScheduler.module.css';
import { useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { AddScheduleRowButton } from './AddScheduleRowButton';
import { AddScheduleRowsForYearButton } from './AddScheduleRowsForYearButton';
import { SchedulerDatePicker } from './SchedulerDatePicker';
import ResultCalcSchedulerGrid from './ResultCalcSchedulerGrid/ResultCalcSchedulerGrid';
import ResultCalcSchedulerHeader from '../../Headers/ActionHeaders/ResultCalculation/ResultCalcSchedulerHeader';
import { ScheduleEdit } from '../../../interfaces/systemSettings/RevenueRecognitionSchedule';


const ResultCalculationScheduler = () => {

  const gridRef = useRef<AgGridReact>(null);

  const date = new Date().toLocaleDateString('sv-SV');
  const [changedDate, setChangedDate] = useState(date);

  const [lastAddedRowId, setLastAddedRowId] = useState(0);

  const [creates, setCreates] = useState<ScheduleEdit[]>([]);
  const [updates, setUpdates] = useState<ScheduleEdit[]>([]);
  const [deletes, setDeletes] = useState<ScheduleEdit[]>([]);

  const schedulerDatePicker = () => SchedulerDatePicker(setChangedDate);

  const addScheduleRowButton = () =>
    AddScheduleRowButton(
      gridRef,
      lastAddedRowId,
      changedDate,
      creates,
      setLastAddedRowId,
      setCreates
    );


  const addScheduleRowsForYearButton = () =>
    AddScheduleRowsForYearButton(
      gridRef,
      lastAddedRowId,
      changedDate,
      creates,
      setLastAddedRowId,
      setCreates
    );


  return (
    <div className={styles.resultCalcSchedulerContainer} data-testid="ResultCalculationScheduler">
      <ResultCalcSchedulerHeader
        creates={creates}
        setCreates={setCreates}
        updates={updates}
        setUpdates={setUpdates}
        deletes={deletes}
        setDeletes={setDeletes}
        schedulerDatePicker={schedulerDatePicker}
        addScheduleRowButton={addScheduleRowButton}
        addScheduleRowsForYearButton={addScheduleRowsForYearButton}
      />

      <ResultCalcSchedulerGrid
        creates={creates}
        setCreates={setCreates}
        updates={updates}
        setUpdates={setUpdates}
        deletes={deletes}
        setDeletes={setDeletes}
        gridRef={gridRef}
      />
    </div >
  );
};



export default ResultCalculationScheduler;
