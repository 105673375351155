import { useProfitOnContract } from "../../../../fetchData/queries/reports/grid/useProfitOnContract";
import { SystemError } from "../../../SystemState/States/SystemError";
import { SystemLoading } from "../../../SystemState/States/SystemLoading";
import { ProfitOnContractPdf } from "../Documents/ProfitOnContractPdf";
import { PdfReportViewer } from "../PdfReportViewer/PdfReportViewer";


export const ProfitOnContractReport = () => {
    const { isLoading, error, data } = useProfitOnContract();

    document.title = 'Rapport entrepenadvinst';

    if (error) return <SystemError errors={[error]} />;

    if (isLoading) return <SystemLoading text="Laddar rapport entrepenadvinst..." />;

    return (
        <PdfReportViewer>
            <ProfitOnContractPdf reportData={data} />
        </PdfReportViewer>
    );
}