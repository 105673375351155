import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAccessToken } from "../useAccessToken";
import { useCallback, } from "react";
import useProjectId from "../../customHooks/useProjectId";
import { SheetsDto } from "../../interfaces/editing/dtos/SheetsDto";
import { useEditingContext } from "../../context/EditingContext";
import { createEditsDto } from "../../utils/createEditsDto";
import { FetchResponse, makeResultFetch } from "../genericFetch";
import { apiRequestParams } from "../fetchOptions";


export const useSaveSheets = () => {
    const getAccessToken = useAccessToken();
    const projectId = useProjectId();
    const { edits, setEdits } = useEditingContext();
    const queryClient = useQueryClient();


    const mutationFunction = useCallback(async () => {
        return getAccessToken().then(accessToken => {
            if (!accessToken) throw new Error("Access token is undefined");

            const editsDto = createEditsDto(edits);

            const sheetsDto: SheetsDto = {
                columns: [...editsDto.columns],
                budgets: [...editsDto.budgets],
                comments: [...editsDto.comments]
            };

            const fetchedData = makeResultFetch<boolean>(
                `/Sheet/${projectId}/Save`,
                apiRequestParams<SheetsDto>(accessToken, 'PUT', sheetsDto)
            );

            return fetchedData;
        });
    }, [edits, getAccessToken, projectId]);

    // optional data variable
    const handleResponse = (response: FetchResponse<unknown>) => {
        if (response.success) {
            setEdits(prevState => {
                return { ...prevState, budgets: {}, columns: {}, comments: {} }
            });

            queryClient.invalidateQueries([projectId, 'cashflow']);
            queryClient.invalidateQueries([projectId, 'cashflowNotices']);
            queryClient.invalidateQueries([projectId, 'monthColumns']);

            queryClient.invalidateQueries([projectId, 'performance']);
            queryClient.invalidateQueries([projectId, 'performanceNotices']);
            queryClient.invalidateQueries([projectId, 'performanceTotals']);
        }

        return response;
    };


    const saveSheets = useMutation({
        mutationFn: mutationFunction,
        onSuccess: handleResponse,
        onError: handleResponse,
    });


    return saveSheets;
}