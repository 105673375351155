import { Dispatch, SetStateAction } from "react";
import { useManualRevenueRecognition } from "../../../../../fetchData/mutations/useManualRevenueRecognition";
import { AccountingModalContent } from "./AccountingModalContent";
import { modalTimeout } from "../../../../../utils/modalTimeout";


export const ResultCalculationModalContent = ({
    setIsModalOpen,
    setModalLock,
}: {
    setIsModalOpen: Dispatch<SetStateAction<boolean>>;
    setModalLock: Dispatch<SetStateAction<boolean>>;
}) => {
    const manualRevenueRecognition = useManualRevenueRecognition();

    setModalLock(manualRevenueRecognition.isLoading)

    if (manualRevenueRecognition.data?.success) modalTimeout(setIsModalOpen);

    return (
        <AccountingModalContent
            infoText={`Klicka på "Kör" för att resultatavräkna detta projekt och skapa bokföringstransaktioner till BW`}
            loadingText={'Manuell resultatavräkning körs...'}
            successText={'Manuell resultatavräkning har körts'}
            mutation={manualRevenueRecognition}
        />
    );
}