import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import {
  CellClassParams, CellValueChangedEvent, ColDef, GetContextMenuItemsParams, GetDataPath, GetRowIdFunc, GetRowIdParams, IRowNode,
  MenuItemDef, ValueSetterParams
} from 'ag-grid-community';
import { useMsal } from '@azure/msal-react';
import { InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';
import { ACCOUNTTEMPLATES } from '../../../../fetchData/fetch/accountTemplates';
import { onRowDragEnd } from './rowDrag';
import { AccountTemplateChanges, AccountTemplateResponse, AccountTemplateRow, AccountTemplateStatus, NumberStringPairs, StringRowPairs } from '../../../../interfaces/systemSettings/AccountTemplate';
import { AG_GRID_LOCALE_SV } from '../../../MainGrids/gridConfig/SwedishLocale';
import { useAccountTemplateColDefs } from './useAccountTemplateColDefs';


interface AccountTemplatesGridProps {
  rowData: AccountTemplateRow[];
  vatTypes: NumberStringPairs;
  sheetTypeKey: number;
  economyTypeKey: number;
  accountTemplateEdits: StringRowPairs;
  setAccountTemplateEdits: Dispatch<SetStateAction<AccountTemplateChanges>>;
  setAccountTemplateDeletes: Dispatch<SetStateAction<AccountTemplateChanges>>;
  setAccountTemplateValidated: Dispatch<SetStateAction<AccountTemplateStatus>>;
  lastAddedRowId: number;
  setLastAddedRowId: Dispatch<SetStateAction<number>>;
}

const AccountTemplateGrid = ({
  rowData,
  vatTypes,
  sheetTypeKey,
  economyTypeKey,
  accountTemplateEdits,
  setAccountTemplateEdits,
  setAccountTemplateDeletes,
  setAccountTemplateValidated,
  lastAddedRowId,
  setLastAddedRowId,
}: AccountTemplatesGridProps) => {

  const accountTemplateColDefs = useAccountTemplateColDefs(vatTypes);

  const gridStyle = useMemo(() => ({ height: '100%', width: '100' }), []);
  const gridRef = useRef<AgGridReact | null>(null);
  const { instance, inProgress, accounts } = useMsal();
  const sortColId: string = 'sortOrder';

  // deleteAction false => edit
  const [validateChange, setValidateChange] = useState<{ changesToValidate: AccountTemplateRow[], deleteAction: boolean }>({ changesToValidate: [], deleteAction: false });


  const handleRowDelete = useCallback((deletedRows: AccountTemplateRow[]) => {
    let deleteRows: StringRowPairs = {};
    let editedRows: StringRowPairs = accountTemplateEdits;

    deletedRows?.forEach((deletedRow: AccountTemplateRow) => {
      const rowId: number = deletedRow?.id;

      if (editedRows[rowId]) {
        delete editedRows[rowId];
      } else {
        deleteRows[rowId] = deletedRow;
      }
    });

    // if row has been deleted
    if (Object.keys(deleteRows)?.length > 0) {
      setAccountTemplateDeletes((prevState: AccountTemplateChanges) => {
        return {
          ...prevState,
          [sheetTypeKey]: {
            ...prevState[sheetTypeKey as keyof AccountTemplateChanges],
            ...deleteRows
          }
        };
      });
    }

    // if unsaved row has been deleted
    if (Object.keys(accountTemplateEdits)?.length !== Object.keys(editedRows)?.length) {
      setAccountTemplateEdits((prevState: AccountTemplateChanges) => {
        return {
          ...prevState, [sheetTypeKey]: { ...editedRows }
        };
      });
    }

    setValidateChange({ changesToValidate: [], deleteAction: false });
    gridRef.current!.api.applyTransaction({ remove: deletedRows });

  }, [accountTemplateEdits, setAccountTemplateDeletes, setAccountTemplateEdits, sheetTypeKey]);


  useEffect(() => {
    const accessTokenRequest = {
      scopes: ["user.read"],
      account: accounts[0]
    }
    if (inProgress === InteractionStatus.None) {
      instance.acquireTokenSilent(accessTokenRequest).then((response) => {
        const accessToken: string = response.accessToken;
        const changesToValidate: AccountTemplateRow[] = validateChange.changesToValidate;
        const deleteAction: boolean = validateChange.deleteAction;

        if (changesToValidate.length > 0) {
          ACCOUNTTEMPLATES.postValidateAccountTemplate(economyTypeKey, sheetTypeKey, changesToValidate, deleteAction, accessToken).then((response: AccountTemplateResponse) => {

            if (response.success && deleteAction) handleRowDelete(changesToValidate);
            setAccountTemplateValidated(prevState => { return { ...prevState, [sheetTypeKey]: response } });
          }).catch((e: Error) => console.log(e));

          setValidateChange({ changesToValidate: [], deleteAction: false });
        }
      }).catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenRedirect(accessTokenRequest);
        }
        console.log(error);
      })
    }
  }, [accounts, economyTypeKey, handleRowDelete, inProgress, instance, setAccountTemplateValidated, sheetTypeKey, validateChange]);


  const sortColumn = useCallback((sortCol: string, sort: "asc" | "desc" | null | undefined) => {
    gridRef && gridRef.current!.columnApi.applyColumnState({
      state: [{ colId: sortCol, sort: sort }],
    })
  }, []);

  const getDataPath = useMemo<GetDataPath>(() => {
    return (data: any) => data.path;
  }, []);


  const getRowId = useMemo<GetRowIdFunc>(() => {
    return (params: GetRowIdParams) => params.data.id.toString();
  }, []);


  const handleRowEdit = useCallback((changedRow: AccountTemplateRow) => {
    if (changedRow) {
      setAccountTemplateEdits((prevState: AccountTemplateChanges) => {
        return {
          ...prevState,
          [sheetTypeKey]: {
            ...prevState[sheetTypeKey as keyof AccountTemplateChanges],
            [changedRow?.id]: changedRow
          }
        };
      });
      setValidateChange({ changesToValidate: [changedRow], deleteAction: false })
    }
  }, [setAccountTemplateEdits, sheetTypeKey]);


  const onCellValueChanged = useCallback((event: CellValueChangedEvent) => {
    handleRowEdit(event.data);

    if (event.column.getColId() === sortColId) {
      sortColumn(sortColId, null);
      sortColumn(sortColId, 'asc');
    }
  }, [handleRowEdit, sortColumn]);

  const onGridReady = useCallback(() => {
    sortColumn(sortColId, 'asc');
  }, [sortColumn]);


  const autoGroupColumnDef = useMemo<ColDef>(() => {
    return {
      headerName: 'Namn',
      cellRendererParams: {
        suppressCount: true,
        suppressDoubleClickExpand: true,
      },
      flex: 3,
      editable: true,
      rowDrag: true,
      cellStyle: (params: CellClassParams) => {
        const colStyle = { backgroundColor: '#d9dcde', fontWeight: '' };

        switch (params.node.level) {
          case 1:
            colStyle.backgroundColor = `${colStyle.backgroundColor}30`;
            colStyle.fontWeight = '700';
            break;
          case 2:
            colStyle.backgroundColor = 'transparent';
            break;
          default:
            colStyle.backgroundColor = `${colStyle.backgroundColor}80`;
            colStyle.fontWeight = '900';
            break;
        }
        return colStyle
      },
      valueSetter: (params: ValueSetterParams) => {
        if (params.newValue.length > 0) {

          const nodeLevel: number | undefined = params.node?.level;

          // note params.data.path[nodeLevel] === params.oldValue
          if (nodeLevel !== undefined && params.data.path[nodeLevel] !== params.newValue) {

            let accountTemplateRows: AccountTemplateRow[] = [];
            gridRef.current!.api.forEachNode(node => {
              accountTemplateRows.push(node.data)
            });

            // only change group name if another group with same name does not exist
            // cannot be empty string
            if (accountTemplateRows?.filter(x => x?.path?.[nodeLevel] === params.newValue)?.length < 2 && params.newValue.length > 0) {

              if (nodeLevel === 0) {
                let rowsInPath: AccountTemplateRow[] = accountTemplateRows?.filter((row: AccountTemplateRow) => {
                  return row?.path?.[nodeLevel] === params.oldValue;
                });

                rowsInPath.forEach((row: AccountTemplateRow) => {
                  row.path[nodeLevel] = params.newValue;
                  row.name = params.newValue;
                });
                gridRef.current!.api.applyTransaction({ update: rowsInPath })

              }
              if (nodeLevel === 1 || 2) {
                let rowsInPath: AccountTemplateRow[] = accountTemplateRows?.filter((row: AccountTemplateRow) => {
                  return JSON.stringify(row?.path.slice(0, nodeLevel + 1)) === JSON.stringify(params.data.path);
                });

                rowsInPath.forEach((data: AccountTemplateRow) => {
                  data.path[nodeLevel] = params.newValue
                  data.name = params.newValue;
                });
                gridRef.current!.api.applyTransaction({ update: rowsInPath });

              }
            }
          }
        }
        return true;
      },
    };
  }, []);

  const defaultEconomyTemplateColDef = useMemo(() => {
    return ({
      flex: 1,
      minWidth: 50,
      sortable: false,
      suppressMovable: true,
      resizable: true,
      menuTabs: [],
    })
  }, []);


  const addNewRow = useCallback((params: GetContextMenuItemsParams, addInsideGroup?: boolean) => {
    const node: IRowNode | null = params?.node;
    let pathIndex: number = node?.level ?? 0;
    let newPath: string[] = node?.data?.path?.length > 0 ? [...node?.data?.path] : [];
    let newRowId: number = lastAddedRowId + 1;
    let newRowName: string = `Ny rad...  (${newRowId})`;
    let newParentId: number | null = pathIndex === 0 ? null : Number(node?.parent?.id)

    if (addInsideGroup) {
      pathIndex = pathIndex + 1;
      newParentId = Number(node?.id);
    }

    newPath[pathIndex] = newRowName;

    const newRow: AccountTemplateRow = {
      id: newRowId,
      parentId: newParentId,
      path: newPath,
      accountNumber: "",
      name: newRowName,
      sortOrder: null,
      isNegative: false,
      shouldAggregateAmount: false,
      vatType: 1,
      isEditable: false,
      notice: null
    }

    gridRef.current!.api.applyTransaction({
      add: [newRow]
    });

    handleRowEdit(newRow);
    setLastAddedRowId(newRowId);
  }, [lastAddedRowId, setLastAddedRowId, handleRowEdit]);

  const getContextMenuItems = useCallback((params: GetContextMenuItemsParams): (
    | string
    | MenuItemDef
  )[] => {
    let level: number | undefined = params.node?.level;

    // disable if no row id from db
    const actionDisabled: boolean = !(level !== undefined && level >= 0 && level < 2) || !(lastAddedRowId > 0);

    let result: (string | MenuItemDef)[] = [
      {
        name: 'Expandera alla grupper',
        icon: '<span class="ag-icon ag-icon-tree-open" unselectable="on" role="presentation"></span>',
        action: () => gridRef.current!.api.expandAll()
      },
      {
        name: 'Stäng alla grupper',
        icon: '<span class="ag-icon ag-icon-tree-closed" unselectable="on" role="presentation"></span>',
        action: () => gridRef.current!.api.collapseAll()
      },
      'separator',
      {
        name: 'Lägg till',
        icon: '+',
        action: () => addNewRow(params)
      },
      {
        name: 'Lägg till underliggande nivå',
        icon: '/+',
        disabled: actionDisabled,
        action: () => {
          addNewRow(params, true);

          if (params.node && !params.node.expanded) gridRef.current!.api.setRowNodeExpanded(params.node, true);
        }
      },
      {
        name: 'Ta bort',
        icon: '<span class="ag-icon ag-icon-cross" role="presentation"></span>',
        // allleafchildren/data for group remove
        action: () => {
          const rowsToRemove: AccountTemplateRow[] | undefined = params.node?.allLeafChildren.map((node) => node.data);

          rowsToRemove && setValidateChange({ changesToValidate: rowsToRemove, deleteAction: true });
        }
      },
    ];

    return result;
  }, [addNewRow, lastAddedRowId]);


  return (
    <div className="ag-theme-balham" style={gridStyle} data-testid="AccountTemplateGrid">
      <AgGridReact
        ref={gridRef}
        columnDefs={accountTemplateColDefs}
        rowData={rowData}
        autoGroupColumnDef={autoGroupColumnDef}
        getContextMenuItems={getContextMenuItems}
        onCellValueChanged={onCellValueChanged}
        defaultColDef={defaultEconomyTemplateColDef}
        treeData={true}
        getDataPath={getDataPath}
        getRowId={getRowId}
        groupDefaultExpanded={1}
        onGridReady={onGridReady}
        onRowDragEnd={(e) => { onRowDragEnd(e, handleRowEdit) }}
        localeText={AG_GRID_LOCALE_SV}
      />
    </div>
  );
};

export default AccountTemplateGrid;