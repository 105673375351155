import { PrimaryButton } from '@fluentui/react';
import styles from '../../AdminTools.module.css';

interface AdminActionButtonProps {
    buttonText: string;
    handleAction: () => void;
    actionDisabled?: boolean;
}

export const AdminActionButton = ({
    buttonText,
    handleAction,
    actionDisabled
}: AdminActionButtonProps) => (
    <div className={styles.buttonContainer}>
        <PrimaryButton
            disabled={actionDisabled}
            onClick={handleAction}>
            {buttonText}
        </PrimaryButton>
    </div>
)