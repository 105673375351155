
import { Fragment } from 'react';
import styles from './PanelContent.module.css';

interface PanelContentProps {
    panelInputs: JSX.Element[];
}

export const PanelContent = ({
    panelInputs
}: PanelContentProps) => (
    <div className={styles.contentContainer}>
        {panelInputs?.map((input, index) => {
            return <Fragment key={index} >{input}</Fragment>
        })}
    </div>
);