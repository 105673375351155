import { Dispatch, SetStateAction } from 'react';
import styles from './DateInput.module.css';
import { ProjectAttributes } from '../../../../interfaces/project/ProjectAttributes';
import { useInteractionContext } from '../../../../context/InteractionContext';
import { useMemorizedEditing } from '../../customHooks/useMemorizedEditing';


interface MonthDateInputProps {
  values: ProjectAttributes;
  initialValues: ProjectAttributes;
  label: string;
  setValues: Dispatch<SetStateAction<ProjectAttributes>>;
  setChangedValue: Dispatch<SetStateAction<{ [key: string]: string | number | null }>>;
  attributeName: string;
  disabled: boolean;
}


export const MonthDateInput = ({
  values,
  initialValues,
  setValues,
  setChangedValue,
  label,
  attributeName,
  disabled
}: MonthDateInputProps) => {

  const { refreshLoading } = useInteractionContext();

  const {
    setInputBlurEvent,
    setKeyTriggerEvent,
    handleChangedDate
  } = useMemorizedEditing(
    {
      values,
      initialValues,
      setValues,
      setChangedValue,
      attributeName,
      regExp: /^(19[4-9]\d|20\d{2})-(0[1-9]|1[0-2])$/
    }
  );


  const monthDateInput = () => (
    <input
      lang='sv-Sv'
      data-testid={attributeName}
      value={values[attributeName as keyof ProjectAttributes] as string ?? ''}
      className={styles.dateInput}
      type={'month'}
      min={'1940-01'}
      max={'9999-12'}
      disabled={disabled}
      tabIndex={0}
      onChange={e => handleChangedDate(e)}
      onKeyDown={e => setKeyTriggerEvent(e)}
      onBlur={e => setInputBlurEvent(e)}
    />
  );


  const disabledMonthDateInput = () => (
    <input
      lang='sv-Sv'
      data-testid={attributeName}
      value={values[attributeName as keyof ProjectAttributes] as string ?? ''}
      className={styles.dateInput}
      type={'month'}
      disabled
    />
  );


  return (
    <div className={styles.dateInputContainer}>
      <label className={styles.dateInputLabel}>{label}</label>
      <span className={styles.dateInputHolder}>
        {refreshLoading
          ?
          disabledMonthDateInput()
          :
          monthDateInput()
        }
      </span>
    </div>
  );
};
