import { IRowNode, RowDragEndEvent } from "ag-grid-community";
import { AccountTemplateRow } from "../../../../interfaces/systemSettings/AccountTemplate";

export const onRowDragEnd = (event: RowDragEndEvent, handleRowEdit: (changedRow: AccountTemplateRow) => void) => {
    // this is the row the mouse is hovering over
    let overNode = event.overNode;
    if (!overNode) {
        return;
    }

    // folder to drop into is where we are going to move the file/folder to
    let folderToDropInto =
        overNode.data.type === 'folder'
            ? // if over a folder, we take the immediate row
            overNode
            : // if over a file, we take the parent row (which will be a folder)
            overNode.parent;

    // the data we want to move
    let movingData = event.node.data;

    // update parent id
    movingData.parentId = folderToDropInto?.id?.toLocaleLowerCase() === 'root_node_id' ? null : folderToDropInto?.id;

    // take new parent path from parent, if data is missing, means it's the root node,
    // which has no data.
    let newParentPath = folderToDropInto!.data
        ? folderToDropInto!.data.path
        : [];
    let needToChangeParent = !arePathsEqual(newParentPath, movingData.path);

    // check we are not moving a folder into a child folder
    let invalidMode = isSelectionParentOfTarget(event.node, folderToDropInto);

    if (needToChangeParent && !invalidMode) {
        let updatedRows: any[] = [];
        moveToPath(newParentPath, event.node, updatedRows);

        event!.api!.applyTransaction({
            update: updatedRows,
        });

        const movedRow: AccountTemplateRow = updatedRows[0];
        handleRowEdit(movedRow);
        event.api!.clearFocusedCell();
    }
}

const moveToPath = (
    newParentPath: string[],
    node: IRowNode,
    allUpdatedNodes: any[]
) => {
    // last part of the file path is the file name
    let oldPath = node.data.path;
    let fileName = oldPath?.length > 0 && oldPath[oldPath.length - 1];
    let newChildPath = newParentPath?.slice();
    newChildPath?.push(fileName);

    node.data.path = newChildPath;

    allUpdatedNodes.push(node.data);

    if (node.childrenAfterGroup) {
        node.childrenAfterGroup.forEach(function (childNode) {
            moveToPath(newChildPath, childNode, allUpdatedNodes);
        });
    }
}

const isSelectionParentOfTarget = (
    selectedNode: IRowNode,
    targetNode: IRowNode | null
) => {
    let children = [...(selectedNode.childrenAfterGroup || [])];

    // invalid move if moving row to group containing row with same name
    if (targetNode?.childrenAfterGroup?.find((row: IRowNode) => row.key === selectedNode.key)) {
        return true;
    }

    if (!targetNode) {
        return false;
    }

    while (children.length) {
        const node = children.shift();
        if (!node) {
            continue;
        }

        if (node.key === targetNode.key) {
            return true;
        }

        if (node.childrenAfterGroup && node.childrenAfterGroup.length) {
            children.push(...node.childrenAfterGroup);
        }
    }

    return false;
}

const arePathsEqual = (path1: string[], path2: string[]) => {
    if (path1 && path2) {
        if (path1.length !== path2.length) {
            return false;
        }

        let equal = true;
        path1.forEach(function (item, index) {
            if (path2[index] !== item) {
                equal = false;
            }
        });

        return equal;
    }
}