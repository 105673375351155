import { DefaultButton, PrimaryButton, TooltipHost } from '@fluentui/react';
import { Dispatch, SetStateAction } from 'react';
import styles from './MoveBuildStart.module.css';

interface MoveBuildStartProps {
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  changedInitiationMonth: string;
  unchangedInitiationMonth: string;
  setModalInput: Dispatch<SetStateAction<string | number | null>>;
}

const MoveBuildStart = ({
  setIsModalOpen,
  changedInitiationMonth,
  unchangedInitiationMonth,
  setModalInput,
}: MoveBuildStartProps) => {

  let calculatedDateYearMonthChange: string = '';
  let changeDirection: number = 0;


  if (changedInitiationMonth && unchangedInitiationMonth) {
    const changedYearMonth: string[] = changedInitiationMonth?.split('-');
    const unchangedYearMonth: string[] = unchangedInitiationMonth?.split('-');
    const yearDiff: number = Number(changedYearMonth[0]) - Number(unchangedYearMonth[0]);
    const monthDiff: number = Number(changedYearMonth[1]) - Number(unchangedYearMonth[1]) + yearDiff * 12;

    const monthMonths: string = Math.abs(monthDiff) === 1 ? 'månad' : 'månader';

    if (monthDiff > 0) {
      changeDirection = 1;
      calculatedDateYearMonthChange = `${Math.abs(monthDiff)} ${monthMonths}`;
    }
    if (monthDiff < 0) {
      changeDirection = -1;
      calculatedDateYearMonthChange = `${Math.abs(monthDiff)} ${monthMonths}`;
    }
  }


  return (
    <div data-testid="MoveBuildStart" className={styles.moveBuildStartBody}>
      <p>Igångsättning kommer {changeDirection === 1 ? 'flyttas fram' : 'tidigareläggas'} med {calculatedDateYearMonthChange} till {changedInitiationMonth}</p>

      <p><b>Vill du även flytta prognoser {calculatedDateYearMonthChange} {changeDirection === 1 ? 'framåt' : 'bakåt'} i tiden?</b></p>

      <div className={styles.buttonContainer}>
        <TooltipHost
          content={'Flyttar igångsättning och prognos'}
          id={'moveBuildStartPrognosis'}>
          <PrimaryButton
            data-testid="MoveBuildStartYesBtn"
            style={{ margin: '5px' }}
            onClick={() => {
              setModalInput(1);
              setIsModalOpen(false);
            }}
          >Ja</PrimaryButton>
        </TooltipHost>

        <TooltipHost
          content={'Flyttar endast igångsättning'}
          id={'moveBuildStart'}>
          <PrimaryButton
            data-testid="MoveBuildStartNoBtn"
            style={{ margin: '5px' }}
            onClick={() => {
              setModalInput(-1);
              setIsModalOpen(false);
            }}
          >Nej</PrimaryButton>
        </TooltipHost>
        <DefaultButton
          data-testid="MoveBuildStartCancelBtn"
          style={{ margin: '5px' }}
          onClick={() => {
            setModalInput(0);
            setIsModalOpen(false);
          }}
        >Avbryt</DefaultButton>
      </div>
      <p>Flyttning sparar ej projektekonomin men flytt av prognoser kan endast göras en gång då projektekonomin inte är sparad</p>
    </div>)
};

export default MoveBuildStart;
