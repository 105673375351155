import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ProjectEconomyWrappers from './components/ProjectEconomyWrappers/ProjectEconomyWrappers';
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import SystemSettings from './components/SystemSettings/SystemSettings';
import { RouteNotFound } from './components/SystemState/States/RouteNotFound';
import ResultCalculatedProjects from './components/Reports/GridReports/ResultCalculatedProjects/ResultCalculatedProjects';
import { ResultCalculationReport } from './components/Reports/PdfReports/Reports/ResultCalculationReport';
import { FinalAccountReport } from './components/Reports/PdfReports/Reports/FinalAccountReport';
import ForecastResultCalculation from './components/Reports/GridReports/ForecastResultCalculation/ForecastResultCalculation';
import SectionAccess from './AccessControl/SectionAccess';
import { ProfitOnContractReport } from './components/Reports/PdfReports/Reports/ProfitOnContractReport';

initializeIcons();

// if (process.env.NODE_ENV === 'development') {
//   const { worker } = require('./mocks/browser')
//   worker.start()
// }

const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<RouteNotFound />} />

          <Route path="projekt" element={<App />} >
            <Route index element={<RouteNotFound />} />
            <Route
              path=":projectId"
              element={<SectionAccess scopeKey='sheet_read'><ProjectEconomyWrappers /></SectionAccess>}
            />
          </Route>

          <Route path="" element={<App />} >
            <Route
              path="installningar"
              element={<SectionAccess scopeKey={['account_templates', 'scheduling_crud', 'accounting_read', 'system_settings']}><SystemSettings /></SectionAccess>}
            />
          </Route>

          <Route path="rapport" element={<App />} >
            <Route index element={<RouteNotFound />} />
            <Route
              path="resultatavrakning/:projectId"
              element={<SectionAccess scopeKey='report_read'><ResultCalculationReport /></SectionAccess>}
            />
            <Route
              path="slutredovisning/:projectId"
              element={<SectionAccess scopeKey='report_read'><FinalAccountReport /></SectionAccess>}
            />
            <Route
              path="entreprenadVinst/:projectId"
              element={<SectionAccess scopeKey='report_read'><ProfitOnContractReport /></SectionAccess>}
            />
            <Route
              path="resultatavraknadeProjekt"
              element={<SectionAccess scopeKey='accounting_read'><ResultCalculatedProjects /></SectionAccess>}
            />
            <Route
              path="prognosResultatavrakning"
              element={<SectionAccess scopeKey='accounting_read'><ForecastResultCalculation /></SectionAccess>}
            />
          </Route>

          <Route path="*" element={<RouteNotFound />} />
        </Routes>
      </BrowserRouter>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
