import { Dispatch, SetStateAction } from 'react';
import styles from '../Buttons.module.css';
import navButtonStyles from './PivotButton.module.css';
import { NumberStringPairs } from '../../../interfaces/systemSettings/AccountTemplate';

interface PivotButtonProps {
  selectedPivot: number;
  onClick: Dispatch<SetStateAction<number>>;
  pivotItem: NumberStringPairs;
}

const PivotButton = ({
  selectedPivot,
  onClick,
  pivotItem
}: PivotButtonProps) => {
  const pivotKey = Number(pivotItem[0]);
  const pivotValue = pivotItem[1];

  return (
    <button
      className={`${styles.actionButton} ${styles.defaultButton} 
    ${pivotKey === selectedPivot && navButtonStyles.pivotButton}`}
      onClick={() => onClick(pivotKey)}
    >
      {pivotValue}
    </button>
  )
};

export default PivotButton;