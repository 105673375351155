import { Dispatch, SetStateAction, useMemo } from 'react';
import { CellClassParams, ColDef } from 'ag-grid-community';
import { dateTimeFormatter } from '../../../../utils/dateTimeFormatter';
import { dateFormatter } from '../../../../utils/dateFormatter';
import { ExtendedGridReportInputs } from '../../../../interfaces/reports/ResCalcProjReport';
import { useResCalcProjReportFlow } from '../../../../fetchData/queries/reports/grid/useResCalcProjRreportFlow';
import { useQueriesProgress } from '../customHooks/useQueriesProgress';
import { useQueriesFetched } from '../customHooks/useQueriesFetched';
import { reportNumberFormatter, reportPercentageFormatter } from '../helper/reportValueFormatter';
import { getRowHierarchyStyle } from '../helper/getRowHierarchyStyle';


export const useResCalcProjectsGrid = (
    gridReportInputs: ExtendedGridReportInputs,
    setReportIsGenerated: Dispatch<SetStateAction<boolean>>,
) => {
    const report = useResCalcProjReportFlow(gridReportInputs);

    const queriesProgress = useQueriesProgress(report);

    useQueriesFetched(report, setReportIsGenerated);

    const filteredData = report?.map(response => response.data)
        ?.filter(data => data && data?.length > 0)?.flat();

    const reportError = report?.map(response => response.error)
        ?.filter(error => error !== undefined && error !== null)?.length > 0;


    const columnDefs = useMemo(() => {
        const colDefs = [
            {
                headerName: 'Projektinfo',
                children: [
                    {
                        headerName: 'Projektnamn',
                        rowGroup: true,
                        hide: true,
                        field: 'projectName',
                        pinned: 'left',
                        cellStyle: (params: CellClassParams) => getRowHierarchyStyle(params),
                    },
                    {
                        headerName: 'MO',
                        hide: true,
                        field: 'organizationUnit',
                        cellStyle: (params: CellClassParams) => getRowHierarchyStyle(params),
                    },
                    {
                        headerName: 'RBAnr',
                        field: 'projectId',
                        type: 'numericColumn',
                        cellStyle: (params: CellClassParams) => getRowHierarchyStyle(params),
                    },
                    {
                        headerName: 'Projektklass',
                        field: 'class.name',
                        cellStyle: (params: CellClassParams) => getRowHierarchyStyle(params),
                    },
                ]
            },
            {
                headerName: 'Avräkningsgrad',
                children: [
                    {
                        headerName: 'Fsg.grad (%)',
                        field: 'resultCalculations.progress.salesRatio',
                        valueFormatter: reportPercentageFormatter,
                        cellStyle: (params: CellClassParams) => getRowHierarchyStyle(params),
                    },
                    {
                        headerName: 'Upparb.grad (%)',
                        field: 'resultCalculations.progress.degreeOfCompletion',
                        valueFormatter: reportPercentageFormatter,
                        cellStyle: (params: CellClassParams) => getRowHierarchyStyle(params),
                    },
                    {
                        headerName: 'Avr.grad (%)',
                        field: 'resultCalculations.progress.settlementRate',
                        valueFormatter: reportPercentageFormatter,
                        cellStyle: (params: CellClassParams) => getRowHierarchyStyle(params),
                    },
                ]
            },
            {
                headerName: 'Ekonomi',
                children: [
                    {
                        headerName: 'Prog.intäkter',
                        field: 'forecastedTotal.income',
                        type: 'numericColumn',
                        valueFormatter: reportNumberFormatter,
                        cellStyle: (params: CellClassParams) => getRowHierarchyStyle(params),
                    },
                    {
                        headerName: 'Prog.kostnad',
                        field: 'forecastedTotal.cost',
                        type: 'numericColumn',
                        valueFormatter: reportNumberFormatter,
                        cellStyle: (params: CellClassParams) => getRowHierarchyStyle(params),
                    },
                    {
                        headerName: 'Prog.resultat',
                        field: 'forecastedTotal.result',
                        type: 'numericColumn',
                        valueFormatter: reportNumberFormatter,
                        cellStyle: (params: CellClassParams) => getRowHierarchyStyle(params),
                    },
                ]
            },
            {
                headerName: 'Resultatavräkning',
                children: [
                    {
                        headerName: 'Bokf.dat.',
                        field: 'bookingDate',
                        type: 'date',
                        valueFormatter: dateFormatter,
                        cellStyle: (params: CellClassParams) => getRowHierarchyStyle(params),
                    },
                    {
                        headerName: 'Körningstidpunkt',
                        field: 'runTimestamp',
                        type: 'dateTime', // custom type for excel formatting
                        valueFormatter: dateTimeFormatter,
                        cellStyle: (params: CellClassParams) => getRowHierarchyStyle(params),
                    },
                    {
                        headerName: 'Bokf.kostn.ex.mark',
                        field: 'resultCalculations.recorded.costsExclLand',
                        type: 'numericColumn',
                        valueFormatter: reportNumberFormatter,
                        cellStyle: (params: CellClassParams) => getRowHierarchyStyle(params),
                    },
                    {
                        headerName: 'Bokf.kostn.mark',
                        field: 'resultCalculations.recorded.landCost',
                        type: 'numericColumn',
                        valueFormatter: reportNumberFormatter,
                        cellStyle: (params: CellClassParams) => getRowHierarchyStyle(params),
                    },
                    {
                        headerName: 'Int. att avr.',
                        field: 'financialPeriod.revenue.value',
                        type: 'numericColumn',
                        aggFunc: 'sum',
                        valueFormatter: reportNumberFormatter,
                        cellStyle: (params: CellClassParams) => getRowHierarchyStyle(params),
                    },
                    {
                        headerName: 'Int. tid. avr.',
                        field: 'financialPeriod.revenue.previouslyOffset',
                        type: 'numericColumn',
                        valueFormatter: reportNumberFormatter,
                        cellStyle: (params: CellClassParams) => getRowHierarchyStyle(params),
                    },
                    {
                        headerName: 'Int. tot. avr.',
                        field: 'financialPeriod.revenue.total',
                        type: 'numericColumn',
                        valueFormatter: reportNumberFormatter,
                        cellStyle: (params: CellClassParams) => getRowHierarchyStyle(params),
                    },
                    {
                        headerName: 'Kostn. att avr.',
                        field: 'financialPeriod.cost.value',
                        type: 'numericColumn',
                        aggFunc: 'sum',
                        valueFormatter: reportNumberFormatter,
                        cellStyle: (params: CellClassParams) => getRowHierarchyStyle(params),
                    },
                    {
                        headerName: 'Kostn. tid. avr.',
                        field: 'financialPeriod.cost.previouslyOffset',
                        type: 'numericColumn',
                        valueFormatter: reportNumberFormatter,
                        cellStyle: (params: CellClassParams) => getRowHierarchyStyle(params),
                    },
                    {
                        headerName: 'Kostn. tot. avr.',
                        field: 'financialPeriod.cost.total',
                        type: 'numericColumn',
                        valueFormatter: reportNumberFormatter,
                        cellStyle: (params: CellClassParams) => getRowHierarchyStyle(params),
                    },
                    {
                        headerName: 'Res. att avr.',
                        field: 'financialPeriod.result',
                        type: 'numericColumn',
                        aggFunc: 'sum',
                        valueFormatter: reportNumberFormatter,
                        cellStyle: (params: CellClassParams) => getRowHierarchyStyle(params),
                    },
                ]
            },
        ];

        return colDefs;
    }, []);


    const autoGroupColumnDef = useMemo((): ColDef => {
        return {
            headerName: '',
            field: 'organizationUnit',
            pinned: 'left',
            cellRendererParams: {
                suppressCount: true,
                suppressDoubleClickExpand: true,
            },
            cellStyle: (params: CellClassParams) => getRowHierarchyStyle(params),
        };
    }, []);


    const gridDefs = {
        columnDefs: columnDefs,
        autoGroupColumnDef: autoGroupColumnDef,
        rowData: filteredData,
        queriesProgress: queriesProgress,
        reportError: reportError,
    };


    return gridDefs;
}
