import { useEffect, useState } from 'react';
import { FluentModal } from '../../Modals/FluentModal/FluentModal';
import ActionButton from '../../Buttons/ActionButton';
import InternalCreditsGrid from '../InternalCreditsGrid/InternalCreditsGrid';
import { useInternalCredits } from '../../../fetchData/queries/internalCredits/useInternalCredits';
import { GridRow } from '../../../interfaces/grids/GridRow';
import { UnableToLoadData } from '../../SystemState/ErrorManagement/UnableToLoadData/UnableToLoadData';
import { AddInternalCredit } from '../AddInternalCredit/AddInternalCredit';
import ElementAccess from '../../../AccessControl/ElementAccess';
import styles from './InternalCreditsButton.module.css';
import { InternalCreditsAmounts } from '../interfaces/InternalCreditsAmounts';
import { useEditingContext } from '../../../context/EditingContext';


const InternalCreditsButton = () => {
  const { error, data } = useInternalCredits();

  const { snapshotMode } = useEditingContext();

  const [isInternalCreditsModalOpen, setIsInternalCreditsModalOpen] = useState<boolean>(false);
  const handleOnClick = () => setIsInternalCreditsModalOpen(true);

  const [internalCredits, setInternalCredits] = useState<GridRow[] | undefined>(undefined);
  const [internalCreditsAmounts, setInternalCreditsAmounts] = useState<InternalCreditsAmounts>({
    grantedAmount: 0,
    currentBalance: 0
  });


  useEffect(() => {
    if (data) {
      let grantedAmountSum: number = 0;
      let currentBalanceSum: number = 0;

      data?.forEach((row: GridRow) => {
        const type: number = Number(row.type);
        const amount: number = Number(row.amount);

        if (type > 1) { grantedAmountSum += amount; }

        currentBalanceSum += amount;
      });

      setInternalCredits(data);

      setInternalCreditsAmounts({
        grantedAmount: Math.round(grantedAmountSum),
        currentBalance: Math.round(currentBalanceSum)
      });
    }
  }, [data]);



  const modalContent = error ?
    <UnableToLoadData message={'Internkrediter kunde ej hämtas'} /> :

    <div className={styles.internalCreditsModal}>
      <ElementAccess scopeKey='accounting_create'><AddInternalCredit /></ElementAccess>

      <InternalCreditsGrid
        internalCredits={internalCredits}
        internalCreditsAmounts={internalCreditsAmounts}
      />
    </div>;


  return (
    <>
      <ActionButton
        text={'Internkrediter'}
        handleOnClick={handleOnClick}
        buttonStyles={['defaultButton']}
        disabled={snapshotMode}
      />

      <FluentModal
        isModalOpen={isInternalCreditsModalOpen}
        setIsModalOpen={setIsInternalCreditsModalOpen}
        isModeLess={false}
        modalHeader={'Internkrediter'}
        modalContent={modalContent}
      />
    </>)
};

export default InternalCreditsButton;
