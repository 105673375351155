import styles from '../../AdminTools.module.css';
import { DefaultButton, IContextualMenuProps } from "@fluentui/react";

interface AdminDeleteButtonProps {
    buttonText: string;
    menuProps: IContextualMenuProps;
    disabled?: boolean;
}

export const AdminDeleteButton = ({
    buttonText,
    menuProps,
    disabled
}: AdminDeleteButtonProps) => (
    <div className={styles.buttonContainer}>
        <DefaultButton
            disabled={disabled}
            onClick={() => { }}
            split
            primaryDisabled
            menuProps={menuProps}
        >
            {buttonText}
        </DefaultButton>
    </div>
)