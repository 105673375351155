import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { useAccessToken } from "../../useAccessToken";
import useProjectId from "../../../customHooks/useProjectId";
import { makeResultFetch } from "../../genericFetch";
import { apiRequestParams } from "../../fetchOptions";
import { GridRow } from "../../../interfaces/grids/GridRow";
import { useHandleRetry } from "../../useHandleRetry";


const useFetch = () => {
    const getAccessToken = useAccessToken();
    const projectId = useProjectId();

    const queryFunction = useCallback(async () => {
        return getAccessToken().then(accessToken => {
            if (!accessToken) throw new Error("Access token is undefined");

            const fetchedData = makeResultFetch<GridRow[]>(
                `/Projects/${projectId}/InternalCredits/List`,
                apiRequestParams(accessToken, 'GET')
            ).then((response) => {
                const { data, message, success } = response;

                if (!success) throw Error(message);

                return data;
            });

            return fetchedData;
        });
    }, [getAccessToken, projectId]);

    return { projectId, queryFunction };
}


export const usePrefetchInternalCredits = () => {
    const { projectId, queryFunction } = useFetch();
    const queryClient = useQueryClient();

    const prefetchQuery = useCallback(() => {
        queryClient.prefetchQuery({
            queryKey: [projectId, 'internalCredits'],
            queryFn: queryFunction,
        });
    }, [projectId, queryClient, queryFunction])

    return prefetchQuery;
}


export const useInternalCredits = () => {
    const { projectId, queryFunction } = useFetch();
    const handleRetry = useHandleRetry();

    const { isLoading, isFetching, error, data } = useQuery({
        queryKey: [projectId, 'internalCredits'],
        queryFn: queryFunction,
        enabled: !!projectId && !!queryFunction,
        retry: handleRetry,
    });

    return { isLoading, isFetching, error, data };
}
