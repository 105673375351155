import { useCallback, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import MainHeader from '../../../Headers/MainHeader/MainHeader';
import { GridReportSubHeader } from '../../../Headers/SubHeaders/GridReportSubHeader';
import { getTwoYearPeriodDates } from '../helper/getGridReportDates';
import ReportGrid from '../ReportGrid/ReportGrid';
import { GridReportInputs } from '../../../../interfaces/reports/ResCalcProjReport';
import { useOrgStructureGrid } from '../OrgStructureGrid/OrgStructureGrid';
import { ReportPeriodInputs } from '../Panel/PanelContent/ReportPeriodInputs';
import { ForecastResCalcTooltip } from '../../../InfoTooltip/Tooltips/ForecastResCalcTooltip';
import { useForecastResCalcGrid } from './useForecastResCalcGrid';
import { useQueryClient } from '@tanstack/react-query';


const ForecastResultCalculation = () => {
    document.title = 'Rapport - Prognos resultatavräkning';

    const reportGridRef = useRef<AgGridReact>(null);

    const queryClient = useQueryClient();
    const queryKey = 'forecastResCalcReport';

    const [reportIsGenerated, setReportIsGenerated] = useState(false);


    const [selectedOrgUnitIds, setSelectedOrgUnitIds] = useState<string[]>([]);
    const { isLoading: orgStructureGridLoading, OrgStructureGrid } = useOrgStructureGrid({
        setSelectedOrgUnitIds,
        reportIsGenerated,
    });


    const { firstDayOfThisYear, lastDayOfNextYear } = getTwoYearPeriodDates();
    const [fromDate, setFromDate] = useState(firstDayOfThisYear);
    const [toDate, setToDate] = useState(lastDayOfNextYear);

    const [gridReportInputs, setGridReportInputs] = useState<GridReportInputs>({
        orgUnitIds: [],
        fromDate: '',
        toDate: '',
    });


    const {
        columnDefs,
        autoGroupColumnDef,
        rowData,
        queriesProgress,
        reportError,
    } = useForecastResCalcGrid(gridReportInputs, setReportIsGenerated);


    const panelInputs = [
        <OrgStructureGrid />,
        < ReportPeriodInputs
            fromDate={fromDate}
            setFromDate={setFromDate}
            toDate={toDate}
            setToDate={setToDate}
            reportIsGenerated={reportIsGenerated}
        />
    ];

    const panelSubmitDisabled = selectedOrgUnitIds?.length === 0 || fromDate === '' || toDate === '';


    const handleGenerate = useCallback(() => {
        queryClient.invalidateQueries({
            queryKey: [queryKey]
        });

        setGridReportInputs({
            orgUnitIds: selectedOrgUnitIds,
            fromDate: fromDate,
            toDate: toDate,
        });

        setReportIsGenerated(true);

    }, [fromDate, queryClient, selectedOrgUnitIds, toDate]);


    const handleAbort = () => {
        queryClient.cancelQueries({
            queryKey: [queryKey]
        })

        setReportIsGenerated(false);
    };


    return (
        <>
            <MainHeader />

            <GridReportSubHeader
                reportGridRef={reportGridRef}
                header={'Rapport - Prognos resultatavräkning'}
                excelReportName={'Prognos_resultatavräkning'}
                panelSubmitDisabled={panelSubmitDisabled}
                panelButtonDisabled={orgStructureGridLoading}
                reportIsGenerated={reportIsGenerated}
                queriesProgress={queriesProgress}
                panelInputs={panelInputs}
                handleGenerate={handleGenerate}
                handleAbort={handleAbort}
                tooltipContent={<ForecastResCalcTooltip />}
            />

            <ReportGrid
                reportGridRef={reportGridRef}
                columnDefs={columnDefs}
                autoGroupColumnDef={autoGroupColumnDef}
                rowData={rowData}
                reportIsGenerated={reportIsGenerated}
                reportError={reportError}
            />
        </>
    );
}

export default ForecastResultCalculation;
