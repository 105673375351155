import { ChangeEvent } from 'react';
import styles from '../../AdminTools.module.css';

interface AdminInputProps {
    label: string,
    inputValue: string;
    handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
    maxLength?: number;
    type?: string;
}

export const AdminInput = ({
    label,
    inputValue,
    handleChange,
    maxLength,
    type
}: AdminInputProps) => {
    const inputStyle = type === 'email' ? styles.emailInput : styles.textInput;

    return (
        <div className={styles.inputSpaceRow}>
            <label className={styles.labelPadding}>{label}</label>

            <input className={inputStyle}
                maxLength={maxLength ?? 10}
                type={type ?? 'text'}
                value={inputValue}
                onChange={handleChange}
            />
        </div>
    )
}