import styles from './Buttons.module.css';

interface ActionButtonProps {
  text: string,
  buttonStyles: string[],
  handleOnClick: () => void;
  disabled?: boolean;
}

const ActionButton = ({
  text,
  handleOnClick,
  disabled,
  buttonStyles,
}: ActionButtonProps) => (
  <button
    className={`${styles.actionButton} ${buttonStyles.map(style => styles[style])}`}
    onClick={() => handleOnClick()}
    disabled={disabled}>
    {text}
  </button>
);

export default ActionButton;
