import styles from '../InfoTooltip.module.css';

export const ProjectEconomyTooltip = () => (
    <div className={styles.infoTooltip} data-testid="InfoTooltip">
        <h2 className={styles.infoTooltipHeader}>Information</h2>

        <div className={styles.infoHeader}>Allmänt</div>
        <ul className={styles.infoList}>
            <li>
                <p className={`${styles.infoText} ${styles.regularColor}`}>- Samtliga belopp är i tusental kronor</p>
            </li>
            <li>
                <p className={`${styles.infoText} ${styles.regularColor}`}>- Att budgeten är låst visas med * bredvid kolumnnamnet</p>
            </li>
        </ul>

        <div className={styles.infoHeader}>Textfärger</div>

        <p className={styles.infoText} >Totaler</p>
        <ul className={styles.infoList}>
            <li>
                <p className={`${styles.infoText} ${styles.vatFreeColor}`} >- Momsfritt konto</p>
            </li>
            <li>
                <p className={`${styles.infoText} ${styles.prognosisLessThanOutcome}`} >- Prognos understiger utfall</p>
            </li>
        </ul>

        <p className={styles.infoText} >Månadskolumner</p>
        <ul className={styles.infoList}>
            <li>
                <p className={`${styles.infoText} ${styles.manualColor}`} >- Manuellt inlagda värden i beräknade fält</p>
            </li>
            <li>
                <p className={`${styles.infoText} ${styles.calculatedColor}`}>- Beräknade fält</p>
            </li>
        </ul>

        <div className={styles.infoHeader}>Skeden i månadskolumner</div>
        <div className={styles.infoContainer}>
            <div className={`${styles.projectStageColorBox} ${styles.projectionStageColor}`} data-testid="ProjectionStage" />
            <div className={styles.projectStageDescription}>Projektering</div>
            <div className={`${styles.projectStageColorBox} ${styles.buildingStageColor}`} data-testid="BuildingStage" />
            <div className={styles.projectStageDescription}>Bygg</div>
            <div className={`${styles.projectStageColorBox} ${styles.usingStageColor}`} data-testid="UsingStage" />
            <div className={styles.projectStageDescription}>Brukar</div>
        </div>
    </div>
);