import { useMsal } from "@azure/msal-react";
import { IconButton, TooltipHost } from "@fluentui/react";
import styles from './ProjectEconomyButton.module.css';
import { IPublicClientApplication } from "@azure/msal-browser";

const handleLogout = (instance: IPublicClientApplication) => {
  instance.logoutRedirect().catch((e: Error) => {
    console.error(e);
  });
}

export const SignOutButton = () => {
  const { instance } = useMsal();

  return (
    <TooltipHost content='Logga ut' id={'signOut'} >
      <IconButton
        data-testid="SignOutButton"
        className={styles.projectEconomyButton}
        iconProps={{ iconName: 'FollowUser' }}
        onClick={() => handleLogout(instance)} />
    </TooltipHost>
  );
}

