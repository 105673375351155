import { useEffect, useState } from "react";
import { Scopes } from "../../interfaces/UserPermission";


export const useControlAccess = (
    scopes: Scopes | undefined,
    scopeKey?: string | string[],
) => {
    const [access, setAccess] = useState(false);

    useEffect(() => {
        if (scopeKey) {
            if (typeof scopeKey === 'string') {
                const scope = scopes?.[scopeKey as keyof Scopes]
                setAccess(typeof scope === 'boolean' && scope);
            } else if (Array.isArray(scopeKey)) {
                const matchingScopes = scopeKey.find((key: string) =>
                    scopes?.[key as keyof Scopes] === true);

                if (matchingScopes) {
                    setAccess(matchingScopes?.length > 0);
                }
            }
        }
    }, [scopeKey, scopes])


    return access;
}