import { CellDoubleClickedEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { RefObject, useCallback } from "react";

export const useOnCellDoubleClicked = (gridRef: RefObject<AgGridReact>) => {
    const onCellDoubleClicked = useCallback((params: CellDoubleClickedEvent) => {
        if (params.node.group) {
            const expandCollapse: boolean = params.node.expanded ? false : true;

            gridRef.current!.api.setRowNodeExpanded(params.node, expandCollapse)
        }
    }, [gridRef]);

    return onCellDoubleClicked;
}