import useProjectId from "../../customHooks/useProjectId"
import { useQuery } from "@tanstack/react-query";
import { useAccessToken } from "../useAccessToken";
import { ProjectInfo } from "../../interfaces/project/ProjectInfo";
import { useInteractionContext } from "../../context/InteractionContext";
import { useCallback } from "react";
import { makeResultFetch } from "../genericFetch";
import { apiRequestParams } from "../fetchOptions";
import { useHandleRetry } from "../useHandleRetry";


const useFetch = () => {
    const getAccessToken = useAccessToken();
    const projectId = useProjectId();

    const queryFunction = useCallback(async () => {
        return getAccessToken().then(accessToken => {
            if (!accessToken) throw new Error("Access token is undefined");

            const fetchedData = makeResultFetch<ProjectInfo>(
                `/Projects/${projectId}`,
                apiRequestParams(accessToken, 'GET')
            ).then((response) => {
                const { data, message, success } = response;

                if (!success) throw Error(message);

                return data;
            });

            return fetchedData;
        });
    }, [getAccessToken, projectId]);

    return { projectId, queryFunction };
}


export const useProjectInfo = () => {
    const { projectId, queryFunction } = useFetch();
    const handleRetry = useHandleRetry();
    const { loadProjectEconomy } = useInteractionContext();

    const { isLoading, error, data } = useQuery({
        queryKey: [projectId, 'projectInfo'],
        queryFn: queryFunction,
        enabled: !!queryFunction && !!projectId && loadProjectEconomy,
        retry: handleRetry,
    });

    return { isLoading, error, data };
}