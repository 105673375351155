import { useCallback } from "react"
import { useSheetContext } from "../context/SheetContext";
import { GridRows } from "../interfaces/grids/GridRows";
import { formatData } from "../utils/formatData";
import { SheetData } from "../interfaces/grids/SheetData";


export const useSetFetchedContextData = () => {
    const {
        setBaseCalculations,
        setLockedBudget,
        setCashflowRows,
        setMonthColumns,
        setPerformanceRows,
        setPerformanceTotals,
        setProjectAttributes,
        setDatesInfo,
        setInitiallyUnsetBuildStartMonth,
    } = useSheetContext();


    const setFetchedContextData = useCallback((data: SheetData) => {
        if (data) {
            const formattedCashflowData: GridRows = formatData(data?.cashflowSheet?.data);
            setCashflowRows(formattedCashflowData);

            setMonthColumns(data?.monthColumns);

            const formattedPerformanceData: GridRows = formatData(data?.performanceSheet?.data);
            setPerformanceRows(formattedPerformanceData);

            setPerformanceTotals(data?.performanceTotals);

            const datesInfo = data?.projectAttributes?.datesInfo;
            const interestInfo = data?.projectAttributes?.interestInfo;
            const indexInfo = data?.projectAttributes?.indexInfo;

            setProjectAttributes({
                buildStartMonth: datesInfo?.buildStartMonth?.slice(0, 7) ?? null,
                startMonth: datesInfo?.startMonth?.slice(0, 7) ?? null,
                endMonth: datesInfo?.endMonth?.slice(0, 7) ?? null,
                mgmtBuildStartConsentPlanned: datesInfo?.mgmtBuildStartConsentPlanned?.slice(0, 10) ?? null,
                mgmtBuildStartConsent: datesInfo?.mgmtBuildStartConsent?.slice(0, 10) ?? null,
                saleOfLandPlanned: datesInfo?.saleOfLandPlanned?.slice(0, 10) ?? null,
                saleOfLand: datesInfo?.saleOfLand?.slice(0, 10) ?? null,
                buildingCreditInterest: interestInfo?.buildingCreditInterest,
                internalInterest: interestInfo?.internalInterest,
                indexBaseMonth: indexInfo?.indexBaseMonth?.slice(0, 7) ?? null,
                indexPercentage: indexInfo?.indexPercentage,
            })

            datesInfo?.buildStartMonth && setInitiallyUnsetBuildStartMonth(false);

            setDatesInfo({ ...datesInfo });

            setLockedBudget(data?.budgetIsLocked as boolean);

            setBaseCalculations(data?.baseCalculations);
        }
    }, [setBaseCalculations, setCashflowRows, setDatesInfo, setInitiallyUnsetBuildStartMonth, setLockedBudget, setMonthColumns,
        setPerformanceRows, setPerformanceTotals, setProjectAttributes]);

    return setFetchedContextData;
}