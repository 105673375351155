import { useId } from '@fluentui/react-hooks';
import {
  Modal,
  getTheme,
  mergeStyleSets,
  FontWeights,
  IDragOptions,
  ContextualMenu,
  IIconProps,
} from '@fluentui/react';
import { IconButton } from '@fluentui/react/lib/Button';
import { Dispatch, SetStateAction } from 'react';
import { useCallback } from 'react';

const dragOptions: IDragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
  dragHandleSelector: '.ms-Modal-scrollableContent > div:first-child',
};
const cancelIcon: IIconProps = { iconName: 'Cancel' };

interface FluentModalProps {
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>> | undefined;
  isModeLess: boolean;
  modalHeader: string;
  modalContent: JSX.Element;
  setModalInput?: Dispatch<SetStateAction<string | number | null>>;
  setChangedValue?: Dispatch<SetStateAction<string>>;
}

export const FluentModal = ({
  isModalOpen,
  setIsModalOpen,
  isModeLess,
  modalHeader,
  modalContent,
  setModalInput,
  setChangedValue
}: FluentModalProps) => {

  const titleId = useId();

  const closeModal = useCallback(() => {
    setModalInput && setModalInput(0);
    setIsModalOpen && setIsModalOpen(false);
    setChangedValue && setChangedValue('');
  }, [setChangedValue, setIsModalOpen, setModalInput]);

  return (
    <Modal
      titleAriaId={titleId}
      isOpen={isModalOpen}
      onDismiss={closeModal}
      isModeless={isModeLess}
      containerClassName={contentStyles.container}
      dragOptions={dragOptions}
    >
      <div className={contentStyles.header}>
        <h2 className={contentStyles.heading} id={titleId}>
          {modalHeader}
        </h2>
        <IconButton
          data-testid="CloseModalBtn"
          styles={iconButtonStyles}
          iconProps={cancelIcon}
          onClick={closeModal}
          disabled={setIsModalOpen === undefined}
        />
      </div>
      <div className={contentStyles.body}>
        {modalContent}
      </div>
    </Modal>
  );
};

const theme = getTheme();

// default fluent ui modal styling
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  header: [
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      borderTop: `4px solid ${theme.palette.themePrimary}`,
      color: theme.palette.neutralPrimary,
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '12px 12px 14px 24px',
    },
  ],
  heading: {
    color: theme.palette.neutralPrimary,
    fontWeight: FontWeights.semibold,
    fontSize: 'inherit',
    margin: '0',
  },
  body: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px',
    overflowY: 'hidden',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
});
const iconButtonStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};
