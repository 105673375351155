import styles from '../InfoTooltip.module.css';

export const ResCalcSchedulerTooltip = () => (
    <div className={styles.infoTooltip}>
        <h2 className={styles.infoTooltipHeader}>Information</h2>
        <ul className={styles.infoList}>
            <li>
                <p className={styles.infoText}>- Håll inne Ctrl/Shift och vänsterklicka på rader för att markera flera rader</p>
            </li>
            <li>
                <p className={styles.infoText}>- Högerklicka på en rad för att radera markerade rader</p>
            </li>
        </ul>
    </div>
);