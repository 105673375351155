import { ICellRendererParams } from "ag-grid-community";
import { formatNumber } from "../../../../utils/formatNumber";
import { ForecastResCalcReportTotals } from "../../../../interfaces/reports/ForecastResCalcReport";

export const TotalsCellRenderer = (params: ICellRendererParams) => {

    const field = params.column?.getColId();
    const filteredTotals: { [key: string]: ForecastResCalcReportTotals } = params.colDef?.cellRendererParams?.filteredTotals;
    const key = params.node?.key?.split('-')?.[0].trim();


    if (key && field) {
        const totals = filteredTotals?.[key];
        const fieldTotal = totals?.[field as keyof ForecastResCalcReportTotals];

        const colType = params.colDef?.type;
        if (fieldTotal) {
            let formattedValue = '';

            if (colType === 'date') {
                formattedValue = (fieldTotal as string)?.split('T')[0];
                if (totals.buildStartDatePlanned) formattedValue += '*';
            } else if (colType === 'numericColumn') {
                formattedValue = formatNumber(fieldTotal as number)
            } else {
                formattedValue = fieldTotal as string;
            }

            return <span>{formattedValue}</span>;
        }
    }

    return <></>;
}