import { AgGridReact } from 'ag-grid-react';
import styles from '../ResultCalculationLog.module.css';
import { RefObject } from 'react';
import ResultCalcLogHeader from '../../../Headers/ActionHeaders/ResultCalculation/ResultCalcLogHeader';
import GridFilter from '../../../GridFilter/GridFilter';
import ResCalcLogDetailsGrid from './ResCalcLogDetailsGrid';


interface ResCalcLogDetailsProps {
    logDetailsGridRef: RefObject<AgGridReact>;
    taskId: number | null;
}

const ResCalcLogDetails = ({
    logDetailsGridRef,
    taskId
}: ResCalcLogDetailsProps) => (
    <div className={styles.resCalcLog}>
        <ResultCalcLogHeader>
            <div className={styles.headerContentsContainer}>
                <h6 className={styles.headerTitle}>Körning med id: {taskId}</h6>

                <div className={styles.filterInputContainer}>
                    <GridFilter
                        gridRefs={[logDetailsGridRef]}
                        elementId={'resCalcLogDetails-filter'} />
                </div>
            </div>
        </ResultCalcLogHeader>

        <ResCalcLogDetailsGrid
            logDetailsGridRef={logDetailsGridRef}
            taskId={taskId}
        />
    </div>
);

export default ResCalcLogDetails;
