import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { ModalV2 } from "../../Modals/ModalV2";
import { useInteractionContext } from "../../../context/InteractionContext";
import { IconButton, PrimaryButton, TooltipHost } from "@fluentui/react";
import styles from './ErrorModal.module.css';

interface ErrorModalProps {
    isModalOpen: boolean;
    setIsModalOpen: Dispatch<SetStateAction<boolean>>;
}

export const ErrorModal = ({
    isModalOpen,
    setIsModalOpen
}: ErrorModalProps
) => {
    const { errors, setErrors } = useInteractionContext();
    const [errorModalContent, setErrorModalContent] = useState<JSX.Element>(<></>);


    const copyButton = (text: string) => {
        const copyText = () => navigator.clipboard.writeText(text);

        return (
            <TooltipHost content='Kopiera' id={'copyTextButton'}>
                <IconButton
                    iconProps={{ iconName: 'Copy' }}
                    onClick={copyText}
                />
            </TooltipHost>
        )
    };


    const mapErrors = useCallback(() => {
        if (errors) {
            return errors.map((error, index) => (
                <div className={`${styles.errorMessageRow} ${index > 0 ? styles.errorMessageBorder : undefined}`} key={index}>
                    <p className={`${styles.errorMessage} ${index > 0 ? styles.notFirstErrorMessage : undefined}`}>
                        {error.message}
                    </p>

                    {copyButton(error.message)}
                </div>
            ));
        }
    }, [errors]);


    const generateModalContent = useCallback(() => {
        const clearErrors = () => setErrors([]);

        return (
            <>
                <div className={styles.errorsContainer}>
                    {mapErrors()}
                </div>
                <div className={styles.clearButton}>
                    <PrimaryButton onClick={clearErrors}>
                        Rensa fel
                    </PrimaryButton>
                </div>
            </>
        );
    }, [mapErrors, setErrors]);


    useEffect(() => {
        if (errors?.length > 0) {
            setErrorModalContent(generateModalContent());
        }
    }, [errors, generateModalContent]);


    return (
        <>
            <ModalV2
                modalHeader="Något gick fel"
                modalContent={errorModalContent}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
            />
        </>
    );
}