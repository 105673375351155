import { DefaultButton, PrimaryButton } from '@fluentui/react';
import { Dispatch, SetStateAction } from 'react';
import styles from './Confirmation.module.css';
import LoadingSpinner from './../../../../StatusIndicators/LoadingSpinner';

interface ConfirmPrognosisProps {
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  setModalInput: Dispatch<SetStateAction<string | number | null>>;
  confirmPrognosisStatus: number;
  setConfirmPrognosisStatus: Dispatch<SetStateAction<number>>;
}

const ConfirmPrognosis = ({
  setIsModalOpen,
  setModalInput,
  confirmPrognosisStatus,
  setConfirmPrognosisStatus
}: ConfirmPrognosisProps) => {

  return (
    <div className={`${styles.confirmBody} ${styles.prognosisBody} ${confirmPrognosisStatus === 0 ? styles.startBody : styles.loadingBody}`}>
      {confirmPrognosisStatus === 0 &&
        <>
          <p><b>Vill du bekräfta prognosen?</b></p>

          <div className={styles.buttonContainer}>
            <div className={styles.buttonMargin}>
              <PrimaryButton
                onClick={() => { setConfirmPrognosisStatus(1); setModalInput(1) }}
              >Ja</PrimaryButton>
            </div>
            <div className={styles.buttonMargin}>
              <DefaultButton
                onClick={() => { setConfirmPrognosisStatus(0); setIsModalOpen(false); }}
              >Avbryt</DefaultButton>
            </div>
          </div>
          <p>Har prognosen tidigare bekräftats samma månad verifieras den</p>
        </>
      }

      {confirmPrognosisStatus > 0 &&
        <div className={styles.messageContainer}>
          <div className={styles.spinnerContainer}>
            {confirmPrognosisStatus === 1
              ?
              <LoadingSpinner text={'Prognosen bekräftas...'} />
              :
              <p className={styles.statusMessage}>Prognosen bekräftad</p>
            }
          </div>
        </div>
      }
    </div>
  )
};

export default ConfirmPrognosis;
