import {
    Dispatch, ReactNode, RefObject, SetStateAction, createContext, useContext, useRef, useState
} from "react";
import { BaseCalculations } from "../interfaces/baseCalculation/BaseCalculations";
import { AggTranslations } from "../interfaces/grids/performance/AggTranslation";
import { MonthColumn } from "../interfaces/grids/cashflow/MonthColumns";
import { AgGridReact } from "ag-grid-react";
import { GridRows } from "../interfaces/grids/GridRows";
import { DatesInfo } from "../interfaces/baseCalculation/DatesInfo";
import { MonthColumnIds } from "../interfaces/grids/cashflow/MonthColumnIds";
import { ProjectAttributes } from "../interfaces/project/ProjectAttributes";


type SheetContextProviderProps = {
    children: ReactNode;
}

export type SheetContextStates = {
    cashflowGridRef: RefObject<AgGridReact>;
    cashflowRows: GridRows | undefined;
    setCashflowRows: Dispatch<SetStateAction<GridRows | undefined>>;
    monthColumns: { [key: string]: MonthColumn };
    setMonthColumns: Dispatch<SetStateAction<{ [key: string]: MonthColumn }>>;
    monthColumnIds: MonthColumnIds;
    setMonthColumnIds: Dispatch<SetStateAction<MonthColumnIds>>;

    performanceGridRef: RefObject<AgGridReact>;
    performanceRows: GridRows | undefined;
    setPerformanceRows: Dispatch<SetStateAction<GridRows | undefined>>;
    performanceTotals: AggTranslations | undefined;
    setPerformanceTotals: Dispatch<SetStateAction<AggTranslations | undefined>>;

    projectAttributes: ProjectAttributes;
    setProjectAttributes: Dispatch<SetStateAction<ProjectAttributes>>;
    baseCalculations: BaseCalculations;
    setBaseCalculations: Dispatch<SetStateAction<BaseCalculations>>;
    datesInfo: DatesInfo;
    setDatesInfo: Dispatch<SetStateAction<DatesInfo>>;

    lockedBudget: boolean;
    setLockedBudget: Dispatch<SetStateAction<boolean>>;
    initiallyUnsetBuildStartMonth: boolean;
    setInitiallyUnsetBuildStartMonth: Dispatch<SetStateAction<boolean>>;
}


export const SheetContext = createContext<SheetContextStates | null>(null);

export const SheetContextProvider = ({ children }: SheetContextProviderProps) => {

    const cashflowGridRef = useRef<AgGridReact>(null);
    const [cashflowRows, setCashflowRows] = useState<GridRows | undefined>(undefined);
    const [monthColumnIds, setMonthColumnIds] = useState<MonthColumnIds>({ prognosis: [], outcome: [] });
    const [monthColumns, setMonthColumns] = useState<{ [key: string]: MonthColumn }>({});

    const performanceGridRef = useRef<AgGridReact>(null);
    const [performanceRows, setPerformanceRows] = useState<GridRows | undefined>(undefined);
    const [performanceTotals, setPerformanceTotals] = useState<AggTranslations | undefined>(undefined);


    const [projectAttributes, setProjectAttributes] = useState<ProjectAttributes>({
        buildStartMonth: '',
        startMonth: '',
        endMonth: '',
        mgmtBuildStartConsentPlanned: '',
        mgmtBuildStartConsent: '',
        saleOfLandPlanned: '',
        saleOfLand: '',
        buildingCreditInterest: null,
        internalInterest: null,
        indexBaseMonth: '',
        indexPercentage: null
    });


    const [datesInfo, setDatesInfo] = useState<DatesInfo>({} as DatesInfo);
    const [baseCalculations, setBaseCalculations] = useState<BaseCalculations>({
        projectedCostsSubjectToVat: null,
        profitOnContract: null,
        profitOnContractLimit: null,
        expenditureOnCostsSubjectToVat: null,
    });

    const [lockedBudget, setLockedBudget] = useState<boolean>(false);
    const [initiallyUnsetBuildStartMonth, setInitiallyUnsetBuildStartMonth] = useState<boolean>(false);


    const value = {
        cashflowGridRef,
        cashflowRows,
        setCashflowRows,
        monthColumns,
        setMonthColumns,
        monthColumnIds,
        setMonthColumnIds,

        performanceGridRef,
        performanceRows,
        setPerformanceRows,
        performanceTotals,
        setPerformanceTotals,

        projectAttributes,
        setProjectAttributes,
        datesInfo,
        setDatesInfo,
        baseCalculations,
        setBaseCalculations,

        lockedBudget,
        setLockedBudget,
        initiallyUnsetBuildStartMonth,
        setInitiallyUnsetBuildStartMonth,
    }

    return (<SheetContext.Provider
        value={value}>
        {children}
    </SheetContext.Provider>);
}


export const useSheetContext = () => {
    const context = useContext(SheetContext);

    if (!context) {
        throw Error("useSheetContext must be used within a SheetContextProvider")
    }

    return context;
}

