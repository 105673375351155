import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useState } from "react";
import { Edits } from "../interfaces/editing/Edits";


type EditingContextProviderProps = {
    children: ReactNode;
}

type EditingContextStates = {
    edits: Edits;
    setEdits: Dispatch<SetStateAction<Edits>>;

    editingEnabled: boolean;
    setEditingEnabled: Dispatch<SetStateAction<boolean>>;
    readOnlyMode: boolean;
    setReadOnlyMode: Dispatch<SetStateAction<boolean>>;
    snapshotMode: boolean;
    setSnapshotMode: Dispatch<SetStateAction<boolean>>;

    emptyRowsHiddenToggle: boolean;
    setEmptyRowsHiddenToggle: Dispatch<SetStateAction<boolean>>;
    prognosisShifted: boolean;
    setPrognosisShifted: Dispatch<SetStateAction<boolean>>;
    selectedSnapshotId: string;
    setSelectedSnapshotId: Dispatch<SetStateAction<string>>;

    easterEggMode: boolean;
    setEasterEggMode: Dispatch<SetStateAction<boolean>>;
}


export const EditingContext = createContext<EditingContextStates | null>(null);

export const EditingContextProvider = ({ children }: EditingContextProviderProps) => {

    const [edits, setEdits] = useState<Edits>(
        {
            columns: {},
            budgets: {},
            comments: {},
            projectAttributes: {}
        }
    );
    const [editingEnabled, setEditingEnabled] = useState<boolean>(false);     // permission
    const [readOnlyMode, setReadOnlyMode] = useState<boolean>(false);     // interaction
    const [snapshotMode, setSnapshotMode] = useState<boolean>(false);
    const [emptyRowsHiddenToggle, setEmptyRowsHiddenToggle] = useState<boolean>(false);
    const [prognosisShifted, setPrognosisShifted] = useState<boolean>(false);
    const [selectedSnapshotId, setSelectedSnapshotId] = useState<string>('aktuell projektekonomi');

    const [easterEggMode, setEasterEggMode] = useState<boolean>(false);

    const value: EditingContextStates = {
        edits,
        setEdits,

        editingEnabled,
        setEditingEnabled,
        readOnlyMode,
        setReadOnlyMode,
        snapshotMode,
        setSnapshotMode,

        emptyRowsHiddenToggle,
        setEmptyRowsHiddenToggle,
        prognosisShifted,
        setPrognosisShifted,
        selectedSnapshotId,
        setSelectedSnapshotId,

        easterEggMode,
        setEasterEggMode
    }

    return (<EditingContext.Provider
        value={value}>
        {children}
    </EditingContext.Provider>);
}


export const useEditingContext = () => {
    const context = useContext(EditingContext);

    if (!context) {
        throw Error("useEditingContext must be used within a EditingContextProvider")
    }

    return context;
}

