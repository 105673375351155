import styles from './CashflowGrid.module.css';
import { useEffect, useMemo, useCallback, Dispatch, SetStateAction } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import { CellClassParams, CellClickedEvent, ColDef, Column, } from 'ag-grid-community';
import { defaultColDef } from '../gridConfig/DefaultColDef';
import { AG_GRID_LOCALE_SV } from '../gridConfig/SwedishLocale';
import TotalTextCellRenderer from '../cellRenderers/TotalTextCellRenderer';
import LoadingSpinner from '../../StatusIndicators/LoadingSpinner';
import { customAggFuncs } from '../helper/customAggFuncs';
import { LicenseManager } from 'ag-grid-enterprise';
import { useEditingContext } from '../../../context/EditingContext';
import { useInteractionContext } from '../../../context/InteractionContext';
import { useSheetContext } from '../../../context/SheetContext';
import { useCashflow } from '../../../fetchData/queries/cashflow/useCashflow';
import { useMonthColumns } from '../../../fetchData/queries/cashflow/useMonthColumns';
import { useCashflowNotices } from '../../../fetchData/queries/cashflow/useCashflowNotices';
import { UnableToLoadData } from '../../SystemState/ErrorManagement/UnableToLoadData/UnableToLoadData';
import { useCashflowColDefs } from './useCashflowColDefs';
import { useStatusBar } from '../customHooks/useStatusBar';
import { useGetRowId } from '../customHooks/useGetRowId';
import { useOnCellDoubleClicked } from '../customHooks/useOnCellDoubleClicked';
import { useOnCellContextMenu } from '../customHooks/useOnCellContextMenu';
import { useGetContextMenuItems } from '../customHooks/useGetContextMenuItems';
import { gridLicenseKey } from '../gridLicenseKey';
import { useOnCellValueChanged } from '../customHooks/useOnCellValueChanged';


LicenseManager.setLicenseKey(gridLicenseKey);


interface CashflowGridProps {
  setIsAccountSpecificationModalOpen: Dispatch<SetStateAction<boolean>>;
  modalAccountId: string;
  setModalAccountId: Dispatch<SetStateAction<string>>;
  modalOutcomeDate: string;
  setModalOutcomeDate: Dispatch<SetStateAction<string>>;
}

const CashflowGrid = ({
  setIsAccountSpecificationModalOpen,
  modalAccountId,
  setModalAccountId,
  modalOutcomeDate,
  setModalOutcomeDate,
}: CashflowGridProps) => {

  const cashflow = useCashflow();
  const monthCols = useMonthColumns();
  const accountNotices = useCashflowNotices().data;


  const {
    cashflowGridRef,
    cashflowRows,
    setCashflowRows,
    monthColumns,
    setMonthColumns,
    lockedBudget,
    datesInfo,
    setMonthColumnIds
  } = useSheetContext();

  const {
    loadProjectEconomy,
    setLoadProjectEconomy
  } = useInteractionContext();

  const {
    edits,
    setEdits,
    emptyRowsHiddenToggle,
    editingEnabled,
    readOnlyMode
  } = useEditingContext();


  useEffect(() => {
    if (cashflow.data) setCashflowRows(cashflow.data);
    if (monthCols.data) setMonthColumns(monthCols.data);
  }, [cashflow.data, loadProjectEconomy, monthCols.data, setCashflowRows, setMonthColumns]);


  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);

  const cashflowColDefsHook = {
    datesInfo,
    monthColumns,
    lockedBudget,
    setMonthColumnIds
  };

  const cashflowColDefs = useCashflowColDefs({ ...cashflowColDefsHook });
  const getRowId = useGetRowId();
  const statusBar = useStatusBar();


  const onCellValueChangedProps = {
    editingEnabled,
    readOnlyMode,
    edits,
    setEdits,
    setLoadProjectEconomy
  };

  const onCellValueChanged = useOnCellValueChanged({ ...onCellValueChangedProps })
  const onCellDoubleClicked = useOnCellDoubleClicked(cashflowGridRef);
  const onCellContextMenu = useOnCellContextMenu(modalAccountId, modalOutcomeDate);

  const getContextMenuItemsHookParams = {
    totals: undefined,
    setIsAccountSpecificationModalOpen,
    setModalAccountId,
    setModalOutcomeDate,
  }

  const getContextMenuItems = useGetContextMenuItems({ ...getContextMenuItemsHookParams });


  const autoGroupColumnDef = useMemo((): ColDef => {
    return {
      headerName: 'Kassaflöde',
      cellRendererParams: {
        suppressCount: true,
        suppressDoubleClickExpand: true,
        innerRenderer: TotalTextCellRenderer,
        innerRendererParams: { ...accountNotices }
      },
      field: 'displayName',
      pinned: 'left',
      cellStyle: (params: CellClassParams) => {
        const colStyle = { backgroundColor: '#d9dcde', fontWeight: '' };

        switch (params.node.level) {
          case 1:
            colStyle.backgroundColor = `${colStyle.backgroundColor}30`;
            colStyle.fontWeight = '700';
            break;
          case 2:
            colStyle.backgroundColor = params.node.data?.editable ? 'transparent' : `${colStyle.backgroundColor}30`;
            break;
          default:
            colStyle.backgroundColor = `${colStyle.backgroundColor}80`;
            colStyle.fontWeight = '900';
            break;
        }
        return colStyle
      },
    };
  }, [accountNotices]);


  const onFirstDataRendered = useCallback(() => {
    const allColumnIds: string[] = cashflowGridRef.current!.columnApi.getColumns()!.map((column: Column) => {
      return column.getColId();
    });

    cashflowGridRef.current!.columnApi.autoSizeColumns(allColumnIds, true);
    // autosized without skipping header
    cashflowGridRef.current!.columnApi.autoSizeColumns(["totalPrognosis"]);

    const todayYearMonth = new Date().toISOString().slice(0, 7);
    const todayColumnId: string = `outcome_${todayYearMonth}-01T00:00:00`;
    cashflowGridRef.current!.api.ensureColumnVisible(todayColumnId, 'start');
  }, [cashflowGridRef]);


  return (
    <div className="ag-theme-balham" style={gridStyle} data-testid="CashflowGrid">
      {cashflow.error ?
        <div className={styles.topBorderOnError}>
          <UnableToLoadData message={'Kassaflöde kunde ej laddas'} />
        </div> :
        <AgGridReact
          ref={cashflowGridRef}
          rowData={emptyRowsHiddenToggle ? cashflowRows?.emptyRowsHidden : cashflowRows?.prefixedData}
          columnDefs={cashflowColDefs}
          rowSelection={"multiple"}
          defaultColDef={defaultColDef}
          autoGroupColumnDef={autoGroupColumnDef}
          getContextMenuItems={getContextMenuItems}
          onCellContextMenu={onCellContextMenu}
          onCellDoubleClicked={onCellDoubleClicked}
          onCellValueChanged={onCellValueChanged}
          onFirstDataRendered={onFirstDataRendered}
          groupDefaultExpanded={1}
          statusBar={statusBar}
          enableRangeSelection={true}
          enableRangeHandle={true}
          localeText={AG_GRID_LOCALE_SV}
          loadingOverlayComponent={LoadingSpinner}
          suppressAggFuncInHeader={true}
          getRowId={getRowId}
          groupRowsSticky={true}
          aggFuncs={customAggFuncs}
          groupAllowUnbalanced
          groupMaintainOrder

          // dev
          onCellClicked={(params: CellClickedEvent) => console.log('Cell was clicked', params)}
        />
      }
    </div>
  );
};

export default CashflowGrid;
