import styles from './ReportGrid.module.css';
import { CellClickedEvent, ColDef } from "ag-grid-community";
import { RefObject, useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import { useDefaultGroupsContextMenu } from '../../../../customHooks/useDefaultGroupsContextMenu';
import { AG_GRID_LOCALE_SV } from '../../../MainGrids/gridConfig/SwedishLocale';
import errorStyles from '../../../SystemState/ErrorManagement/UnableToLoadData/UnableToLoadData.module.css';
import { UnableToLoadData } from '../../../SystemState/ErrorManagement/UnableToLoadData/UnableToLoadData';
import { useStatusBar } from '../../../MainGrids/customHooks/useStatusBar';
import { useOnCellDoubleClicked } from '../../../MainGrids/customHooks/useOnCellDoubleClicked';
import { NotificationBars } from '../../../SystemState/ErrorManagement/MessageBars/NotificationBars';


interface ReportGridProps<T> {
  reportGridRef: RefObject<AgGridReact>;
  columnDefs: ColDef[];
  autoGroupColumnDef: ColDef;
  rowData: (T | undefined)[];
  reportIsGenerated: boolean;
  reportError: boolean;
}


const ReportGrid = <T,>({
  reportGridRef,
  columnDefs,
  autoGroupColumnDef,
  rowData,
  reportIsGenerated,
  reportError
}: ReportGridProps<T>) => {

  const statusBar = useStatusBar();
  const onCellDoubleClicked = useOnCellDoubleClicked(reportGridRef);

  const contextMenu = useDefaultGroupsContextMenu(reportGridRef);


  const defaultColDef = useMemo(() => {
    const colDef = {
      minWidth: 50,
      sortable: true,
      resizable: true,
      enableRowGroup: true,
      enableValue: true,
      enablePivot: true,
      menuTabs: [],
      flex: 1,
      wrapHeaderText: true,
      autoHeaderHeight: true,
    }

    return colDef;
  }, []);


  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
    };
  }, []);


  return (
    <div className={styles.reportGridContainer}>
      <NotificationBars />

      <div className={`ag-theme-balham ${styles.reportGrid}`}>
        {(!reportIsGenerated && reportError && rowData?.length === 0) ?
          <div className={errorStyles.topBorderOnError}>
            <UnableToLoadData message={'Rapport kunde ej laddas'} />
          </div> :
          <AgGridReact
            ref={reportGridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            rowSelection={"multiple"}
            defaultColDef={defaultColDef}
            autoGroupColumnDef={autoGroupColumnDef}
            getContextMenuItems={contextMenu}
            onCellDoubleClicked={onCellDoubleClicked}
            groupDefaultExpanded={4}
            localeText={AG_GRID_LOCALE_SV}
            suppressLoadingOverlay={true}
            suppressAggFuncInHeader={true}
            cacheQuickFilter={true}
            sideBar={sideBar}
            statusBar={statusBar}
            enableRangeSelection={true}
            enableRangeHandle={true}
            groupRowsSticky={true}

            // dev
            onCellClicked={(params: CellClickedEvent) => console.log('Cell was clicked', params)}
          />
        }
      </div>
    </div>
  );
}

export default ReportGrid;
