// input validation

import { ValueParserParams } from "ag-grid-community";

export const numericValueParser = (params: ValueParserParams) => {
    if (isNaN(params.newValue)) {
        return params.oldValue
    } else {
        const newValue: string = params.newValue;
        return newValue === '' ? null : newValue
    }
}