import { Dispatch, SetStateAction } from 'react';
import { DefaultDatePicker } from '../../../../Inputs/DefaultDatePicker/DefaultDatePicker';
import styles from './PanelContent.module.css';

interface ReportPeriodProps {
    fromDate: string;
    setFromDate: Dispatch<SetStateAction<string>>;
    toDate: string;
    setToDate: Dispatch<SetStateAction<string>>;
    reportIsGenerated: boolean;
}

export const ReportPeriodInputs = ({
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    reportIsGenerated,
}: ReportPeriodProps) => (
    <div className={styles.sectionMargin}>
        <h5 className={styles.header}>Rapportperiod</h5>

        <div className={styles.datePickers}>
            <div className={styles.datePicker}>
                <label>Start</label>
                <DefaultDatePicker
                    setChangedDate={setFromDate}
                    dateFormat={'month'}
                    defaultDate={fromDate}
                    disabled={reportIsGenerated}
                />
            </div>

            <div className={styles.datePicker}>
                <label>Slut</label>
                <DefaultDatePicker
                    setChangedDate={setToDate}
                    dateFormat={'month'}
                    defaultDate={toDate}
                    lowerLimit={fromDate}
                    disabled={reportIsGenerated}
                />
            </div>
        </div>
    </div>
)
