import { Dispatch, SetStateAction } from "react";
import { useFinalAccounting } from "../../../../../fetchData/mutations/finalAccounting/useFinalAccounting";
import { AccountingModalContent } from "./AccountingModalContent";
import { modalTimeout } from "../../../../../utils/modalTimeout";


export const FinalAccountModalContent = ({
  setIsModalOpen,
  setModalLock,
}: {
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  setModalLock: Dispatch<SetStateAction<boolean>>;
}) => {
  const finalAccounting = useFinalAccounting();

  setModalLock(finalAccounting.isLoading);

  if (finalAccounting.data?.success) modalTimeout(setIsModalOpen);

  return (
    <AccountingModalContent
      infoText={`Klicka på "Kör" för att slutredovisa detta projekt och skapa bokföringstransaktioner till BW`}
      loadingText={'Slutredovisning körs...'}
      successText={'Projektet har slutredovisats'}
      mutation={finalAccounting}
      verificationText={'Jag är säker på att jag vill slutredovisa projektet'}
    />
  );
}
