import { useMutation } from "@tanstack/react-query";
import { useAccessToken } from "../../useAccessToken";
import { useCallback, } from "react";
import { FetchResponse, makeResultFetch } from "../../genericFetch";
import { apiRequestParams } from "../../fetchOptions";
import { useHandleError } from "../../useHandleError";


interface DeleteUserCacheParams {
    email: string;
    projectId?: string;
}

export const useDeleteUserCache = () => {
    const getAccessToken = useAccessToken();
    const handleError = useHandleError();


    const mutationFunction = useCallback(async ({
        email,
        projectId
    }: DeleteUserCacheParams) => {
        return getAccessToken().then(accessToken => {
            if (!accessToken) throw new Error("Access token is undefined");

            const endpointUrl = projectId
                ? `/Cache/Projects/${projectId}/User/${email}`
                : `/Cache/User/${email}`;


            const fetchedData = makeResultFetch<boolean>(
                endpointUrl,
                apiRequestParams(accessToken, 'DELETE')
            ).then((response: FetchResponse<boolean>) => {
                const { message, success } = response;

                if (!success) throw Error(message);

                return response;
            });

            return fetchedData;
        });
    }, [getAccessToken]);


    const handleSuccess = (response: FetchResponse<boolean>) => {
        if (response.success) {
            alert(`Användarcache har tagits bort`);
        }
    };


    const deleteProjectCache = useMutation({
        mutationFn: mutationFunction,
        onSuccess: handleSuccess,
        onError: handleError,
    });


    return deleteProjectCache;
}