import { useCallback, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import MainHeader from '../../../Headers/MainHeader/MainHeader';
import { GridReportSubHeader } from '../../../Headers/SubHeaders/GridReportSubHeader';
import { getGridReportDates } from '../helper/getGridReportDates';
import ReportGrid from '../ReportGrid/ReportGrid';
import { useResCalcProjectsGrid } from './useResCalcProjectsGrid';
import { ExtendedGridReportInputs } from '../../../../interfaces/reports/ResCalcProjReport';
import { useOrgStructureGrid } from '../OrgStructureGrid/OrgStructureGrid';
import { ReportPeriodInputs } from '../Panel/PanelContent/ReportPeriodInputs';
import { useProjectTypeInputs } from '../Panel/PanelContent/useProjectTypeInputs';
import { useQueryClient } from '@tanstack/react-query';


const ResultCalculatedProjects = () => {
  document.title = 'Rapport - Resultatavräknade projekt';

  const reportGridRef = useRef<AgGridReact>(null);

  const queryClient = useQueryClient();
  const queryKey = 'resCalcProjReport';

  const [reportIsGenerated, setReportIsGenerated] = useState(false);


  const [selectedOrgUnitIds, setSelectedOrgUnitIds] = useState<string[]>([]);
  const { isLoading: orgStructureGridLoading, OrgStructureGrid } = useOrgStructureGrid({
    setSelectedOrgUnitIds,
    reportIsGenerated,
  });


  const [selectedProjectTypeIds, setSelectedProjectTypeIds] = useState<string[]>([]);
  const { isLoading: projectTypeInputsLoading, ProjectTypeInputs } = useProjectTypeInputs({
    selectedProjectTypeIds,
    setSelectedProjectTypeIds,
    reportIsGenerated
  });


  const gridReportDates = getGridReportDates();
  const [fromDate, setFromDate] = useState(gridReportDates.fromDate);
  const [toDate, setToDate] = useState(gridReportDates.toDate);

  const [gridReportInputs, setGridReportInputs] = useState<ExtendedGridReportInputs>({
    orgUnitIds: [],
    projectTypeIds: [],
    fromDate: '',
    toDate: '',
  });


  const {
    columnDefs,
    autoGroupColumnDef,
    rowData,
    queriesProgress,
    reportError,
  } = useResCalcProjectsGrid(gridReportInputs, setReportIsGenerated);


  const panelInputs = [
    <OrgStructureGrid />,
    <ProjectTypeInputs />,
    < ReportPeriodInputs
      fromDate={fromDate}
      setFromDate={setFromDate}
      toDate={toDate}
      setToDate={setToDate}
      reportIsGenerated={reportIsGenerated}
    />
  ];

  const panelButtonDisabled = projectTypeInputsLoading || orgStructureGridLoading;
  const panelSubmitDisabled = selectedOrgUnitIds?.length === 0 || selectedProjectTypeIds?.length === 0
    || fromDate === '' || toDate === '';


  const handleGenerate = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [queryKey]
    });

    setGridReportInputs({
      projectTypeIds: selectedProjectTypeIds,
      orgUnitIds: selectedOrgUnitIds,
      fromDate: fromDate,
      toDate: toDate,
    });

    setReportIsGenerated(true);

  }, [queryClient, selectedProjectTypeIds, selectedOrgUnitIds, fromDate, toDate]);


  const handleAbort = () => {
    queryClient.cancelQueries({
      queryKey: [queryKey]
    })

    setReportIsGenerated(false);
  };


  return (
    <div>
      <MainHeader />

      <GridReportSubHeader
        reportGridRef={reportGridRef}
        header={'Rapport - Resultatavräknade projekt'}
        excelReportName={'Resultatavräknade_projekt'}
        panelSubmitDisabled={panelSubmitDisabled}
        panelButtonDisabled={panelButtonDisabled}
        reportIsGenerated={reportIsGenerated}
        queriesProgress={queriesProgress}
        panelInputs={panelInputs}
        handleGenerate={handleGenerate}
        handleAbort={handleAbort}
      />

      <ReportGrid
        reportGridRef={reportGridRef}
        columnDefs={columnDefs}
        autoGroupColumnDef={autoGroupColumnDef}
        rowData={rowData}
        reportIsGenerated={reportIsGenerated}
        reportError={reportError}
      />
    </div>
  );
}

export default ResultCalculatedProjects;
