import styles from './MainHeader.module.css';
import logoWhite from '../../../images/Riksbyggen_logo_vit.png';
import { SignOutButton } from '../../Buttons/ProjectEconomyHeaderButtons/SignOutButton';
import SettingsButton from '../../Buttons/ProjectEconomyHeaderButtons/SettingsButton';
import { useLocation } from 'react-router-dom';
import ElementAccess from '../../../AccessControl/ElementAccess';
import { InfoTooltip } from '../../InfoTooltip/InfoTooltip';
import { ProjectEconomyTooltip } from '../../InfoTooltip/Tooltips/ProjectEconomyTooltip';
import { RefreshCacheButton } from '../../Buttons/ProjectEconomyHeaderButtons/RefreshCacheButton';


const MainHeader = () => {
  const location = useLocation();
  const route: string = location.pathname.split('/')?.[1];

  return (
    <header className={styles.mainBanner} data-testid="MainHeader">
      <div className={styles.logoTitle}>
        <img height='38' src={logoWhite} alt="" />
        <h1 className={styles.mainHeader}>Projektekonomi</h1>

        {route === 'projekt' && <InfoTooltip content={<ProjectEconomyTooltip />} />}
      </div>

      <div className={styles.buttonContainer}>
        <RefreshCacheButton />

        {route === 'projekt' &&
          <>
            <ElementAccess scopeKey={['account_templates', 'scheduling_crud', 'accounting_read', 'system_settings']}>
              <SettingsButton />
            </ElementAccess>

            <SignOutButton />
          </>
        }
      </div>
    </header >
  )
};

export default MainHeader; 