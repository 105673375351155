import styles from '../AdminTools.module.css';
import { useState } from "react";
import { AdminInput } from './AdminInputs/AdminInput';
import { useCheckUser } from '../../../../fetchData/queries/settings/useCheckUser';
import LoadingSpinner from '../../../StatusIndicators/LoadingSpinner';
import { AdminActionButton } from './AdminInputs/AdminActionButton';


export const CheckUser = () => {
    const [email, setEmail] = useState<string>('');
    const [projectId, setProjectId] = useState<string>('');
    const [handleCheckUser, setHandleCheckUser] = useState<boolean>(false);

    const [queryParams, setQueryParams] = useState<{ email: string, projectId: string }>({ email: '', projectId: '' });
    const { isLoading, data } = useCheckUser({ handleCheckUser, ...queryParams });


    const handleControl = () => {
        setHandleCheckUser(true);
        setQueryParams({ email, projectId });
    }



    const userData = () => {
        if (isLoading && handleCheckUser) {
            return <LoadingSpinner />
        }

        if (data === null) {
            return <p>Ingen data</p>
        }

        if (data && Object.keys(data)?.length > 0) {
            return (
                <div className={styles.userData}>
                    <pre>{JSON.stringify(data, null, 2)}</pre>
                </div>
            )
        }

        return <></>
    }



    return (
        <div className={styles.formContainer}>
            <h6 className={styles.inputGroupHeader}>Kontroll av global eller projektspecifik behörighet</h6>

            <AdminInput
                label={'E-mail*'}
                inputValue={email}
                handleChange={(e) => setEmail(e.target.value)}
                maxLength={50}
                type={'email'}
            />

            <AdminInput
                label={'Projekt-id'}
                inputValue={projectId}
                handleChange={(e) => setProjectId(e.target.value)}
            />

            <AdminActionButton
                buttonText={'Kontrollera'}
                handleAction={() => handleControl()}
                actionDisabled={!email}
            />

            <div className={styles.userDataContainer}>
                {userData()}
            </div>
        </div>
    );
}



