import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useProjectTypes } from "../../../../../fetchData/queries/reports/grid/useProjectTypes";
import styles from './PanelContent.module.css';
import { Checkbox } from "@fluentui/react";
import { UnableToLoadData } from "../../../../SystemState/ErrorManagement/UnableToLoadData/UnableToLoadData";


interface ProjectTypeInputsProps {
    selectedProjectTypeIds: string[];
    setSelectedProjectTypeIds: Dispatch<SetStateAction<string[]>>;
    reportIsGenerated: boolean;
}


export const useProjectTypeInputs = ({
    selectedProjectTypeIds,
    setSelectedProjectTypeIds,
    reportIsGenerated,
}: ProjectTypeInputsProps) => {

    const { isLoading, error, data } = useProjectTypes();
    const [allProjectTypesSelected, setAllProjectTypesSelected] = useState(true);
    const [allProjectTypeIdsLength, setAllProjectTypeIdsLength] = useState<number | null>(null);


    useEffect(() => {
        if (data) {
            const projectTypeIds = data?.map(projectType => projectType?.id);

            setSelectedProjectTypeIds(projectTypeIds);
            setAllProjectTypeIdsLength(projectTypeIds?.length);
        };
    }, [data, setSelectedProjectTypeIds]);


    useEffect(() => {
        const selectedIds = selectedProjectTypeIds?.length;

        if (allProjectTypeIdsLength) {
            if (selectedIds < allProjectTypeIdsLength) {
                setAllProjectTypesSelected(false);
            } else if (selectedIds === allProjectTypeIdsLength) {
                setAllProjectTypesSelected(true);
            }
        }
    }, [allProjectTypeIdsLength, selectedProjectTypeIds?.length]);


    // fluent ui styles
    const componentStyles = {
        text: {
            fontSize: 12,
            fontStyle: 'normal',
            color: 'black',
        },
    }


    const ProjectTypeInputs = () => {

        const CheckAllCheckboxes = () => {
            if (!error) {
                const checkAllProjectTypes = () => {
                    if (allProjectTypesSelected) {
                        setAllProjectTypesSelected(false);
                        setSelectedProjectTypeIds([]);
                    } else {
                        setAllProjectTypesSelected(true);
                        const projectTypeKeys = data?.map(projectType => projectType.id);

                        if (projectTypeKeys) setSelectedProjectTypeIds(projectTypeKeys);
                    }
                };

                return (
                    <div className={styles.checkAllCheckboxes}>
                        <Checkbox
                            styles={componentStyles}
                            label={'Välj alla'}
                            checked={allProjectTypesSelected}
                            onChange={checkAllProjectTypes}
                            disabled={reportIsGenerated}
                        />
                    </div>
                );
            }

            return null;
        }


        const CheckBoxes = () => {
            if (!error) {
                const handleChange = (id: string) => {
                    if (selectedProjectTypeIds.includes(id)) {
                        setSelectedProjectTypeIds(prevState => prevState.filter(projectType => projectType !== id));
                    } else {
                        setSelectedProjectTypeIds(prevState => [...prevState, id]);
                    }
                };


                return (
                    <div className={styles.checkboxes}>
                        {data?.map((projectType, index) => {
                            const { id, name } = projectType;

                            return (
                                <div key={index} className={styles.checkbox}>
                                    <Checkbox
                                        styles={componentStyles}
                                        label={name}
                                        checked={selectedProjectTypeIds.includes(id)}
                                        onChange={() => handleChange(id)}
                                        disabled={reportIsGenerated}
                                    />
                                </div>
                            );
                        })}
                    </div>
                );
            }

            return <UnableToLoadData message={'Projekttyper kunde ej laddas'} />;
        }


        return (
            <div className={styles.sectionMargin}>
                <div className={styles.headerRow}>
                    <h5 className={styles.header}>Projekttyper</h5>

                    <CheckAllCheckboxes />
                </div>

                <CheckBoxes />
            </div>
        )
    }


    return { isLoading, ProjectTypeInputs };
}