import { apiRequestParams, getApiUrl } from "../fetchOptions";


const apiUrl = getApiUrl();

export const SHEET = {
    // todo refactor function to query
    async putConfirmPrognosis(projectId: string, accessToken: string) {
        return await fetch(`${apiUrl}/Sheet/${projectId}/Verify`,
            apiRequestParams(accessToken, 'POST')
        ).then(response => {
            // endpoint not using resultDto
            if (!response.ok) {
                throw Error('Prognos kunde ej bekräftas');
            }
            return response;
        });
    },
}