import { CellClassParams } from "ag-grid-community";

export const getRowHierarchyStyle = (params: CellClassParams) => {

    const style = { backgroundColor: '#d9dcde', fontWeight: '' };

    if (params.node.group) {
        switch (params.node.level) {
            case 0:
                style.backgroundColor = `${style.backgroundColor}90`;
                style.fontWeight = '900';
                break;
            case 1:
                style.backgroundColor = `${style.backgroundColor}60`;
                style.fontWeight = '800';
                break;
            case 2:
                style.backgroundColor = `${style.backgroundColor}30`;
                style.fontWeight = '700';
                break;
            default:
                style.backgroundColor = '';
                break;
        }
    } else {
        style.backgroundColor = '';
    }

    return style;
};