import { Dispatch, RefObject, SetStateAction, useCallback } from "react";
import { useUser } from "../../../fetchData/queries/useUser";
import ActionButton from "../../Buttons/ActionButton";
import { AgGridReact } from "ag-grid-react";
import { ScheduleEdit } from "../../../interfaces/systemSettings/RevenueRecognitionSchedule";


export const AddScheduleRowButton = (
    gridRef: RefObject<AgGridReact>,
    lastAddedRowId: number,
    changedDate: string,
    creates: ScheduleEdit[],
    setLastAddedRowId: Dispatch<SetStateAction<number>>,
    setCreates: Dispatch<SetStateAction<ScheduleEdit[]>>,
) => {

    const { data } = useUser();

    const addScheduleRow = useCallback(() => {
        if (gridRef?.current) {
            const currentDateTime = new Date().toLocaleString('sv-SV');

            const createTransaction = {
                id: lastAddedRowId,
                date: changedDate,
                setBy: data?.accountId ?? data?.email ?? data?.name,
                setTime: currentDateTime
            };

            gridRef.current.api.applyTransaction({ add: [createTransaction] });

            const newScheduleRow: ScheduleEdit = { id: lastAddedRowId, date: changedDate };
            setCreates([...creates, newScheduleRow]);

            setLastAddedRowId(lastAddedRowId - 1);
        }
    }, [changedDate, creates, gridRef, lastAddedRowId, setCreates, setLastAddedRowId,
        data?.accountId, data?.email, data?.name]);


    return (
        <ActionButton
            text={'Lägg till rad med valt datum'}
            handleOnClick={addScheduleRow}
            buttonStyles={['defaultButton']}
        />)
}