
type Methods = 'GET' | 'PUT' | 'POST' | 'DELETE';


export const apiRequestParams = <T>(
    accessToken: string,
    method: Methods,
    payload?: T,
    signal?: AbortSignal | null
) => {
    let headers = new Headers();
    let bearer = "Bearer " + accessToken;

    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json")


    interface Params {
        method: Methods;
        headers: Headers;
        body?: string;
        signal?: AbortSignal;
    }

    let params: Params =
    {
        method: method,
        headers: headers,
    };


    if (signal) params.signal = signal;

    if (method !== 'GET' && payload) params.body = JSON.stringify(payload);


    return params;
}


// todo remove when regular fetch migrated to react-query
export const getApiUrl = () => {

    const apiUrl = process.env.REACT_APP_API_URL!;

    return apiUrl;
}