import { GetContextMenuItemsParams } from "ag-grid-community";
import { AggTranslations } from "../../../interfaces/grids/performance/AggTranslation";
import { Dispatch, SetStateAction, useCallback } from "react";
import { getContextMenu } from "../../../utils/getContextMenu";

interface GetContextMenuItems {
    totals: AggTranslations | undefined;
    setIsAccountSpecificationModalOpen: Dispatch<SetStateAction<boolean>>;
    setModalAccountId: Dispatch<SetStateAction<string>>;
    setModalOutcomeDate: Dispatch<SetStateAction<string>>;
}


export const useGetContextMenuItems = ({
    totals,
    setIsAccountSpecificationModalOpen,
    setModalAccountId,
    setModalOutcomeDate,
}: GetContextMenuItems) => {
    const getContextMenuItems = useCallback((params: GetContextMenuItemsParams) => {
        return getContextMenu(params, setIsAccountSpecificationModalOpen, setModalAccountId, setModalOutcomeDate, totals);
    }, [setIsAccountSpecificationModalOpen, setModalAccountId, setModalOutcomeDate, totals]);

    return getContextMenuItems;
}