import { Dispatch, SetStateAction, useEffect } from 'react';
import styles from './SubHeaders.module.css';
import { NumberStringPairs } from '../../../interfaces/systemSettings/AccountTemplate';
import PivotButton from '../../Buttons/PivotButton/PivotButton';
import ElementAccess from '../../../AccessControl/ElementAccess';
import { useUser } from '../../../fetchData/queries/useUser';
import { Scopes } from '../../../interfaces/UserPermission';
import { InfoTooltip } from '../../InfoTooltip/InfoTooltip';
import { AccountTemplateTooltip } from '../../InfoTooltip/Tooltips/AccountTemplateTooltip';
import { ResCalcSchedulerTooltip } from '../../InfoTooltip/Tooltips/ResCalcSchedulerTooltip';

interface SystemSettingsSubHeaderProps {
  selectedPivot: number;
  setSelectedPivot: Dispatch<SetStateAction<number>>;
}

const SystemSettingsSubHeader = ({
  selectedPivot,
  setSelectedPivot
}: SystemSettingsSubHeaderProps) => {

  const pivotItems: NumberStringPairs = {
    0: 'Logg resultatavräkning',
    1: 'Schemaläggning resultatavräkning',
    2: 'Projektekonomiarksmallar',
    3: 'Övriga inställningar',
  };

  const pivotScopes: NumberStringPairs = {
    0: 'accounting_read',
    1: 'scheduling_crud',
    2: 'account_templates',
    3: 'system_settings'
  }

  const { data } = useUser();

  const isMatchingValidScope = (key: number) => {
    if (data) {
      const scope = Object.keys(data?.scopes)
        .find(scopeId => scopeId === pivotScopes[key] && data?.scopes[scopeId as keyof Scopes] === true);

      return scope;
    }
  };

  const defaultSelectedPivotKey = Object.keys(pivotScopes).map(Number)
    .find(key => pivotScopes[key] === isMatchingValidScope(key));


  useEffect(() => {
    if (!(typeof defaultSelectedPivotKey === 'undefined')) {
      setSelectedPivot(defaultSelectedPivotKey);
    }
  }, [defaultSelectedPivotKey, setSelectedPivot]);


  const tooltipContent = () => {
    let content: JSX.Element | undefined = undefined;

    switch (selectedPivot) {
      case 1:
        content = <ResCalcSchedulerTooltip />
        break;
      case 2:
        content = <AccountTemplateTooltip />
        break;
      default:
        break;
    }

    return content;
  };


  return (
    <div className={styles.subBanner}>
      <div className={`${styles.flexContainer} ${styles.flexStart}`}>
        <h4 className={styles.subHeader}>{pivotItems[selectedPivot]}</h4>
        <InfoTooltip content={tooltipContent()} />
      </div>

      <div className={`${styles.flexContainer} ${styles.flexEnd}`}>
        {Object.entries(pivotItems).map((item, index) =>
          <ElementAccess key={index} scopeKey={pivotScopes[index]}>
            <PivotButton
              selectedPivot={selectedPivot}
              onClick={setSelectedPivot}
              pivotItem={item}
            />
          </ElementAccess>
        )}
      </div>
    </div>)
};

export default SystemSettingsSubHeader;
