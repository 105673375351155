import { MessageBar, MessageBarType } from "@fluentui/react";
import { useEffect, useState } from "react";
import { useSystemMessage } from "../../../../fetchData/queries/settings/useSystemMessage";

export const SystemBar = () => {
    const systemMessage = useSystemMessage().data;
    const message = systemMessage?.message;
    const type = systemMessage?.type;


    const [showSystemMessage, setShowSystemMessage] = useState<boolean>(true);


    useEffect(() => {
        if (message && message?.length > 0) {
            setShowSystemMessage(true);
        } else {
            setShowSystemMessage(false);
        }
    }, [message])


    const messageBarType = () => {
        switch (type) {
            case 1:
                return 'warning';
            case 2:
                return 'severeWarning';
            default:
                return 'info';
        }
    }



    if (showSystemMessage && message) {
        return (
            <MessageBar
                messageBarType={MessageBarType[messageBarType()]}
                isMultiline={false}
                onDismiss={() => setShowSystemMessage(false)}
                dismissButtonAriaLabel="Close"
            >
                {message}
            </MessageBar>
        );
    }


    return <></>;
}