import styles from '../InfoTooltip.module.css';

export const ForecastResCalcTooltip = () => (
    <div className={styles.infoTooltip} data-testid="InfoTooltip">
        <h2 className={styles.infoTooltipHeader}>Information</h2>

        <div className={styles.infoHeader}>Perioden baseras på:</div>
        <div className={styles.infoContainer}>
            <div className={styles.projectStageColorBox} />
            <div className={styles.projectStageDescription}>Prognos</div>

            <div className={`${styles.projectStageColorBox} ${styles.isOutcome}`} />
            <div className={styles.projectStageDescription}>Utfall</div>
        </div>
    </div>
);