import { RefObject, useCallback, useState } from 'react';
import TooltipIconButton from '../../../../Buttons/TooltipIconButton/TooltipIconButton';
import styles from './ButtonRow.module.css';
import { Column } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useEditingContext } from '../../../../../context/EditingContext';
import { useSheetContext } from '../../../../../context/SheetContext';

interface ButtonRowProps {
  cashflowGridRef: RefObject<AgGridReact>;
  performanceGridRef: RefObject<AgGridReact>;
}

const ButtonRow = ({
  cashflowGridRef,
  performanceGridRef,
}: ButtonRowProps) => {

  const { monthColumnIds } = useSheetContext();

  const { emptyRowsHiddenToggle, setEmptyRowsHiddenToggle } = useEditingContext();
  const { cashflowRows, performanceRows } = useSheetContext();

  const [totalsToggle, setTotalsToggle] = useState<boolean>(false);
  const [monthsToggle, setMonthsToggle] = useState<boolean>(false);
  const [budgetToggle, setBudgetToggle] = useState<boolean>(false);
  const [differenceToggle, setDifferenceToggle] = useState<boolean>(false);
  const [commentToggle, setCommentToggle] = useState<boolean>(false);
  const [outcomeToggle, setOutcomeToggle] = useState<boolean>(false);
  const [prognosisToggle, setPrognosisToggle] = useState<boolean>(false);

  const expandAllRows = useCallback(() => {
    if (cashflowGridRef && performanceGridRef) {
      cashflowGridRef.current!.api.expandAll();
      performanceGridRef.current!.api.expandAll();
    }
  }, [cashflowGridRef, performanceGridRef]);

  const collapseAllRows = useCallback(() => {
    if (cashflowGridRef && performanceGridRef) {
      cashflowGridRef.current!.api.collapseAll();
      performanceGridRef.current!.api.collapseAll();
    }
  }, [cashflowGridRef, performanceGridRef]);

  const ensureCurrentDateColVisible = useCallback((colType: string) => {
    if (cashflowGridRef) {
      const todayYearMonth = new Date().toISOString().slice(0, 7);
      const todayColumnId: string = `${colType}_${todayYearMonth}-01T00:00:00`;
      cashflowGridRef.current!.api.ensureColumnVisible(todayColumnId, 'start');
    }
  }, [cashflowGridRef])

  const toggleColumn = useCallback((columnName: string) => {
    if (cashflowGridRef && performanceGridRef) {
      const cashflowGridRefColApi = cashflowGridRef.current!.columnApi;
      const renderedColIds = cashflowGridRefColApi.getAllDisplayedVirtualColumns();
      const pinned = cashflowGridRefColApi.getDisplayedLeftColumns();
      const renderedMonthCols = renderedColIds.slice(0, renderedColIds.length - pinned.length);

      switch (columnName) {
        case 'budget':
          setBudgetToggle(!budgetToggle);
          break;
        case 'totalOutcome':
          setOutcomeToggle(!outcomeToggle);
          cashflowGridRefColApi.setColumnsVisible(monthColumnIds.outcome, outcomeToggle);

          if (renderedMonthCols.length > 0) {
            cashflowGridRef.current!.api.ensureColumnVisible(renderedMonthCols[3].getColId().replace('outcome', 'prognosis'), 'start');
          } else {
            ensureCurrentDateColVisible('outcome')
          }
          break;
        case 'totalPrognosis':
          setPrognosisToggle(!prognosisToggle);
          cashflowGridRefColApi.setColumnsVisible(monthColumnIds.prognosis, prognosisToggle);

          if (renderedMonthCols.length > 0) {
            cashflowGridRef.current!.api.ensureColumnVisible(renderedMonthCols[3].getColId(), 'start');
          } else {
            ensureCurrentDateColVisible('prognosis')
          }
          break;
        case 'difference':
          setDifferenceToggle(!differenceToggle);
          break;
        case 'comment':
          setCommentToggle(!commentToggle);
          break;
      }

      const performanceGridRefColApi = performanceGridRef?.current!.columnApi;

      const cashflowColVisibility: boolean = cashflowGridRefColApi.getColumn(columnName)?.isVisible() ?? false;
      const performanceColVisibility: boolean = performanceGridRefColApi?.getColumn(columnName)?.isVisible() ?? false;

      cashflowGridRefColApi.setColumnVisible(columnName, !cashflowColVisibility);
      performanceGridRefColApi?.setColumnVisible(columnName, !performanceColVisibility);
    }
  }, [budgetToggle, cashflowGridRef, commentToggle, differenceToggle, ensureCurrentDateColVisible, monthColumnIds.outcome,
    monthColumnIds.prognosis, outcomeToggle, performanceGridRef, prognosisToggle]);

  const toggleTotalColumnsPinned = useCallback(() => {
    if (cashflowGridRef) {
      const cashflowGridRefColApi = cashflowGridRef.current!.columnApi;
      const totalsColumns = ['budget', 'totalOutcome', 'totalPrognosis', 'difference', 'comment'];

      cashflowGridRefColApi.setColumnsPinned(totalsColumns, totalsToggle);

      const allColumnIds: string[] = [];
      cashflowGridRef.current!.columnApi.getColumns()!.forEach((column: Column) => {
        allColumnIds.push(column.getId());
      });
      cashflowGridRef.current!.columnApi.autoSizeColumns(allColumnIds, true);

      setTotalsToggle(!totalsToggle);
    }
  }, [cashflowGridRef, totalsToggle]);

  const toggleMonthColumns = useCallback(() => {
    if (cashflowGridRef) {
      setMonthsToggle(!monthsToggle);
      const cashflowGridRefColApi = cashflowGridRef.current!.columnApi;
      cashflowGridRefColApi.setColumnsVisible(monthColumnIds.outcome, monthsToggle);
      cashflowGridRefColApi.setColumnsVisible(monthColumnIds.prognosis, monthsToggle);
    }
  }, [cashflowGridRef, monthColumnIds.outcome, monthColumnIds.prognosis, monthsToggle]);

  const toggleEmptyRows = useCallback(() => {
    if (cashflowGridRef && performanceGridRef && cashflowRows && performanceRows) {
      if (emptyRowsHiddenToggle) {
        cashflowGridRef.current!.api.setRowData(cashflowRows?.prefixedData);
        performanceGridRef.current!.api.setRowData(performanceRows?.prefixedData);
      } else {
        cashflowGridRef.current!.api.setRowData(cashflowRows?.emptyRowsHidden);
        performanceGridRef.current!.api.setRowData(performanceRows?.emptyRowsHidden);
      }
      setEmptyRowsHiddenToggle(!emptyRowsHiddenToggle);
    }
  }, [cashflowGridRef, cashflowRows, emptyRowsHiddenToggle, performanceGridRef, performanceRows, setEmptyRowsHiddenToggle]);


  return (
    <div className={styles.ButtonRow} data-testid="ButtonRow">
      <div className={styles.ButtonBorder} />
      <TooltipIconButton
        tooltipContent={'Expandera alla rader'}
        tooltipId={'expandAllRows'}
        buttonIcon={'ChevronDown'}
        clickFunction={expandAllRows}
      />
      <div className={styles.ButtonBorder} />
      <TooltipIconButton
        tooltipContent={'Stäng alla rader'}
        tooltipId={'collapseAllRows'}
        buttonIcon={'ChevronRight'}
        clickFunction={collapseAllRows}
      />
      <div className={styles.ButtonBorder} />
      <TooltipIconButton
        tooltipContent={monthsToggle ? 'Visa månadskolumner' : 'Dölj månadskolumner'}
        tooltipId={'toggleMonthCols'}
        buttonIcon={'EventDate'}
        clickFunction={toggleMonthColumns}
        clickState={monthsToggle}
      />
      <div className={styles.ButtonBorder} />
      <TooltipIconButton
        tooltipContent={totalsToggle ? 'Lås totalkolumner' : 'Släpp totalkolumner'}
        tooltipId={'toggleTotalCols'}
        buttonIcon={'ColumnRightTwoThirds'}
        clickFunction={toggleTotalColumnsPinned}
        clickState={totalsToggle}
        disabled={monthsToggle}
      />
      <div className={styles.ButtonBorder} />
      <TooltipIconButton
        tooltipContent={emptyRowsHiddenToggle ? 'Visa tomma rader' : 'Dölj tomma rader'}
        tooltipId={'toggleEmptyRows'}
        buttonIcon={'DuplicateRow'}
        clickFunction={toggleEmptyRows}
        clickState={emptyRowsHiddenToggle} />
      <div className={styles.ButtonBorder} />
      <TooltipIconButton
        tooltipContent={budgetToggle ? 'Visa budgetkolumn' : 'Dölj budgetkolumn'}
        tooltipId={'toggleBudgetCol'}
        buttonLetter={'B'}
        clickFunction={() => toggleColumn('budget')}
        clickState={budgetToggle}
      />
      <div className={styles.ButtonBorder} />
      <TooltipIconButton
        tooltipContent={prognosisToggle ? 'Visa prognoskolumn' : 'Dölj prognoskolumn'}
        tooltipId={'togglePrognosisCol'} buttonLetter={'P'}
        clickFunction={() => toggleColumn('totalPrognosis')}
        clickState={prognosisToggle}
      />
      <div className={styles.ButtonBorder} />
      <TooltipIconButton
        tooltipContent={outcomeToggle ? 'Visa utfallskolumn' : 'Dölj utfallskolumn'}
        tooltipId={'toggleOutcomeCol'}
        buttonLetter={'U'}
        clickFunction={() => toggleColumn('totalOutcome')}
        clickState={outcomeToggle}
      />
      <div className={styles.ButtonBorder} />
      <TooltipIconButton
        tooltipContent={differenceToggle ? 'Visa diffkolumn' : 'Dölj diffkolumn'}
        tooltipId={'toggleDiffCol'}
        buttonLetter={'D'}
        clickFunction={() => toggleColumn('difference')}
        clickState={differenceToggle}
      />
      <div className={styles.ButtonBorder} />
      <TooltipIconButton
        tooltipContent={commentToggle ? 'Visa kommentarskolumn' : 'Dölj kommentarskolumn'}
        tooltipId={'toggleCommentCol'}
        buttonLetter={'K'}
        clickFunction={() => toggleColumn('comment')}
        clickState={commentToggle}
      />
      <div className={styles.ButtonBorder} />
    </div>
  );
};

export default ButtonRow;
