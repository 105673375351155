import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./../../authConfig";
import { PrimaryButton } from "@fluentui/react";
import { IPublicClientApplication } from "@azure/msal-browser";

function handleLogin(instance: IPublicClientApplication) {
  instance.loginRedirect(loginRequest).catch((e: Error) => {
    console.error(e);
  });
}

export const SignInButton = () => {
  const { instance } = useMsal();

  return (
    <PrimaryButton
      data-testid="SignInBtn"
      onClick={() => handleLogin(instance)}>
      Logga in
    </PrimaryButton>
  );
}